












































import Vue from "vue";
import { MfaProvider, providerTypes } from "@/models/mfaProvider";
import VaNotification from "@/components/molecules/VaNotification.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";

export default Vue.extend({
  name: "MfaLogin",
  components: {
    VaTextField,
    VaButton,
    VaNotification,
  },
  mixins: [validationMixin],
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      verificationCode: "",
    };
  },
  async beforeMount() {
    await this.$store.dispatch("mfa/fetchProvidersChallenge", this.token);
  },
  methods: {
    getTitle(type: number): string {
      const names = {
        [this.types.email]: "email",
        [this.types.authenticator]: "authenticator",
        [this.types.backup]: "backup code",
      };
      return `Use ${names[type]} provider`;
    },
    async onSubmit(): Promise<void> {
      await this.$store.dispatch("mfa/mfaLogin", {
        token: this.token,
        code: this.verificationCode,
      });
    },
  },
  computed: {
    enabledProviders(): MfaProvider[] {
      return this.$store.getters["mfa/enabledProviders"];
    },
    types(): Record<string, number> {
      return providerTypes;
    },
    selectedProvider(): MfaProvider {
      return this.$store.getters["mfa/selectedProvider"];
    },
  },
});
