var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-6" },
    [
      _c(
        "v-chip",
        { attrs: { label: "", large: true, "x-large": false } },
        [
          _c("va-icon", {
            staticClass: "mr-4",
            attrs: { icon: "vi vi-c-warning" }
          }),
          _vm._v(" " + _vm._s(_vm.$t("common.noData")) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }