


























import Vue from "vue";

export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: false,
    },
    square: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      validator(value) {
        return ["l", "m", "s", "xs"].includes(value);
      },
    },
  },
  computed: {
    computedColor(): string {
      if (this.$store.getters["app/darkTheme"]) {
        if (!this.color) {
          return "secondary darken-4";
        }
        switch (this.color) {
          case "primary":
            return "primary darken-1";
          case "secondary":
            return "secondary darken-1";
          case "success":
            return "success darken-1";
          case "warning":
            return "warning darken-1";
          case "error":
            return "error darken-1";
          default:
            return this.color;
        }
        // return this.color ? this.color : "secondary darken-4";
      }
      return this.color;
    },
    colorClass(): string {
      switch (this.color) {
        case "primary":
          return "icon-primary";
        case "secondary":
          return "icon-secondary";
        case "accent":
          return "icon-accent";
        case "success":
          return "icon-success";
        case "warning":
          return "icon-warning";
        case "error":
          return "icon-error";
        default:
          return "";
      }
    },
    sizeClass(): string {
      switch (this.size) {
        case "xs":
          return "icon-xsmall";
        case "s":
          return "icon-small";
        case "m":
          return "icon-medium";
        case "l":
          return "icon-large";
        default:
          return "";
      }
    },
  },
});
