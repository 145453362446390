
























import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  components: {
    VaIcon,
  },
  props: {
    type: {
      type: String,
      required: false,
      validator(value) {
        return ["info", "success", "warning", "error"].includes(value);
      },
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedType(): { color: string; icon: string } {
      switch (this.type) {
        case "info":
          return {
            color: "primary",
            icon: "vi vi-c-info",
          };
        case "success":
          return {
            color: "success",
            icon: "vi vi-c-check",
          };
        case "warning":
          return {
            color: "warning",
            icon: "vi vi-c-warning",
          };
        case "error":
          return {
            color: "error",
            icon: "vi vi-c-warning",
          };
        default:
          return {
            color: "secondary",
            icon: "vi vi-c-info",
          };
      }
    },
  },
});
