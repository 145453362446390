import axios from "axios";
import apiService from "./apiService";
import File, { FileFormatted, FileType } from "@/models/file";
import FileUtil from "@/utils/fileUtil";
import i18n from "@/i18n";
import store from "@/store";
import logger from "@/services/loggerService";
import tenantService from "@/services/tenantService";

export default {
  async latestVersion(fastChannel = false): Promise<string> {
    const res = await axios.get(apiService.file.latestVersionUrl());
    const url = fastChannel ? res.data.fastChannelUrl : res.data.url;
    if (!url) {
      logger.warn(
        `no download url for current tenant: ${tenantService.getTenant()}`
      );
      await store.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("software.noAvailableUrl"),
          type: "error",
        },
        { root: true }
      );
    }
    return url || "";
  },
  async upload(file: File, type = FileType.UNKNOWN): Promise<FileFormatted> {
    const formData = new FormData();
    formData.append("File", file);
    if (type !== undefined && type in FileType) {
      formData.append("FileType", type.toString());
    }
    const res = await axios.post(apiService.file.baseUrl(), formData);
    return File.deserialize(res.data);
  },
  async log(
    startDate: number,
    endDate: number,
    filterGroup: Array<number>,
    filters: Array<number>,
    roomId: string,
    userId: string
  ): Promise<void> {
    const res = await axios.post(
      apiService.file.baseUrl() + "/log",
      {
        startDate: startDate,
        endDate: endDate,
        trackerGroup: filterGroup,
        trackerLogType: filters,
        roomId: roomId,
        userId: userId,
      },
      {
        responseType: "blob",
        headers: {
          "Access-Control-Expose-Headers": "Content-Disposition",
        },
      }
    );
    const filename = FileUtil.getFileName(res) || "Log_" + Date.now() + ".zip";
    FileUtil.download(res.data, filename);
  },
};
