var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("light-switcher", { staticClass: "fixed right-0 ma-3" }),
      _c(
        "div",
        {
          staticClass: "d-flex align-center flex-column",
          attrs: { id: "auth" }
        },
        [
          _c("img", {
            staticClass: "logo mb-6 mt-auto",
            attrs: { src: _vm.logo, alt: "Valarea logo" }
          }),
          _c(
            "div",
            { staticClass: "mb-auto auth-container" },
            [_vm._t("default")],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }