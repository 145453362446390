var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.providerToEnable && _vm.providerToEnable.setup
        ? _c(
            "va-card",
            { staticClass: "pa-6" },
            [
              _c(
                "v-form",
                {
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.enableMFA },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-end",
                      attrs: {
                        "x-small": "",
                        fab: "",
                        depressed: "",
                        color: "secondary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$store.commit(
                            "mfa/setProviderToEnable",
                            undefined
                          )
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("div", { staticClass: "va-title" }, [
                    _vm._v(_vm._s(_vm.$t("accountMFA.title")))
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("div", { staticClass: "va-subtitle-s" }, [
                            _vm._v(
                              " 1 - " +
                                _vm._s(_vm.$t("accountMFA.step1.title")) +
                                " "
                            )
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("accountMFA.step1.content")))
                          ]),
                          _c("qrcode-vue", {
                            staticClass: "d-flex justify-center my-6",
                            attrs: {
                              value: _vm.providerToEnable.setup.deepLink,
                              size: "200"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center mb-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("accountMFA.step1.tip")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-bold d-flex justify-center"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.providerToEnable.setup.encodedSecret
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-6 mt-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("div", { staticClass: "va-subtitle-s" }, [
                            _vm._v(
                              " 2 - " +
                                _vm._s(_vm.$t("accountMFA.step2.title")) +
                                " "
                            )
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("accountMFA.step1.content")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex mt-6" },
                            [
                              _c("va-text-field", {
                                attrs: {
                                  dense: "",
                                  label: _vm.$t("common.code"),
                                  rules: [_vm.required]
                                },
                                model: {
                                  value: _vm.code,
                                  callback: function($$v) {
                                    _vm.code = $$v
                                  },
                                  expression: "code"
                                }
                              }),
                              _c("va-button", {
                                staticClass: "ml-6 mt-1",
                                attrs: {
                                  submit: "",
                                  disabled: !_vm.valid,
                                  loading: _vm.loading,
                                  cta: _vm.$t("common.enable")
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }