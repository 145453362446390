












import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import store from "@/store";

export default Vue.extend({
  components: {
    VaIcon,
  },
  props: {
    color: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      validator(value) {
        return ["l", "m", "s", "xs"].includes(value);
      },
    },
  },
  computed: {
    icon(): string {
      return this.$store.getters["session/darkMode"]
        ? "vi vi-bulb-61-1"
        : "vi vi-bulb-61";
    },
  },
  methods: {
    toggleLight() {
      store.commit("session/toggleDarkMode");
    },
  },
});
