var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Snackbar")]),
      _c("h3", [_vm._v("Colors")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-button", {
            attrs: { cta: "base" },
            on: {
              click: function() {
                _vm.baseSnackbar = !_vm.baseSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              model: {
                value: _vm.baseSnackbar,
                callback: function($$v) {
                  _vm.baseSnackbar = $$v
                },
                expression: "baseSnackbar"
              }
            },
            [
              _vm._v(" Error msg with "),
              _c("em", [_vm._v("some "), _c("b", [_vm._v("formatting")])]),
              _vm._v(" without icon ")
            ]
          ),
          _c("va-button", {
            attrs: { cta: "info" },
            on: {
              click: function() {
                _vm.infoSnackbar = !_vm.infoSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              attrs: { type: "info" },
              model: {
                value: _vm.infoSnackbar,
                callback: function($$v) {
                  _vm.infoSnackbar = $$v
                },
                expression: "infoSnackbar"
              }
            },
            [
              _vm._v(" Info msg with "),
              _c("em", [_vm._v("some "), _c("b", [_vm._v("formatting")])])
            ]
          ),
          _c("va-button", {
            attrs: { cta: "success" },
            on: {
              click: function() {
                _vm.successSnackbar = !_vm.successSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              attrs: { type: "success" },
              model: {
                value: _vm.successSnackbar,
                callback: function($$v) {
                  _vm.successSnackbar = $$v
                },
                expression: "successSnackbar"
              }
            },
            [
              _vm._v(" Success msg with "),
              _c("em", [_vm._v("some "), _c("b", [_vm._v("formatting")])])
            ]
          ),
          _c("va-button", {
            attrs: { cta: "error" },
            on: {
              click: function() {
                _vm.errorSnackbar = !_vm.errorSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              attrs: { type: "error" },
              model: {
                value: _vm.errorSnackbar,
                callback: function($$v) {
                  _vm.errorSnackbar = $$v
                },
                expression: "errorSnackbar"
              }
            },
            [
              _vm._v(" Error msg with "),
              _c("em", [_vm._v("some "), _c("b", [_vm._v("formatting")])])
            ]
          ),
          _c("va-button", {
            attrs: { cta: "warning" },
            on: {
              click: function() {
                _vm.warningSnackbar = !_vm.warningSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              attrs: { type: "warning" },
              model: {
                value: _vm.warningSnackbar,
                callback: function($$v) {
                  _vm.warningSnackbar = $$v
                },
                expression: "warningSnackbar"
              }
            },
            [
              _vm._v(" Warning msg with "),
              _c("em", [_vm._v("some "), _c("b", [_vm._v("formatting")])])
            ]
          )
        ],
        1
      ),
      _c("h3", [_vm._v("Other settings")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-button", {
            attrs: { cta: "undismissable" },
            on: {
              click: function() {
                _vm.undismissableSnackbar = !_vm.undismissableSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              attrs: { dismissable: false, type: "info" },
              model: {
                value: _vm.undismissableSnackbar,
                callback: function($$v) {
                  _vm.undismissableSnackbar = $$v
                },
                expression: "undismissableSnackbar"
              }
            },
            [_vm._v(" message without dismiss button ")]
          ),
          _c("va-button", {
            attrs: { cta: "custom timeout" },
            on: {
              click: function() {
                _vm.customTimeSnackbar = !_vm.customTimeSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              attrs: { timeout: 5000, type: "info" },
              model: {
                value: _vm.customTimeSnackbar,
                callback: function($$v) {
                  _vm.customTimeSnackbar = $$v
                },
                expression: "customTimeSnackbar"
              }
            },
            [_vm._v(" message with custom timeout ")]
          ),
          _c("va-button", {
            attrs: { cta: "permanent" },
            on: {
              click: function() {
                _vm.permanentSnackbar = !_vm.permanentSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              attrs: { timeout: -1, type: "info" },
              model: {
                value: _vm.permanentSnackbar,
                callback: function($$v) {
                  _vm.permanentSnackbar = $$v
                },
                expression: "permanentSnackbar"
              }
            },
            [_vm._v(" permanent message ")]
          ),
          _c("va-button", {
            attrs: { cta: "long text" },
            on: {
              click: function() {
                _vm.longSnackbar = !_vm.longSnackbar
              }
            }
          }),
          _c(
            "va-snackbar",
            {
              attrs: { type: "info" },
              model: {
                value: _vm.longSnackbar,
                callback: function($$v) {
                  _vm.longSnackbar = $$v
                },
                expression: "longSnackbar"
              }
            },
            [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }