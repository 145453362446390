export interface BuildData {
  name: string;
  url: string;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export const buildDataDeserialize = (input: any): BuildData => {
  return {
    name: input.buildName || "",
    url: input.buildUrl || "",
  };
};
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export const buildDataSerialize = (input: BuildData): any => {
  return {
    buildName: input.name,
    buildUrl: input.url,
  };
};
