var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("header", [
          _c("h3", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.$t("common.roomCapacity")))
          ])
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "section",
            { attrs: { id: "performance-range" } },
            [
              _c(
                "va-info-row",
                { attrs: { label: _vm.$t("common.range") } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "range-btn",
                                      attrs: {
                                        small: "",
                                        text: "",
                                        disabled: _vm.loading
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(" " + _vm._s(_vm.range.label) + " "),
                                  _vm.loading
                                    ? _c("v-progress-circular", {
                                        staticClass: "ml-1",
                                        attrs: {
                                          color: "accent",
                                          indeterminate: "",
                                          size: "14",
                                          width: "2"
                                        }
                                      })
                                    : _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-chevron-down")
                                      ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        _vm._l(_vm.ranges, function(r, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              on: {
                                click: function($event) {
                                  _vm.range = r
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v(_vm._s(r))])],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-6" }),
          _c(
            "section",
            { attrs: { id: "capacity-chart" } },
            [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center align-center pa-10" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "accent" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.loading && _vm.capacityDatasets
                ? _c("capacity-chart", {
                    attrs: {
                      data: _vm.capacityDatasets,
                      "max-persons": _vm.capacity
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }