
































































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { Group } from "@/models/group";
import PoliciesChooser from "@/components/organisms/policies/PoliciesChooser.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaBack from "@/components/molecules/VaBack.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import GeocodingService from "@/services/geocodingService";
import RoomsListTable from "@/components/organisms/rooms/RoomList.vue";
import RoomsMap from "@/components/organisms/RoomsMap.vue";
import { Marker } from "@/models/address";
import mapService from "@/services/mapService";

export default Vue.extend({
  name: "Groups",
  mixins: [validationMixin],
  components: {
    RoomsMap,
    RoomsListTable,
    VaTextField,
    VaCard,
    VaBack,
    VaButton,
    MainColumns,
    PoliciesChooser,
  },
  async beforeMount() {
    this.$store.dispatch("group/fetchGroup", this.$route.params.id);
  },
  computed: {
    group(): Group | undefined {
      return this.$store.getters["group/currentGroup"];
    },
    marker(): Marker[] {
      if (this.$store.getters["group/currentGroup"]) {
        return [
          mapService.group2Marker(
            this.$store.getters["group/currentGroup"]
          ) as Marker,
        ];
      }
      return [];
    },
  },
  watch: {
    group: function () {
      this.$nextTick(() => {
        const el = document.getElementsByName(
          "groupAddress"
        )[0] as HTMLInputElement;
        // eslint-disable-next-line no-undef
        const autocomplete = new google.maps.places.Autocomplete(el);
        // eslint-disable-next-line no-undef
        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          this.onChange
        );
        setTimeout(() => {
          el.placeholder = "";
        }, 100);
      });
    },
  },
  methods: {
    onAddressChanged() {
      if (!this.group) return;
      this.group.address.isLocationVerified = false;
    },
    async onChange(): Promise<void> {
      if (!this.group) return;
      const addressInput = document.getElementsByName(
        "groupAddress"
      )[0] as HTMLInputElement;
      const address = await GeocodingService.getCoordinates(addressInput.value);
      this.group.address.formattedAddress = address.formatted_address;
      this.group.address.isLocationVerified = true;
      this.group.address.coordinates.lat = Number(
        address.geometry.location.lat
      );
      this.group.address.coordinates.lng = Number(
        address.geometry.location.lng
      );
    },
  },
});
