import {
  FilterBy,
  HeaderTable,
  OptionsTable,
  RawFilterBy,
  SortBy,
} from "@/models/table";

//TODO: CANDIDATE TO DELETION
export default {
  buildSortBy(options: OptionsTable): Array<SortBy> | null {
    if (!options.sortBy || options.sortBy.length === 0) return null;
    const sortBy: Array<SortBy> = [];
    options.sortBy.forEach((field: string, index: number) => {
      sortBy.push({
        field: field,
        direction: options.sortDesc[index] ? "desc" : "asc",
      } as SortBy);
    });
    return sortBy;
  },
  buildFilterBy(
    search: string,
    headers: Array<HeaderTable>,
    extraFilters: Array<RawFilterBy>
  ): { values: Array<FilterBy> } | null {
    if (!search && extraFilters.length === 0) return null;
    const filterBy: { values: Array<FilterBy> } = {
      values: [] as Array<FilterBy>,
    };

    if (search) {
      headers.forEach((header) => {
        if (header.filterable) {
          filterBy.values.push({
            field: header.value,
            value: search,
          } as FilterBy);
        }
      });
    }
    if (extraFilters) {
      extraFilters.forEach((filter) => {
        filter.values.forEach((value) => {
          if (value !== null) {
            filterBy.values.push({
              field: filter.field,
              value,
            } as FilterBy);
          }
        });
      });
    }
    return filterBy.values ? filterBy : null;
  },
};
