// export interface Tag {
//   tagId: string;
//   name: string;
// }

import i18n from "@/i18n";
import {
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";

// import { Group, groupConfig } from "@/models/group";

/** ------------ new models ------------ **/

export interface Tag {
  id?: string;
  name: string;
}

export const tagConfig = {
  id: {
    dtoName: "tagId",
    sortable: false,
    filterable: false,
    label: i18n.t("common.id"),
    hideColumn: true,
  },
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
} as TableConfig;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function TagDeserializer(input: any): Tag {
  return {
    id: input.tagId,
    name: input.name,
  };
}

export function TagSerializer(group: Tag): unknown {
  return {
    tagId: group.id,
    name: group.name,
  };
}

export function TagSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, tagConfig),
    filterBy: { values: FilterBySerializer(tagConfig, searchString) },
  };
}
