import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

/**
 * Return messages for each json locale file
 * @returns {LocaleMessages}
 */
function getLocaleMessages(): LocaleMessages {
  const locales = require.context(
    // `./${process.env.VUE_APP_TENANT}`,
    `./translations`,
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    const locale = matched && matched.length > 1 ? matched[1] : undefined;
    if (locale) {
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const options = {
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: getLocaleMessages(),
};

export default new VueI18n(options);
