var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Switch")]),
      _c("h3", [_vm._v("Label")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-switch", {
            attrs: { text: "label" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { text: "value: " + _vm.val },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          })
        ],
        1
      ),
      _c("h3", [_vm._v("Color")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-switch", {
            attrs: { color: "primary lighten-4" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "primary lighten-3" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "primary lighten-2" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "primary lighten-1" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "primary" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "primary darken-1" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "primary darken-2" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "primary darken-3" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "primary darken-4" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-switch", {
            attrs: { color: "secondary lighten-4" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "secondary lighten-3" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "secondary lighten-2" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "secondary lighten-1" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "secondary" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "secondary darken-1" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "secondary darken-2" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "secondary darken-3" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "secondary darken-4" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-switch", {
            attrs: { color: "success lighten-4" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "success lighten-3" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "success lighten-2" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "success lighten-1" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "success" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "success darken-1" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "success darken-2" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "success darken-3" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _c("va-switch", {
            attrs: { color: "success darken-4" },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }