var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user.company
    ? _c(
        "div",
        [
          _c(
            "v-form",
            {
              ref: "forgotPasswordForm",
              attrs: { "lazy-validation": "", onSubmit: "return false;" },
              on: { submit: _vm.onSubmit },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: { rules: [_vm.required], label: _vm.$t("common.name") },
                model: {
                  value: _vm.user.company.name,
                  callback: function($$v) {
                    _vm.$set(_vm.user.company, "name", $$v)
                  },
                  expression: "user.company.name"
                }
              }),
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: { label: _vm.$t("common.legalName") },
                model: {
                  value: _vm.user.company.legalName,
                  callback: function($$v) {
                    _vm.$set(_vm.user.company, "legalName", $$v)
                  },
                  expression: "user.company.legalName"
                }
              }),
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: { label: _vm.$t("common.address") },
                model: {
                  value: _vm.user.company.address,
                  callback: function($$v) {
                    _vm.$set(_vm.user.company, "address", $$v)
                  },
                  expression: "user.company.address"
                }
              }),
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: { label: _vm.$t("common.phone") },
                model: {
                  value: _vm.user.company.telephone,
                  callback: function($$v) {
                    _vm.$set(_vm.user.company, "telephone", $$v)
                  },
                  expression: "user.company.telephone"
                }
              }),
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: { label: _vm.$t("common.vat") },
                model: {
                  value: _vm.user.company.vat,
                  callback: function($$v) {
                    _vm.$set(_vm.user.company, "vat", $$v)
                  },
                  expression: "user.company.vat"
                }
              }),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("va-text-field", {
                    staticClass: "mt-4 mr-4",
                    attrs: { label: _vm.$t("common.city") },
                    model: {
                      value: _vm.user.company.city,
                      callback: function($$v) {
                        _vm.$set(_vm.user.company, "city", $$v)
                      },
                      expression: "user.company.city"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: { label: _vm.$t("common.postalCode") },
                    model: {
                      value: _vm.user.company.postalCode,
                      callback: function($$v) {
                        _vm.$set(_vm.user.company, "postalCode", $$v)
                      },
                      expression: "user.company.postalCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("va-text-field", {
                    staticClass: "mt-4 mr-4",
                    attrs: { label: _vm.$t("common.region") },
                    model: {
                      value: _vm.user.company.region,
                      callback: function($$v) {
                        _vm.$set(_vm.user.company, "region", $$v)
                      },
                      expression: "user.company.region"
                    }
                  }),
                  _c("v-select", {
                    staticClass: "mt-4",
                    attrs: {
                      attach: "",
                      items: _vm.countryItems,
                      outlined: "",
                      "item-text": "name",
                      "item-value": "code",
                      "return-object": "",
                      label: _vm.$t("common.country")
                    },
                    on: { change: _vm.updateCountry },
                    model: {
                      value: _vm.user.company.country,
                      callback: function($$v) {
                        _vm.$set(_vm.user.company, "country", $$v)
                      },
                      expression: "user.company.country"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex mt-2" },
                [
                  _c("va-button", {
                    staticClass: "ml-auto",
                    attrs: {
                      submit: "",
                      disabled: !_vm.valid,
                      loading: _vm.$store.getters["session/isLoading"],
                      cta: _vm.$t("common.save")
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }