export interface Locale {
  id: string;
  label: string;
  code2: string;
  code3: string;
  iso: string;
  image: string;
  supported: boolean;
  default: boolean;
}

export const LOCALES: Array<Locale> = [
  {
    id: "en",
    label: "English",
    code2: "en",
    code3: "en",
    iso: "en_US",
    image: require("@/assets/images/flags/en.png"),
    supported: true,
    default: true,
  },
  {
    id: "it",
    label: "Italiano",
    code2: "it",
    code3: "ita",
    iso: "it_IT",
    image: require("@/assets/images/flags/it.png"),
    supported: false,
    default: false,
  },
];
