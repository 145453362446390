














































import Vue from "vue";
import { Timezone, TimezoneItem, TIMEZONES_ITEMS } from "@/models/timezone";
import cloneDeep from "lodash/cloneDeep";
import VaChip from "@/components/atoms/VaChip.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  name: "TimezonePicker",
  components: { VaIcon, VaChip },
  beforeMount() {
    //timezoneUtil.generateTimezones();
    this.timezones = cloneDeep(TIMEZONES_ITEMS);
    this.updateTime();
    this.clock = setInterval(this.updateTime, 30000);
  },
  mounted() {
    this.picker = this.$refs.picker as HTMLElement;
  },
  beforeDestroy() {
    clearInterval(this.clock);
  },
  data() {
    return {
      timezones: [] as Array<TimezoneItem>,
      currentTimestamp: 0,
      clock: 0,
      hide: false,
      picker: null as unknown as HTMLElement,
    };
  },
  methods: {
    onFocus(status: boolean): void {
      this.hide = status;
      if (!this.hide) {
        this.picker.blur();
      }
    },
    updateTime(): void {
      this.currentTimestamp = Date.now();
    },
  },
  computed: {
    currentTime(): number {
      return this.currentTimestamp;
    },
    currentTimezone: {
      get(): Timezone {
        return this.$store.getters["session/timezone"];
      },
      set(timezone: Timezone): void {
        this.$notification.success(this.$i18n.t("timezonePicker.success"));
        this.$store.commit("session/setTimezone", timezone);
        this.picker.blur();
      },
    },
  },
});
