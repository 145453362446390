


































































































import Vue, { PropType } from "vue";
import L, { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import apiService from "@/services/apiService";
import { Marker } from "@/models/address";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  components: {
    VaIcon,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    Vue2LeafletMarkercluster,
  },
  props: {
    markers: {
      type: Array as PropType<Marker[]>,
      required: false,
      default: [],
    },
    showAll: {
      type: Boolean,
      required: false,
      default: true,
    },
    mapCenter: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      zoom: 6,
      defatulCenter: latLng(51.4778684, 0.0014781),
      mapOptions: {
        zoomSnap: 0.5,
        attributionControl: false,
      },
      clusterOptions: {
        singleMarkerMode: false,
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
      },
    };
  },
  computed: {
    url(): string {
      return this.$store.getters["session/darkMode"]
        ? apiService.map.darkThemeUrl()
        : apiService.map.lightThemeUrl();
    },
    cleanMarkers(): Marker[] {
      return this.$props.markers.filter((m: Marker) => m.isLocationVerified);
    },
  },
  watch: {
    cleanMarkers: function (): void {
      this.updatePosition();
    },
  },
  mounted() {
    this.updatePosition();
  },
  methods: {
    getMarkerIcon(m: Marker) {
      let colorClass = "primary--text";
      if (m.refObject && m.refObject.criticalQt > 0) colorClass = "error--text";
      else if (m.refObject && m.refObject.warningQt > 0)
        colorClass = "warning--text";
      return L.divIcon({
        html: '<i class="vi vi-pin-3" style="font-size: 2.5rem;"></i>',
        className: colorClass,
      });
    },
    updatePosition() {
      const markerPoints = this.cleanMarkers.map((m: Marker) => {
        return latLng(m.position.lat, m.position.lng);
      });
      const mapComponent = this.$refs.groupsMap as LMap;
      if (markerPoints.length > 1) {
        const bounds = new L.LatLngBounds(markerPoints);
        mapComponent.mapObject.fitBounds(bounds);
      } else if (markerPoints.length == 1) {
        mapComponent.mapObject.panTo(markerPoints[0]);
      }
    },
  },
});
