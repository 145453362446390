




import Vue from "vue";
import VaCard from "@/components/molecules/VaCard.vue";

export default Vue.extend({
  name: "console_users",
  components: { VaCard },
});
