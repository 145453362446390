






































import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import store from "@/store";

export default Vue.extend({
  components: {
    VaIcon,
  },
  data() {
    return {
      infoDialog: false,
      version: process.env.VUE_APP_VERSION || "not provided",
    };
  },
  mounted() {
    store.dispatch("onprem/getConfiguration");
  },
  computed: {
    isOnPrem(): boolean {
      return this.$store.getters["onprem/isOnPrem"];
    },
    envId(): string {
      return this.$store.getters["onprem/envId"];
    },
  },
  methods: {
    toggleLight() {
      store.commit("session/toggleDarkMode");
    },
  },
});
