var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rounded border-a",
      staticStyle: { "background-color": "black" }
    },
    [
      _c("div", {
        attrs: {
          id: "appleid-signin",
          "data-color": "black",
          "data-border": "false",
          "data-type": _vm.signup ? "sign-up" : "sign-in",
          "data-height": "36",
          "data-width": "180"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }