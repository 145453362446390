
















import Vue from "vue";

export default Vue.extend({
  props: {
    color: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: "m",
      validator(value) {
        return ["xl", "l", "m", "s", "xs"].includes(value);
      },
    },
    iconClass: {
      type: String,
      required: false,
      default: "vi vi-shape-oval",
    },
  },
  computed: {},
});
