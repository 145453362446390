






















































































































































import Vue, { PropType } from "vue";
import { Building } from "@/models/building";
import VaInfoRow from "@/components/molecules/VaInfoRow.vue";
import VaChip from "@/components/atoms/VaChip.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import { HeaderTable } from "@/models/table";
import { RoomDetail } from "@/models/room";
import RoomsAutocomplete from "@/components/organisms/RoomsAutocomplete.vue";
import VaBadge from "@/components/atoms/VaBadge.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "BuildingDetail",
  components: {
    VaChip,
    VaInfoRow,
    VaIcon,
    VaNoDataTag,
    RoomsAutocomplete,
    VaBadge,
    VaButton,
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: false,
    },
  },
  data() {
    return {
      addRoomChecked: false,
      dialog: false,
      selectedRooms: [] as RoomDetail[],
      selectedFloor: "",
      headers: [
        {
          text: this.$i18n.t("common.roomName"),
          value: "name",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$i18n.t("common.floor"),
          value: "floor",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$i18n.t("common.actions"),
          value: "actions",
          sortable: false,
          filterable: false,
          width: 100,
        },
      ] as HeaderTable[],
      footerProps: {
        "items-per-page-options": [5, 10, 15],
        "items-per-page-text": this.$t("common.itemsPerPage"),
        "page-text": "",
      },
    };
  },
  beforeMount() {
    if (!this.$store.getters["building/currentBuilding"].uuid) {
      this.$store.commit(
        "building/setCurrentBuilding",
        this.$store.getters["building/buildings"].find(
          (building: Building) => building.uuid === this.$route.params.id
        )
      );
    }
  },
  computed: {
    currentBuilding(): Building {
      return this.$store.getters["building/currentBuilding"];
    },
    rooms() {
      return this.$store.getters["building/currentBuilding"].rooms;
      // return this.$props.building.rooms;
      // return this.$store.getters["room/rooms"].filter(
      //   (room: RoomDetail) =>
      //     this.$props.building.rooms?.findIndex(
      //       (el: { roomId: string; floor: string }) => el.roomId === room.id
      //     ) > -1
      // );
    },
    scrollerHeight(): number {
      return this.selectedRooms.length < 3
        ? this.selectedRooms.length * 75
        : 220;
    },
  },
  methods: {
    onSelectRoom(room: RoomDetail): void {
      this.selectedRooms.push(room);
    },
    onRemoveRoom(roomToRemove: RoomDetail) {
      this.selectedRooms = this.selectedRooms.filter((room: RoomDetail) => {
        return room.id !== roomToRemove.id;
      });
    },
    clearData() {
      this.selectedRooms = [];
      this.selectedFloor = "";
    },
    onAddRoom() {
      //TODO: save room in store until API is ready
      const updatedBuilding = cloneDeep(this.currentBuilding);
      const newRooms: { roomId: string; name: string; floor: string }[] =
        this.selectedRooms.map((el: RoomDetail) => {
          return {
            roomId: el.id,
            name: el.name,
            floor: this.selectedFloor,
          };
        });
      updatedBuilding.rooms = updatedBuilding.rooms?.length
        ? [...updatedBuilding.rooms, ...newRooms]
        : newRooms;
      this.$store.commit("room/setCurrentBuilding", updatedBuilding);
      this.clearData();
      if (!this.addRoomChecked) {
        this.dialog = false;
      }
    },
    onEdit(item: { roomId: string; name: string; floor: string }) {
      this.$router.push(`/rooms/${item.roomId}`);
    },
    isDisabled(): boolean {
      const alreadySelected: RoomDetail[] = [];
      for (let i in this.selectedRooms) {
        if (
          this.currentBuilding.rooms !== undefined &&
          this.currentBuilding.rooms?.findIndex(
            (el: { roomId: string; name: string; floor: string }) =>
              el.roomId === this.selectedRooms[i].id
          ) > -1
        ) {
          alreadySelected.push(this.selectedRooms[i]);
        }
      }
      return this.selectedRooms.length === 0 || alreadySelected.length > 0;
    },
    onDismissDialog(): void {
      this.dialog = false;
      this.clearData();
    },
    removeRoomFromList(item: {
      roomId: string;
      name: string;
      floor: string;
    }): void {
      const updatedBuilding = cloneDeep(this.currentBuilding);
      updatedBuilding.rooms = updatedBuilding.rooms?.filter(
        (el: { roomId: string; name: string; floor: string }) =>
          el.roomId !== item.roomId
      );
      this.$store.commit("room/setCurrentBuilding", updatedBuilding);
    },
  },
});
