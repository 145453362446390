var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "va-card",
        [
          _c("div", { staticClass: "va-title" }, [
            _vm._v(_vm._s(_vm.$t("common.profile")))
          ]),
          _c(
            "v-row",
            { staticClass: "mt-6" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(" " + _vm._s(_vm.$t("common.information")) + " ")
                  ]),
                  _c("user-data-form"),
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(_vm._s(_vm.$t("common.langtimezone")))
                  ]),
                  _c("timezone-picker", { staticClass: "mb-4" }),
                  _c("locale-picker")
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(_vm._s(_vm.$t("common.company")))
                  ]),
                  _c("company-form")
                ],
                1
              ),
              _vm.mfaEnabled
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "va-subtitle-s" }, [
                        _vm._v(_vm._s(_vm.$t("common.mfa")))
                      ]),
                      _c("mfa-settings")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }