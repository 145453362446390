// export interface FileRaw {
//   companyId: string;
//   fileHash: string;
//   fileLength: number;
//   fileId: string;
//   fileName: string;
//   fileType: number;
//   fileMimeType: string;
//   fileUrl: string;
// }

export interface FileFormatted {
  id?: string;
  name: string;
  type: string;
  hash?: string;
  path: string;
}

export enum FileType {
  UNKNOWN,
  BACKGROUND,
  LOGO,
  LOG_ZIP,
  SCRIPT,
  SCREENSHOT,
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function DeserializeFile(rawfile: any): FileFormatted | undefined {
  return rawfile
    ? {
        id: rawfile.fileId,
        path: rawfile.fileUrl,
        name: rawfile.fileName,
        hash: rawfile.fileHash,
        type: rawfile.fileMimeType,
      }
    : undefined;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(file: any): FileFormatted {
    //TODO REMOVE ME
    return {
      id: file.fileId,
      name: file.fileName,
      type: file.fileMimeType,
      path: file.fileUrl,
      hash: file.fileHash,
    } as FileFormatted;
  },
};
