


















































































































































































































import Vue from "vue";
import {
  RoomDetail,
  RoomOverallStateData,
  RoomOverallStatesData,
} from "@/models/room";
import VaInfoRow from "@/components/molecules/VaInfoRow.vue";
import VaChip from "@/components/atoms/VaChip.vue";
import VaNotification from "@/components/molecules/VaNotification.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  name: "RoomInformation",
  components: { VaIcon, VaNotification, VaChip, VaInfoRow },
  methods: {},
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
    peopleCountValue() {
      const room = this.$store.getters["room/room"];
      if (
        !room.online ||
        !room.roomCapacity ||
        (room.roomCapacity && room.roomCapacity.peopleCountValue === undefined)
      ) {
        return this.$t("common.n/a");
      } else {
        return room.roomCapacity?.peopleCountValue?.toString();
      }
    },
    overallStateData(): RoomOverallStateData {
      const room = this.$store.getters["room/room"];
      return RoomOverallStatesData.find((overallState) => {
        return overallState.id === room.overallState;
      }) as RoomOverallStateData;
    },
    overallPerformanceStatusData(): any {
      return {
        color: "",
        label: "",
      };
      /*return PerformanceUtil.overallStatusData(
        this.rooms.performance as RoomPerformance
      );*/
    },
  },
});
