











































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import validationMixin from "@/components/mixins/validationMixin";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import dataTableMixin from "@/components/mixins/dataTableMixin";
import { taskConfig } from "@/models/scheduler";

export default Vue.extend({
  name: "TaskList",
  mixins: [validationMixin, dataTableMixin],
  components: {
    VaDetailsButton,
    VaTableTop,
    MainColumns,
    VaNoDataTag,
    Notifications,
  },
  data() {
    return {
      storeName: "task",
      tableConfig: taskConfig,
    };
  },
  computed: {},
});
