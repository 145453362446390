


















import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import DateRangeSelector from "@/components/organisms/DateRangeSelector.vue";

export default Vue.extend({
  components: { DateRangeSelector, VaCard, VaIcon },
  props: {
    title: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
  },
});
