import { Coordinates } from "@/models/address";

export const convertHexToRGBA = (hex: string, opacity: number): string => {
  const tempHex = hex.replace("#", "");
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const bytesToSize = (bytes: number): string => {
  const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "";
  const i: number = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString()
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};

export const currentEnv = (): string => {
  let env = "dev";
  if (process.env.NODE_ENV === "staging") {
    env = "stg";
  }
  if (process.env.NODE_ENV === "production") {
    env = "prd";
  }
  return env;
};

export const getRandomLocation = (
  latitude: number,
  longitude: number,
  radiusInMeters: number
): Coordinates => {
  const getRandomCoordinates = function (radius: number, uniform: boolean) {
    // Generate two random numbers
    let a = Math.random(),
      b = Math.random();

    // Flip for more uniformity.
    if (uniform) {
      if (b < a) {
        const c = b;
        b = a;
        a = c;
      }
    }

    // It's all triangles.
    return [
      b * radius * Math.cos((2 * Math.PI * a) / b),
      b * radius * Math.sin((2 * Math.PI * a) / b),
    ];
  };

  const randomCoordinates = getRandomCoordinates(radiusInMeters, true);

  // Earths radius in meters via WGS 84 model.
  const earth = 6378137;

  // Offsets in meters.
  const northOffset = randomCoordinates[0],
    eastOffset = randomCoordinates[1];

  // Offset coordinates in radians.
  const offsetLatitude = northOffset / earth,
    offsetLongitude =
      eastOffset / (earth * Math.cos(Math.PI * (latitude / 180)));

  // Offset position in decimal degrees.
  return {
    lat: latitude + offsetLatitude * (180 / Math.PI),
    lng: longitude + offsetLongitude * (180 / Math.PI),
  };
};
