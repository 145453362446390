





















































































































































































import Vue from "vue";
import { DashboardData, DashboardTopRoom } from "@/models/dashboard";
import AnalyticUtil from "@/utils/analyticUtil";
import {
  AnalyticDynamicChartRawData,
  CHART_PALETTES,
  ChartData,
} from "@/models/analytics";
import RoomService from "@/services/roomService";
import Chart from "@/components/shared/Chart.vue";
import isEmpty from "lodash/isEmpty";
import { AnalyticChartRawData } from "@/models/analytics";
import DashboardService from "@/services/dashboardService";
import DashboardUtil from "@/utils/dashboardUtil";
import { HeatMapChartData, HeatMapChartDataset } from "@/models/chart";
import HeatMapChart from "@/components/shared/charts/HeatMapChart.vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import { Marker } from "@/models/address";
import mapService from "@/services/mapService";
import RoomsMap from "@/components/organisms/RoomsMap.vue";
import DashboardKpi from "@/components/organisms/charts/DashboardKpi.vue";

export default Vue.extend({
  name: "Index",
  components: {
    DashboardKpi,
    RoomsMap,
    Notifications,
    MainColumns,
    Chart,
    HeatMapChart,
  },
  data() {
    return {
      downloading: false,
      polling: 0,
      notificationsLoading: false,
      roomsOverallStateRange: "weekly",
      roomsOverallStateData: [] as Array<AnalyticChartRawData>,
      roomsOverallStateLoading: false,
      topRoomsRange: "daily",
      topRoomsLoading: false,
      topRoomsData: [] as Array<DashboardTopRoom>,
      meetingCountLoading: false,
      meetingCountRange: "monthly",
      meetingCountData: {} as HeatMapChartData | ChartData,
      totalMeetingCount: 0,
      currentRoomsOverallStateLoading: false,
      timeout: 10000,
      pending: false,
    };
  },
  async beforeMount(): Promise<void> {
    this.$store.commit("app/setLoading", true);
    this.notificationsLoading = true;
    this.roomsOverallStateLoading = true;
    this.currentRoomsOverallStateLoading = true;
    this.meetingCountLoading = true;
    try {
      await this.firstUpdate();
      this.polling = setInterval(async () => {
        await this.update(true);
      }, this.timeout);
    } catch (e) {
      this.$notification.error(e);
      this.$sentry.capture(e, "Index", "beforeMount");
    } finally {
      this.$store.commit("app/setLoading", false);
      this.notificationsLoading = false;
      this.roomsOverallStateLoading = false;
      this.currentRoomsOverallStateLoading = false;
    }
    this.$store.dispatch("group/fetchAll");
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    async onMeetingRoomsPanelClick(): Promise<void> {
      await Promise.all([
        this.updateRoomsOverallState(),
        this.updateTopRooms(),
      ]);
    },
    async firstUpdate(silent = false): Promise<void> {
      if (this.pending) {
        return;
      }
      try {
        this.pending = true;
        await Promise.all([
          this.updateRoomsOverallState(silent),
          this.updateTopRooms(silent),
          this.updateDashboard(silent),
          this.updateMeetingCount(silent),
        ]).then();
      } finally {
        this.pending = false;
      }
    },
    async update(silent = false): Promise<void> {
      if (this.pending) {
        return;
      }
      try {
        this.pending = true;
        // await Promise.all([
        await this.updateRoomsOverallState(silent);
        await this.updateTopRooms(silent);
        await this.updateDashboard(silent);
        await this.updateMeetingCount(silent);
        // ]).then();
      } finally {
        this.pending = false;
      }
    },
    async updateDashboard(silent: boolean): Promise<void> {
      await this.$store.dispatch("app/setDashboard", silent);
    },
    async updateTopRooms(silent = false): Promise<void> {
      if (!silent) {
        this.topRoomsLoading = true;
      }
      try {
        this.topRoomsData = await DashboardService.topRooms(this.topRoomsRange);
      } catch (e) {
        if (!silent) {
          this.topRoomsData = [] as Array<DashboardTopRoom>;
          this.$notification.error(e);
        }
        this.$sentry.capture(e, "Index", "updateTopRooms");
      } finally {
        this.topRoomsLoading = false;
      }
    },
    async updateRoomsOverallState(silent = false): Promise<void> {
      if (!silent) {
        this.roomsOverallStateLoading = true;
      }
      try {
        this.roomsOverallStateData = await RoomService.overallState(
          this.roomsOverallStateRange
        );
      } catch (e) {
        if (!silent) {
          this.roomsOverallStateData = [] as Array<AnalyticChartRawData>;
          this.$notification.error(e);
        }
        this.$sentry.capture(e, "Index", "updateRoomsOverallState");
      } finally {
        if (!silent) {
          this.roomsOverallStateLoading = false;
        }
      }
    },
    async updateMeetingCount(silent = false): Promise<void> {
      if (!silent) {
        this.meetingCountLoading = true;
      }
      try {
        const chartData: AnalyticDynamicChartRawData =
          await DashboardService.meetingCount(this.meetingCountRange);
        this.meetingCountData =
          this.meetingCountRange !== "daily"
            ? AnalyticUtil.buildHeatMapChartData(
                chartData.data,
                chartData.labels,
                CHART_PALETTES[4]
              )
            : AnalyticUtil.buildChartFixedData(
                chartData.data,
                chartData.labels,
                CHART_PALETTES[4]
              );

        const datasets = this.meetingCountData
          .datasets as HeatMapChartDataset[];
        this.totalMeetingCount = datasets
          .map((d: HeatMapChartDataset): number => {
            if (d.data) {
              return d.data.reduce((partialSum, a) => partialSum + a, 0);
            }
            return 0;
          })
          .reduce((sum: number, a: number) => sum + a, 0);
      } catch (e) {
        if (!silent) {
          this.meetingCountData = {} as HeatMapChartData;
          this.$notification.error(e);
        }
        this.$sentry.capture(e, "Index", "updateMeetingCount");
      } finally {
        if (!silent) {
          this.meetingCountLoading = false;
        }
      }
    },
    roomAnalytic(
      point: MouseEvent,
      event: Array<{ _model: { label: string } }>
    ): void {
      if (event && event.length > 0) {
        const room: DashboardTopRoom | undefined = this.topRoomsData.find(
          (topRoom: DashboardTopRoom) => {
            return topRoom.podName === event[0]._model.label;
          }
        );
        if (room) {
          this.$router.push("/rooms/" + room.podId + "/analytics");
        }
      }
    },
  },
  computed: {
    markers(): Marker[] {
      return this.$store.getters["group/availableGroups"].map(
        mapService.group2Marker
      );
    },
    roomsOverallStateChart(): ChartData {
      if (!this.roomsOverallStateData.length) {
        return {
          labels: [],
          datasets: [],
        };
      }
      const datasetLabels = [
        this.$t("common.online") as string,
        this.$t("common.warning") as string,
        this.$t("common.critical") as string,
      ];
      return AnalyticUtil.buildChartData(
        this.roomsOverallStateData,
        datasetLabels,
        CHART_PALETTES[3]
      );
    },
    loading(): boolean {
      return this.$store.getters["app/loading"];
    },
    dashboard(): DashboardData {
      return this.$store.getters["app/dashboard"];
    },
    currentRoomsOverallState(): ChartData {
      if (!this.dashboard || isEmpty(this.dashboard.overallStatus))
        return {
          labels: [],
          datasets: [],
        };
      return AnalyticUtil.buildChartFixedData(
        [this.dashboard.overallStatus],
        [
          this.$t("common.ok") as string,
          this.$t("common.warning") as string,
          this.$t("common.critical") as string,
          this.$t("common.offline") as string,
          this.$t("common.maintenance") as string,
        ],
        CHART_PALETTES[3]
      );
    },
    meetingCountRangeTitle(): string {
      switch (this.meetingCountRange) {
        case "daily":
          return `${this.$t("common.daily")} meetings`;
        case "weekly":
          return `${this.$t("common.weekly")} meetings`;
        default:
          return `${this.$t("common.monthly")} meetings`;
      }
    },
    overallStateAverage(): boolean {
      return this.dashboard?.overallStatus.values[1] > 0;
    },
    overallStatePoor(): boolean {
      return this.dashboard?.overallStatus.values[2] > 0;
    },
    overallStateOffline(): boolean {
      return this.dashboard?.overallStatus.values[3] > 0;
    },
    topRoomsChart(): ChartData {
      return DashboardUtil.buildTopChartData(this.topRoomsData);
    },
  },
  watch: {
    roomsOverallStateRange: function (): void {
      this.updateRoomsOverallState();
    },
    topRoomsRange: function (): void {
      this.updateTopRooms();
    },
    meetingCountRange: function (): void {
      this.updateMeetingCount();
    },
  },
});
