export interface SortBy {
  field: string;
  direction: "asc" | "desc";
}

export interface FilterBy {
  value: string;
  field: string;
}

export interface RawFilterBy {
  values: Array<string | number>;
  field: string;
}

export interface OptionsTable {
  // groupBy: Array(0)
  // groupDesc: Array(0)
  // itemsPerPage: 10
  // multiSort: true
  // mustSort: false
  // page: 1
  // sortBy: Array(0)
  // sortDesc: Array(0)
  itemsPerPage: number;
  page: number;
  sortBy: Array<string>;
  sortDesc: Array<string>;
}

export interface FooterProps {
  "items-per-page-options": number[];
  "items-per-page-text": string;
  "page-text": string;
}

export interface HeaderTable {
  text: string;
  align?: string;
  filterable?: boolean;
  sortable?: boolean;
  value: string;
  width?: number | string;
}

export interface QueryTable {
  limit: number;
  page: number;
  sortBy: SortBy[] | null;
  filterBy: {
    operator?: string;
    values: FilterBy[];
  } | null;
}

export interface QueryResult {
  count: number;
  items: Array<never>;
}

export interface TableConfig {
  [key: string]: {
    label: string;
    dtoName: string;
    sortable: boolean;
    filterable: boolean;
    hideColumn?: boolean;
  };
}

export function SortBySerializer(
  options: OptionsTable,
  config: TableConfig
): SortBy[] {
  return options.sortBy
    .map((field: string, index: number) => {
      if (
        config[field] &&
        config[field].sortable &&
        !config[field].hideColumn
      ) {
        return {
          field: config[field]?.dtoName,
          direction: options.sortDesc[index] ? "desc" : "asc",
        };
      } else {
        return null;
      }
    })
    .filter((el) => el !== undefined) as SortBy[];
}

export function FilterBySerializer(
  config: TableConfig,
  searchString?: string,
  additionalFilters?: FilterBy[]
): FilterBy[] {
  const res = Object.keys(config)
    .filter((key: string) => {
      return searchString && config[key].filterable && !config[key].hideColumn;
    })
    .map((field) => {
      return {
        field: config[field]?.dtoName,
        value: searchString,
      } as FilterBy;
    });
  if (additionalFilters) {
    return res.concat(additionalFilters);
  }
  return res;
}
