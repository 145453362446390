




























































import Vue from "vue";
import VaLinesChart from "@/components/organisms/charts/VaLinesChart.vue";
import VaBarChart from "@/components/organisms/charts/VaBarChart.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import AnalyticsTopBar from "@/components/organisms/AnalyticsTopBar.vue";

export default Vue.extend({
  name: "AnalyticsHealth",
  components: {
    AnalyticsTopBar,
    VaCard,
    VaLinesChart,
    VaBarChart,
  },
  data() {
    return {
      ghostVsTotal: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            label: "Ghost meetings",
            backgroundColor: [
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
            ],
            data: [4, 1, 5, 4, 6, 2, 8],
          },
          {
            label: "Total meetings",
            backgroundColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
            ],
            data: [12, 19, 13, 15, 12, 18, 11],
          },
        ],
      },
      averageStartTime: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            backgroundColor: ["rgba(122,26,225,0.3)"],
            borderColor: ["rgba(122,26,225,0.8)"],
            pointBackgroundColor: this.$vuetify.theme.currentTheme.primary,
            pointBorderColor: this.$vuetify.theme.currentTheme.primary,
            data: [10, 8, 4, 7, 7, 5, 6],
          },
        ],
      },
      access: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            backgroundColor: [
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
            ],
            label: "Early",
            data: [4, 3, 2, 3, 1, 0, 2],
          },
          {
            backgroundColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
            ],
            label: "On time",
            data: [9, 12, 13, 15, 18, 19, 10],
          },
          {
            backgroundColor: [
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
            ],
            label: "Late",
            data: [15, 5, 11, 15, 18, 9, 17],
          },
        ],
      },
      incidentsInMeeting: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
            ],
            pointBackgroundColor: this.$vuetify.theme.currentTheme.primary,
            pointBorderColor: this.$vuetify.theme.currentTheme.primary,
            data: [10, 8, 4, 7, 7, 5, 6],
          },
        ],
      },
    };
  },
});
