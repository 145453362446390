var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "card room-notices",
        width: "700",
        scrollable: ""
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("common.notices")))])
          ]),
          _c(
            "v-card-text",
            [
              _vm.offline
                ? _c(
                    "v-alert",
                    {
                      staticClass: "light",
                      attrs: { dense: "", type: "error" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("roomNotices.offline")) + " ")]
                  )
                : _vm._e(),
              _vm.update
                ? _c(
                    "v-alert",
                    {
                      staticClass: "light",
                      attrs: { dense: "", type: "info" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between flex-grow-1"
                        },
                        [
                          _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("roomNotices.update")) + " "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm.invalidPolicies
                ? _c(
                    "v-alert",
                    {
                      staticClass: "light",
                      attrs: { dense: "", type: "warning" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between flex-grow-1"
                        },
                        [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("roomNotices.invalidPolicies")) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-space-between" },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "secondary" },
                  on: { click: _vm.notShowAgain }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.doNotShowAgain")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "accent", depressed: "" },
                  on: {
                    click: function($event) {
                      _vm.show = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.ok")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }