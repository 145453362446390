var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "mt-8",
    attrs: {
      dense: "",
      headers: _vm.headers,
      items: _vm.commandsList,
      "hide-default-footer": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function() {
          return [
            _c("div", { staticClass: "pa-4" }, [
              _vm._v(_vm._s(_vm.$t("common.noCommandAdded")))
            ])
          ]
        },
        proxy: true
      },
      {
        key: "item.code",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(_vm.$i18n.t("commands." + item.code)) + " ")
            ])
          ]
        }
      },
      {
        key: "item.parameter",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("div", [
              item.parameterType == "boolean"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.parameter
                            ? _vm.$t("common.inactive")
                            : _vm.$t("common.active")
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              item.parameterType == "number" || item.parameterType == "string"
                ? _c("span", [_vm._v(" " + _vm._s(item.parameter) + " ")])
                : _vm._e(),
              item.parameterType == "file"
                ? _c("span", [
                    _vm._v(" " + _vm._s(item.parameter.fileName) + " ")
                  ])
                : _vm._e(),
              item.parameterType == "none"
                ? _c("span", [_vm._v("-")])
                : _vm._e()
            ])
          ]
        }
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("va-button", {
              attrs: {
                icon: "mdi mdi-delete",
                type: "icon",
                color: "secondary"
              },
              on: {
                click: function($event) {
                  return _vm.removeCommand(item)
                }
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }