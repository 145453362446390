import i18n from "@/i18n";
import {
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";
import { FileFormatted, DeserializeFile } from "@/models/file";
import {
  PodApplication,
  PodApplicationDeserializer,
} from "@/models/podApplication";

export interface GroupedPolicy {
  groupName: string;
  groupPolicies: Array<{
    policyId: string;
    name: string;
  }>;
  isInPolicy: boolean | null;
  policyGroupId: number;
  selectedPolicyId: null | string;
  selectedGroupPolicyId: null | string;
  isVersionReady?: boolean | null;
}

/** ------------ new models ------------ **/

export enum groupsDecoder {
  "language" = 6,
  "display" = 10,
  "appearance" = 12,
  "calendar" = 13,
  "instantMeetings" = 14,
  "presentation" = 15,
  "applications" = 16,
  "workspace" = 17,
  "security" = 18, //18
  "advanced" = 19,
  "update" = 5,
  // "general" = 3,
  // "homeLauncher" = 2,
  // "videoConference" = 4,
  // "eula" = 7,
  // "network" = 8,
  // "license" = 9,
  // "localUser" = 11,
  // "presentation" = 15,
}

export const possibleGroups = Object.keys(groupsDecoder).filter((v) =>
  isNaN(Number(v))
);

export type PoliciesGroups = typeof possibleGroups[number];

export const possibleFieldTypes = [
  "int",
  "boolean",
  "string",
  "file",
  "object",
  "appArray",
];
export type FieldTypes = typeof possibleFieldTypes[number];

export type FieldValues =
  | string
  | number
  | boolean
  | FileFormatted
  | FileFormatted[]
  | PodApplication[]
  | { [key: string]: string };

export interface PolicyField {
  keyId?: number;
  keyName: string;
  keyValue: FieldValues;
  keyType: FieldTypes;
}

export interface Policy {
  id?: string;
  name: string;
  createdUserId?: string;
  type: PoliciesGroups;
  fields?: {
    [key: string]: PolicyField;
  };
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function PolicyGroupDeserializer(input: any[]): GroupedPolicy[] {
  return input
    .filter((pol: any) => {
      return groupsDecoder[pol.policyGroupId];
    })
    .map(GroupedPolicyDeserializer)
    .sort(function (a, b) {
      return (
        possibleGroups.indexOf(groupsDecoder[a.policyGroupId]) -
        possibleGroups.indexOf(groupsDecoder[b.policyGroupId])
      );
    });
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function GroupedPolicyDeserializer(input: any): GroupedPolicy {
  //TODO: missing stronger validation
  return {
    groupName: input.groupName,
    groupPolicies: input.groupPolicies,
    isInPolicy: input.isInPolicy,
    policyGroupId: input.policyGroupId,
    selectedPolicyId: input.selectedPolicyId,
    selectedGroupPolicyId: input.selectedGroupPolicyId,
    isVersionReady: input.isVersionReady,
  };
}

export const policyConfig = {
  // id: {
  //   dtoName: "groupId",
  //   sortable: false,
  //   filterable: false,
  //   label: i18n.t("common.id"),
  // },
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
  group: {
    dtoName: "group",
    sortable: false,
    filterable: false,
    label: i18n.t("common.group"),
  },
} as TableConfig;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function PolicyDeserializer(input: any): Policy {
  return {
    id: input.policyId,
    name: input.name,
    createdUserId: input.createdUserId,
    type: groupsDecoder[input.policyGroupId],
  };
}

export function PolicySerializer(input: Policy): unknown {
  const values = input.fields ? PolicyFieldsSerializer(input.fields) : [];
  return {
    name: input.name,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    policyGroupId: groupsDecoder[input.type],
    keyValues: values,
  };
}

export function PolicyFieldsDeserializer(input: any[]): {
  [key: string]: PolicyField;
} {
  return input.reduce((acc, curr) => {
    let val;
    switch (curr.keyType) {
      case "string":
        val = deserializeString(curr.keyValue);
        break;
      case "int":
        val = deserializeNumber(curr.keyValue);
        break;
      case "boolean":
        val = deserializeBoolean(curr.keyValue);
        break;
      case "object":
        //BAD! copy and trust object as is
        val = curr.keyValue;
        break;
      case "file":
        val = DeserializeFile(curr.keyValue);
        break;
      case "fileArray":
        val = curr.keyValue.map(DeserializeFile);
        break;
      case "appArray":
        val = curr.keyValue.map(PodApplicationDeserializer);
        break;
      default:
        //not handled
        val = curr.keyValue;
    }

    const res = {
      keyId: deserializeNumber(curr.keyId),
      keyName: curr.keyName,
      keyValue: val,
      keyType: curr.keyType,
    };
    return (acc[curr.keyName] = res), acc;
  }, {});
}

export const fileFields = ["Logo", "Backgrounds", "WorkspaceBackground"];

export function PolicyFieldsSerializer(input: {
  [key: string]: PolicyField;
}): unknown {
  return Object.values(input).map((el) => {
    let val = el.keyValue as unknown;
    if (el.keyType == "file") {
      if (Array.isArray(el.keyValue)) {
        val = (el.keyValue as FileFormatted[]).map((bg) => bg.id || "");
      } else {
        val = (el.keyValue as FileFormatted).id;
      }
    } else if (el.keyType == "fileArray") {
      val = (el.keyValue as FileFormatted[]).map((bg) => bg.id || "");
    } else if (el.keyType == "appArray") {
      val = (el.keyValue as PodApplication[]).map(
        (app: PodApplication) => app.id || ""
      );
    }

    return {
      keyId: el.keyId,
      keyName: el.keyName,
      keyValue: val,
      keyType: el.keyType,
    };
  });
}

export function PolicySearchSerializer(
  options: OptionsTable,
  searchString?: string,
  groupFilter?: PoliciesGroups
): QueryTable {
  const res = {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, policyConfig),
    filterBy: {
      values: FilterBySerializer(policyConfig, searchString),
      operator: undefined,
    },
  } as QueryTable;
  if (groupFilter && res.filterBy) {
    res.filterBy.operator = "AND";
    res.filterBy?.values.push({
      field: "policyGroupId",
      // eslint-disable-next-line
      // @ts-ignore
      value: groupsDecoder[groupFilter],
    });
  }
  return res;
}

function deserializeBoolean(field: any): boolean {
  //TODO ultrabad! REMOVE ME WITH NEW API!!, need to cast boolean due to unreliable api result => "False"
  return field ? field.toString().toLowerCase() == "true" : false;
}

function deserializeNumber(field: any): number {
  //TODO ultrabad! REMOVE ME WITH NEW API!!, need to cast number due to unreliable api result
  return parseInt(field) | 0;
}

function deserializeString(field: any): string {
  //TODO ultrabad! REMOVE ME WITH NEW API!!
  return !field || field == "null" ? "" : field;
}
