











import Vue from "vue";
import { OauthProvider } from "@/models/oauth.interface";
// import VaButton from "@/components/atoms/VaButton.vue";
import { getBrowserVersion } from "@/services/browserVersionService";

export default Vue.extend({
  name: "GoogleSignIn",
  components: {},
  props: {
    signup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    providerType(): number {
      return OauthProvider.Google;
    },
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.head.appendChild(script);
    script.onload = () => {
      window.google?.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.onSuccess,
        ux_mode: "popup",
        auto_select: false,
      });
      window.google?.accounts.id.renderButton(this.$refs.signinBtn, {
        text: "signin_with", // or 'signup_with' | 'continue_with' | 'signin'
        size: "large", // or 'small' | 'medium'
        width: "180px", // max width 400
        theme: "outline", // or 'filled_black' |  'filled_blue'
        logo_alignment: "left", // or 'center'
      });
    };
  },
  methods: {
    // login() {
    //   window.google?.accounts.id.prompt();
    // },
    // eslint-disable-next-line no-undef
    async onSuccess(response: google.accounts.id.CredentialResponse) {
      const data = {
        deviceUniqueId: getBrowserVersion(),
        appPlatform: "web",
        grantType: "access_token",
        oauthProviderType: this.providerType,
        callbackUrl: `${window.location.origin}/auth/signin`,
        authCode: response.credential,
      };
      this.$store.dispatch("mfa/login", data);
    },
  },
});
