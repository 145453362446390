import {
  AnalyticChartRawData,
  BORDER_WIDTH,
  ChartData,
  ChartDataset,
  ChartPalette,
  MAX_BAR_THICKNESS,
} from "@/models/analytics";
import { DateTime } from "luxon";
import { HeatMapChartData, HeatMapChartDataset } from "@/models/chart";

export default {
  buildChartFixedData(
    chartRawData: Array<AnalyticChartRawData>,
    datasetsLabels: Array<string>,
    palette: ChartPalette
  ): ChartData {
    const labels = datasetsLabels;
    const datasets = [] as Array<ChartDataset>;

    chartRawData.forEach((data: AnalyticChartRawData, index: number) => {
      const values = [] as Array<number>;
      const backgrounds = [] as Array<string>;
      const borders = [] as Array<string>;

      data.values.forEach((value: number, index2: number) => {
        values.push(value);
        backgrounds.push(palette.backgrounds[index2]);
        borders.push(palette.borders[index2]);
      });
      datasets.push({
        backgroundColor: backgrounds,
        borderColor: borders,
        borderWidth: BORDER_WIDTH,
        data: values,
        label: datasetsLabels[index],
        maxBarThickness: MAX_BAR_THICKNESS,
      });
    });
    return {
      labels,
      datasets,
    } as ChartData;
  },
  buildChartData(
    chartRawData: Array<AnalyticChartRawData>,
    datasetsLabels: Array<string>,
    palette: ChartPalette
  ): ChartData {
    const labels = [] as Array<string>;
    const datasets = [] as Array<ChartDataset>;
    chartRawData.forEach((data: AnalyticChartRawData) => {
      labels.push(DateTime.fromMillis(data.date).toFormat("dd LLL"));
    });

    datasetsLabels.forEach((datasetLabel: string, index: number) => {
      const values = [] as Array<number>;
      chartRawData.forEach((data: AnalyticChartRawData) => {
        values.push(data.values[index]);
      });
      datasets.push({
        backgroundColor: palette.backgrounds[index],
        borderColor: palette.borders[index],
        data: values,
        label: datasetsLabels[index],
        borderWidth: BORDER_WIDTH,
        maxBarThickness: MAX_BAR_THICKNESS,
      });
    });
    return {
      labels,
      datasets,
    } as ChartData;
  },
  buildHeatMapChartData(
    chartRawData: Array<AnalyticChartRawData>,
    datasetsLabels: Array<string>,
    palette: ChartPalette
  ): HeatMapChartData {
    const labels = [] as Array<string>;
    const datasets = [] as Array<HeatMapChartDataset>;
    chartRawData.forEach((data: AnalyticChartRawData) => {
      labels.push(DateTime.fromMillis(data.date).toFormat("dd LLL"));
    });

    datasetsLabels.forEach((datasetLabel: string, index: number) => {
      const values = [] as Array<number>;
      chartRawData.forEach((data: AnalyticChartRawData) => {
        values.push(data.values[index]);
      });
      datasets.push({
        data: values,
        label: datasetsLabels[index],
      });
    });
    return {
      labels,
      datasets,
      palette,
    } as HeatMapChartData;
  },
};
