




































































































































import Vue, { VueConstructor } from "vue";
import { FileFormatted } from "@/models/file";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import validationMixin from "@/components/mixins/validationMixin";
import { LoggerMixin } from "@/components/mixins/errorMixin";
import policiesMixin from "@/components/mixins/policiesMixin";
import { FieldValues } from "@/models/policy";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "CalendarConfiguration",
  components: { VaSwitch },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
      canJoinDelayOptions: [
        {
          value: 5,
          text: "5 " + this.$t("common.min"),
        },
        {
          value: 10,
          text: "10 " + this.$t("common.min"),
        },
        {
          value: 15,
          text: "15 " + this.$t("common.min"),
        },
        {
          value: 30,
          text: "30 " + this.$t("common.min"),
        },
        {
          value: 60,
          text: "60 " + this.$t("common.min"),
        },
        {
          value: 0,
          text: this.$t("policiesFields.Anytime"),
        },
      ],
      displayMeetingInfoOptions: [
        "Title",
        "TitleAndOrganizer",
        "Organizer",
        "Blank",
      ],
      freeRoomDelayOptions: [
        {
          value: 0,
          text: this.$t("policiesFields.Disabled"),
        },
        {
          value: 5,
          text: "5 " + this.$t("common.min"),
        },
        {
          value: 10,
          text: "10 " + this.$t("common.min"),
        },
        {
          value: 15,
          text: "15 " + this.$t("common.min"),
        },
      ],
      defaultExtendCountdownOptions: [
        {
          value: 0,
          text: this.$t("policiesFields.countdownActionClose"),
        },
        {
          value: 1,
          text: this.$t("policiesFields.countdownActionExtend"),
        },
      ],
      meetingExtensionTimeOptions: [
        {
          value: 10,
          text: "10 " + this.$t("common.min"),
        },
        {
          value: 15,
          text: "15 " + this.$t("common.min"),
        },
        {
          value: 30,
          text: "30 " + this.$t("common.min"),
        },
        {
          value: 60,
          text: "60 " + this.$t("common.min"),
        },
      ],
      notifyEndMeetingTimeoutOptions: [
        {
          value: 10,
          text: "10 " + this.$t("common.sec"),
        },
        {
          value: 30,
          text: "30 " + this.$t("common.sec"),
        },
        {
          value: 60,
          text: "60 " + this.$t("common.sec"),
        },
        {
          value: 120,
          text: "120 " + this.$t("common.sec"),
        },
      ],
      roomAutoFreedTimeOptions: [
        {
          value: 0,
          text: this.$t("common.disabled") as string,
        },
        {
          value: 10,
          text: "10 " + this.$t("common.min"),
        },
        {
          value: 20,
          text: "20 " + this.$t("common.min"),
        },
        {
          value: 30,
          text: "30 " + this.$t("common.min"),
        },
      ],
      maxMeetingsInListOptions: [
        {
          value: 0,
          text: "All",
        },
        {
          value: 1,
          text: "1",
        },
        {
          value: 2,
          text: "2",
        },
        {
          value: 3,
          text: "3",
        },
        {
          value: 5,
          text: "5",
        },
        {
          value: 8,
          text: "8",
        },
        {
          value: 10,
          text: "10",
        },
        {
          value: 15,
          text: "15",
        },
        {
          value: 20,
          text: "20",
        },
        {
          value: 30,
          text: "30",
        },
      ],
    };
  },
  methods: {
    uploadLogo(logo: FileFormatted) {
      this.fields.Logo = logo;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange();
    },
    uploadBackgrounds(backgrounds: Array<FileFormatted>) {
      this.fields.Backgrounds = backgrounds;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange();
    },
  },
});
