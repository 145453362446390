import i18n from "@/i18n";
import Vue from "vue";

/** example of usage
 *   <va-text-field
 :rules="[required, minLength(tag.name, 3)]"
 ...
 **/
const urlRegex =
  "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)";
const smtpUrlRegex =
  "(smtp:\\/\\/)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)";
const timeAmPmRegex = "\\b((1[0-2]|0?[0-9]):([0-5][0-9]) ([AP][M]))$";
const timeRegex = "\\b((1[0-2]|0?[0-9]):([0-5][0-9]))$";
const emailRegex =
  "(?!^[.+&'_-]*@.*$)(^[_\\w\\d+&'-]+(\\.[_\\w\\d+&'-]*)*@[\\w\\d-]+(\\.[\\w\\d-]+)*\\.(([\\d]{1,3})|([\\w]{2,}))$)";
// "/^(0?[1-9]|1[0-2]):([0-5]\\d)\\s?((?:A|P)\\.?M\\.?)$/i";

export default Vue.extend({
  data() {
    return {
      valid: false,
      required: (value: string) =>
        !!value || value == "0" || i18n.t("validate.required"),
      maxLength: (value: string, max: number) =>
        value.length <= max || `${i18n.t("validate.maxLength")}: ${max}`,
      minLength: (value: string, min: number) =>
        value.length >= min || `${i18n.t("validate.minLength")}: ${min}`,
      validMail: (value: string) =>
        value == "" ||
        new RegExp(emailRegex).test(value) ||
        i18n.t("validate.email"),
      url: (value: string) =>
        new RegExp(urlRegex).test(value) || i18n.t("validate.url"),
      smtpUrlRegex: (value: string) =>
        new RegExp(smtpUrlRegex).test(value) || i18n.t("validate.smtpUrl"),
      timeAmPM: (value: string) =>
        !value ||
        new RegExp(timeAmPmRegex).test(value) ||
        i18n.t("validate.amPm"),
      time: (value: string) =>
        !value || new RegExp(timeRegex).test(value) || i18n.t("validate.amPm"),
      mustMatch: (v1: string, v2: string) =>
        v1 === v2 || i18n.t("validate.confirmed"),
      positiveNumber: (value: number) => value >= 0 || `Only positive numbers`,
      maxNumber: (value: number, max: number) =>
        value <= max || `${i18n.t("validate.maxValue")} ${max}`,
      minNumber: (value: number, min: number) =>
        value >= min || `${i18n.t("validate.minValue")} ${min}`,
      //add more rules EXTEND ME
    };
  },
});
