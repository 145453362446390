var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.security")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c(
            "section",
            { staticClass: "health-check" },
            [
              _c("div", { staticClass: "va-subtitle-s" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("securityConfiguration.healthCheckTitle")) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("securityConfiguration.isHealthCheckEnabled")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.IsHealthCheckEnabled,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "IsHealthCheckEnabled", $$v)
                  },
                  expression: "fields.IsHealthCheckEnabled"
                }
              }),
              _vm.fields.IsHealthCheckEnabled
                ? _c(
                    "div",
                    { staticClass: "va-card" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: 6 } },
                            [
                              _c("va-switch", {
                                attrs: {
                                  text: _vm.$t(
                                    "securityConfiguration.healthCheckSpeakerMute"
                                  )
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: _vm.fields.HealthCheckSpeakerMute,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "HealthCheckSpeakerMute",
                                      $$v
                                    )
                                  },
                                  expression: "fields.HealthCheckSpeakerMute"
                                }
                              }),
                              _c("va-switch", {
                                attrs: {
                                  text: _vm.$t(
                                    "securityConfiguration.healthCheckSpeakerLowLevel"
                                  )
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: _vm.fields.HealthCheckSpeakerLowLevel,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "HealthCheckSpeakerLowLevel",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "fields.HealthCheckSpeakerLowLevel"
                                }
                              }),
                              _c("va-switch", {
                                attrs: {
                                  text: _vm.$t(
                                    "securityConfiguration.healthCheckMicrophoneMute"
                                  )
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: _vm.fields.HealthCheckMicrophoneMute,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "HealthCheckMicrophoneMute",
                                      $$v
                                    )
                                  },
                                  expression: "fields.HealthCheckMicrophoneMute"
                                }
                              }),
                              _c("va-switch", {
                                attrs: {
                                  text: _vm.$t(
                                    "securityConfiguration.healthCheckMicrophoneLowLevel"
                                  )
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value:
                                    _vm.fields.HealthCheckMicrophoneLowLevel,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "HealthCheckMicrophoneLowLevel",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "fields.HealthCheckMicrophoneLowLevel"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: 6 } },
                            [
                              _c("va-switch", {
                                attrs: {
                                  text: _vm.$t(
                                    "securityConfiguration.healthCheckHasCamera"
                                  )
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: _vm.fields.HealthCheckHasCamera,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "HealthCheckHasCamera",
                                      $$v
                                    )
                                  },
                                  expression: "fields.HealthCheckHasCamera"
                                }
                              }),
                              _c("va-switch", {
                                attrs: {
                                  text: _vm.$t(
                                    "securityConfiguration.healthCheckHasInternetConnection"
                                  )
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value:
                                    _vm.fields.HealthCheckHasInternetConnection,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "HealthCheckHasInternetConnection",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "fields.HealthCheckHasInternetConnection"
                                }
                              }),
                              _c("va-switch", {
                                attrs: {
                                  text: _vm.$t(
                                    "securityConfiguration.healthCheckHasMagoConnection"
                                  )
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value:
                                    _vm.fields.HealthCheckHasValareaConnection,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "HealthCheckHasValareaConnection",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "fields.HealthCheckHasValareaConnection"
                                }
                              }),
                              _c("va-switch", {
                                attrs: {
                                  text: _vm.$t(
                                    "securityConfiguration.smtpSendHealthCheck"
                                  )
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: _vm.fields.SmtpSendHealthCheck,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "SmtpSendHealthCheck",
                                      $$v
                                    )
                                  },
                                  expression: "fields.SmtpSendHealthCheck"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.fields.SmtpSendHealthCheck
                            ? _c(
                                "v-row",
                                { staticClass: "px-4" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: 6 } },
                                    [
                                      _c("va-text-field", {
                                        staticClass: "mt-4",
                                        attrs: {
                                          label: _vm.$t("common.email"),
                                          rules: [_vm.required, _vm.validMail]
                                        },
                                        on: { input: _vm.onChange },
                                        model: {
                                          value:
                                            _vm.fields
                                              .SmtpSendHealthCheckReceiver,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "SmtpSendHealthCheckReceiver",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "fields.SmtpSendHealthCheckReceiver"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: 6 } },
                                    [
                                      _c("v-select", {
                                        staticClass: "mt-4",
                                        attrs: {
                                          rules: [_vm.required],
                                          outlined: "",
                                          attach: "",
                                          label: _vm.$t(
                                            "securityConfiguration.smtpSendHealthCheckTime"
                                          ),
                                          items: _vm.minutesBeforeOptions,
                                          "item-text": "text",
                                          "item-value": "value"
                                        },
                                        on: { input: _vm.onChange },
                                        model: {
                                          value:
                                            _vm.fields.SmtpSendHealthCheckTime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "SmtpSendHealthCheckTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "fields.SmtpSendHealthCheckTime"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "bluetooth-proximity" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("securityConfiguration.bluetoothProximityTitle")
                    ) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("securityConfiguration.bleProximityEnabled")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.BleProximityEnabled,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "BleProximityEnabled", $$v)
                  },
                  expression: "fields.BleProximityEnabled"
                }
              })
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "app-security" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("securityConfiguration.appSecurityTitle")) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("securityConfiguration.keepMobileSessionAlive")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.KeepMobileSessionAlive,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "KeepMobileSessionAlive", $$v)
                  },
                  expression: "fields.KeepMobileSessionAlive"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }