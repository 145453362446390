import { RoomCommandData, RoomCommandParameterTypes } from "@/models/room";
import {
  Command,
  CommandSerializer,
  CommandDeserializer,
} from "@/models/command";
import i18n from "@/i18n";
import {
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";

export enum SchedulerType {
  NEVER,
  DAILY,
  WEEKLY,
  MONTHLY,
}

export interface SchedulerDailyOptions {
  recurrence: number;
}

export interface SchedulerWeeklyOptions extends SchedulerDailyOptions {
  weekDays: Array<number>;
}

export interface SchedulerMonthlyOptions {
  months: Array<number>;
  days: Array<number>;
}

export type SchedulerOptions =
  | SchedulerDailyOptions
  | SchedulerWeeklyOptions
  | SchedulerMonthlyOptions
  | null;

export interface SchedulerData {
  name: string;
  ids: Array<string>;
  idType: number;
  startDate: number | null;
  endDate: number | null;
  scheduler: SchedulerType;
  randomDelay: number;
  schedulerOptions: SchedulerOptions;
  commands: Array<RoomCommandData>;
}

export interface SchedulerCommand {
  schedulerCommandId?: string;
  commandTypeId: number;
  parameter: RoomCommandParameterTypes;
}

export interface SchedulerItem {
  itemId: string;
  name: string;
}

export interface SchedulerDetail {
  id: string;
  name: string;
  commands: Array<SchedulerCommand>;
  randomDelay: number;
  type: SchedulerType;
  startDate: number | null;
  endDate: number | null;
  schedulerOptions: SchedulerOptions;
  itemType: number;
  targets: Array<SchedulerItem>;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): SchedulerDetail {
    return {
      id: input.schedulerId,
      name: input.name || "",
      commands: input.commands || [],
      randomDelay: input.randomDelay || 0,
      type: input.scheduler,
      startDate: input.startDate,
      endDate: input.endDate,
      schedulerOptions: input.schedulerOptions,
      itemType: input.itemType || 0,
      targets: input.items || [],
    };
  },
  // /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  requestSerialize(input: SchedulerDetail): any {
    return {
      schedulerId: input.id,
      name: input.name,
      commands: input.commands,
      randomDelay: input.randomDelay,
      scheduler: input.type,
      startDate: input.startDate,
      endDate: input.endDate,
      schedulerOptions: input.schedulerOptions,
      itemType: input.itemType,
      items: input.targets,
    };
  },
};

/** ------------ new models ------------ **/

export type schedulerType = "NEVER" | "DAILY" | "WEEKLY" | "MONTHLY";
export type targetType = "TAG" | "GROUP" | "ROOM";

export interface ScheduleOptions {
  type: schedulerType;
  startDate?: Date;
  endDate?: Date;
  recurrence: number;
  weekDays?: number[];
  months?: number[];
  monthDays?: number[];
  randomDelay: number;
}

export interface Task {
  id?: string;
  name: string;
  commands: Command[];
  scheduleOptions?: ScheduleOptions;
  targetType: targetType;
  targets: string[];
}

export const taskConfig = {
  id: {
    dtoName: "groupId",
    sortable: false,
    filterable: false,
    label: i18n.t("common.id"),
    hideColumn: true,
  },
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
  startDate: {
    dtoName: "startDate",
    sortable: true,
    filterable: false,
    label: i18n.t("common.startDate"),
  },
  endDate: {
    dtoName: "endDate",
    sortable: true,
    filterable: false,
    label: i18n.t("common.endDate"),
  },
} as TableConfig;

const schedulerType = ["NEVER", "DAILY", "WEEKLY", "MONTHLY"]; //TODO REMOVE with new API
const targetType = ["TAG", "GROUP", "ROOM"]; //TODO REMOVE with new API

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function TaskDeserializer(input: any): Task {
  return {
    id: input.schedulerId,
    name: input.name || "",
    commands: input.commands.map(CommandDeserializer),
    scheduleOptions: {
      type: (schedulerType[input.scheduler] as schedulerType) || "NEVER",
      startDate: input.startDate ? new Date(input.startDate) : undefined,
      endDate: input.endDate ? new Date(input.endDate) : undefined,
      recurrence: input.schedulerOptions
        ? input.schedulerOptions.recurrence
        : 1,
      weekDays: input.schedulerOptions
        ? input.schedulerOptions.weekDays
        : undefined,
      months: input.schedulerOptions
        ? input.schedulerOptions.months
        : undefined,
      monthDays: input.schedulerOptions
        ? input.schedulerOptions.monthdays
        : undefined,
      randomDelay: input.randomDelay || 0,
    },
    targetType: (targetType[input.itemType] as targetType) || "ROOM",
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
    targets:
      input.items?.map((item: any) => {
        return {
          id: item.itemId,
          name: item.name,
        };
      }) || [],
  };
}

export function TaskSerializer(task: Task): unknown {
  let options = null; // TODO PLEASE REFACTOR API!
  let repeat = 0;
  if (task.scheduleOptions?.type === "DAILY") {
    options = {
      recurrence: task.scheduleOptions.recurrence,
    };
    repeat = 1;
  } else if (task.scheduleOptions?.type === "WEEKLY") {
    options = {
      recurrence: task.scheduleOptions.recurrence,
      weekDays: task.scheduleOptions.weekDays,
    };
    repeat = 2;
  } else if (task.scheduleOptions?.type === "MONTHLY") {
    options = {
      months: task.scheduleOptions.months,
      days: task.scheduleOptions.monthDays,
    };
    repeat = 3;
  }

  let idType = 0; //TODO REMOVE ME!
  if (task.targetType === "GROUP") idType = 1;
  if (task.targetType === "ROOM") idType = 2;

  return {
    schedulerId: task.id,
    name: task.name,
    ids: task.targets,
    idType: idType,
    startDate: task.scheduleOptions?.startDate
      ? task.scheduleOptions.startDate
      : Date.now() + 1800000, //HACK force 30 minutes delay for instant commands,  see #2181
    endDate: task.scheduleOptions?.endDate
      ? task.scheduleOptions.endDate
      : null,
    randomDelay: task.scheduleOptions?.randomDelay
      ? task.scheduleOptions.randomDelay
      : 1,
    schedulerOptions: options,
    scheduler: repeat,
    commands: task.commands.map(CommandSerializer),
  };
}

export function TaskSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, taskConfig),
    filterBy: { values: FilterBySerializer(taskConfig, searchString) },
  };
}
