// export interface Coordinate {
//   lat: string;
//   lon: string;
// }

import { Company, CompanyDeserializer } from "@/models/company";
import { Error } from "@/models/error";

export interface UserLicense {
  key: string;
  name: string;
  sku: string;
}

// export interface Company {
//   id: string;
//   type: number;
//   name: string;
//   legalName: string;
//   vat: string;
//   city: string;
//   country: string;
//   region: string;
//   postalCode: string;
//   address: string;
//   fullAddress: string;
//   location: Coordinate;
//   telephone: string;
//   parentCompany?: number;
//   createdAt?: Date;
//   updatedAt?: Date;
// }

export interface User {
  id: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  createdAt: number;
  expireAt?: number;
  company?: Company;
  license?: string[];
  roles: string[];
  admin: boolean;
  twoFactorEnabled: boolean;
}

export interface UserList {
  userId: string;
  userName: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  expireDate: number | null;
  creationDate: number;
  license: UserLicense | null;
}

export interface UserForm {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  showPassword: boolean;
}

export default {
  deserialize(input: Record<string, any>): User {
    // const company = input.company ? CompanyDeserializer(input.company) : undefined;
    if (!input?.company) {
      throw new Error("Missing company on user data");
    }
    const company = CompanyDeserializer(input.company);
    return {
      id: input.userId,
      userName: input.userName,
      email: input.email,
      firstName: input.firstName,
      lastName: input.lastName,
      fullName: input.firstName + " " + input.lastName,
      expireAt: input.expireDate,
      createdAt: input.creationDate,
      company: company,
      license: input.license,
      roles: input.roles,
      admin: input.isAdmin,
      twoFactorEnabled: input.twoFactorEnabled ? input.twoFactorEnabled : false,
    };
  },

  serialize(input: User): Record<string, any> {
    return {
      userName: input.userName,
    };
  },
};
