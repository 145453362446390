import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import { FilterBy, FooterProps, OptionsTable } from "@/models/table";
import clone from "lodash/clone";
import i18n from "@/i18n";

export const DefaultFooterProps = {
  "items-per-page-options": [1, 5, 10, 15],
  "items-per-page-text": i18n.t("common.itemsPerPage"),
  "page-text": "1/1",
} as FooterProps;

export const DefaultQueryOptions = {
  itemsPerPage: 10,
  page: 1,
  sortBy: [],
  sortDesc: [],
} as OptionsTable;

export interface ListableState {
  totalItems: number;
  loading: boolean;
  queryOptions: OptionsTable;
  searchString: string;
  additionalFilters: FilterBy[];
}

type ListableContext = ActionContext<ListableState, RootState>;

export const namespaced = true;

export const state = {
  totalItems: 0,
  queryOptions: clone(DefaultQueryOptions),
  searchString: "",
  loading: false,
  additionalFilters: [],
};

export const getters: GetterTree<ListableState, RootState> = {
  totalItems: (state): number => {
    return state.totalItems;
  },
  footerProps: (state): FooterProps => {
    const props = clone(DefaultFooterProps);
    const totalPages = state.totalItems
      ? Math.ceil(state.totalItems / state.queryOptions.itemsPerPage)
      : 1;
    props["page-text"] = state.queryOptions.page + "/" + totalPages;
    return props;
  },
  queryOptions: (state): OptionsTable => {
    return state.queryOptions;
  },
  searchString: (state): string => {
    return state.searchString;
  },
  loading: (state): boolean => {
    return state.loading;
  },
  additionalFilters: (state): FilterBy[] => {
    return state.additionalFilters;
  },
};

export const mutations: MutationTree<ListableState> = {
  setTotalItems(state: ListableState, totalItems: number) {
    state.totalItems = totalItems;
  },
  setQueryOptions(state: ListableState, options: OptionsTable) {
    state.queryOptions = options;
  },
  setSearchString(state: ListableState, string: string) {
    state.searchString = string;
  },
  setLoading(state: ListableState, loading: boolean) {
    state.loading = loading;
  },
  setAdditionalFilters(state: ListableState, filters: FilterBy[]) {
    state.additionalFilters = filters;
  },
  resetQueryOptions(state: ListableState) {
    state.queryOptions = clone(DefaultQueryOptions);
  },
  // addAdditionalFilter(state: ListableState, filter: FilterBy) {
  //   state.additionalFilters.push(filter);
  // },
  // removeAdditionalFilter(state: ListableState, filter: FilterBy) {
  //   state.additionalFilters = state.additionalFilters.filter(f => {
  //     return f.field != filter.field;
  //   });
  // },
  // resetAdditionalFilters(state: ListableState) {
  //   state.additionalFilters = [];
  // }
};

export const actions: ActionTree<ListableState, RootState> = {
  async updateSearchString(
    context: ListableContext,
    search: string
  ): Promise<void> {
    context.commit("setSearchString", search);
    return context.dispatch("fetchList");
  },

  async updateQueryOptions(
    context: ListableContext,
    options: OptionsTable
  ): Promise<void> {
    context.commit("setQueryOptions", options);
    return context.dispatch("fetchList");
  },

  async updateAdditionalFilters(
    context: ListableContext,
    filters: FilterBy[]
  ): Promise<void> {
    context.commit("setAdditionalFilters", filters);
    return context.dispatch("fetchList");
  },

  async fetchList(): Promise<void> {
    //DUMMY action extend me
    return;
  },
};
