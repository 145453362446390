var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    {
      staticClass: "va-avatar",
      attrs: { size: _vm.pixelSize, color: _vm.color, tile: _vm.tile },
      on: {
        click: function() {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.image
        ? _c("v-img", { attrs: { src: _vm.image, alt: _vm.altText } })
        : _vm._e(),
      !_vm.image
        ? _c(
            "span",
            {
              staticClass: "text-uppercase font-weight-bold",
              class: [_vm.textSize]
            },
            [_vm._v(" " + _vm._s(_vm.initials) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }