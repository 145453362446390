var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "va-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("roomActions.title")))
      ]),
      _c("va-card", [
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("va-button", {
              staticClass: "mb-1",
              attrs: {
                size: "s",
                loading: _vm.deleting,
                cta: _vm.$t("common.deleteRoom"),
                color: "red",
                type: "outline",
                disabled: _vm.room && _vm.room.overallState !== 3
              },
              on: { click: _vm.onDelete }
            }),
            _vm.room && _vm.room.overallState !== 3
              ? _c("div", { staticClass: "grey--text" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("roomActions.deleteWarning")) + " "
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }