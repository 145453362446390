var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { attrs: { id: "logs" } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("div", { staticClass: "text-4xl font-bold" }, [
                  _vm._v(_vm._s(_vm.$t("logs.title")))
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("logs.content")))]),
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var invalid = ref.invalid
                          return [
                            _c(
                              "section",
                              { staticClass: "date-range" },
                              [
                                _c("h3", { staticClass: "mb-2" }, [
                                  _vm._v(_vm._s(_vm.$t("common.dateRange")))
                                ]),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("date-time-picker", {
                                          attrs: {
                                            required: "",
                                            label: _vm.$t("common.startDate"),
                                            inputDate: _vm.startDate,
                                            dateTime: true,
                                            max: Date.now()
                                          },
                                          on: {
                                            "update:inputDate": function(
                                              $event
                                            ) {
                                              _vm.startDate = $event
                                            },
                                            "update:input-date": function(
                                              $event
                                            ) {
                                              _vm.startDate = $event
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("date-time-picker", {
                                          attrs: {
                                            "persistent-hint": "",
                                            hint: _vm.$t("logs.endDateHint"),
                                            label: _vm.$t("common.endDate"),
                                            inputDate: _vm.endDate,
                                            dateTime: true,
                                            min: _vm.startDate,
                                            max: Date.now(),
                                            disabled:
                                              _vm.startDate === undefined
                                          },
                                          on: {
                                            "update:inputDate": function(
                                              $event
                                            ) {
                                              _vm.endDate = $event
                                            },
                                            "update:input-date": function(
                                              $event
                                            ) {
                                              _vm.endDate = $event
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "section",
                              { staticClass: "room-user mb-6" },
                              [
                                _c("h3", { staticClass: "mb-2" }, [
                                  _vm._v(_vm._s(_vm.$t("logs.roomUser")))
                                ]),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            outlined: "",
                                            items: _vm.currentRooms,
                                            "item-value": "id",
                                            "item-text": "name",
                                            label: _vm.$t("common.roomId"),
                                            clearable: "",
                                            "hide-details": "auto"
                                          },
                                          model: {
                                            value: _vm.roomId,
                                            callback: function($$v) {
                                              _vm.roomId = $$v
                                            },
                                            expression: "roomId"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "6" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            outlined: "",
                                            items: _vm.currentUsers,
                                            "item-value": "userId",
                                            "item-text": "userName",
                                            label: _vm.$t("common.userId"),
                                            clearable: "",
                                            "hide-details": "auto"
                                          },
                                          model: {
                                            value: _vm.userId,
                                            callback: function($$v) {
                                              _vm.userId = $$v
                                            },
                                            expression: "userId"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("section", { staticClass: "filters" }, [
                              _c(
                                "h3",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.filters")))
                                  ]),
                                  _c("v-switch", {
                                    staticClass:
                                      "ml-2 mt-0 pt-0 d-inline-block",
                                    attrs: { inset: "", "hide-details": "" },
                                    on: { change: _vm.onFiltersEnabled },
                                    model: {
                                      value: _vm.filtersEnabled,
                                      callback: function($$v) {
                                        _vm.filtersEnabled = $$v
                                      },
                                      expression: "filtersEnabled"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.filtersEnabled
                                ? _c(
                                    "div",
                                    { staticClass: "mt-6" },
                                    _vm._l(_vm.filtersOptions, function(
                                      filter,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "filter-group input-border",
                                          class: ["filter-" + filter.id, "my-3"]
                                        },
                                        [
                                          _c("h3", [
                                            _vm._v(_vm._s(filter.label))
                                          ]),
                                          _vm._l(filter.items, function(
                                            item,
                                            index
                                          ) {
                                            return _c("v-checkbox", {
                                              key: index,
                                              staticClass:
                                                "filter-item checkbox-toggle",
                                              attrs: {
                                                disabled: _vm.downloading,
                                                "hide-details": "",
                                                value: item.value,
                                                label: item.label,
                                                "off-icon":
                                                  "mdi-checkbox-blank-circle-outline",
                                                "on-icon": "mdi-check-circle"
                                              },
                                              model: {
                                                value: _vm.filters,
                                                callback: function($$v) {
                                                  _vm.filters = $$v
                                                },
                                                expression: "filters"
                                              }
                                            })
                                          })
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mt-8",
                                attrs: {
                                  depressed: "",
                                  color: "accent",
                                  disabled:
                                    invalid ||
                                    _vm.downloading ||
                                    _vm.filters.length === 0,
                                  loading: _vm.downloading
                                },
                                on: { click: _vm.download }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("common.download")) + " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }