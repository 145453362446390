var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [
              _c("rooms-map", {
                attrs: {
                  "markers-list": _vm.marker,
                  "show-all": false,
                  "map-center": _vm.center
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "va-title d-flex align-self-center" },
        [
          _c("va-back", {
            attrs: {
              target: "/buildings",
              tooltip: _vm.$t("buildingDetail.back")
            }
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v(
              _vm._s(_vm.$t("common.building") + " " + _vm.currentBuilding.name)
            )
          ])
        ],
        1
      ),
      _c(
        "va-card",
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("va-button", {
                staticClass: "ml-auto",
                attrs: { cta: _vm.$t("common.edit") },
                on: { click: _vm.onEdit }
              })
            ],
            1
          ),
          _c("building-info", { attrs: { building: _vm.currentBuilding } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }