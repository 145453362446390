var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-bar d-flex align-center" },
    [
      _c("va-icon", {
        staticClass: "cursor-pointer ml-6 mr-auto",
        attrs: { icon: "vi vi-menu-6" },
        on: {
          click: function($event) {
            return _vm.$store.commit("app/setDisplayMenu", true)
          }
        }
      }),
      _c("info-viewer", { staticClass: "mr-6" }),
      _c("light-switcher", { staticClass: "mr-6" }),
      _c("va-divider"),
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            transition: "slide-y-transition",
            "close-on-click": "",
            origin: "center center",
            "offset-y": ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                return [
                  _c("va-avatar", {
                    attrs: {
                      text: _vm.user.firstName + " " + _vm.user.lastName,
                      color: "primary",
                      size: "m"
                    },
                    on: { click: _vm.toggleMenu }
                  })
                ]
              }
            }
          ]),
          model: {
            value: _vm.displayMenu,
            callback: function($$v) {
              _vm.displayMenu = $$v
            },
            expression: "displayMenu"
          }
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { attrs: { link: "", to: "/profile" } },
                [
                  _c("v-list-item-title", [
                    _vm._v(" " + _vm._s(_vm.$t("common.profile")) + " ")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { link: "" }, on: { click: _vm.onLogout } },
                [
                  _c("v-list-item-title", [
                    _vm._v(" " + _vm._s(_vm.$t("common.logout")) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }