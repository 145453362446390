


































































































































































import Vue, { VueConstructor } from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import VaInfoRow from "@/components/molecules/VaInfoRow.vue";
import {
  License,
  licenseDetailRoomConfig,
  licenseDetailUserConfig,
} from "@/models/license";
import Notifications from "@/components/organisms/Notifications.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaBack from "@/components/molecules/VaBack.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import dataTableMixin, {
  DataTableMixin,
} from "@/components/mixins/dataTableMixin";
import VaBadge from "@/components/atoms/VaBadge.vue";
import versionUtil from "@/utils/versionUtil";
import VaButton from "@/components/atoms/VaButton.vue";

export default (Vue as VueConstructor<DataTableMixin>).extend({
  name: "LicenseDetail",
  mixins: [dataTableMixin],
  components: {
    VaButton,
    VaBadge,
    VaIcon,
    VaNoDataTag,
    VaTableTop,
    VaBack,
    VaCard,
    VaInfoRow,
    MainColumns,
    Notifications,
  },
  data() {
    return {
      storeName: "license",
      roomsConfig: licenseDetailRoomConfig,
      usersConfig: licenseDetailUserConfig,
    };
  },
  async beforeMount() {
    this.resetQueryOptions();
    this.searchString = "";
    await this.$store.dispatch("license/fetchLicense", this.$route.params.id);
  },
  methods: {
    isRoom(license: License): boolean {
      return license.type === "pod";
    },
    async clearSeats() {
      this.$store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: this.$i18n.t("licenseDetail.clearSeats"),
        description: `${this.$i18n.t("licenseDetail.clearSeatsConfirm")}`,
        callback: async () => {
          await this.$store.dispatch(
            "license/clearSeats",
            this.$route.params.id
          );
        },
      });
    },
    async clearExtensions() {
      this.$store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: this.$i18n.t("licenseDetail.clearExtensions"),
        description: `${this.$i18n.t("licenseDetail.clearExtensionsConfirm")}`,
        callback: async () => {
          await this.$store.dispatch(
            "license/clearExtensions",
            this.$route.params.id
          );
        },
      });
    },
    async deleteLicense() {
      this.$store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: this.$i18n.t("licenseDetail.deleteLicense"),
        description: `${this.$i18n.t("licenseDetail.deleteLicenseConfirm")}`,
        callback: async () => {
          await this.$store.dispatch(
            "license/deleteLicense",
            this.$route.params.id
          );
        },
      });
    },
  },
  computed: {
    isProduction(): boolean {
      return versionUtil.isProduction();
    },
    license(): License {
      return this.$store.getters["license/currentLicense"];
    },
  },
});
