











































import Vue, { VueConstructor } from "vue";
import { MfaProvider, providerTypes } from "@/models/mfaProvider";
import BackupCodesProvider from "@/components/organisms/auth/BackupCodesProvider.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import errorMixin, { LoggerMixin } from "@/components/mixins/errorMixin";
import MfaQrcodeDialog from "@/components/organisms/auth/MfaQrcodeDialog.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "MfaSettings",
  components: {
    VaTextField,
    BackupCodesProvider,
    VaButton,
    MfaQrcodeDialog,
  },
  mixins: [errorMixin],
  data() {
    return {
      verificationCode: "",
    };
  },
  beforeMount() {
    this.verificationCode = "";
    this.$store.dispatch("mfa/fetchAvailableProviders");
    this.$store.dispatch("mfa/fetchEnabledProviders");
  },
  methods: {
    isEnabled(item: MfaProvider): boolean {
      return this.enabledProviders.some((p: MfaProvider) => {
        return p.type === item.type;
      });
    },
    async enableMailProvider() {
      this.$store.dispatch("mfa/confirmProvider", {
        token: this.mfaToken,
        code: this.verificationCode,
      });
    },
    async handleProvider(item: MfaProvider) {
      if (this.isEnabled(item)) {
        this.$store.dispatch("mfa/disableProvider", item);
      } else {
        this.$store.dispatch("mfa/enableProvider", item);
      }
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.getters["mfa/isLoading"];
    },
    providers(): MfaProvider[] {
      return this.$store.getters["mfa/providers"];
    },
    enabledProviders(): MfaProvider[] {
      return this.$store.getters["mfa/enabledProviders"];
    },
    types(): Record<string, number> {
      return providerTypes;
    },
    mfaToken(): string {
      return this.$store.getters["mfa/mfaToken"];
    },
    providerToEnable(): MfaProvider | undefined {
      return this.$store.getters["mfa/providerToEnable"];
    },
    dialog: {
      get(): boolean {
        const provider = this.$store.getters["mfa/providerToEnable"];
        return provider && provider.type === this.types.authenticator;
      },
      set(val: boolean): void {
        if (!val) this.$store.commit("mfa/setProviderToEnable", undefined);
      },
    },
  },
});
