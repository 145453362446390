var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.providers, function(item) {
        return _c(
          "div",
          { key: item.type, staticClass: "d-flex justify-space-between" },
          [
            _vm._v(" " + _vm._s(item.name) + " "),
            _c("va-button", {
              staticClass: "mb-2",
              attrs: {
                size: "s",
                loading: _vm.loading || _vm.providerToEnable === item.type,
                cta: _vm.isEnabled(item)
                  ? _vm.$t("mfa.disableCta")
                  : _vm.$t("mfa.enableCta")
              },
              on: {
                click: function($event) {
                  return _vm.handleProvider(item)
                }
              }
            }),
            _c(
              "v-dialog",
              {
                attrs: { "content-class": "fullscreen card", scrollable: "" },
                model: {
                  value: _vm.dialog,
                  callback: function($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog"
                }
              },
              [_c("mfa-qrcode-dialog")],
              1
            )
          ],
          1
        )
      }),
      _c("v-divider", { staticClass: "my-4" }),
      _c(
        "div",
        [
          _vm.providerToEnable && _vm.providerToEnable.type === _vm.types.email
            ? _c("div", [
                _c("div", [_vm._v(_vm._s(_vm.$t("mfa.emailProvider")))]),
                _c(
                  "div",
                  { staticClass: "d-flex mt-3" },
                  [
                    _c("va-text-field", {
                      attrs: { dense: "", label: _vm.$t("mfa.insertCode") },
                      model: {
                        value: _vm.verificationCode,
                        callback: function($$v) {
                          _vm.verificationCode = $$v
                        },
                        expression: "verificationCode"
                      }
                    }),
                    _c("va-button", {
                      staticClass: "ml-4",
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.loading,
                        cta: _vm.$t("mfa.verifyCode")
                      },
                      on: { click: _vm.enableMailProvider }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.enabledProviders.length > 0
            ? _c("backup-codes-provider")
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }