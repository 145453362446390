var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: [
        "va-button",
        _vm.type === "square" ? "square square-" + _vm.color : "",
        _vm.type === "squareText" ? "squareText" : ""
      ],
      attrs: {
        color: _vm.color,
        disabled: _vm.disabled,
        loading: _vm.loading,
        depressed: !(_vm.type === "shadow"),
        text: _vm.type === "text" || _vm.type === "squareText",
        outlined: _vm.type === "outline" || _vm.type === "square",
        rounded: _vm.rounded,
        large: _vm.size === "l",
        "x-large": _vm.size === "xl",
        small: _vm.size === "s",
        "x-small": _vm.size === "xs",
        icon: _vm.type === "icon",
        block: _vm.block,
        fab: _vm.type === "circle",
        type: _vm.computedBtnType
      },
      on: {
        click: function() {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.displayPrependIcon
        ? _c("i", { class: [_vm.icon, _vm.displayCta ? "mr-1" : ""] })
        : _vm._e(),
      _vm.displayCta ? _c("span", [_vm._v(_vm._s(_vm.cta))]) : _vm._e(),
      _vm.displayAppendIcon
        ? _c("i", {
            class: [
              _vm.icon,
              _vm.displayCta ? "ml-1" : "",
              _vm.size === "s" ? "smallIcon" : ""
            ]
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }