var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-tabs",
        {
          staticClass: "va-card pa-0 mb-6",
          attrs: { "show-arrows": "" },
          on: { change: _vm.onChange },
          model: {
            value: _vm.window,
            callback: function($$v) {
              _vm.window = $$v
            },
            expression: "window"
          }
        },
        _vm._l(_vm.tabs, function(item) {
          return _c("v-tab", { key: item, attrs: { value: item } }, [
            _vm._v(" " + _vm._s(_vm.$t("policiesGroups." + item)) + " ")
          ])
        }),
        1
      ),
      _c(
        "v-window",
        {
          model: {
            value: _vm.window,
            callback: function($$v) {
              _vm.window = $$v
            },
            expression: "window"
          }
        },
        _vm._l(_vm.tabs, function(item, index) {
          return _c(
            "v-window-item",
            { key: index, attrs: { value: index } },
            [_c("policies-list")],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }