var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c("room-remote-management"),
        _c("room-last-commands", { staticClass: "mt-6" }),
        _c("room-update", { staticClass: "mt-6" }),
        _c("room-meeting-settings", { staticClass: "mt-6" }),
        _c("room-policies", { staticClass: "mt-6" }),
        _c("room-superadmin", { staticClass: "mt-6" }),
        _c("room-actions", { staticClass: "mt-6" }),
        _c("room-notices")
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }