var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "shadow-md rounded border-a",
      staticStyle: { height: "500px" }
    },
    [
      _vm.showMap
        ? _c(
            "l-map",
            {
              staticClass: "rounded",
              staticStyle: { height: "100%" },
              attrs: {
                zoom: _vm.zoom,
                center: _vm.center,
                options: _vm.mapOptions
              },
              on: {
                "update:center": _vm.centerUpdate,
                "update:zoom": _vm.zoomUpdate
              }
            },
            [
              _c("l-tile-layer", {
                attrs: { url: _vm.url, attribution: _vm.attribution }
              }),
              _c(
                "vue2-leaflet-markercluster",
                { attrs: { options: _vm.clusterOptions } },
                _vm._l(_vm.markers, function(marker, index) {
                  return _c(
                    "l-marker",
                    {
                      key: index,
                      ref: "markersRef",
                      refInFor: true,
                      attrs: { "lat-lng": marker.position, icon: _vm.icon }
                    },
                    [
                      _c("l-popup", [
                        _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/buildings/" + marker.id } },
                              [
                                _c("div", { staticClass: "d-flex mb-2" }, [
                                  _c("i", {
                                    staticClass:
                                      "vi vi-new-construction primary--text mr-2",
                                    staticStyle: { "font-size": "1.5rem" }
                                  }),
                                  _c("span", { staticClass: "font-bold" }, [
                                    _vm._v(_vm._s(marker.name))
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v("Rooms count:")
                          ]),
                          _vm._v(" " + _vm._s(marker.roomsCount) + " ")
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v("State:")
                          ]),
                          _vm._v(" Medium")
                        ])
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }