var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-data-table", {
        staticClass: "border-a pa-6 row-pointer rounded",
        attrs: {
          headers: _vm.generateHeaders(_vm.tableConfig),
          items: _vm.apps,
          options: _vm.groupOptions,
          "server-items-length":
            _vm.$store.getters["podApplication/totalItems"],
          "item-key": "ID",
          loading: _vm.$store.getters["podApplication/isLoading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["podApplication/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.onEdit(item)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: {
                    title: _vm.$t("podApplications.title"),
                    cta: _vm.$t("podApplications.application")
                  },
                  on: { ctaClick: _vm.onCreate },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-img", {
                      staticClass: "mr-3",
                      attrs: {
                        src: item.icon ? item.icon.path : _vm.logo,
                        "lazy-src": item.icon ? item.icon.path : _vm.logo,
                        height: 30,
                        width: 30,
                        "max-height": 30,
                        "max-width": 30
                      }
                    }),
                    _vm._v(" " + _vm._s(item.name) + " ")
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("va-button", {
                  attrs: {
                    icon: "vi vi-c-info",
                    "icon-position": "append",
                    size: "s",
                    disabled: item.isDefault,
                    cta: _vm.$t("common.edit")
                  }
                })
              ]
            }
          },
          {
            key: "item.isDefault",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("va-badge", {
                  staticClass: "mx-4",
                  attrs: {
                    size: "xs",
                    text: item.isDefault
                      ? _vm.$t("podApplications.default")
                      : _vm.$t("podApplications.custom"),
                    color: item.isDefault ? "secondary" : "primary"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "va-dialog", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "va-card",
            [
              _c("div", { staticClass: "va-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.update
                        ? _vm.$t("podApplications.newTitle")
                        : _vm.$t("podApplications.editTitle")
                    ) +
                    " "
                )
              ]),
              _vm.app
                ? _c(
                    "v-form",
                    {
                      ref: "editApplicationForm",
                      attrs: {
                        "lazy-validation": "",
                        onSubmit: "return false;"
                      },
                      on: { submit: _vm.onSave },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("va-text-field", {
                        staticClass: "mb-6",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("common.name")
                        },
                        model: {
                          value: _vm.app.name,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "name", $$v)
                          },
                          expression: "app.name"
                        }
                      }),
                      _c("va-text-field", {
                        staticClass: "mb-6",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("podApplications.path")
                        },
                        model: {
                          value: _vm.app.path,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "path", $$v)
                          },
                          expression: "app.path"
                        }
                      }),
                      _c("va-text-field", {
                        staticClass: "mb-6",
                        attrs: { label: _vm.$t("podApplications.parameters") },
                        model: {
                          value: _vm.app.parameters,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "parameters", $$v)
                          },
                          expression: "app.parameters"
                        }
                      }),
                      _c("va-text-field", {
                        staticClass: "mb-6",
                        attrs: { label: _vm.$t("common.description") },
                        model: {
                          value: _vm.app.description,
                          callback: function($$v) {
                            _vm.$set(_vm.app, "description", $$v)
                          },
                          expression: "app.description"
                        }
                      }),
                      _c("file-uploader", {
                        staticClass: "my-4",
                        attrs: {
                          files: _vm.app.icon ? [_vm.app.icon] : [],
                          title: _vm.$t("podApplications.customIcon"),
                          accept: [".png", ".jpg", ".jpeg", ".ico"],
                          "file-type": 2
                        },
                        on: { upload: _vm.updateIcon }
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _vm.update
                            ? _c("va-button", {
                                attrs: {
                                  disabled: !_vm.valid,
                                  cta: _vm.$t("common.delete"),
                                  color: "error"
                                },
                                on: { click: _vm.onDelete }
                              })
                            : _vm._e(),
                          _c("va-button", {
                            staticClass: "mr-4 ml-auto",
                            attrs: {
                              color: "secondary",
                              type: "outline",
                              loading:
                                _vm.$store.getters["podApplication/isLoading"],
                              cta: _vm.$t("common.dismiss")
                            },
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          }),
                          _c("va-button", {
                            attrs: {
                              submit: "",
                              disabled: !_vm.valid,
                              loading:
                                _vm.$store.getters["podApplication/isLoading"],
                              cta: _vm.$t("common.submit")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }