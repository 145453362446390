

















































































































import Vue, { PropType } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { HeatMapChartData } from "@/models/chart";

interface CellStyle {
  borderColor: string;
  backgroundColor: string;
}

interface LegendItem {
  borderColor: string;
  backgroundColor: string;
  value: number;
}

export default Vue.extend({
  name: "HeatMapChart",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: Object as PropType<HeatMapChartData>,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    showLegend: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showValue: true,
      selection: [] as Array<string>,
      filter: [] as Array<number>,
    };
  },
  methods: {
    getStyle(value: number): CellStyle {
      const legend = this.getLegendByValue(value);
      return {
        borderColor: legend.borderColor,
        backgroundColor: legend.backgroundColor,
      };
    },
    getLegendByValue(value: number): LegendItem {
      let leg: LegendItem = {} as LegendItem;
      this.legend.forEach((legendItem) => {
        if (value >= legendItem.value) {
          leg = cloneDeep(legendItem);
        }
      });
      return leg;
    },
    linearBackground(start: string, end: string): string {
      return `linear-gradient(to right, ${start}, ${end ? end : start})`;
    },
    onLegendClick(legend: number): void {
      const index = this.filter.findIndex((el: number) => {
        return el === legend;
      });
      if (index === -1) {
        this.filter.push(legend);
      } else {
        this.filter.splice(index, 1);
      }
    },
    onCellClick(): void {
      // onCellClick(dataset: number, cell: number): void {
      // TODO
      return;
      // const index = dataset.toString() + ":" + cell.toString();
      // const selectedIndex = this.selection.findIndex((sel: string) => {
      //   return sel === index;
      // });
      // if (selectedIndex === -1) {
      //   this.selection.push(index);
      // } else {
      //   this.selection.splice(selectedIndex, 1);
      // }
    },
    isCellDisabled(cell: number): boolean {
      let legendIndex;
      this.legend.forEach((legendItem, index) => {
        if (cell >= legendItem.value) {
          legendIndex = index;
        }
      });
      return legendIndex === undefined
        ? false
        : this.filter.includes(legendIndex);
    },
  },
  computed: {
    datasets() {
      return this.data.datasets;
    },
    max(): number {
      let max = 0;
      this.datasets.map((dataset) => {
        dataset.data.map((value: number) => {
          if (value > max) {
            max = value;
          }
        });
      });
      return max;
    },
    legend(): Array<LegendItem> {
      const legend = [] as Array<LegendItem>;

      if (!this.data.palette) return legend;

      const intervals = Math.min(
        this.data.palette.backgrounds.length,
        this.data.palette.borders.length
      );

      const step = Math.floor(this.max / intervals);

      for (let i = 0; i < intervals; i++) {
        legend.push({
          value: step * i,
          borderColor: this.data.palette.borders[intervals - i - 1],
          backgroundColor: this.data.palette.backgrounds[intervals - i - 1],
        });
      }
      return legend;
    },
    classes(): Array<string> {
      const classes = [];
      if (this.showValue) {
        classes.push("show-value");
      }
      if (this.showLegend) {
        classes.push("show-legend");
      }
      if (this.title) {
        classes.push("has-title");
      }
      if (this.noData) {
        classes.push("no-data");
      }
      return classes;
    },
    noData(): boolean {
      return (
        this.legend.length > 0 &&
        this.legend[0].value === this.legend[this.legend.length - 1].value
      );
    },
  },
});
