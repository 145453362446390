


















































































































































import Vue, { VueConstructor } from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { License, LicenseData, licenseConfig } from "@/models/license";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaBadge from "@/components/atoms/VaBadge.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import validationMixin from "@/components/mixins/validationMixin";
import dataTableMixin, {
  DataTableMixin,
} from "@/components/mixins/dataTableMixin";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import Notifications from "@/components/organisms/Notifications.vue";

export default (Vue as VueConstructor<DataTableMixin>).extend({
  name: "LicensesList",
  mixins: [validationMixin, dataTableMixin],
  components: {
    VaSwitch,
    VaTextField,
    VaButton,
    VaCard,
    VaDetailsButton,
    VaTableTop,
    MainColumns,
    VaNoDataTag,
    VaBadge,
    Notifications,
  },
  async beforeMount() {
    this.$store.commit("license/resetCurrentLicense");
    this.resetQueryOptions();
    this.searchString = "";
  },
  data() {
    return {
      storeName: "license",
      dialog: false,
      newLicense: {
        licenseKey: "",
        isRenewal: false,
      } as LicenseData,
      tableConfig: licenseConfig,
      fileLicense: undefined,
    };
  },
  methods: {
    async onUpload(): Promise<void> {
      await this.$store.dispatch("onprem/uploadLicense", this.fileLicense);
      this.dialog = false;
      this.fileLicense = undefined;
    },

    async onSave(): Promise<void> {
      if (
        (
          this.$refs.newLicenseForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        this.dialog = false;
        await this.$store.dispatch("license/addLicense", this.newLicense);
        this.newLicense = {
          licenseKey: "",
          isRenewal: false,
        };
      }
    },
    statusColor(license: License): string {
      switch (license.status) {
        case "active":
          return "success";
        case "inactive":
          return "error";
        case "expired":
          return "secondary";
        default:
          return "secondary";
      }
    },
  },
  computed: {
    isOnPrem(): boolean {
      return this.$store.getters["onprem/isOnPrem"];
    },
  },
});
