var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "va-subtitle-s" }, [
        _vm._v(_vm._s(_vm.$t("common.settings")))
      ]),
      _c("date-time-picker", {
        staticClass: "mt-4",
        attrs: {
          label: _vm.$t("common.startDate"),
          inputDate: _vm.options.startDate,
          dateTime: true,
          min: Date.now() + 1800000
        },
        on: {
          "update:inputDate": function($event) {
            return _vm.$set(_vm.options, "startDate", $event)
          },
          "update:input-date": function($event) {
            return _vm.$set(_vm.options, "startDate", $event)
          }
        }
      }),
      _c("v-select", {
        staticClass: "mt-4",
        attrs: {
          outlined: "",
          attach: "",
          items: _vm.schedulerRepeatOptions,
          "item-text": "label",
          "item-value": "id",
          label: _vm.$t("common.repeat"),
          "hide-details": "auto"
        },
        model: {
          value: _vm.options.type,
          callback: function($$v) {
            _vm.$set(_vm.options, "type", $$v)
          },
          expression: "options.type"
        }
      }),
      _vm.options.type === "DAILY" || _vm.options.type === "WEEKLY"
        ? _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("span", { staticClass: "pt-3 pr-6" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("common.every")) +
                    " " +
                    _vm._s(_vm.options.recurrence) +
                    " " +
                    _vm._s(
                      _vm.options.type === "WEEKLY"
                        ? _vm.$t("common.week")
                        : _vm.$t("common.day")
                    ) +
                    " "
                )
              ]),
              _c("v-slider", {
                staticClass: "mt-3",
                attrs: {
                  min: 1,
                  max: 50,
                  "thumb-label": "",
                  "thumb-size": 26,
                  "hide-details": ""
                },
                model: {
                  value: _vm.options.recurrence,
                  callback: function($$v) {
                    _vm.$set(_vm.options, "recurrence", $$v)
                  },
                  expression: "options.recurrence"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.options.type === "WEEKLY"
        ? _c("v-autocomplete", {
            staticClass: "mt-6",
            attrs: {
              outlined: "",
              items: _vm.recurOptions.days,
              multiple: "",
              "item-value": "id",
              "item-text": "label",
              label: _vm.$t("common.recurrency"),
              clearable: "",
              "hide-details": "auto"
            },
            model: {
              value: _vm.options.weekDays,
              callback: function($$v) {
                _vm.$set(_vm.options, "weekDays", $$v)
              },
              expression: "options.weekDays"
            }
          })
        : _vm._e(),
      _vm.options.type === "MONTHLY"
        ? _c("v-autocomplete", {
            staticClass: "mt-6",
            attrs: {
              outlined: "",
              items: _vm.recurOptions.months,
              multiple: "",
              "item-value": "id",
              "item-text": "label",
              label: _vm.$t("common.recurrency"),
              clearable: "",
              "hide-details": "auto"
            },
            model: {
              value: _vm.options.months,
              callback: function($$v) {
                _vm.$set(_vm.options, "months", $$v)
              },
              expression: "options.months"
            }
          })
        : _vm._e(),
      _vm.options.type === "MONTHLY"
        ? _c("v-autocomplete", {
            staticClass: "mt-6",
            attrs: {
              outlined: "",
              items: _vm.recurOptions.montDays,
              multiple: "",
              "item-value": "id",
              "item-text": "label",
              label: _vm.$t("common.monthDays"),
              clearable: "",
              "hide-details": "auto"
            },
            model: {
              value: _vm.options.monthDays,
              callback: function($$v) {
                _vm.$set(_vm.options, "monthDays", $$v)
              },
              expression: "options.monthDays"
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("span", { staticClass: "pt-3 pr-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("schedulersLauncher.schedulerDelay")) +
                " " +
                _vm._s(_vm.options.randomDelay) +
                " " +
                _vm._s(_vm.$t("common.seconds")) +
                " "
            )
          ]),
          _c("v-slider", {
            staticClass: "mt-3",
            attrs: {
              min: 1,
              max: 100,
              "thumb-label": "",
              "thumb-size": 26,
              "hide-details": ""
            },
            model: {
              value: _vm.options.randomDelay,
              callback: function($$v) {
                _vm.$set(_vm.options, "randomDelay", $$v)
              },
              expression: "options.randomDelay"
            }
          })
        ],
        1
      ),
      _c("date-time-picker", {
        staticClass: "mt-4",
        attrs: {
          label: _vm.$t("common.endDate"),
          inputDate: _vm.options.endDate,
          dateTime: true,
          min: Date.now()
        },
        on: {
          "update:inputDate": function($event) {
            return _vm.$set(_vm.options, "endDate", $event)
          },
          "update:input-date": function($event) {
            return _vm.$set(_vm.options, "endDate", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }