












import Vue from "vue";
import LightSwitcher from "@/components/organisms/LightSwitcher.vue";
import tenantService from "@/services/tenantService";

export default Vue.extend({
  name: "Auth",
  components: { LightSwitcher },
  computed: {
    logo() {
      return this.$store.getters["session/darkMode"]
        ? tenantService.requireAsset("powered-by-light.svg")
        : tenantService.requireAsset("powered-by-dark.svg");
    },
  },
});
