var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Notification")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c("va-notification", { attrs: { type: "info" } }, [_vm._v("info")]),
      _c("va-notification", { attrs: { type: "success" } }, [
        _vm._v("success")
      ]),
      _c("va-notification", { attrs: { type: "warning" } }, [
        _vm._v("warning")
      ]),
      _c("va-notification", { attrs: { type: "error" } }, [_vm._v("error")]),
      _c("va-notification", { attrs: { type: "" } }, [
        _c("b", [_vm._v("Lorem ipsum dolor sit amet")]),
        _vm._v(", consectetur adipiscing elit ")
      ]),
      _c("va-notification", { attrs: { outlined: "", type: "info" } }, [
        _vm._v("info outlined")
      ]),
      _c("va-notification", { attrs: { outlined: "", type: "success" } }, [
        _vm._v("success outlined")
      ]),
      _c("va-notification", { attrs: { outlined: "", type: "warning" } }, [
        _vm._v("warning outlined")
      ]),
      _c("va-notification", { attrs: { outlined: "", type: "error" } }, [
        _vm._v("error outlined")
      ]),
      _c("va-notification", { attrs: { outlined: "", type: "" } }, [
        _vm._v(' "Lorem ipsum dolor sit amet, consectetur '),
        _c("b", [_vm._v("adipiscing elit,")]),
        _vm._v(' " ')
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }