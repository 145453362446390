


















































































































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { PodApplication, podApplicationConfig } from "@/models/podApplication";
import VaButton from "@/components/atoms/VaButton.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import clone from "lodash/clone";
import validationMixin from "@/components/mixins/validationMixin";
import dataTableMixin from "@/components/mixins/dataTableMixin";
import FileUploader from "@/components/shared/FileUploader.vue";
import { FileFormatted } from "@/models/file";
import tenantService from "@/services/tenantService";
import VaBadge from "@/components/atoms/VaBadge.vue";

export default Vue.extend({
  name: "PodApplicationList",
  mixins: [validationMixin, dataTableMixin],
  components: {
    VaBadge,
    FileUploader,
    VaCard,
    VaTextField,
    VaTableTop,
    MainColumns,
    VaButton,
    VaNoDataTag,
    Notifications,
  },
  data() {
    return {
      update: false,
      storeName: "podApplication",
      tableConfig: podApplicationConfig,
    };
  },
  methods: {
    updateIcon(icon: FileFormatted) {
      this.$store.commit("podApplication/setCurrentPodApplicationIcon", icon);
    },
    onSave(): void {
      if (
        (
          this.$refs.editApplicationForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        if (this.update) {
          this.$store.dispatch("podApplication/updateCurrentPodApplication");
        } else {
          this.$store.dispatch("podApplication/createPodApplication");
        }
      }
    },
    onDelete(): void {
      this.$store.dispatch("podApplication/deleteCurrentPodApplication");
    },
    onCreate(): void {
      this.update = false;
      this.$store.commit("podApplication/resetCurrentPodApplication");
      this.dialog = true;
      this.$nextTick(() => {
        (
          this.$refs.editApplicationForm as Vue & {
            resetValidation: () => void;
          }
        ).resetValidation();
      });
    },
    onEdit(app: PodApplication): void {
      if (app.isDefault) return;
      this.$store.commit("podApplication/setCurrentPodApplication", clone(app));
      this.update = true;
      this.dialog = true;
    },
  },
  computed: {
    logo() {
      return tenantService.requireAsset("square.svg");
    },
    app(): PodApplication | undefined {
      return this.$store.getters["podApplication/currentPodApplication"];
    },
    apps(): PodApplication[] {
      return this.$store.getters["podApplication/podApplications"];
    },
    dialog: {
      get(): boolean {
        return this.$store.getters["podApplication/editing"];
      },
      set(dialog: boolean): void {
        this.$store.commit("podApplication/setEditing", dialog);
      },
    },
  },
});
