var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "analytics-top-bar",
        {
          attrs: {
            title: "Average Efficency 10%",
            icon: "vi vi-cloudscale",
            "icon-color": "purple"
          }
        },
        [
          _vm._v(" Average Efficency is calculated by "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(
              " Ghost / Total meetings Average start time Meeting access (early, on time, late) "
            )
          ]),
          _vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit ")
        ]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-bar-chart", {
                    attrs: {
                      chartData: _vm.ghostVsTotal,
                      title: "Ghost meetings (no-show)",
                      legend: "",
                      stacked: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.averageStartTime,
                      area: "",
                      "hide-dots": "",
                      title: "Average start time"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.access,
                      title: "Meeting access",
                      "hide-dots": "",
                      legend: "",
                      stacked: "",
                      area: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-bar-chart", {
                    attrs: {
                      chartData: _vm.incidentsInMeeting,
                      title: "Incidents during meetings"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }