


























































import Vue from "vue";
import { RoomDetail } from "@/models/room";
import PreferenceUtil from "@/utils/preferenceUtil";
import { GroupedPolicy } from "@/models/policy";

export default Vue.extend({
  name: "RoomNotices",
  data() {
    return {
      show: false,
      dismiss: false,
    };
  },
  methods: {
    notShowAgain(): void {
      PreferenceUtil.setPreference("rooms." + this.room.id + ".notices", false);
      this.show = false;
    },
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
    offline(): boolean {
      return !this.room?.online;
    },
    update(): boolean {
      return this.room?.hasUpdate;
    },
    invalidPolicies(): boolean {
      return this.room?.policies.some((policy: GroupedPolicy) => {
        return policy.isInPolicy === false;
      });
    },
  },
  watch: {
    room(newVal: RoomDetail, oldVal: RoomDetail) {
      if (
        (!oldVal || oldVal.group != newVal.group) &&
        !this.$store.getters["policy/hasBeenChanged"] &&
        !newVal.isMaintenanceModeEnabled
      ) {
        const preference = PreferenceUtil.getPreference(
          "rooms." + newVal.id + ".notices"
        );
        if (preference === false) return;
        if (this.offline || this.update || this.invalidPolicies) {
          this.show = true;
        }
      }
    },
  },
});
