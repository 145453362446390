































































































































import Vue from "vue";
import VaCard from "@/components/molecules/VaCard.vue";
import LanguageConfiguration from "@/components/organisms/policies/LanguageConfiguration.vue";
import SecurityConfiguration from "@/components/organisms/policies/SecurityConfiguration.vue";
import UpdateConfiguration from "@/components/organisms/policies/UpdateConfiguration.vue";
import AdvancedConfiguration from "@/components/organisms/policies/AdvancedConfiguration.vue";
import CalendarConfiguration from "@/components/organisms/policies/CalendarConfiguration.vue";
import AppearanceConfiguration from "@/components/organisms/policies/AppearanceConfiguration.vue";
import WorkspaceConfiguration from "@/components/organisms/policies/WorkspaceConfiguration.vue";
import InstantMeetingsConfiguration from "@/components/organisms/policies/InstantMeetingsConfiguration.vue";
import LicenseConfiguration from "@/components/organisms/policies/LicenseConfiguration.vue";
import DisplayConfiguration from "@/components/organisms/policies/DisplayConfiguration.vue";
import PresentationConfiguration from "@/components/organisms/policies/PresentationConfiguration.vue";
import { PoliciesGroups, Policy } from "@/models/policy";
import VaBack from "@/components/molecules/VaBack.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import PolicyService from "@/services/policyService";
import ApplicationsConfiguration from "@/components/organisms/policies/ApplicationsConfiguration.vue";

export default Vue.extend({
  name: "PolicyDetailForm",
  components: {
    ApplicationsConfiguration,
    MainColumns,
    Notifications,
    VaTextField,
    VaButton,
    VaBack,
    VaCard,
    LanguageConfiguration,
    // HomeLauncherConfiguration,
    SecurityConfiguration,
    UpdateConfiguration,
    AdvancedConfiguration,
    CalendarConfiguration,
    AppearanceConfiguration,
    WorkspaceConfiguration,
    InstantMeetingsConfiguration,
    LicenseConfiguration,
    DisplayConfiguration,
    PresentationConfiguration,
  },
  mixins: [validationMixin],
  async beforeMount() {
    if (this.policyId) {
      this.$store.dispatch("policy/fetchDetail", this.policyId);
    }
  },
  data() {
    return {
      formState: {
        policy: {
          name: "",
          type: this.$store.getters["policy/currentGroup"],
          fields: {}, //TODO BAD BAD BAD fields has a different type
        } as Policy,
        valid: false,
      },
    };
  },
  methods: {
    onChange(): void {
      this.formState.policy.fields = {};
    },
    onSave(): void {
      // eslint-disable-next-line
      // @ts-ignore
      this.$refs.updateConfigurationForm?.validate();
      // eslint-disable-next-line
      // @ts-ignore
      this.$refs.childForm?.validate();
      const res = PolicyService.validateFileFields(this.formState.policy);
      this.$nextTick(() => {
        if (this.formState.valid && !res) {
          this.$store.dispatch("policy/savePolicy", this.formState.policy);
        }
        if (res) {
          this.$store.commit("notifications/displayNotification", {
            message: res,
            type: "error",
          });
        }
      });
    },
  },
  computed: {
    policyId(): string | undefined {
      return this.$route.params?.id && this.$route.params.id !== "new"
        ? this.$route.params.id
        : undefined;
    },
    storePolicy(): string | undefined {
      return this.$store.getters["policy/currentPolicy"];
    },
    groups(): Array<{ id: PoliciesGroups; label: string }> {
      const list = this.$store.getters["policy/groupsList"];
      return list.map((item: string) => {
        return {
          id: item,
          label: this.$i18n.t(`policiesGroups.${item}`),
        };
      });
    },
  },
  watch: {
    storePolicy: function (val): void {
      this.formState.policy = val;
    },
  },
});
