


















import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import LiveData from "@/components/organisms/LiveData.vue";

export default Vue.extend({
  name: "Analytics",
  props: {},
  components: {
    MainColumns,
    LiveData,
  },
  data() {
    return {
      ovarallState: {
        labels: ["Ok", "Warning", "Critical", "Offline"],
        datasets: [
          {
            backgroundColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.error,
              "#3A4047",
            ],
            borderColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.error,
              "#3A4047",
            ],
            data: [10, 5, 3, 6],
          },
        ],
      },
      meetingCount: {
        labels: ["12am-6am", "6am-129m", "12pm-18pm", "18pm-12am"],
        datasets: [
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.8)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.4)",
            ],
            borderColor: [
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.8)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.4)",
            ],
            data: [0, 30, 20, 6],
          },
        ],
      },
    };
  },
});
