


































































































































































import Vue, { PropType } from "vue";
import { Tag } from "@/models/tag";
import { Group } from "@/models/group";
import {
  RoomOverallStateData,
  RoomOverallStatesData,
  roomConfig,
  RoomDetail,
} from "@/models/room";
import VaStateBadge from "@/components/molecules/VaStateBadge.vue";
import VaChip from "@/components/atoms/VaChip.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import dataTableMixin from "@/components/mixins/dataTableMixin";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import { FilterBy } from "@/models/table";
import VaBadge from "@/components/atoms/VaBadge.vue";
import MaintenanceSwitch from "@/components/organisms/rooms/MaintenanceSwitch.vue";

export default Vue.extend({
  name: "RoomsList",
  mixins: [dataTableMixin],
  props: {
    group: {
      type: Object as PropType<Group>,
      required: false,
    },
  },
  async beforeMount() {
    this.initFilters();
    await this.$store.dispatch("tag/fetchList");
    await this.$store.dispatch("group/fetchAll");
  },
  beforeDestroy() {
    this.$store.commit("room/setAdditionalFilters", []);
  },
  components: {
    MaintenanceSwitch,
    VaBadge,
    VaDetailsButton,
    VaTableTop,
    VaStateBadge,
    VaChip,
    VaNoDataTag,
  },
  data() {
    return {
      storeName: "room",
      tableConfig: roomConfig,
      filterTags: [],
      filterGroup: {} as Group,
      filterState: [] as number[],
      filterMaintenance: undefined as string | undefined,
      filterOngoing: undefined as string | undefined,
      overallStatesData: RoomOverallStatesData as Array<RoomOverallStateData>,
      maintenanceOptions: [
        {
          id: "1",
          label: "Enabled",
        },
        {
          id: "0",
          label: "Disabled",
        },
      ],
      onGoingOptions: [
        {
          id: "1",
          label: this.$t("common.inProgress"),
        },
        {
          id: "0",
          label: this.$t("common.available"),
        },
      ],
    };
  },
  methods: {
    changeMaintenance(room: RoomDetail) {
      this.$store.dispatch("room/toggleMaintenance", room);
    },
    gotoRoom(id: string) {
      this.$router.push({
        path: "/rooms/" + id,
      });
    },
    getStatus(room: RoomDetail) {
      return this.overallStatesData.find((stateData) => {
        return room.overallState === stateData.id;
      })?.name;
    },
    initFilters() {
      if (this.$route.query?.overallState) {
        this.filterState = (this.$route.query?.overallState as string)
          .split(",")
          .map(Number);
      }
      this.filterMaintenance = this.$route.query?.maintenance as
        | string
        | undefined;
      this.filterOngoing = this.$route.query?.onGoingMeeting as
        | string
        | undefined;
      if (this.$props.group) {
        this.filterGroup = this.$props.group;
      }
    },
  },
  computed: {
    groups(): Group[] {
      return this.$store.getters["group/availableGroups"];
    },
    tags(): Tag[] {
      return this.$store.getters["tag/tags"];
    },
    rooms(): RoomDetail[] {
      const rooms = this.$store.getters["room/rooms"];
      return rooms.filter((room: RoomDetail) => {
        return !!room.name;
      });
    },
    filters(): FilterBy[] {
      let filters = [];
      const filtersTags = this.filterTags.map((f) => {
        return {
          field: "tagId",
          value: f,
        } as FilterBy;
      });
      if (this.filterGroup?.id) {
        filters.push({ field: "groupId", value: this.filterGroup.id });
      }
      const filtersState = this.filterState.map((f) => {
        return {
          field: "overallState",
          value: f.toString(),
        } as FilterBy;
      });

      filters = filters.concat(filtersState).concat(filtersTags);

      if (this.filterMaintenance) {
        filters.push({
          field: "isMaintenanceModeEnabled",
          value: this.filterMaintenance,
        });
      }
      if (this.filterOngoing) {
        filters.push({
          field: "hasOngoingMeeting",
          value: this.filterOngoing,
        });
      }
      return filters;
    },
  },
  watch: {
    filters: function (newVal): void {
      this.$store.dispatch("room/updateAdditionalFilters", newVal);
    },
  },
});
