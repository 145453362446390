var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "va-subtitle-s" }, [
      _vm._v(_vm._s(_vm.$t("common.applyCommandsBy")))
    ]),
    _c(
      "div",
      { staticClass: "d-flex align-center" },
      [
        _c(
          "v-btn-toggle",
          {
            staticClass: "mr-6",
            attrs: { color: "primary", mandatory: "" },
            on: { change: _vm.onchangeMode },
            model: {
              value: _vm.mode,
              callback: function($$v) {
                _vm.mode = $$v
              },
              expression: "mode"
            }
          },
          [
            _c("v-btn", { attrs: { value: "TAG" } }, [
              _vm._v(_vm._s(_vm.$t("common.tags")))
            ]),
            _c("v-btn", { attrs: { value: "GROUP" } }, [
              _vm._v(_vm._s(_vm.$t("common.group")))
            ]),
            _c("v-btn", { attrs: { value: "ROOM" } }, [
              _vm._v(_vm._s(_vm.$t("common.rooms")))
            ])
          ],
          1
        ),
        _c("v-autocomplete", {
          attrs: {
            outlined: "",
            items: _vm.currentItems,
            multiple: "",
            "item-value": "id",
            "item-text": "name",
            label: _vm.currentLabel,
            clearable: "",
            "hide-details": "auto"
          },
          model: {
            value: _vm.selectedItems,
            callback: function($$v) {
              _vm.selectedItems = $$v
            },
            expression: "selectedItems"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }