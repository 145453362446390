


































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  components: {
    VaButton,
    VaIcon,
  },
  data: () => ({
    snackbar: false,
  }),
  props: {
    value: Boolean,
    dismissable: {
      type: Boolean,
      required: false,
      default: true,
    },
    timeout: {
      type: Number,
      required: false,
      default: 5000,
    },
    type: {
      type: String,
      required: false,
      validator(value) {
        return ["info", "success", "warning", "error"].includes(value);
      },
    },
  },
  methods: {
    dismiss() {
      this.snackbar = false;
      this.$emit("input", this.snackbar);
    },
  },
  watch: {
    value: function (newVal) {
      this.snackbar = newVal;
    },
  },
  computed: {
    computedType(): { color: string; icon: string } {
      switch (this.type) {
        case "info":
          return {
            color: "primary",
            icon: "vi vi-c-info",
          };
        case "success":
          return {
            color: "success",
            icon: "vi vi-c-check",
          };
        case "warning":
          return {
            color: "warning",
            icon: "vi vi-c-warning",
          };
        case "error":
          return {
            color: "error",
            icon: "vi vi-c-warning",
          };
        default:
          return {
            color: "",
            icon: "",
          };
      }
    },
  },
});
