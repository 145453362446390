var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "shadow-md rounded border-a",
      staticStyle: { height: "100%" }
    },
    [
      _c(
        "l-map",
        {
          ref: "groupsMap",
          staticClass: "rounded",
          staticStyle: { height: "100%" },
          attrs: {
            zoom: _vm.zoom,
            options: _vm.mapOptions,
            center: _vm.defatulCenter
          }
        },
        [
          _c("l-tile-layer", { attrs: { url: _vm.url } }),
          _c(
            "vue2-leaflet-markercluster",
            { attrs: { options: _vm.clusterOptions } },
            _vm._l(_vm.cleanMarkers, function(marker, index) {
              return _c(
                "l-marker",
                {
                  key: index,
                  ref: "markersRef",
                  refInFor: true,
                  attrs: {
                    "lat-lng": marker.position,
                    icon: _vm.getMarkerIcon(marker)
                  }
                },
                [
                  _c("l-popup", [
                    _c("div", { staticClass: "popup-content" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/rooms/groups/" + marker.id } },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex mb-2 group-title" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "vi vi-networking primary--text mr-2"
                                  }),
                                  _c("span", { staticClass: "font-bold" }, [
                                    _vm._v(_vm._s(marker.name))
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      marker.refObject
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "font-bold" }, [
                                _vm._v(
                                  " Rooms count: " +
                                    _vm._s(marker.refObject.roomQt) +
                                    " "
                                )
                              ]),
                              _c(
                                "v-row",
                                { staticClass: "mb-1" },
                                [
                                  _c("v-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between"
                                      },
                                      [
                                        _c("va-icon", {
                                          staticClass: "mr-1",
                                          attrs: {
                                            icon: "vi vi-c-check",
                                            color: "success",
                                            size: "xs"
                                          }
                                        }),
                                        _vm._v(" Ok: "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ml-2 font-bold flex-grow-1"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(marker.refObject.okQt) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between"
                                      },
                                      [
                                        _c("va-icon", {
                                          staticClass: "mr-1",
                                          attrs: {
                                            icon: "vi vi-c-remove",
                                            color: "secondary darken-4",
                                            size: "xs"
                                          }
                                        }),
                                        _vm._v(" Offline: "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ml-2 font-bold flex-grow-1"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  marker.refObject.offlineQt
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between"
                                      },
                                      [
                                        _c("va-icon", {
                                          staticClass: "mr-1",
                                          attrs: {
                                            icon: "vi vi-c-warning",
                                            color: "warning",
                                            size: "xs"
                                          }
                                        }),
                                        _vm._v(" Warning: "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ml-2 font-bold flex-grow-1"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  marker.refObject.warningQt
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between"
                                      },
                                      [
                                        _c("va-icon", {
                                          staticClass: "mr-1",
                                          attrs: {
                                            icon: "vi vi-c-warning",
                                            color: "error",
                                            size: "xs"
                                          }
                                        }),
                                        _vm._v(" Critical: "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ml-2 font-bold flex-grow-1"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  marker.refObject.criticalQt
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }