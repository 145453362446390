var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("div", [_vm._v(_vm._s(_vm.$t("mfa.backupCodes")))]),
      _c("va-button", {
        attrs: {
          size: "s",
          loading: _vm.loading,
          disabled: _vm.loading,
          cta: _vm.$t("mfa.getCodesCta")
        },
        on: { click: _vm.getBackupCodes }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "max-w-xl", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "va-card",
            [
              _c("div", { staticClass: "va-subtitle" }, [
                _vm._v(_vm._s(_vm.$t("mfa.backupCodes")))
              ]),
              _vm._v(" " + _vm._s(_vm.$t("mfa.backupCodesDescription")) + " "),
              _c("div", { staticClass: "my-4 text-right" }, [
                _c("a", { on: { click: _vm.onResetBackupCodes } }, [
                  _vm._v(_vm._s(_vm.$t("mfa.backupCodesReset")))
                ])
              ]),
              _c("v-divider", { staticClass: "my-4" }),
              _vm._l(_vm.codes, function(item) {
                return _c(
                  "div",
                  { key: item, staticClass: "text-center text-xl" },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }