








import Vue from "vue";
import RoomDisplays from "@/components/dashboard/rooms/room/RoomDisplays.vue";

export default Vue.extend({
  name: "RoomDetailEquipment",
  components: {
    RoomDisplays,
  },
});
