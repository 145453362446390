import { render, staticRenderFns } from "./VaCard.vue?vue&type=template&id=20fc77ad&scoped=true&"
import script from "./VaCard.vue?vue&type=script&lang=ts&"
export * from "./VaCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20fc77ad",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20fc77ad')) {
      api.createRecord('20fc77ad', component.options)
    } else {
      api.reload('20fc77ad', component.options)
    }
    module.hot.accept("./VaCard.vue?vue&type=template&id=20fc77ad&scoped=true&", function () {
      api.rerender('20fc77ad', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/VaCard.vue"
export default component.exports