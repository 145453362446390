




import Vue from "vue";
import VaCard from "@/components/molecules/VaCard.vue";

export default Vue.extend({
  name: "Company_Account",
  components: { VaCard },
});
