
























































































import Vue from "vue";
import VaChip from "@/components/atoms/VaChip.vue";

export default Vue.extend({
  name: "Buttons",
  components: {
    VaChip,
  },
});
