var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        [
          _c(
            "v-form",
            {
              ref: "userDataForm",
              attrs: { "lazy-validation": "", onSubmit: "return false;" },
              on: { submit: _vm.onSubmit },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: {
                  rules: [_vm.required],
                  label: _vm.$t("common.email"),
                  disabled: true
                },
                model: {
                  value: _vm.user.email,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email"
                }
              }),
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: {
                  rules: [_vm.required],
                  label: _vm.$t("common.firstname")
                },
                model: {
                  value: _vm.user.firstName,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "firstName", $$v)
                  },
                  expression: "user.firstName"
                }
              }),
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: {
                  rules: [_vm.required],
                  label: _vm.$t("common.lastname")
                },
                model: {
                  value: _vm.user.lastName,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "lastName", $$v)
                  },
                  expression: "user.lastName"
                }
              }),
              _c(
                "div",
                { staticClass: "d-flex mt-4" },
                [
                  _c("va-button", {
                    staticClass: "ml-auto",
                    attrs: {
                      submit: "",
                      disabled: !_vm.valid,
                      loading: _vm.$store.getters["session/isLoading"],
                      cta: _vm.$t("common.save")
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }