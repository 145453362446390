<script>
import { Line } from "vue-chartjs";
import Vue from "vue";

export default Vue.extend({
  extends: Line,
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: this.$vuetify.theme.currentTheme.chartText,
          },
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
                beginAtZero: true,
                min: 0,
                max: 100,
              },
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
              },
            },
          ],
        },
        tension: false,
        stepped: 0,
        borderDash: [],
        spanGaps: true,
        tooltips: {
          enabled: false,
          mode: "point",
          intersect: true,
          position: "nearest",
          custom: function (tooltip) {
            const id = "performance-chart-tooltip" + this._chart.id;
            // Tooltip Element
            var tooltipEl = document.getElementById(id);

            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.classList.add("chartjs-tooltip");
              tooltipEl.id = id;
              tooltipEl.style.zIndex = 10;
              tooltipEl.innerHTML = "<table></table>";
              this._chart.canvas.parentNode.appendChild(tooltipEl);
              let tooltipsStillOpened =
                document.getElementsByClassName("chartjs-tooltip");
              for (var i = 0; i < tooltipsStillOpened.length; i++) {
                if (tooltipsStillOpened[i].style.opacity === "1") {
                  tooltipsStillOpened[i].remove();
                }
              }
            }

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltip.yAlign) {
              tooltipEl.classList.add(tooltip.yAlign);
            } else {
              tooltipEl.classList.add("no-transform");
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltip.body) {
              var titleLines = tooltip.title || [];
              var bodyLines = tooltip.body.map(getBody);

              var innerHtml = "<thead>";

              titleLines.forEach(function (title) {
                innerHtml += "<tr><th>" + title + "</th></tr>";
              });
              innerHtml += "</thead><tbody>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltip.labelColors[i];
                var style = "background:" + colors.backgroundColor;
                style += "; border: 1px solid " + colors.borderColor;
                var span =
                  '<span class="chartjs-tooltip-key" style="' +
                  style +
                  '"></span>';
                innerHtml += "<tr><td>" + span + body + "</td></tr>";
              });

              this._chart.config.data.datasets[
                tooltip.dataPoints[0].datasetIndex
              ].processes[tooltip.dataPoints[0].index].forEach((process) => {
                innerHtml +=
                  "<tr><td style='font-size: 10px;'>" + process + "</td></tr>";
              });

              innerHtml += "</tbody>";

              var tableRoot = tooltipEl.querySelector("table");
              tableRoot.innerHTML = innerHtml;
            }

            var positionY = this._chart.canvas.offsetTop;
            var positionX = this._chart.canvas.offsetLeft;

            // Display, position, and set styles for font
            tooltipEl.style.minWidth = "130px";
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + "px";
            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
            tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
            tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
            tooltipEl.style.padding =
              tooltip.yPadding + "px " + tooltip.xPadding + "px";
          },
        },
        lineAt: 70,
      },
    };
  },
  computed: {
    chartData() {
      return this.data;
    },
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
  mounted() {
    var horizonalLinePlugin = {
      afterDraw: function (chart) {
        if (typeof chart.config.options.lineAt != "undefined") {
          let lineAt = chart.config.options.lineAt;
          const ctxPlugin = chart.chart.ctx;
          const xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
          const yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];

          ctxPlugin.strokeStyle = "red";
          ctxPlugin.lineWidth = 2;
          ctxPlugin.beginPath();
          ctxPlugin.setLineDash([20, 5]);
          lineAt = (lineAt - yAxe.min) * (100 / yAxe.max);
          lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.top;
          ctxPlugin.moveTo(xAxe.left, lineAt);
          ctxPlugin.lineTo(xAxe.right, lineAt);
          ctxPlugin.stroke();
        }
      },
    };
    this.addPlugin(horizonalLinePlugin);
    this.renderChart(this.data, this.options);
  },
  watch: {
    data: function () {
      this.$data._chart.destroy();
      this.renderChart(this.data, this.options);
      //this.update();
    },
  },
});
</script>

<style lang="scss" scoped></style>
