









import Vue from "vue";
import { OauthProvider } from "@/models/oauth.interface";
import * as msal from "@azure/msal-browser";
import logger from "@/services/loggerService";
import { PublicClientApplication } from "@azure/msal-browser";
import VaButton from "@/components/atoms/VaButton.vue";
import { getBrowserVersion } from "@/services/browserVersionService";

export default Vue.extend({
  name: "MicosoftSignIn",
  components: { VaButton },
  data() {
    return {
      msalInstance: undefined as unknown as PublicClientApplication,
    };
  },
  props: {
    signup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    providerType(): number {
      return OauthProvider.Microsoft;
    },
  },
  mounted() {
    const msalConfig = {
      auth: {
        clientId: process.env.VUE_APP_TEAMS_CLIENT_ID,
        redirectUri: window.location.origin,
      },
    };

    this.msalInstance = new msal.PublicClientApplication(msalConfig);
  },
  methods: {
    async handleSignIn() {
      try {
        const msalUser = await this.msalInstance.loginPopup({
          scopes: ["openid User.ReadBasic.All"],
          prompt: "select_account",
        });
        if (msalUser) await this.onSuccess(msalUser);
      } catch (e) {
        logger.error(e);
      }
    },
    //TODO: move in mixin
    async onSuccess(microsoftUser: any) {
      const data = {
        deviceUniqueId: getBrowserVersion(),
        appPlatform: "web",
        grantType: "access_token",
        oauthProviderType: this.providerType,
        callbackUrl: window.location.origin,
        authCode: microsoftUser.idToken,
      };
      this.$store.dispatch("mfa/login", data);
    },
  },
});
