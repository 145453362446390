















































import Vue from "vue";
import VaNotification from "@/components/molecules/VaNotification.vue";
// import AuthService, { SignUpData } from "@/services/authService";
import AuthTemplate from "@/components/templates/Auth.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";
import VaTextField from "@/components/atoms/VaTextField.vue";
import i18n from "@/i18n";

export default Vue.extend({
  components: {
    VaButton,
    VaCard,
    AuthTemplate,
    VaTextField,
    VaNotification,
  },
  mixins: [errorMixin, validationMixin],
  name: "SignUp",
  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },
  async mounted() {
    this.onConfirm();
  },
  computed: {
    success(): boolean {
      return this.$store.getters["session/signupSuccess"];
    },
  },
  methods: {
    matching(value: string) {
      return value == this.password || i18n.t("validate.confirmed");
    },
    async onConfirm(): Promise<void> {
      if (
        this.$route.query.mail &&
        this.$route.query.confirmationToken &&
        this.$route.query.resetToken
      ) {
        await this.$store.dispatch("session/confirmEmail", {
          email: this.$route.query.mail,
          token: encodeURIComponent(
            this.$route.query.confirmationToken as string
          ),
        });
      }
    },
    async onSubmit(): Promise<void> {
      if (
        (
          this.$refs.confirmForm as Vue & { validate: () => boolean }
        ).validate() &&
        this.$route.query.mail &&
        this.$route.query.resetToken
      ) {
        await this.$store.dispatch("session/resetPassword", {
          token: this.$route.query.resetToken,
          password: this.password,
          email: this.$route.query.mail,
        });
      }
    },
  },
});
