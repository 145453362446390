var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.advanced")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c("va-switch", {
            staticClass: "mt-4",
            attrs: { text: _vm.$t("policiesFields.UseTeamsDesktopClient") },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.UseTeamsDesktopClient,
              callback: function($$v) {
                _vm.$set(_vm.fields, "UseTeamsDesktopClient", $$v)
              },
              expression: "fields.UseTeamsDesktopClient"
            }
          }),
          _c("va-switch", {
            staticClass: "mt-4",
            attrs: {
              text: _vm.$t("policiesFields.ForcePreviousTeamsDesktopClient")
            },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.ForcePreviousTeamsDesktopClient,
              callback: function($$v) {
                _vm.$set(_vm.fields, "ForcePreviousTeamsDesktopClient", $$v)
              },
              expression: "fields.ForcePreviousTeamsDesktopClient"
            }
          }),
          _c("va-switch", {
            staticClass: "mt-4",
            attrs: { text: _vm.$t("policiesFields.UseWebExDesktopClient") },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.UseWebExDesktopClient,
              callback: function($$v) {
                _vm.$set(_vm.fields, "UseWebExDesktopClient", $$v)
              },
              expression: "fields.UseWebExDesktopClient"
            }
          }),
          _c("div", { staticClass: "va-subtitle-s" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("generalConfiguration.autostartTitle")) + " "
            )
          ]),
          _c("va-switch", {
            staticClass: "mt-4",
            attrs: { text: _vm.$t("generalConfiguration.enableStartupTask") },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.EnableStartupTask,
              callback: function($$v) {
                _vm.$set(_vm.fields, "EnableStartupTask", $$v)
              },
              expression: "fields.EnableStartupTask"
            }
          }),
          _c("va-switch", {
            staticClass: "mt-4",
            attrs: { text: _vm.$t("generalConfiguration.enableRebootTask") },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.EnableRebootTask,
              callback: function($$v) {
                _vm.$set(_vm.fields, "EnableRebootTask", $$v)
              },
              expression: "fields.EnableRebootTask"
            }
          }),
          _vm.fields.EnableRebootTask
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("va-text-field", {
                    attrs: {
                      rules: [_vm.required, _vm.timeAmPM],
                      label: _vm.$t("generalConfiguration.rebootTime")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.RebootTime,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "RebootTime", $$v)
                      },
                      expression: "fields.RebootTime"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "section",
            { staticClass: "smtp" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("securityConfiguration.smtpTitle")) + " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("securityConfiguration.useSmtpToSendMail")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.UseSmtpToSendMail,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "UseSmtpToSendMail", $$v)
                  },
                  expression: "fields.UseSmtpToSendMail"
                }
              }),
              _vm.fields.UseSmtpToSendMail
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: 6 } },
                            [
                              _c("va-text-field", {
                                staticClass: "mt-4",
                                attrs: {
                                  label: _vm.$t("common.server"),
                                  rules: [_vm.required]
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: _vm.fields.WhiteboardSMTPServer,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "WhiteboardSMTPServer",
                                      $$v
                                    )
                                  },
                                  expression: "fields.WhiteboardSMTPServer"
                                }
                              }),
                              _c("va-text-field", {
                                staticClass: "mt-4",
                                attrs: {
                                  label: _vm.$t(
                                    "policiesFields.WhiteboardEmailSender"
                                  ),
                                  rules: [_vm.required]
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: _vm.fields.WhiteboardEmailSender,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "WhiteboardEmailSender",
                                      $$v
                                    )
                                  },
                                  expression: "fields.WhiteboardEmailSender"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: 6 } },
                            [
                              _c("va-text-field", {
                                staticClass: "mt-4",
                                attrs: {
                                  number: "",
                                  label: _vm.$t("common.port"),
                                  rules: [_vm.required, _vm.positiveNumber]
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: _vm.fields.WhiteboardSMTPPort,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "WhiteboardSMTPPort",
                                      $$v
                                    )
                                  },
                                  expression: "fields.WhiteboardSMTPPort"
                                }
                              }),
                              _c("va-text-field", {
                                staticClass: "mt-4",
                                attrs: {
                                  label: _vm.$t(
                                    "policiesFields.WhiteboardEmailSenderAddress"
                                  ),
                                  rules: [_vm.validMail]
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value:
                                    _vm.fields.WhiteboardEmailSenderAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fields,
                                      "WhiteboardEmailSenderAddress",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "fields.WhiteboardEmailSenderAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("va-text-field", {
                        staticClass: "mt-4",
                        attrs: {
                          password: "",
                          label: _vm.$t("common.password"),
                          rules: [_vm.required]
                        },
                        on: { input: _vm.onChange },
                        model: {
                          value: _vm.fields.WhiteboardSMTPPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.fields, "WhiteboardSMTPPassword", $$v)
                          },
                          expression: "fields.WhiteboardSMTPPassword"
                        }
                      }),
                      _c("va-switch", {
                        attrs: {
                          text: _vm.$t(
                            "securityConfiguration.whiteboardSMTPSSL"
                          )
                        },
                        on: { input: _vm.onChange },
                        model: {
                          value: _vm.fields.WhiteboardSMTPSSL,
                          callback: function($$v) {
                            _vm.$set(_vm.fields, "WhiteboardSMTPSSL", $$v)
                          },
                          expression: "fields.WhiteboardSMTPSSL"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "kiosk" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("securityConfiguration.kioskModeTitle")) +
                    " "
                )
              ]),
              _c("div", { staticClass: "text-sm" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("securityConfiguration.kioskModeContent")) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: { text: _vm.$t("securityConfiguration.kioskMode") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.KioskModeSelection,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "KioskModeSelection", $$v)
                  },
                  expression: "fields.KioskModeSelection"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }