var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "va-card",
    { staticClass: "mb-6" },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("div", { staticClass: "va-title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.icon
            ? _c("va-icon", {
                staticClass: "ml-6",
                attrs: { icon: _vm.icon, color: _vm.iconColor, size: "l" }
              })
            : _vm._e(),
          _c("v-spacer"),
          _c("date-range-selector")
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }