

































import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({
    displayPicker: false,
  }),
  methods: {
    togglePicker(): void {
      this.displayPicker = !this.displayPicker;
    },
    setDays(days: number): void {
      const interval = 1000 * 60 * 60 * 24 * days;
      this.$store.commit("analytics/setCurrentRange", {
        start: Date.now() - interval,
        end: Date.now(),
      });
    },
  },
  computed: {
    dates() {
      return [
        this.$store.getters["analytics/startDate"],
        this.$store.getters["analytics/endDate"],
      ];
    },
    computedDates: {
      get() {
        const res = [];
        if (this.$store.getters["analytics/startDate"]) {
          res.push(
            new Date(this.$store.getters["analytics/startDate"]).toISOString()
          );
        }
        if (this.$store.getters["analytics/endDate"]) {
          res.push(
            new Date(this.$store.getters["analytics/endDate"]).toISOString()
          );
        }
        return res;
      },
      set(val: string[]) {
        this.$store.commit("analytics/setCurrentRange", {
          start: val[0] ? Date.parse(val[0]) : undefined,
          end: val[1] ? Date.parse(val[1]) : undefined,
        });
      },
    },
  },
});
