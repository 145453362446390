var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c("va-kpi", {
            attrs: {
              value: _vm.onlineRoomsCount,
              icon: "vi vi-presentation",
              "icon-color": "success",
              title: "Online rooms",
              to: "rooms?overallState=0,1,2"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c("va-kpi", {
            attrs: {
              value: _vm.issuesRoomsCount,
              icon: "vi vi-warning-sign",
              "icon-color": "warning",
              title: "Room with issues",
              to: "rooms?overallState=1,2"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c("va-kpi", {
            attrs: {
              value: _vm.offlineRoomsCount,
              icon: "vi vi-engine-start",
              "icon-color": "error",
              title: "Offline rooms",
              to: "rooms?overallState=3"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c("va-kpi", {
            attrs: {
              value: _vm.maintenanceRoomsCount,
              icon: "vi vi-settings",
              "icon-color": "purple",
              title: "Room in maintenance",
              to: "rooms?maintenance=1"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        [
          _c("va-kpi", {
            attrs: {
              value: _vm.ongoingMeetingsCount,
              icon: "vi vi-users-meeting",
              "icon-color": "primary",
              title: _vm.$t("common.meetingsInProgress"),
              to: "rooms?onGoingMeeting=1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }