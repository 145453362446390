import i18n from "@/i18n";
import store from "@/store";
import { Locale, LOCALES } from "@/models/locale";
import PreferenceUtil from "@/utils/preferenceUtil";

export default {
  /**
   * Return supported locales
   * Supported locale must have relative flag enabled and json file
   * @returns {Locale[]}
   */
  getSupportedLocales(): Locale[] {
    const jsonLocales = require
      .context("@/i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i)
      .keys()
      .map((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          return matched[1];
        }
      });
    return LOCALES.filter((locale: Locale) => {
      return locale.supported && jsonLocales.includes(locale.id);
    });
  },

  /**
   * Return locale for current browser
   * @returns {Locale | undefined}
   */
  getBrowserLocale(): Locale | undefined {
    const navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language;

    if (!navigatorLocale) {
      return undefined;
    }
    const browserLocale = navigatorLocale.trim().split(/-|_/)[0];
    return this.getSupportedLocales().find((locale: Locale) => {
      return locale.id === browserLocale;
    });
  },

  /**
   * Return locale by id
   * @param id
   * @returns {Locale | undefined}
   */
  getLocaleById(id: string): Locale | undefined {
    return this.getSupportedLocales().find((locale: Locale) => {
      return locale.id === id;
    });
  },

  /**
   * Return the default locale
   * App default locale -> browser locale -> vmc locale
   * @returns {Locale}
   */
  getDefaultLocale(): Locale {
    let locale: Locale = this.getLocaleById(
      process.env.VUE_APP_I18N_LOCALE || "en"
    ) as Locale;
    const browserLocale: Locale | undefined = this.getBrowserLocale();
    if (browserLocale) {
      locale = browserLocale;
    }
    const vmcLocale = PreferenceUtil.getPreference("locale") as Locale;
    if (vmcLocale) {
      locale = vmcLocale;
    }
    return locale;
  },

  /**
   * Return app local fallback
   * @returns {string}
   */
  getFallbackLocale(): string {
    return (process.env.VUE_APP_I18N_FALLBACK_LOCALE as string) || "en";
  },

  /**
   * Init app locale
   * Locale is already set to vue18n
   */
  initLocale(): void {
    const defaultLocale: Locale = this.getDefaultLocale() as Locale;
    this.setLocale(defaultLocale);
  },

  /**
   * Set locale
   * @param locale
   * @param syncState
   */
  setLocale(locale: Locale, syncState = true): void {
    PreferenceUtil.setPreference("locale", locale);
    i18n.locale = locale.id;
    if (syncState) {
      store.commit("session/setLocale", locale);
    }
  },
};
