











































import Vue from "vue";
import { Datetime } from "vue-datetime";

// TODO: this component is a nightmare, candidate to total rewrite

export default Vue.extend({
  components: {
    datetime: Datetime,
  },
  props: {
    inputDate: {
      required: false,
      type: [Number, Date],
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    dateTime: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    persistentHint: {
      required: false,
      type: Boolean,
      default: false,
    },
    hint: {
      required: false,
      type: String,
      default: "",
    },
    min: {
      required: false,
      type: Number,
      default: null,
    },
    max: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      date: this.inputDate,
      isoDate: this.inputDate
        ? new Date(this.inputDate as number).toISOString()
        : "",
    };
  },
  methods: {
    formatDate(date: number): string {
      return this.$options?.filters?.formatDateTime(date);
    },
  },
  mounted() {
    // TODO refactor asap
    this.date = this.inputDate;
    this.isoDate = this.inputDate
      ? new Date(this.inputDate as number).toISOString()
      : "";
  },
  computed: {
    dateFormatted: {
      get(): string {
        return this.date ? this.formatDate(this.date as number) : "";
      },
      set(val: string): void {
        if (!val) {
          this.$emit("update:inputDate", undefined);
          this.$emit("change", undefined);
        }
      },
    },
  },
  watch: {
    inputDate: function (): void {
      // TODO refactor asap
      this.date = this.inputDate;
      this.isoDate = this.inputDate
        ? new Date(this.inputDate as number).toISOString()
        : "";
    },
    isoDate: function (): void {
      this.date = new Date(Date.parse(this.isoDate)).getTime();
      this.$emit("update:inputDate", this.date);
      this.$emit("change", this.date);
    },
  },
});
