



































































import Vue, { PropType } from "vue";
import { FileFormatted } from "@/models/file";
import VaButton from "@/components/atoms/VaButton.vue";
import { Command } from "@/models/command";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import FileUploader from "@/components/shared/FileUploader.vue";

export default Vue.extend({
  name: "CommandsSelector",
  components: {
    VaButton,
    FileUploader,
    VaSwitch,
  },
  props: {
    value: {
      type: Array as PropType<Command[]>,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      currentCommand: undefined as Command | undefined,
      commandsList: [] as Command[],
      scriptFile: undefined as FileFormatted | undefined,
      runAsSystem: false,
    };
  },
  methods: {
    clearCurrentCommand() {
      this.currentCommand = undefined;
      this.scriptFile = undefined;
      this.runAsSystem = false;
    },
    addCommand() {
      if (this.currentCommand) {
        if (this.currentCommand.parameterType == "file" && this.scriptFile) {
          this.currentCommand.parameter = {
            fileId: this.scriptFile.id || "",
            fileName: this.scriptFile.name,
            runAsSystem: this.runAsSystem,
          };
        }
        this.commandsList.push(this.currentCommand);

        this.clearCurrentCommand();
      }
    },
    disabledCommands(item: Command): boolean {
      return (
        this.commandsList.findIndex((command) => {
          return command.id === item.id;
        }) >= 0
      );
    },
  },
  computed: {
    canAddCommand(): boolean {
      return (
        !!this.currentCommand &&
        (this.currentCommand.id !== "20" || !!this.scriptFile)
      );
    },
  },
  mounted() {
    this.commandsList = this.value;
  },
  watch: {
    value: function (newVal) {
      this.commandsList = newVal;
    },
    commandsList: function (newVal) {
      this.$emit("input", newVal);
    },
  },
});
