
























































































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import FileService from "@/services/fileService";
import Notifications from "@/components/organisms/Notifications.vue";
import { RoomDetail } from "@/models/room";
import { UserList } from "@/models/user";

export default Vue.extend({
  name: "Logs",
  components: { DateTimePicker, MainColumns, Notifications },
  data() {
    return {
      startDate: undefined as any,
      endDate: undefined as any,
      roomId: "",
      userId: "",
      filtersOptions: [
        {
          value: 1,
          id: "login",
          label: this.$t("logs.filterOptions.groups.login") as string,
          items: [
            {
              value: 3,
              label: this.$t(
                "logs.filterOptions.items.successfulLogin"
              ) as string,
            },
            {
              value: 4,
              label: this.$t("logs.filterOptions.items.failedLogin") as string,
            },
            {
              value: 5,
              label: this.$t(
                "logs.filterOptions.items.profileLocked"
              ) as string,
            },
          ],
        },
        {
          value: 2,
          id: "commands",
          label: this.$t("logs.filterOptions.groups.commands") as string,
          items: [
            {
              value: 1,
              label: this.$t(
                "logs.filterOptions.items.executeCommandsByUser"
              ) as string,
            },
            {
              value: 2,
              label: this.$t(
                "logs.filterOptions.items.executedSchedulerByUser"
              ) as string,
            },
          ],
        },
        {
          value: 3,
          id: "workspace-activity",
          label: this.$t(
            "logs.filterOptions.groups.workspaceActivity"
          ) as string,
          items: [
            {
              value: 7,
              label: this.$t(
                "logs.filterOptions.items.deleteWorkspace"
              ) as string,
            },
            {
              value: 8,
              label: this.$t(
                "logs.filterOptions.items.openWorkspace"
              ) as string,
            },
            {
              value: 9,
              label: this.$t(
                "logs.filterOptions.items.exitWorkspace"
              ) as string,
            },
            {
              value: 10,
              label: this.$t("logs.filterOptions.items.renameRoom") as string,
            },
            {
              value: 11,
              label: this.$t(
                "logs.filterOptions.items.changeRoomDescription"
              ) as string,
            },
            {
              value: 12,
              label: this.$t(
                "logs.filterOptions.items.enableGuestAccess"
              ) as string,
            },
            {
              value: 13,
              label: this.$t(
                "logs.filterOptions.items.disableGuestAccess"
              ) as string,
            },
            {
              value: 14,
              label: this.$t(
                "logs.filterOptions.items.addRoomMember"
              ) as string,
            },
            {
              value: 15,
              label: this.$t(
                "logs.filterOptions.items.removeRoomMember"
              ) as string,
            },
          ],
        },
        {
          value: 4,
          id: "maintenance",
          label: this.$t("logs.filterOptions.groups.maintenance") as string,
          items: [
            {
              value: 20,
              label: this.$t("logs.filterOptions.items.maintenance") as string,
            },
          ],
        },
      ],
      filters: [] as Array<number>,
      filtersEnabled: false,
      downloading: false,
      allFilters: [] as Array<number>,
    };
  },
  beforeMount(): void {
    this.$store.dispatch("room/getRooms");
    this.$store.dispatch("users/getUsers");
    const allFilters = [] as Array<number>;
    this.filtersOptions.forEach((filterGroup) => {
      filterGroup.items.forEach((filter) => {
        allFilters.push(filter.value);
      });
    });
    this.allFilters = allFilters;
    this.filters = [...allFilters];
  },
  methods: {
    async download(): Promise<void> {
      this.downloading = true;
      let filtersGroup = [] as Array<number>;
      this.filtersOptions.forEach((filterGroup) => {
        let filterByGroup = filterGroup.items.map((filter) => {
          return filter.value;
        });
        if (
          this.filters.filter((filter) => filterByGroup.includes(filter))
            .length > 0
        ) {
          filtersGroup.push(filterGroup.value);
        }
      });
      try {
        await FileService.log(
          this.startDate,
          this.endDate ? this.endDate : Date.now(),
          filtersGroup,
          this.filters,
          this.roomId,
          this.userId
        );
        this.$notification.success(this.$t("logs.success"));
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "Logs", "download");
      } finally {
        this.downloading = false;
      }
    },
    onFiltersEnabled(): void {
      this.filters = [...this.allFilters];
    },
  },
  computed: {
    currentRooms(): RoomDetail[] {
      return this.$store.getters["room/rooms"];
    },
    currentUsers(): UserList[] {
      return this.$store.getters["users/users"];
    },
  },
});
