




























































import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
// import VaChip from "@/components/atoms/VaChip.vue";
import router from "@/router";

export default Vue.extend({
  components: {
    VaIcon,
    // VaChip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    trendValue: {
      type: String,
      required: false,
    },
    trend: {
      type: String,
      required: false,
      default: "positive",
      validator(value) {
        return ["positive", "negative", "neutral"].includes(value);
      },
    },
    trendColor: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
    },
    // type: {
    //   type: String,
    //   required: false,
    //   default: "simple",
    //   validator(value) {
    //     return [
    //       "simple",
    //       "percentage"
    //     ].includes(value);
    //   }
    // },
    sidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
    styled: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      required: false,
    },
    textColor: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
  },
  computed: {
    trendIconColor(): string {
      return this.trendColor ? this.trendColor : this.textColor;
    },
  },
  methods: {
    navigateTo() {
      if (this.to) {
        router.push(this.to);
      }
    },
  },
});
