var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-template",
    [
      _c(
        "va-card",
        { staticClass: "pa-6" },
        [
          _c("div", { staticClass: "va-title mb-2" }, [
            _vm._v(_vm._s(_vm.$t("signUp.confirm")))
          ]),
          _vm._v(
            " " +
              _vm._s(_vm.$t("signUp.enterPassword")) +
              ": " +
              _vm._s(_vm.$route.query.mail) +
              " "
          ),
          _c("va-notification", { attrs: { type: "info" } }, [
            _vm._v(_vm._s(_vm.$t("resetPassword.criteria")) + " ")
          ]),
          _c(
            "v-form",
            {
              ref: "confirmForm",
              attrs: { "lazy-validation": "", onSubmit: "return false;" },
              on: { submit: _vm.onSubmit },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("va-text-field", {
                staticClass: "mt-6",
                attrs: {
                  rules: [_vm.required],
                  label: _vm.$t("signUp.password"),
                  disabled: !_vm.success,
                  password: ""
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              }),
              _c("va-text-field", {
                staticClass: "mt-6",
                attrs: {
                  rules: [_vm.required, _vm.matching],
                  label: _vm.$t("signUp.repeatPassword"),
                  password: "",
                  disabled: !_vm.success
                },
                model: {
                  value: _vm.confirmPassword,
                  callback: function($$v) {
                    _vm.confirmPassword = $$v
                  },
                  expression: "confirmPassword"
                }
              }),
              _c(
                "div",
                { staticClass: "d-flex mt-5" },
                [
                  _c("va-button", {
                    staticClass: "flex-grow-1",
                    attrs: {
                      submit: "",
                      disabled: !_vm.valid || !_vm.success,
                      loading: _vm.$store.getters["session/isLoading"],
                      cta: _vm.$t("signUp.title")
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }