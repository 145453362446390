var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-StateBadge")]),
      _c("h3", [_vm._v("color")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-state-badge", { attrs: { type: "ok" } }),
          _c("va-state-badge", { attrs: { type: "average" } }),
          _c("va-state-badge", { attrs: { type: "poor" } }),
          _c("va-state-badge", { attrs: { type: "offline" } })
        ],
        1
      ),
      _c("h3", [_vm._v("size")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-state-badge", { attrs: { type: "ok", size: "xs" } }),
          _c("va-state-badge", { attrs: { type: "average", size: "s" } }),
          _c("va-state-badge", { attrs: { type: "poor", size: "m" } }),
          _c("va-state-badge", { attrs: { type: "offline", size: "l" } }),
          _c("va-state-badge", { attrs: { type: "offline", size: "xl" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }