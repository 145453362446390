






















































import Vue from "vue";
import Buttons from "@/components/_showcase/atoms/_buttons.vue";
import Chips from "@/components/_showcase/atoms/_chips.vue";
import Typography from "@/components/_showcase/atoms/_typography.vue";
import Icons from "@/components/_showcase/atoms/_icons.vue";
import Cards from "@/components/_showcase/molecules/_cards.vue";
import Avatar from "@/components/_showcase/atoms/_avatar.vue";
import TextField from "@/components/_showcase/atoms/_textField.vue";
import Kpi from "@/components/_showcase/molecules/_kpi.vue";
import RoomsMap from "@/components/organisms/RoomsMap.vue";
import LightSwitcher from "@/components/organisms/LightSwitcher.vue";
import StateBadge from "@/components/_showcase/molecules/_stateBadge.vue";
import Badge from "@/components/_showcase/atoms/_badge.vue";
import va_switch from "@/components/_showcase/atoms/_switch.vue";
import Notifications from "@/components/_showcase/molecules/_notifications.vue";
import Snackbar from "@/components/_showcase/molecules/_snackbar.vue";
import Charts from "@/components/_showcase/organisms/_charts.vue";

export default Vue.extend({
  name: "DesignSystem",
  components: {
    Snackbar,
    va_switch,
    Kpi,
    Typography,
    Buttons,
    Avatar,
    Icons,
    Chips,
    Cards,
    StateBadge,
    Badge,
    TextField,
    RoomsMap,
    LightSwitcher,
    Notifications,
    Charts,
  },
});
