
































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { Building } from "@/models/building";
import VaBadge from "@/components/atoms/VaBadge.vue";
import { RoomDetail } from "@/models/room";

export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    VaBadge,
    VaButton,
  },
  data: () => ({
    currentRoom: null,
    search: null,
  }),
  mounted() {
    this.$store.commit("room/clearSearchResults");
  },
  computed: {
    isLoading(): boolean {
      return this.$store.getters["room/isLoading"];
    },
    rooms(): { text: string; value: Building }[] {
      const rooms = this.$store.getters["room/rooms"];
      return rooms.map((room: RoomDetail) => {
        return {
          text: room.name,
          value: room,
        };
      });
    },
  },
  watch: {
    async search(val) {
      if (val?.length > 3 && !this.isLoading) {
        this.$store.dispatch("room/searchRooms", val);
      }
    },
  },
});
