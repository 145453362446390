























import Vue from "vue";
import {
  RoomDetail,
  RoomOverallStateData,
  RoomOverallStatesData,
} from "@/models/room";
import RoomService from "@/services/roomService";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  name: "RoomActions",
  components: { VaButton, VaCard },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  methods: {
    async onDelete(): Promise<void> {
      this.$store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: this.$i18n.t("common.deleteRoom"),
        description: `${this.$i18n.t("common.deleteRoomConfirm")}`,
        callback: async () => {
          this.deleting = true;
          try {
            await RoomService.delete(this.room.id);
            this.$notification.success(this.$t("roomActions.deleteSuccess"));
            await this.$router.push("/rooms");
          } catch (e) {
            this.$notification.error(e);
            this.$sentry.capture(e, "RoomDelete", "onDelete");
          } finally {
            this.deleting = false;
          }
        },
      });
    },
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
    overallStateData(): RoomOverallStateData {
      const room = this.$store.getters["room/room"];
      return RoomOverallStatesData.find((overallState) => {
        return overallState.id === room.overallState;
      }) as RoomOverallStateData;
    },
  },
});
