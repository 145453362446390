















































































import Vue, { VueConstructor } from "vue";
import validationMixin from "@/components/mixins/validationMixin";
import policiesMixin from "@/components/mixins/policiesMixin";
import { PodApplication } from "@/models/podApplication";
import VaButton from "@/components/atoms/VaButton.vue";
import tenantService from "@/services/tenantService";
import { LoggerMixin } from "@/components/mixins/errorMixin";
import VaBadge from "@/components/atoms/VaBadge.vue";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "HomeLauncherConfiguration",
  components: { VaBadge, VaButton },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      val: undefined,
      fields: {
        RoomApplications: [],
      } as { [key: string]: PodApplication[] },
    };
  },
  mounted() {
    this.$store.dispatch("podApplication/fetchAll");
  },
  computed: {
    logo() {
      return tenantService.requireAsset("square.svg");
    },
    policyId(): string | undefined {
      return this.$route.params?.id && this.$route.params.id !== "new"
        ? this.$route.params.id
        : undefined;
    },
    defaultApps() {
      return this.$store.getters["podApplication/podApplicationList"].filter(
        (app: PodApplication) => {
          return app.isDefault;
        }
      );
    },
    availableApps() {
      return this.$store.getters["podApplication/podApplicationList"]
        .filter((app: PodApplication) => {
          return (
            !this.fields.RoomApplications ||
            this.fields.RoomApplications.every((a) => a.id !== app.id)
          );
        })
        .map((app: PodApplication) => {
          return {
            value: app.id,
            text: app.name,
            icon: app.icon ? app.icon.path : this.logo,
            fullItem: app,
          };
        });
    },
  },
  watch: {
    defaultApps: function (newVal) {
      if (
        !this.policyId &&
        (!this.fields.RoomApplications ||
          this.fields.RoomApplications.length == 0)
      ) {
        this.fields.RoomApplications = newVal;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.onChange("appArray");
      }
    },
  },
  methods: {
    onDelete(app: PodApplication) {
      this.fields.RoomApplications = this.fields.RoomApplications.filter(
        (a: PodApplication) => a.id !== app.id
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange("appArray");
      this.val = undefined;
    },
    onAdd(appId: string) {
      const item = this.$store.getters[
        "podApplication/podApplicationList"
      ].find((a: PodApplication) => a.id == appId);
      this.fields.RoomApplications.push(item);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange("appArray");
    },
  },
});
