var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "va-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("common.performance")))
      ]),
      !_vm.room || !_vm.room.performance
        ? _c(
            "v-alert",
            { staticClass: "light mb-0", attrs: { type: "info", dense: "" } },
            [_vm._v(" " + _vm._s(_vm.$t("roomPerformance.empty")) + " ")]
          )
        : _vm._l(_vm.room.performance, function(stat, statKey) {
            return _c(
              "va-info-row",
              { key: statKey, attrs: { label: _vm.statLabel(statKey) } },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("span", { staticClass: "mr-1" }, [
                      _vm._v(
                        _vm._s(
                          stat.usage !== null
                            ? stat.alert
                              ? stat.usage + "%"
                              : _vm.$t("common.ok")
                            : _vm.$t("common.n/a")
                        )
                      )
                    ]),
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          small: "",
                          color: stat.alert ? "error" : "success"
                        }
                      },
                      [_vm._v("mdi-circle ")]
                    )
                  ],
                  1
                )
              ]
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }