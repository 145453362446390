var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", "close-on-content-click": false },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                return [
                  _c(
                    "v-chip",
                    {
                      attrs: { label: "", outlined: "" },
                      on: { click: _vm.togglePicker }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm._f("formatDate")(_vm.dates[0])) + " - "
                      ),
                      _vm.dates[1]
                        ? _c("span", [
                            _vm._v(_vm._s(_vm._f("formatDate")(_vm.dates[1])))
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.displayPicker,
            callback: function($$v) {
              _vm.displayPicker = $$v
            },
            expression: "displayPicker"
          }
        },
        [
          _c(
            "div",
            { staticClass: "pa-4 va-bg-base d-flex" },
            [
              _c("div", { staticClass: "mr-4" }, [
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer mb-1",
                    on: {
                      click: function($event) {
                        return _vm.setDays(1)
                      }
                    }
                  },
                  [_vm._v("Last Day")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer mb-1",
                    on: {
                      click: function($event) {
                        return _vm.setDays(7)
                      }
                    }
                  },
                  [_vm._v("Last 7 Days")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer mb-1",
                    on: {
                      click: function($event) {
                        return _vm.setDays(14)
                      }
                    }
                  },
                  [_vm._v(" Last 14 Days ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer mb-1",
                    on: {
                      click: function($event) {
                        return _vm.setDays(30)
                      }
                    }
                  },
                  [_vm._v(" Last 30 Days ")]
                )
              ]),
              _c("v-date-picker", {
                attrs: {
                  "no-title": "",
                  range: "",
                  "show-adjacent-months": "",
                  max: new Date(Date.now()).toISOString()
                },
                model: {
                  value: _vm.computedDates,
                  callback: function($$v) {
                    _vm.computedDates = $$v
                  },
                  expression: "computedDates"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }