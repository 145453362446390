


























































































































import Vue from "vue";
import VaDoughnutChart from "@/components/organisms/charts/VaDoughnutChart.vue";
import VaBarChart from "@/components/organisms/charts/VaBarChart.vue";
import VaHorizontalBarChart from "@/components/organisms/charts/VaHorizontalBarChart.vue";
import VaLinesChart from "@/components/organisms/charts/VaLinesChart.vue";
import VaCard from "@/components/molecules/VaCard.vue";

export default Vue.extend({
  components: {
    VaCard,
    VaLinesChart,
    VaHorizontalBarChart,
    VaDoughnutChart,
    VaBarChart,
  },
  data() {
    return {
      chartDataSingle: {
        labels: [
          "Doc",
          "Grumpy",
          "Happy",
          "Sleepy",
          "Bashful",
          "Sneezy",
          "Dopey",
        ],
        datasets: [
          {
            data: [12, 19, 3, 5, 2, 3, 6],
          },
        ],
      },
      chartDataSingleColors: {
        labels: [
          "Doc",
          "Grumpy",
          "Happy",
          "Sleepy",
          "Bashful",
          "Sneezy",
          "Dopey",
        ],
        datasets: [
          {
            backgroundColor: [
              "rgba(122,26,225,0.9)",
              "rgba(122,26,225,0.8)",
              "rgba(122,26,225,0.7)",
              "rgba(122,26,225,0.6)",
              "rgba(122,26,225,0.5)",
              "rgba(122,26,225,0.3)",
              "rgba(122,26,225,0.2)",
            ],
            borderColor: [
              "rgba(122,26,225,0.9)",
              "rgba(122,26,225,0.8)",
              "rgba(122,26,225,0.7)",
              "rgba(122,26,225,0.6)",
              "rgba(122,26,225,0.5)",
              "rgba(122,26,225,0.3)",
              "rgba(122,26,225,0.2)",
            ],
            pointBackgroundColor: "rgba(122,26,225,0.9)",
            pointBorderColor: "rgba(122,26,225,0.9)",
            data: [12, 19, 3, 5, 2, 3, 6],
          },
        ],
      },
      chartDataMultiple: {
        labels: ["Doc", "Grumpy", "Happy", "Sleepy", "Bashful"],
        datasets: [
          {
            // backgroundColor:['red', 'green', 'blue', 'purple', 'teal'],
            label: "pizza",
            data: [12, 19, 3, 5, 2],
          },
          {
            // backgroundColor:['yellow', '#6CAA5C', '#E8A427','#E04A52', 'blue'],
            label: "burger",
            data: [2, 10, 13, 5, 12],
          },
          {
            label: "cake",
            data: [12, 3, 7, 5, 21],
          },
        ],
      },
      chartDataCustomColors: {
        labels: ["Doc", "Grumpy", "Happy", "Sleepy", "Bashful"],
        datasets: [
          {
            label: "cani",
            backgroundColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
            ],
            data: [12, 19, 3, 5, 2],
          },
          {
            label: "gatti",
            backgroundColor: [
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
            ],
            data: [2, 10, 13, 5, 12],
          },
          {
            label: "topi",
            backgroundColor: [
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
            ],
            data: [12, 3, 7, 5, 21],
          },
        ],
      },
      chartDataCustomColors2: {
        labels: ["Doc", "Grumpy", "Happy", "Sleepy", "Bashful"],
        datasets: [
          {
            label: "Uno",
            backgroundColor: [
              "rgba(122,26,225,0.2)",
              "rgba(122,26,225,0.2)",
              "rgba(122,26,225,0.2)",
              "rgba(122,26,225,0.2)",
              "rgba(122,26,225,0.2)",
            ],
            data: [12, 19, 3, 5, 2],
          },
          {
            label: "Due",
            backgroundColor: [
              "rgba(122,26,225,0.6)",
              "rgba(122,26,225,0.6)",
              "rgba(122,26,225,0.6)",
              "rgba(122,26,225,0.6)",
              "rgba(122,26,225,0.6)",
            ],
            data: [2, 10, 13, 5, 12],
          },
          {
            label: "Label lunghissima che spacca tutto il mondo ",
            backgroundColor: [
              "rgba(122,26,225,0.8)",
              "rgba(122,26,225,0.8)",
              "rgba(122,26,225,0.8)",
              "rgba(122,26,225,0.8)",
              "rgba(122,26,225,0.8)",
            ],
            data: [12, 3, 7, 5, 21],
          },
        ],
      },
    };
  },
});
