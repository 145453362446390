var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "analytics-top-bar",
        {
          attrs: {
            title: "Average Health 80%",
            icon: "vi vi-heartbeat-2",
            "icon-color": "error"
          }
        },
        [
          _vm._v(" Average healt is calculated by"),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(
              "Overall status, Total incidents, Average performance, Rooms updates"
            )
          ]),
          _vm._v(", Lorem ipsum dolor sit amet, consectetur adipiscing elit ")
        ]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-bar-chart", {
                    attrs: {
                      chartData: _vm.overallStatus,
                      title: "Meeting rooms overall status",
                      legend: "",
                      stacked: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.incidents,
                      area: "",
                      "hide-dots": "",
                      title: "Incidents"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.performance,
                      title: "Average Performance",
                      "hide-dots": "",
                      legend: "",
                      area: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-bar-chart", {
                    attrs: {
                      chartData: _vm.updates,
                      title: "Software update staus"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }