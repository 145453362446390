













import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      mutableVal: this.value,
    };
  },
  watch: {
    value: function (newVal) {
      this.mutableVal = newVal;
    },
  },
});
