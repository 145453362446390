


































































import Vue, { VueConstructor } from "vue";
import errorMixin, { LoggerMixin } from "@/components/mixins/errorMixin";
import { SignInData } from "@/services/authService";
import AuthTemplate from "@/components/templates/Auth.vue";
import MfaLogin from "@/components/organisms/auth/MfaLogin.vue";
import GoogleSignIn from "@/components/organisms/auth/GoogleSignIn.vue";
import AppleSignIn from "@/components/organisms/auth/AppleSignIn.vue";
import MicrosoftSignIn from "@/components/organisms/auth/MicrosoftSignIn.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import validationMixin from "@/components/mixins/validationMixin";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { getBrowserVersion } from "@/services/browserVersionService";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "SignIn",
  components: {
    VaButton,
    VaTextField,
    VaCard,
    AuthTemplate,
    MfaLogin,
    GoogleSignIn,
    AppleSignIn,
    MicrosoftSignIn,
  },
  mixins: [errorMixin, validationMixin],
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    mfaToken(): string {
      return this.$store.getters["mfa/mfaToken"];
    },
    mfaEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_DISABLED ||
        process.env.VUE_APP_OAUTH_DISABLED === "false"
      );
    },
    appleEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_APPLE_DISABLED ||
        process.env.VUE_APP_OAUTH_APPLE_DISABLED === "false"
      );
    },
    googleEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_GOOGLE_DISABLED ||
        process.env.VUE_APP_OAUTH_GOOGLE_DISABLED === "false"
      );
    },
    msEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_MS_DISABLED ||
        process.env.VUE_APP_OAUTH_MS_DISABLED === "false"
      );
    },
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (
        (this.$refs.signInForm as Vue & { validate: () => boolean }).validate()
      ) {
        const signInData = {
          email: this.email,
          password: this.password,
          deviceUniqueId: getBrowserVersion(),
          appPlatform: "web",
          grantType: "password",
        } as Partial<SignInData>;
        await this.$store.dispatch("mfa/login", signInData);
      }
    },
  },
});
