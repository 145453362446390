










































































































































































































import Vue from "vue";
import Chart from "@/components/shared/Chart.vue";
import StatsService from "@/services/analyticService";
import { Analytics, CHART_PALETTES } from "@/models/analytics";
import { AnalyticTrendCard as AnalyticTrendCardI } from "@/models/analytics";
import AnalyticUtil from "@/utils/analyticUtil";
import { RoomDetail } from "@/models/room";
import { ChartData } from "@/models/analytics";
import HeatMapChart from "@/components/shared/charts/HeatMapChart.vue";
import { convertHexToRGBA } from "@/utils";
import { HeatMapChartData } from "@/models/chart";
import CapacityPerformance from "@/components/dashboard/analytics/CapacityPerformance.vue";
import VaKpi from "@/components/molecules/VaKpi.vue";

interface AnalyticsChartData {
  earlyLateChart: ChartData;
  instantBookedChart: ChartData;
  meetingsTypesChart: ChartData;
  applicationsChart: ChartData;
  airServerProtocolsChart: ChartData;
  videoConferenceChart: ChartData;
  offlineOnlineChart: ChartData;
  peopleCountChart: ChartData | HeatMapChartData;
}

export default Vue.extend({
  name: "Analytics",
  components: {
    VaKpi,
    // AnalyticTrendCard,
    Chart,
    HeatMapChart,
    CapacityPerformance,
  },
  data() {
    return {
      key: 0,
      stats: {} as Analytics,
      polling: 0,
      loading: false,
      timeout: 5000,
      range: "weekly",
      filters: [
        {
          text: this.$t("common.today"),
          value: "daily",
        },
        {
          text: this.$t("common.lastWeek"),
          value: "weekly",
        },
        {
          text: this.$t("common.lastMonth"),
          value: "monthly",
        },
      ],
      palette: {
        backgrounds: [
          convertHexToRGBA("#091375", 70),
          convertHexToRGBA("#192CB0", 70),
          convertHexToRGBA("#3351F5", 70),
          convertHexToRGBA("#849AFC", 70),
          convertHexToRGBA("#D6DFFE", 70),
        ],
        borders: ["#091375", "#192CB0", "#3351F5", "#849AFC", "#D6DFFE"],
      },
    };
  },
  async beforeMount() {
    await this.initByRoute();
  },
  computed: {
    room(): RoomDetail | null {
      return this.$store.getters["room/room"];
    },
    trendsCard(): Array<AnalyticTrendCardI> {
      const cards = [] as Array<AnalyticTrendCardI>;

      cards.push({
        trend: this.stats?.totalMeetings?.percentageChange || 0,
        value: this.stats?.totalMeetings?.actualPeriod || 0,
        label: this.$t("analytics.totalMeetings") as string,
      });

      cards.push({
        value: this.stats?.averageMeetingDuration?.actualPeriod / 60000 || 0,
        label: this.$t("analytics.averageMeetingDuration") as string,
        trend: this.stats?.averageMeetingDuration?.percentageChange || 0,
      });

      cards.push({
        value: this.stats?.totalNotShow?.actualPeriod || 0,
        label: this.$t("analytics.ghostMeetings") as string,
        trend: this.stats?.totalNotShow?.percentageChange || 0,
        inverted: true,
      });

      cards.push({
        value: this.stats?.averageStartTime?.actualPeriod / 60000 || 0,
        label: this.$t("analytics.averageStart") as string,
        trend: this.stats?.averageStartTime?.percentageChange || 0,
      });

      cards.push({
        value: this.stats?.totalMeetingTime?.actualPeriod / 60000 || 0,
        label: this.$t("analytics.totalDuration") as string,
        trend: this.stats?.totalMeetingTime?.percentageChange || 0,
      });

      return cards;
    },
    chartsData(): AnalyticsChartData {
      const data = {} as AnalyticsChartData;
      let datasetsLabels = [];

      datasetsLabels = [
        this.$t("analytics.instant") as string,
        this.$t("analytics.booked") as string,
        this.$t("analytics.byom") as string,
      ];
      data.instantBookedChart =
        this.range !== "daily"
          ? AnalyticUtil.buildChartData(
              this.stats?.instantBooked ? this.stats.instantBooked : [],
              datasetsLabels,
              CHART_PALETTES[0]
            )
          : AnalyticUtil.buildChartFixedData(
              this.stats?.instantBooked ? this.stats.instantBooked : [],
              datasetsLabels,
              CHART_PALETTES[0]
            );

      datasetsLabels = [
        this.$t("analytics.onTime") as string,
        this.$t("analytics.early") as string,
        this.$t("analytics.late") as string,
      ];
      data.earlyLateChart =
        this.range !== "daily"
          ? AnalyticUtil.buildChartData(
              this.stats?.earlyLate ? this.stats.earlyLate : [],
              datasetsLabels,
              CHART_PALETTES[2]
            )
          : AnalyticUtil.buildChartFixedData(
              this.stats?.earlyLate ? this.stats.earlyLate : [],
              datasetsLabels,
              CHART_PALETTES[2]
            );

      data.meetingsTypesChart =
        this.range !== "daily"
          ? AnalyticUtil.buildChartData(
              this.stats?.meetingsTypesStats?.data
                ? this.stats.meetingsTypesStats?.data
                : [],
              this.stats?.meetingsTypesStats?.labels
                ? this.stats.meetingsTypesStats?.labels
                : [],
              CHART_PALETTES[2]
            )
          : AnalyticUtil.buildChartFixedData(
              this.stats?.meetingsTypesStats?.data
                ? this.stats.meetingsTypesStats.data
                : [],
              this.stats?.meetingsTypesStats?.labels
                ? this.stats.meetingsTypesStats.labels
                : [],
              CHART_PALETTES[2]
            );

      data.airServerProtocolsChart =
        this.range !== "daily"
          ? AnalyticUtil.buildChartData(
              this.stats?.airserverProtocols
                ? this.stats?.airserverProtocols
                : [],
              ["AirPlay", "Miracast", "GoogleCast"],
              CHART_PALETTES[0]
            )
          : AnalyticUtil.buildChartFixedData(
              this.stats?.airserverProtocols
                ? this.stats.airserverProtocols
                : [],
              ["AirPlay", "Miracast", "GoogleCast"],
              CHART_PALETTES[0]
            );

      data.applicationsChart =
        this.range !== "daily"
          ? AnalyticUtil.buildChartData(
              this.stats?.applicationStats?.data
                ? this.stats.applicationStats?.data
                : [],
              this.stats?.applicationStats?.labels
                ? this.stats.applicationStats.labels
                : [],
              CHART_PALETTES[2]
            )
          : AnalyticUtil.buildChartFixedData(
              this.stats?.applicationStats?.data
                ? this.stats.applicationStats.data
                : [],
              this.stats?.applicationStats?.labels
                ? this.stats.applicationStats.labels
                : [],
              CHART_PALETTES[2]
            );

      data.videoConferenceChart =
        this.range !== "daily"
          ? AnalyticUtil.buildChartData(
              this.stats?.videoConfStats?.data
                ? this.stats.videoConfStats.data
                : [],
              this.stats?.videoConfStats?.labels
                ? this.stats.videoConfStats.labels
                : [],
              CHART_PALETTES[1]
            )
          : AnalyticUtil.buildChartFixedData(
              this.stats?.videoConfStats?.data
                ? this.stats.videoConfStats.data
                : [],
              this.stats?.videoConfStats?.labels
                ? this.stats.videoConfStats.labels
                : [],
              CHART_PALETTES[1]
            );

      data.offlineOnlineChart =
        this.range !== "daily"
          ? AnalyticUtil.buildChartData(
              this.stats?.offlineOnline ? this.stats.offlineOnline : [],
              ["Offline", "Online"],
              CHART_PALETTES[1]
            )
          : AnalyticUtil.buildChartFixedData(
              this.stats?.offlineOnline ? this.stats.offlineOnline : [],
              ["Offline", "Online"],
              CHART_PALETTES[1]
            );

      data.peopleCountChart =
        this.range !== "daily"
          ? AnalyticUtil.buildHeatMapChartData(
              this.stats?.peopleCount?.data ? this.stats.peopleCount.data : [],
              this.stats?.peopleCount?.labels
                ? this.stats.peopleCount.labels
                : [],
              CHART_PALETTES[4]
            )
          : AnalyticUtil.buildChartFixedData(
              this.stats?.peopleCount?.data ? this.stats.peopleCount.data : [],
              this.stats?.peopleCount?.labels
                ? this.stats.peopleCount.labels
                : [],
              CHART_PALETTES[4]
            );

      return data;
    },
  },
  methods: {
    async initByRoute(): Promise<void> {
      await this.getAnalytics();
      this.loading = false;
    },
    async getAnalytics(silent = false): Promise<void> {
      if (!silent) {
        this.loading = true;
      }
      try {
        this.stats = await StatsService.get(
          this.range,
          this.room ? this.room.id : this.$route.params.id
        );
      } catch (e) {
        if (!silent) {
          this.$notification.error(e);
          this.stats = {} as Analytics;
        }
        this.$sentry.capture(e, "Analytics", "getAnalytics");
      } finally {
        this.loading = false;
      }
    },
    trendIcon(trend: number): string {
      if (trend === 0 || trend === -100) {
        return "mdi-minus-circle-outline";
      } else if (trend < 0) {
        return "mdi-arrow-bottom-right-thin-circle-outline";
      } else return "mdi-arrow-top-right-thin-circle-outline";
    },
    trendColor(trend: number, inverted = false): string {
      if (trend === 0 || trend === -100) {
        return "";
      } else if (trend < 0) {
        return inverted ? "success" : "error";
      } else {
        return inverted ? "error" : "success";
      }
    },
  },
  watch: {
    range: function (): void {
      this.getAnalytics();
    },
  },
});
