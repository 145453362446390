import Vue from "vue";
import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import Token, { TokenData } from "@/models/token";
import "@/plugins/vueCookies";
import User, { User as UserData } from "@/models/user";
import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";
import { Error } from "@/models/error";
import ProviderFunctions, { MfaProvider } from "@/models/mfaProvider";
import { Company, CompanyFormSerializer } from "@/models/company";

//TODO:
export interface SignInData {
  password: string;
  email: string;
  deviceUniqueId?: string;
  appPlatform?: string;
  grantType?: string;
  mfaCode?: string;
  mfaToken?: string;
}

export interface SignUpData {
  firstName: string;
  lastName: string;
  email: string;
}

export interface ForgotPasswordData {
  email: string;
}

export interface ResetPasswordData {
  token: string;
  password: string;
  email: string;
}

export default {
  // check(licenseKey: string, email: string): Promise<void> {
  //   return axios.post(apiService.auth.checkUrl(), { licenseKey, email });
  // },
  async signUp(data: SignUpData): Promise<AxiosResponse> {
    return await axios.post(apiService.auth.signupUrl(), {
      email: data.email,
      metadata: {
        firstName: data.firstName,
        lastName: data.lastName,
        adminUserEmail: data.email,
      },
    });
  },
  async confirmEmail(email: string, token: string): Promise<AxiosResponse> {
    return await axios.post(apiService.auth.confirmMailUrl(), {
      email: email,
      token: token,
    });
  },
  async signIn(data: Partial<SignInData>): Promise<TokenData> {
    const resp = await axios.post(apiService.auth.signinUrl(), data);
    return Token.deserialize(resp.data);
  },
  async forgotPassword(data: ForgotPasswordData): Promise<AxiosResponse> {
    return await axios.post(apiService.auth.forgotPasswordUrl(), data);
  },
  async resetPassword(data: ResetPasswordData): Promise<AxiosResponse> {
    return await axios.post(apiService.auth.resetPasswordUrl(), data);
  },
  isTokenExpired(data: TokenData): boolean {
    return +data.expirationDateReal < new Date().getTime();
  },
  getTokenData(): TokenData {
    const token = Vue.$cookies.get("token");
    const refreshToken = Vue.$cookies.get("refresh-token");
    const expirationDateReal = Vue.$cookies.get("expiration-real");
    return {
      token: token,
      refreshToken: refreshToken,
      expirationDateReal: expirationDateReal,
    };
  },
  setTokenData(data: TokenData): void {
    Vue.$cookies.set("token", data.token, data.expirationDate);
    Vue.$cookies.set(
      "refresh-token",
      data.refreshToken,
      data.expirationDateReal
    );
    Vue.$cookies.set(
      "expiration-real",
      data.expirationDate,
      data.expirationDateReal
    );
  },
  async refreshToken(data: TokenData): Promise<TokenData> {
    const resp = await axios.post(apiService.auth.refreshTokenUrl(), data);
    if (
      !resp?.data?.token ||
      !resp?.data?.refreshToken ||
      !resp?.data?.expirationUtc
    ) {
      throw new Error("Missing data on refresh token call");
    }
    return Token.deserialize(resp.data);
  },
  async getUser(): Promise<UserData> {
    const res = await axios.get(apiService.admin.baseUrl());
    return User.deserialize(res.data);
  },
  async logout(silent = false): Promise<void> {
    await store.dispatch("app/clearDashboard");
    store.dispatch("session/logout");
    Vue.$cookies.remove("token");
    Vue.$cookies.remove("expiration-real");
    Vue.$cookies.remove("refresh-token");
    if (!silent) {
      Vue.notify({
        type: "success",
        text: i18n.t("common.logoutSuccess") as string,
      });
    }
    router.push("/auth/signin").catch(() => {
      // empty
    });
  },
  async updateUser(data: UserData): Promise<UserData> {
    const res = await axios.patch(
      apiService.admin.baseUrl(),
      User.serialize(data)
    );
    return User.deserialize(res.data);
  },
  async updateUserData(data: {
    firstName: string;
    lastName: string;
  }): Promise<boolean> {
    const res = await axios.put(apiService.admin.baseUrl(), data);
    return res.data;
  },
  async updateUserCompany(data: Company): Promise<boolean> {
    const res = await axios.put(
      apiService.admin.companyUrl(),
      CompanyFormSerializer(data)
    );
    return res.data;
  },
  multiFactorEnabled(e: Error): boolean {
    return e.response?.data?.errorCode === "A403";
  },
  async getMfaProviders(): Promise<Array<MfaProvider>> {
    const res = await axios.get(apiService.admin.mfaProvidersUrl());
    return res.data.providers.map(ProviderFunctions.deserialize);
  },
  async getEnabledProviders(): Promise<Array<MfaProvider>> {
    const res = await axios.get(apiService.admin.mfaEnabledProvidersUrl());
    return res.data.providers.map(ProviderFunctions.deserialize);
  },
  async sendMFACode(data: {
    providerType: number;
  }): Promise<string | undefined> {
    const res = await axios.post(apiService.admin.mfaEnableCodeUrl(), data);
    return res?.data?.mfaToken;
  },
  async verifyMFACode(data: {
    authCode: string;
    mfaToken: string;
  }): Promise<boolean> {
    const res = await axios.post(
      apiService.admin.mfaVerificationCodeUrl(),
      data
    );
    return res?.status === 200;
  },
  async confirmMFAActivationCode(data: { mfaToken: string }): Promise<boolean> {
    const res = await axios.put(
      apiService.admin.confirmMFAActivationUrl(),
      data
    );
    return res?.status === 202;
  },
  async resetBackupCodes(): Promise<string[]> {
    return axios.post(apiService.admin.resetBackupCodesUrl());
  },
  async getBackupCodes(): Promise<string[]> {
    const res = await axios.get(apiService.admin.backupCodesUrl());
    return res?.data
      .filter(
        (item: { isConsumed: boolean; backupCode: string }) => !item.isConsumed
      )
      .map(
        (item: { isConsumed: boolean; backupCode: string }) => item.backupCode
      );
  },
  async getProvidersChallenge(mfaToken: string): Promise<any> {
    const res = await axios.post(apiService.admin.providersChallengeUrl(), {
      mfaToken,
    });
    return res?.data?.providers?.map(ProviderFunctions.deserialize);
  },
  async sendMFACodeAction(data: {
    providerType: number;
    mfaToken: string;
  }): Promise<boolean> {
    const res = await axios.post(apiService.auth.mfaCheckCodeUrl(), data);
    return !!res?.data?.mfaToken;
  },
  async disableMFAProvider(provider: MfaProvider): Promise<void> {
    await axios.delete(
      `${apiService.admin.mfaDisableProviderUrl()}?providerType=${
        provider.type
      }`
    );
  },
};
