import i18n from "@/i18n";

export enum CalendarProvider {
  Microsoft = 1,
  Google = 2,
}

export interface Calendar {
  id: string;
  name: string;
  image: string;
  providerId: CalendarProvider;
}

export const CALENDARS: Array<Calendar> = [
  {
    id: "teams",
    name: i18n.t("common.microsoft365"),
    image: require("@/assets/images/calendars/teams.png"),
    providerId: CalendarProvider.Microsoft,
  },
  {
    id: "google",
    name: i18n.t("common.googleWorkspace"),
    image: require("@/assets/images/calendars/google.png"),
    providerId: CalendarProvider.Google,
  },
] as Array<Calendar>;
