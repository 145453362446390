var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-TextField")]),
      _c("h3", [_vm._v("inputs")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-text-field", {
            staticClass: "mr-4",
            attrs: { label: "Basic text field" }
          }),
          _c("va-text-field", {
            staticClass: "mr-4",
            attrs: {
              rules: _vm.rules,
              label: "Text field with prepend icon and validation",
              "prepend-icon": "vi vi-chart-pie"
            }
          }),
          _c("va-text-field", {
            staticClass: "mr-4",
            attrs: {
              name: "test field",
              label: "Text field with append icon",
              "append-icon": "vi vi-chart-pie",
              value: "precompilated field"
            }
          }),
          _c("va-text-field", {
            staticClass: "mr-4",
            attrs: { password: "", label: "Password field" }
          })
        ],
        1
      ),
      _c("h2", [_vm._v("Va-TextArea")]),
      _c("h3", [_vm._v("inputs")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-text-area", {
            staticClass: "mr-4",
            attrs: { label: "Basic text area" }
          }),
          _c("va-text-area", {
            staticClass: "mr-4",
            attrs: {
              label: "Basic text area",
              value: "text tex text precompliated"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }