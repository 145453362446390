












































































































































































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { ReleaseNotes } from "@/models/releaseNotes";
import FileService from "@/services/fileService";
import FileUtil from "@/utils/fileUtil";

export default Vue.extend({
  name: "DownloadSoftware",
  components: { VaButton, MainColumns, Notifications },
  data() {
    return {
      downloading: false,
      dialog: false,
      changelogText: "",
      changelogTitle: "",
    };
  },
  methods: {
    async downloadLatestVersion(fastchannel = false): Promise<void> {
      this.downloading = true;
      try {
        const url = await FileService.latestVersion(fastchannel);
        if (url !== "") FileUtil.download(url);
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "Index", "downloadLatestVersion");
      } finally {
        this.downloading = false;
      }
    },
  },
  computed: {
    fromCMS() {
      return process.env.VUE_APP_DOWNLOAD_CMS === "true";
    },
    groupedNotes() {
      const notes = this.$store.getters["cms/releaseNotes"];

      return notes
        .sort((note: ReleaseNotes) => {
          //order valareaRoom -> valareaApp -> valareaController
          if (note.product == "room") return -1;
          if (note.product == "controller") return 1;
          return 0;
        })
        .reduce((group: any, note: ReleaseNotes) => {
          const { product } = note;
          group[product] = group[product] ?? [];
          group[product].push(note);
          return group;
        }, {});
    },
  },
  async created() {
    if (this.fromCMS) this.$store.dispatch("cms/fetchReleaseNotes");
  },
});
