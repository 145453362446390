export interface Coordinate {
  lat: string;
  lon: string;
}

// "CompanyName":"Jimmy superior Industries", //req
//   "CompanyLegalName":"",
//   "CountryCode":"IT", //req
//   "Locality":"",
//   "Region":"",
//   "PostalCode":"",
//   "Street":"",
//   "VatId":"",
//   "Lat":"",
//   "Long":"",
//   "Telephone":""

export interface Company {
  id: string;
  type: number;
  name: string;
  legalName: string;
  vat: string;
  city: string;
  country: string;
  region: string;
  postalCode: string;
  address: string;
  fullAddress: string;
  location: Coordinate;
  telephone: string;
  parentCompany?: number;
  createdAt?: Date;
  updatedAt?: Date;
  confirmed: boolean;
}

/* eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types */
export function CompanyDeserializer(input: any): Company {
  const fullAddress = `${input?.street ? input?.street + "," : ""} ${
    input?.postalCode || ""
  } ${input?.region || ""}`;
  return {
    id: input?.id || input?.companyId || "",
    type: input?.companyType || 0,
    name: input?.name || "",
    legalName: input?.legalName || "",
    vat: input?.vatId || "",
    city: input?.locality || "",
    country: input?.countryCode || "US",
    region: input?.region || "",
    postalCode: input?.postalCode || "",
    address: input?.street || "",
    fullAddress: fullAddress,
    location: {
      lat: input?.lat || "",
      lon: input?.long || "",
    } as Coordinate,
    telephone: input?.telephone || "",
    parentCompany: input?.parentCompanyId,
    createdAt: input?.creationDate ? new Date(input.creationDate) : undefined,
    updatedAt: input?.updateDate ? new Date(input.updateDate) : undefined,
    confirmed: input?.companyDataConfirmed,
    // createdAt:  undefined,
    // updatedAt: undefined,
  };
}

/* eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types */
export function CompanyFormSerializer(input: Company): unknown {
  return {
    companyName: input.name, //req
    companyLegalName: input.legalName,
    countryCode: input.country, //req
    locality: input.city,
    region: input.region,
    postalCode: input.postalCode,
    street: input.address,
    vatId: input.vat,
    lat: input.location.lat,
    long: input.location.lon,
    telephone: input.parentCompany,
  };
}
