var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-time-picker" },
    [
      _c("validation-provider", {
        attrs: { rules: _vm.required ? "required" : "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              return [
                _c(
                  "v-text-field",
                  {
                    attrs: {
                      outlined: "",
                      label: _vm.dateFormatted ? _vm.label : null,
                      placeholder: _vm.dateFormatted
                        ? _vm.dateFormatted
                        : _vm.label,
                      "error-messages": errors,
                      hint: _vm.hint,
                      "persistent-hint": _vm.persistentHint,
                      readonly: "",
                      clearable: "",
                      "prepend-inner-icon": "mdi-calendar",
                      disabled: _vm.disabled,
                      "hide-details": "auto"
                    },
                    on: {
                      click: function($event) {
                        _vm.$refs.dateSelector.isOpen = true
                      }
                    },
                    model: {
                      value: _vm.dateFormatted,
                      callback: function($$v) {
                        _vm.dateFormatted = $$v
                      },
                      expression: "dateFormatted"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.dateFormatted) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("datetime", {
        ref: "dateSelector",
        staticClass: "datetimepopup",
        attrs: {
          "input-style": "display:none",
          type: _vm.dateTime ? "datetime" : "date",
          "value-zone": "Europe/Rome",
          "min-datetime": _vm.min ? new Date(_vm.min).toISOString() : "",
          "max-datetime": _vm.max ? new Date(_vm.max).toISOString() : "",
          phrases: {
            ok: "Continue",
            cancel: "Exit"
          },
          "minute-step": 1,
          "week-start": 7
        },
        model: {
          value: _vm.isoDate,
          callback: function($$v) {
            _vm.isoDate = $$v
          },
          expression: "isoDate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }