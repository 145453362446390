// import addressFunctions from "@/models/address";
import i18n from "@/i18n";
import { TableConfig } from "@/models/table";
// import { AddressSerializer } from "@/models/address";

//TODO candidate to full deletion

export interface Building {
  uuid: string;
  name?: string;
  roomsCount?: number;
  rooms?: {
    roomId: string;
    name: string;
    floor: string;
  }[];
  floors?: string[];
  street?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  countryCode?: string;
  lat?: number;
  lng?: number;
}

export const buildingConfig = {
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
  roomsCount: {
    dtoName: "roomsCount",
    sortable: true,
    filterable: true,
    label: i18n.t("common.rooms"),
  },
  street: {
    dtoName: "street",
    sortable: true,
    filterable: true,
    label: i18n.t("common.address"),
  },
} as TableConfig;

// export default {
//   /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
//   deserialize(input: any): Building {
//     return {
//       uuid: input.buildingId,
//       name: input.name || "",
//       roomsCount: input.roomsCount || 0,
//       street: input.address?.street || undefined,
//       city: input.address?.city || undefined,
//       province: input.address?.locality || undefined,
//       postalCode: input.address?.postalCode || undefined,
//       country: input.address?.countryName || undefined,
//       countryCode: input.address?.countryCode || undefined,
//       lat: input.address?.coordinates?.lat || undefined,
//       lng: input.address?.coordinates?.lng || undefined,
//       floors: input.address.floors || [],
//       rooms: input.rooms || [],
//     };
//   },
//   /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
//   requestSerialize(input: Building): any {
//     const address = {
//       city: input.city || "",
//       locality: input.province || "",
//       postalCode: input.postalCode || "",
//       country: input.country || "Italia",
//       countryCode: input.countryCode || "ITA",
//       street: input.street || "",
//       floors: input.floors,
//       coordinates:
//         input.lat && input.lng
//           ? {
//               lat: input.lat,
//               lng: input.lng,
//             }
//           : undefined,
//     };
//
//     return {
//       buildingId: input.uuid,
//       name: input.city,
//       address: address ? AddressSerializer(address) : undefined,
//       rooms: input.rooms,
//     };
//   },
// };
