<script>
import { HorizontalBar } from "vue-chartjs";
import Vue from "vue";
// import { LABEL_COLOR, TICK_COLOR, AXE_COLOR } from "@/models/analytics";

export default Vue.extend({
  extends: HorizontalBar,
  props: {
    data: {
      type: Object,
      required: true,
    },
    stacked: {
      type: Boolean,
      required: false,
      default: false,
    },
    xTitle: {
      type: String,
    },
    total: {
      type: Array,
      required: false,
    },
    click: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
            fontColor: this.$vuetify.theme.currentTheme.chartText,
            fontSize: 10,
          },
        },
        legendCallback: function (chart) {
          let list = document.createElement("ul");
          let datasets = chart.data.datasets;
          let i, ilen, listItem, listItemSpan, labelItem, totalItem;

          list.setAttribute("class", chart.id + "-legend");

          for (i = 0, ilen = datasets.length; i < ilen; i++) {
            listItem = list.appendChild(document.createElement("li"));
            listItemSpan = listItem.appendChild(document.createElement("span"));
            listItemSpan.classList.add("color");
            listItemSpan.style.backgroundColor = datasets[i].backgroundColor;
            listItemSpan.style.borderColor = datasets[i].borderColor;
            if (datasets[i].label) {
              labelItem = document.createElement("span");
              labelItem.innerText = datasets[i].label;
              labelItem.classList.add("label");
              listItem.appendChild(labelItem);
            }
            if (this.total.length !== 0) {
              totalItem = document.createElement("span");
              totalItem.innerText = this.total[i];
              totalItem.classList.add("total");
              listItem.appendChild(totalItem);
            }
          }

          return list.outerHTML;
        }.bind(this),
        scales: {
          xAxes: [
            {
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
                display: !!this.xTitle,
                labelString: this.xTitle,
              },
              stacked: this.stacked,
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
                zeroLineColor: this.$vuetify.theme.currentTheme.chartLines,
                drawBorder: false,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
                fontSize: 10,
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
                //stepSize: 1,
              },
            },
          ],
          yAxes: [
            {
              stacked: this.stacked,
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
                zeroLineColor: this.$vuetify.theme.currentTheme.chartLines,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
                fontSize: 10,
              },
            },
          ],
        },
        onClick: this.click,
      },
    };
  },
  methods: {
    update() {
      const legend = this.$data._chart.generateLegend();
      this.$emit("legend", legend);
      this.$data._chart.update();
    },
  },
  mounted() {
    this.renderChart(this.data, this.options);
    const legend = this.$data._chart.generateLegend();
    this.$emit("legend", legend);
  },
});
</script>

<style lang="scss" scoped></style>
