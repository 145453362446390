export interface CapacityRawData {
  openDate: number;
  closeDate: number;
  meetingTitle: string;
  peopleMin: number;
  peopleMax: number;
  peopleCap: number;
}

export interface RoomCapacity {
  initDate: number;
  finishDate: number;
  meetingTitle: string;
  peopleMin: number;
  peopleMax: number;
  peopleCap: number;
}

export interface RoomCapacityTooltip {
  "Meeting title": string;
  "People min": number;
  "People max": number;
  Capacity: number;
  Start: string;
  Finish: string;
}

export function RoomCapacityDeserializer(input: CapacityRawData): RoomCapacity {
  return {
    initDate: input.openDate,
    finishDate: input.closeDate,
    meetingTitle: input.meetingTitle,
    peopleMin: input.peopleMin,
    peopleMax: input.peopleMax,
    peopleCap: input.peopleCap,
  };
}

export const CAPACITY_RANGE_DATA: Array<number> = [10, 25, 50];
