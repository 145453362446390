var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.locales.length > 0
    ? _c("v-select", {
        staticClass: "text-base",
        attrs: {
          outlined: "",
          items: _vm.locales,
          "item-text": "label",
          "item-value": "id",
          "return-object": "",
          "hide-details": "",
          "menu-props": {
            bottom: true,
            transition: "slide-y-transition",
            offsetY: true
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "selection",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-img", {
                    staticClass: "mr-2",
                    attrs: { "max-width": "16", src: item.image }
                  }),
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("locales." + _vm.currentLocale.id))
                        )
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.currentLocale.code2.toUpperCase()))
                      ])
                ]
              }
            },
            {
              key: "item",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-img", {
                    staticClass: "mr-2",
                    attrs: { "max-width": "16", src: item.image }
                  }),
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("locales." + item.id)))])
                    : _c("span", [_vm._v(_vm._s(item.code2.toUpperCase()))])
                ]
              }
            }
          ],
          null,
          false,
          257499292
        ),
        model: {
          value: _vm.currentLocale,
          callback: function($$v) {
            _vm.currentLocale = $$v
          },
          expression: "currentLocale"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }