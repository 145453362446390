var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.calendar")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c(
            "section",
            [
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  outlined: "",
                  attach: "",
                  rules: [_vm.required],
                  items: _vm.canJoinDelayOptions,
                  label: _vm.$t("policiesFields.canJoinDelay"),
                  "item-text": "text",
                  "item-value": "value"
                },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.fields.CanJoinDelay,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "CanJoinDelay", $$v)
                  },
                  expression: "fields.CanJoinDelay"
                }
              })
            ],
            1
          ),
          _c("v-select", {
            staticClass: "mt-4",
            attrs: {
              outlined: "",
              attach: "",
              rules: [_vm.required],
              items: _vm.displayMeetingInfoOptions,
              label: _vm.$t("policiesFields.DisplayMeetingInfo"),
              "hide-details": "auto"
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.fields.DisplayMeetingInfo,
              callback: function($$v) {
                _vm.$set(_vm.fields, "DisplayMeetingInfo", $$v)
              },
              expression: "fields.DisplayMeetingInfo"
            }
          }),
          _c("v-select", {
            staticClass: "mt-8",
            attrs: {
              outlined: "",
              attach: "",
              rules: [_vm.required],
              items: _vm.freeRoomDelayOptions,
              label: _vm.$t("homeLauncherConfiguration.freeRoomDelay"),
              "item-text": "text",
              "item-value": "value"
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.fields.FreeRoomDelay,
              callback: function($$v) {
                _vm.$set(_vm.fields, "FreeRoomDelay", $$v)
              },
              expression: "fields.FreeRoomDelay"
            }
          }),
          _c("v-select", {
            attrs: {
              outlined: "",
              attach: "",
              rules: [_vm.required],
              items: _vm.defaultExtendCountdownOptions,
              label: _vm.$t("policiesFields.countdownAction"),
              "item-text": "text",
              "item-value": "value"
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.fields.DefaultExtendCountdownAction,
              callback: function($$v) {
                _vm.$set(_vm.fields, "DefaultExtendCountdownAction", $$v)
              },
              expression: "fields.DefaultExtendCountdownAction"
            }
          }),
          _c("v-select", {
            attrs: {
              outlined: "",
              attach: "",
              rules: [_vm.required],
              items: _vm.meetingExtensionTimeOptions,
              label: _vm.$t("homeLauncherConfiguration.meetingExtensionTime"),
              "item-text": "text",
              "item-value": "value"
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.fields.MeetingExtensionTime,
              callback: function($$v) {
                _vm.$set(_vm.fields, "MeetingExtensionTime", $$v)
              },
              expression: "fields.MeetingExtensionTime"
            }
          }),
          _vm.fields.DefaultExtendCountdownAction == 0
            ? _c("v-select", {
                attrs: {
                  rules: [_vm.required],
                  outlined: "",
                  attach: "",
                  items: _vm.notifyEndMeetingTimeoutOptions,
                  label: _vm.$t(
                    "homeLauncherConfiguration.notifyEndMeetingTimeout"
                  ),
                  "item-text": "text",
                  "item-value": "value"
                },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.fields.NotifyEndMeetingTimeout,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "NotifyEndMeetingTimeout", $$v)
                  },
                  expression: "fields.NotifyEndMeetingTimeout"
                }
              })
            : _vm._e(),
          _c("v-select", {
            staticClass: "mt-8",
            attrs: {
              outlined: "",
              rules: [_vm.required],
              attach: "",
              items: _vm.roomAutoFreedTimeOptions,
              label: _vm.$t("policiesFields.roomAutoFreedTime"),
              "item-text": "text",
              "item-value": "value"
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.fields.RoomAutoFreedTime,
              callback: function($$v) {
                _vm.$set(_vm.fields, "RoomAutoFreedTime", $$v)
              },
              expression: "fields.RoomAutoFreedTime"
            }
          }),
          _c("va-switch", {
            attrs: {
              text: _vm.$t("homeLauncherConfiguration.sendMailNoShowMeetings")
            },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.SendMailNoShowMeetings,
              callback: function($$v) {
                _vm.$set(_vm.fields, "SendMailNoShowMeetings", $$v)
              },
              expression: "fields.SendMailNoShowMeetings"
            }
          }),
          _c("va-switch", {
            attrs: { text: _vm.$t("policiesFields.startVcAfterJoin") },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.StartVcAfterJoin,
              callback: function($$v) {
                _vm.$set(_vm.fields, "StartVcAfterJoin", $$v)
              },
              expression: "fields.StartVcAfterJoin"
            }
          }),
          _c("v-select", {
            staticClass: "mt-8",
            attrs: {
              outlined: "",
              rules: [_vm.required],
              attach: "",
              items: _vm.maxMeetingsInListOptions,
              label: _vm.$t("policiesFields.MaxMeetingsInList"),
              "item-text": "text",
              "item-value": "value"
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.fields.MaxMeetingsInList,
              callback: function($$v) {
                _vm.$set(_vm.fields, "MaxMeetingsInList", $$v)
              },
              expression: "fields.MaxMeetingsInList"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }