var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("va-badge", {
    attrs: {
      text: _vm.text,
      color: _vm.color,
      size: _vm.size,
      "icon-class": _vm.iconClass
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }