var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-template",
    [
      _c(
        "va-card",
        { staticClass: "pa-6" },
        [
          _c("div", { staticClass: "va-title mb-2" }, [
            _vm._v(_vm._s(_vm.$t("signIn.title")))
          ]),
          _vm._v(" " + _vm._s(_vm.$t("signIn.content")) + " "),
          _vm.mfaToken
            ? _c("mfa-login", {
                attrs: {
                  token: _vm.mfaToken,
                  email: _vm.email,
                  password: _vm.password
                }
              })
            : _c(
                "v-form",
                {
                  ref: "signInForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.onSubmit },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _vm.mfaEnabled
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-center mt-6" },
                        [
                          _vm.googleEnabled
                            ? _c("google-sign-in", { staticClass: "mr-4" })
                            : _vm._e(),
                          _vm.appleEnabled
                            ? _c("apple-sign-in", { staticClass: "mr-4" })
                            : _vm._e(),
                          _vm.msEnabled ? _c("microsoft-sign-in") : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("va-text-field", {
                    staticClass: "mt-6",
                    attrs: {
                      rules: [_vm.required, _vm.validMail],
                      label: _vm.$t("common.email")
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mt-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.password"),
                      password: ""
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right mb-3 mt-1" },
                    [
                      _c("router-link", { attrs: { to: "/auth/signup" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("signUp.title")) + " ")
                      ]),
                      _vm._v(" - "),
                      _c(
                        "router-link",
                        { attrs: { to: "/auth/forgot-password" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.forgotPassword")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex mt-5" },
                    [
                      _c("va-button", {
                        staticClass: "flex-grow-1",
                        attrs: {
                          submit: "",
                          disabled: !_vm.valid,
                          loading: _vm.$store.getters["mfa/isLoading"],
                          cta: _vm.$t("common.login")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }