import { createClient, EntryCollection } from "contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import loggerService from "@/services/loggerService";
import ReleaseNotesModule, { ReleaseNotes } from "@/models/releaseNotes";
import tenantService from "@/services/tenantService";
import { LocaleMessageObject } from "vue-i18n";

const contentfulApi = createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: process.env.VUE_APP_CONTENTFUL_TOKEN,
});

const getContent = async (id: string): Promise<EntryCollection<any>> => {
  return contentfulApi.getEntries({
    "sys.id": id,
    locale: process.env.VUE_APP_I18N_LOCALE,
  });
};

export default {
  async getHtmlContent(id: string): Promise<string> {
    const resp = await getContent(id);
    if (resp?.items?.length && resp?.items[0].fields?.body) {
      return documentToHtmlString(resp.items[0].fields.body);
    } else {
      loggerService.warn(`Unable to fetch contentful entry: ${id}`);
      return "";
    }
  },
  async getReleaseNotes(): Promise<ReleaseNotes[]> {
    const res = await contentfulApi.getEntries({
      content_type: "releaseNotes",
      locale: process.env.VUE_APP_I18N_LOCALE,
      "fields.tenant": tenantService.getTenantCode(),
    });
    return res.items.map(ReleaseNotesModule.deserialize);
  },
  async getTranslation(): Promise<LocaleMessageObject | undefined> {
    const res = await contentfulApi.getEntries({
      content_type: "jsonTranslation",
      locale: process.env.VUE_APP_I18N_LOCALE,
      "fields.tenant": tenantService.getTenantCode(),
      "fields.product": "console",
    });
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (res.items[0].fields.translation as LocaleMessageObject) || undefined
    );
  },
};
