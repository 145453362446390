























































































































import Vue from "vue";
import VaLinesChart from "@/components/organisms/charts/VaLinesChart.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaBarChart from "@/components/organisms/charts/VaBarChart.vue";
import VaKpi from "@/components/molecules/VaKpi.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import AnalyticsTopBar from "@/components/organisms/AnalyticsTopBar.vue";

export default Vue.extend({
  name: "AnalyticsHealth",
  components: {
    AnalyticsTopBar,
    VaCard,
    VaKpi,
    VaButton,
    VaLinesChart,
    VaBarChart,
  },
  data() {
    return {
      hwConsumption: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            backgroundColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
            ],
            pointBackgroundColor: this.$vuetify.theme.currentTheme.primary,
            pointBorderColor: this.$vuetify.theme.currentTheme.primary,
            data: [5, 5, 6, 7, 9, 4, 3],
          },
        ],
      },
      averageStartTime: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            backgroundColor: ["rgba(122,26,225,0.3)"],
            borderColor: ["rgba(122,26,225,0.8)"],
            pointBackgroundColor: this.$vuetify.theme.currentTheme.primary,
            pointBorderColor: this.$vuetify.theme.currentTheme.primary,
            data: [10, 8, 4, 7, 7, 5, 6],
          },
        ],
      },
      userFeedback: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            label: "Total rating",
            data: [8, 8, 7, 5, 6, 9, 7],
          },
          {
            label: "Seats comfort",
            data: [7, 7, 7, 7, 8, 6, 6],
          },
          {
            label: "Lighting",
            data: [7, 7, 7, 7, 8, 6, 6],
          },
          {
            label: "Room Cleaning",
            data: [3, 9, 6, 6, 7, 6, 8],
          },
          {
            label: "Accessibility",
            data: [4, 2, 5, 4, 2, 5, 6],
          },
        ],
      },
    };
  },
});
