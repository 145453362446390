import { setUser, captureException } from "@sentry/browser";
import store from "@/store";
import { User } from "@/models/user";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";

export default {
  setUser(): void {
    const user: User = store.getters["session/user"];
    setUser({
      id: user.id,
      username: user.userName,
      email: user.email,
    });
  },
  //TODO fix me
  capture(
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
    error: any,
    componentName: string,
    componentContext: string,
    description = "",
    _extra = {},
    _tags = {}
  ): void {
    const response = error.response;

    const extra = cloneDeep(_extra) as any;
    const tags = cloneDeep(_tags) as any;
    tags.api = false;

    const component = componentName + "@" + componentContext;
    tags.component = component;
    let message = clone(component);

    if (description) {
      message = message + " - " + description;
    }

    if (response) {
      if (response.status) {
        tags.api = true;
        extra.method = response.config.method;
        extra.url = response.config.url;
        extra.params = JSON.parse(response.config.data);
      }

      if (response.data && response.data.errorCode) {
        tags.errorCode = response.data.errorCode;
      }
    }

    const context = {
      extra,
      tags,
    };

    error.message = message;
    captureException(error, context);
  },
};
