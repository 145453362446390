









import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
// import LiveData from "@/components/organisms/LiveData.vue";
import UsersList from "@/components/organisms/UsersList.vue";
import Notifications from "@/components/organisms/Notifications.vue";

export default Vue.extend({
  name: "Analytics",
  props: {},
  components: {
    MainColumns,
    Notifications,
    UsersList,
  },
});
