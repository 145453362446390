var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        { staticClass: "configuration", attrs: { id: "update-configuration" } },
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.update")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c(
            "section",
            [
              _c("div", { staticClass: "va-subtitle-s" }, [
                _vm._v(" " + _vm._s(_vm.$t("common.server")) + " ")
              ]),
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  outlined: "",
                  attach: "",
                  items: _vm.updateServerTypeOptions,
                  "item-text": "text",
                  "item-value": "value",
                  label: _vm.$t("updateConfiguration.updateServerType")
                },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.fields.UpdateServerType,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "UpdateServerType", $$v)
                  },
                  expression: "fields.UpdateServerType"
                }
              })
            ],
            1
          ),
          _vm.fields.UpdateServerType > 0
            ? _c(
                "section",
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("updateConfiguration.hostTitle")) +
                        " "
                    )
                  ]),
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t("updateConfiguration.updateHostUrl"),
                      rules: [_vm.required, _vm.url]
                    },
                    on: { input: _vm.extractVariables },
                    model: {
                      value: _vm.fields.UpdateHostUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "UpdateHostUrl", $$v)
                      },
                      expression: "fields.UpdateHostUrl"
                    }
                  }),
                  _vm.fields.UpdateServerType === 1
                    ? _c("div", [
                        _c("div", { staticClass: "text-sm" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("updateConfiguration.variableTip")
                              ) +
                              " "
                          )
                        ]),
                        _vm.fields.UpdateHostOptions != {}
                          ? _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "updateConfiguration.setVariables"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._l(_vm.fields.UpdateHostOptions, function(
                                  value,
                                  property,
                                  index
                                ) {
                                  return _c("va-text-field", {
                                    key: index,
                                    staticClass: "mt-4",
                                    attrs: {
                                      label: "" + property,
                                      rules: [_vm.required]
                                    },
                                    on: { input: _vm.onChange },
                                    model: {
                                      value:
                                        _vm.fields.UpdateHostOptions[property],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.fields.UpdateHostOptions,
                                          property,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "fields.UpdateHostOptions[property]"
                                    }
                                  })
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.fields.UpdateServerType === 1
            ? _c(
                "section",
                { staticClass: "mt-6" },
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("updateConfiguration.authTitle")) +
                        " "
                    )
                  ]),
                  _c("v-select", {
                    staticClass: "mt-4",
                    attrs: {
                      outlined: "",
                      rules: [_vm.required],
                      attach: "",
                      items: _vm.authenticationTypeOptions,
                      "item-text": "text",
                      "item-value": "value",
                      label: _vm.$t("updateConfiguration.authenticationType")
                    },
                    on: { input: _vm.handleAuthenticationOptions },
                    model: {
                      value: _vm.fields.AuthenticationType,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "AuthenticationType", $$v)
                      },
                      expression: "fields.AuthenticationType"
                    }
                  }),
                  _vm.fields.AuthenticationType === 1
                    ? [
                        _c("va-text-field", {
                          staticClass: "mt-4",
                          attrs: {
                            label: _vm.$t("common.username"),
                            rules: [_vm.required]
                          },
                          on: { input: _vm.onChange },
                          model: {
                            value: _vm.fields.AuthenticationOptions.username,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.fields.AuthenticationOptions,
                                "username",
                                $$v
                              )
                            },
                            expression: "fields.AuthenticationOptions.username"
                          }
                        }),
                        _c("va-text-field", {
                          staticClass: "mt-4",
                          attrs: {
                            label: _vm.$t("common.password"),
                            rules: [_vm.required],
                            password: ""
                          },
                          on: { input: _vm.onChange },
                          model: {
                            value: _vm.fields.AuthenticationOptions.password,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.fields.AuthenticationOptions,
                                "password",
                                $$v
                              )
                            },
                            expression: "fields.AuthenticationOptions.password"
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm.fields.UpdateServerType > 0
            ? _c(
                "section",
                { staticClass: "mt-6" },
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("updateConfiguration.backupTitle")) +
                        " "
                    )
                  ]),
                  _c("va-switch", {
                    attrs: { text: _vm.$t("updateConfiguration.updateBackup") },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.UpdateBackup,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "UpdateBackup", $$v)
                      },
                      expression: "fields.UpdateBackup"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }