var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Charts")]),
      _c("h3", { staticClass: "my-4" }, [_vm._v("Lines")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-lines-chart", {
                attrs: {
                  chartData: _vm.chartDataSingle,
                  area: "",
                  title: "Single fill area"
                }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-lines-chart", {
                attrs: {
                  chartData: _vm.chartDataSingleColors,
                  title: "Custom color & hide dots",
                  "hide-dots": ""
                }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-lines-chart", {
                attrs: {
                  chartData: _vm.chartDataMultiple,
                  title: "Multiple values area & legend",
                  legend: "",
                  area: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c(
            "va-card",
            { staticStyle: { width: "47vw" } },
            [
              _c("va-lines-chart", {
                attrs: {
                  chartData: _vm.chartDataCustomColors2,
                  title: "Custom colors",
                  legend: ""
                }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "47vw" } },
            [
              _c("va-lines-chart", {
                attrs: {
                  chartData: _vm.chartDataCustomColors,
                  title: "Custom colors, stacked with legend, hidden dots",
                  legend: "",
                  stacked: "",
                  area: "",
                  "hide-dots": ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("h3", { staticClass: "my-4" }, [_vm._v("Doughnut")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-doughnut-chart", {
                attrs: { chartData: _vm.chartDataSingle, title: "Single" }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-doughnut-chart", {
                attrs: {
                  chartData: _vm.chartDataSingleColors,
                  title: "Custom colors & hidden legend",
                  legend: false
                }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-doughnut-chart", {
                attrs: {
                  chartData: _vm.chartDataMultiple,
                  title: "Multiple values"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("h3", { staticClass: "my-4" }, [_vm._v("Bars")]),
      _c("div", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c(
            "va-card",
            { staticStyle: { width: "47vw" } },
            [
              _c("va-bar-chart", {
                attrs: { chartData: _vm.chartDataSingle, title: "Single value" }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "47vw" } },
            [
              _c("va-bar-chart", {
                attrs: {
                  chartData: _vm.chartDataMultiple,
                  title: "Multiple values"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c(
            "va-card",
            { staticStyle: { width: "47vw" } },
            [
              _c("va-bar-chart", {
                attrs: {
                  chartData: _vm.chartDataCustomColors2,
                  title: "Custom colors",
                  stacked: ""
                }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "47vw" } },
            [
              _c("va-bar-chart", {
                attrs: {
                  chartData: _vm.chartDataCustomColors,
                  title: "Custom colors, stacked with legend",
                  legend: "",
                  stacked: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("h3", { staticClass: "my-4" }, [_vm._v("Horizontal Bars")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-horizontal-bar-chart", {
                attrs: { chartData: _vm.chartDataSingle, title: "Single value" }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-horizontal-bar-chart", {
                attrs: {
                  chartData: _vm.chartDataMultiple,
                  title: "Multiple values, stacked",
                  stacked: ""
                }
              })
            ],
            1
          ),
          _c(
            "va-card",
            { staticStyle: { width: "30vw" } },
            [
              _c("va-horizontal-bar-chart", {
                attrs: {
                  chartData: _vm.chartDataCustomColors,
                  title: "Custom colors with legend",
                  legend: ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }