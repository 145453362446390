import { MutationTree, GetterTree, ActionContext, ActionTree } from "vuex";
import store, { RootState } from "./index";
import { MfaProvider } from "@/models/mfaProvider";
import AuthService, { SignInData } from "@/services/authService";
import { TokenData } from "@/models/token";
import logger from "@/services/loggerService";
import i18n from "@/i18n";
import { getBrowserVersion } from "@/services/browserVersionService";

export interface MFAState {
  providers: MfaProvider[];
  enabledProviders: MfaProvider[];
  selectedProvider: MfaProvider | undefined;
  providerToEnable: MfaProvider | undefined;
  mfaToken: string;
  isLoading: boolean;
}

type MFAContext = ActionContext<MFAState, RootState>;

export const namespaced = true;

export const state = (): MFAState => ({
  providers: [],
  enabledProviders: [],
  selectedProvider: undefined,
  providerToEnable: undefined,
  mfaToken: "",
  isLoading: false,
});

export const getters: GetterTree<MFAState, RootState> = {
  isLoading: (state): boolean => {
    return state.isLoading;
  },
  providers: (state): MfaProvider[] => {
    return state.providers;
  },
  enabledProviders: (state): MfaProvider[] => {
    return state.enabledProviders;
  },
  selectedProvider: (state): MfaProvider | undefined => {
    return state.selectedProvider;
  },
  mfaToken: (state): string => {
    return state.mfaToken;
  },
  providerToEnable: (state): MfaProvider | undefined => {
    return state.providerToEnable;
  },
};

export const mutations: MutationTree<MFAState> = {
  setLoading(state: MFAState, loading: boolean) {
    state.isLoading = loading;
  },
  setMfaToken(state: MFAState, token: string) {
    state.mfaToken = token;
  },
  setProviders(state: MFAState, providers: MfaProvider[]) {
    state.providers = providers;
  },
  setProviderToEnable(state: MFAState, provider: MfaProvider | undefined) {
    state.providerToEnable = provider;
  },
  setEnabledProviders(state: MFAState, providers: MfaProvider[]) {
    state.enabledProviders = providers;
  },
  setSelectedProvider(state: MFAState, provider: MfaProvider | undefined) {
    state.selectedProvider = provider;
  },
};

export const actions: ActionTree<MFAState, RootState> = {
  async fetchAvailableProviders(context: MFAContext): Promise<void> {
    context.commit("setLoading", true);
    try {
      const providers = await AuthService.getMfaProviders();
      context.commit("setProviders", providers);
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async fetchEnabledProviders(context: MFAContext): Promise<void> {
    context.commit("setLoading", true);
    try {
      const myProviders = await AuthService.getEnabledProviders();
      context.commit("setEnabledProviders", myProviders);
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async selectProvider(
    context: MFAContext,
    provider: MfaProvider
  ): Promise<void> {
    context.commit("setLoading", true);
    try {
      const mfaToken = context.state.mfaToken;
      if (
        await AuthService.sendMFACodeAction({
          providerType: provider.type,
          mfaToken: mfaToken,
        })
      ) {
        context.commit("setSelectedProvider", provider);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async fetchProvidersChallenge(
    context: MFAContext,
    mfaToken: string
  ): Promise<void> {
    try {
      const providers = await AuthService.getProvidersChallenge(mfaToken);
      context.commit("setEnabledProviders", providers);
      context.commit("setMfaToken", mfaToken);
    } catch (e) {
      logger.error(e);
    }
  },

  async mfaLogin(
    context: MFAContext,
    data: { token: string; code: string }
  ): Promise<void> {
    context.commit("setLoading", true);
    try {
      const authData = {
        deviceUniqueId: getBrowserVersion(),
        appPlatform: "web",
        grantType: "password",
        mfaToken: data.token,
        mfaCode: data.code,
      };
      const tokenData: TokenData = await AuthService.signIn(authData);
      await store.dispatch("session/login", tokenData);
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async login(
    context: MFAContext,
    signInData: Partial<SignInData>
  ): Promise<void> {
    context.commit("setLoading", true);
    try {
      const tokenData: TokenData = await AuthService.signIn(signInData);
      await context.dispatch("session/login", tokenData, { root: true });
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signIn.success"),
          type: "success",
        },
        { root: true }
      );
    } catch (err) {
      if (AuthService.multiFactorEnabled(err)) {
        context.commit("setMfaToken", err.response.data.info.mfaToken);
      } else {
        logger.error(err);
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("signIn.error"),
            type: "error",
          },
          { root: true }
        );
        AuthService.logout();
      }
    } finally {
      context.commit("setLoading", false);
    }
  },

  // async mfaSignIn(
  //   context: MFAContext,
  //   data: Partial<SignInData>
  // ): Promise<void> {
  //   const tokenData: TokenData = await AuthService.signIn(data);
  //   await store.dispatch("session/login", tokenData);
  // },

  async disableProvider(
    context: MFAContext,
    provider: MfaProvider
  ): Promise<void> {
    context.commit("setLoading", true);
    try {
      await AuthService.disableMFAProvider(provider);
      context.dispatch("fetchEnabledProviders");
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async enableProvider(
    context: MFAContext,
    provider: MfaProvider
  ): Promise<void> {
    try {
      context.commit("setLoading", true);
      const mfaToken = (await AuthService.sendMFACode({
        providerType: provider.type,
      })) as string;
      context.commit("setProviderToEnable", provider);
      context.commit("setMfaToken", mfaToken);
    } catch (e) {
      logger.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async confirmProvider(
    context: MFAContext,
    data: { token: string; code: string }
  ): Promise<void> {
    context.commit("setLoading", true);
    try {
      const confirmed = await AuthService.verifyMFACode({
        authCode: data.code,
        mfaToken: data.token,
      });
      if (confirmed) {
        context.commit(
          "notifications/displayConfirmDialog",
          {
            visible: true,
            title: i18n.t("mfa.confirmTitle"),
            description: `${i18n.t("mfa.confirmDescription")}`,
            callback: async () => {
              try {
                await AuthService.confirmMFAActivationCode({
                  mfaToken: data.token,
                });
                context.commit(
                  "notifications/displayNotification",
                  {
                    message: i18n.t("mfa.mfaEnabled"),
                    type: "success",
                  },
                  { root: true }
                );
                context.commit("setProviderToEnable", undefined);
                context.dispatch("fetchEnabledProviders");
              } catch (e) {
                logger.error(e);
                context.commit("setLoading", false);
              }
            },
          },
          { root: true }
        );
      }
    } catch (e) {
      logger.error(e);
      context.commit("setLoading", false);
    }
  },
};
