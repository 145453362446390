var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "room-meeting-settings" } },
    [
      _c("div", { staticClass: "va-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("roomMeetingSettings.title")))
      ]),
      _vm.room
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "va-card",
                    {
                      staticClass: "fit-height",
                      attrs: { id: "room-calendars" }
                    },
                    [
                      _c("div", { staticClass: "va-subtitle-s" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("roomMeetingSettings.calendarsTitle")
                            ) +
                            " "
                        )
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("roomMeetingSettings.calendarsContent")
                          ) +
                          " "
                      ),
                      _c(
                        "div",
                        { staticClass: "calendars" },
                        _vm._l(_vm.calendars, function(calendar, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "calendar" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: calendar.image,
                                      alt: calendar.name
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "title-name ml-4" },
                                    [
                                      _c("h4", [_vm._v(_vm._s(calendar.name))]),
                                      _vm.isCalendarConnected(calendar)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block secondary--text"
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "d-block" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.room.calendar.name
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            width: "700",
                            "content-class": "fullscreen card",
                            scrollable: ""
                          },
                          model: {
                            value: _vm.calendarsDialog,
                            callback: function($$v) {
                              _vm.calendarsDialog = $$v
                            },
                            expression: "calendarsDialog"
                          }
                        },
                        [
                          _c("validation-observer", {
                            ref: "observer",
                            attrs: { slim: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var invalid = ref.invalid
                                    return [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _c("h2", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "common.selectCalendar"
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          _c(
                                            "v-card-text",
                                            [
                                              _vm.calendarsList.length > 0
                                                ? _c("v-select", {
                                                    attrs: {
                                                      attach: "",
                                                      items: _vm.calendarsList,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      "return-object": "",
                                                      label: _vm.$t(
                                                        "common.calendars"
                                                      )
                                                    },
                                                    model: {
                                                      value: _vm.calendar,
                                                      callback: function($$v) {
                                                        _vm.calendar = $$v
                                                      },
                                                      expression: "calendar"
                                                    }
                                                  })
                                                : _c("p", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$(
                                                          "roomMeetingSettings.noCalendars"
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between"
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    color: "secondary",
                                                    outlined: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.reset(true)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("common.dismiss")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    disabled:
                                                      !_vm.calendar || invalid
                                                  },
                                                  on: {
                                                    click: _vm.submitCalendar
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("common.submit")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2787811251
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "va-card",
                    {
                      staticClass: "fit-height",
                      attrs: { id: "room-video-conference-systems" }
                    },
                    [
                      _c("div", { staticClass: "va-subtitle-s" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("roomMeetingSettings.avConferenceTitle")
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "p",
                        [
                          _vm.showAvConferenceSystems
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "roomMeetingSettings.avConferenceContent"
                                    )
                                  )
                                )
                              ])
                            : _c(
                                "v-alert",
                                {
                                  staticClass: "light",
                                  attrs: { type: "info", dense: "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "roomMeetingSettings.noAvConference"
                                      )
                                    ) + " "
                                  )
                                ]
                              )
                        ],
                        1
                      ),
                      _vm.showAvConferenceSystems
                        ? _c(
                            "div",
                            { staticClass: "systems" },
                            _vm._l(_vm.enabledSystems, function(system, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "system" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: system.icon,
                                          alt: system.name
                                        }
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "title-name ml-4" },
                                        [
                                          _c("h4", [
                                            _vm._v(_vm._s(system.name))
                                          ]),
                                          _vm.isSystemConnected(system.provider)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block secondary--text"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.accountConnected(
                                                          system
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }