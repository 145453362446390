




































































import Vue from "vue";
import QrcodeVue from "qrcode.vue";
import { MfaProvider, providerTypes } from "@/models/mfaProvider";
import VaCard from "@/components/molecules/VaCard.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  name: "MfaQrcodeDialog",
  components: {
    VaButton,
    VaTextField,
    VaCard,
    QrcodeVue,
  },
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      code: "",
    };
  },
  methods: {
    async enableMFA() {
      await this.$store.dispatch("mfa/confirmProvider", {
        token: this.mfaToken,
        code: this.code,
      });
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.getters["mfa/isLoading"];
    },
    types(): Record<string, number> {
      return providerTypes;
    },
    mfaToken(): string {
      return this.$store.getters["mfa/mfaToken"];
    },
    providerToEnable(): MfaProvider | undefined {
      return this.$store.getters["mfa/providerToEnable"];
    },
  },
  watch: {
    mfaToken: function () {
      this.code = "";
    },
  },
});
