




























import Vue, { VueConstructor } from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import AuthService from "@/services/authService";
import errorMixin, { LoggerMixin } from "@/components/mixins/errorMixin";
import VaCard from "@/components/molecules/VaCard.vue";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "BackupCodesProvider",
  mixins: [errorMixin],
  data() {
    return {
      codes: [] as string[],
      loading: false,
      dialog: false,
    };
  },
  components: {
    VaCard,
    VaButton,
  },
  methods: {
    async getBackupCodes() {
      this.dialog = true;
      if (this.codes.length == 0) {
        try {
          this.loading = true;
          this.codes = await AuthService.getBackupCodes();
        } catch (e) {
          this.handleError(e);
        } finally {
          this.loading = false;
        }
      }
    },
    async onResetBackupCodes() {
      try {
        this.loading = true;
        await AuthService.resetBackupCodes();
        this.codes = await AuthService.getBackupCodes();
      } catch (e) {
        this.handleError(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
