import { MutationTree, GetterTree } from "vuex";
import { RootState } from "@/store";
import { Company } from "@/models/company";

export interface CompanyState {
  companies: Company[] | null;
  currentCompany: Company | undefined;
}

// type CompanyContext = ActionContext<CompanyState, RootState>;

export const namespaced = true;

export const state = (): CompanyState => ({
  companies: null,
  currentCompany: undefined,
});

export const getters: GetterTree<CompanyState, RootState> = {
  companies: (state) => {
    return state.companies;
  },
  currentCompany: (state) => {
    return state.currentCompany;
  },
};

export const mutations: MutationTree<CompanyState> = {
  setCompanies(state: CompanyState, companies: Company[] | null) {
    state.companies = companies;
  },
  setCurrentCompany(state: CompanyState, company: Company) {
    state.currentCompany = company;
  },
  clearCurrentCompany(state: CompanyState) {
    state.currentCompany = undefined;
  },
};

// export const actions: ActionTree<CompanyState, RootState> = {
//   async setCompanies(context: CompanyContext): Promise<void> {
//     //
//   },
// };
