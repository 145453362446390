var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "analytics-top-bar",
        {
          attrs: {
            title: " Average Green rating 60%",
            icon: "vi vi-leaf",
            "icon-color": "success"
          }
        },
        [
          _vm._v(" Average Green rating is calculated by "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(
              " Scheduler settings Policies Hardware consumption Average start time "
            )
          ]),
          _vm._v(" , Lorem ipsum dolor sit amet, consectetur adipiscing elit ")
        ]
      ),
      _c("div", { staticClass: "va-subtitle d-flex" }, [_vm._v("Policies")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  icon: "vi vi-c-check",
                  "icon-color": "success",
                  value: "100%",
                  title: "General",
                  to: "/rooms/policies/general",
                  "trend-value": "ok",
                  "trend-color": "success"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  icon: "vi vi-c-warning",
                  "icon-color": "warning",
                  value: "45%",
                  title: "HomeLauncher",
                  to: "/rooms/policies/home-launcher",
                  "trend-value": "ko",
                  "trend-color": "warning"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  icon: "vi vi-c-warning",
                  "icon-color": "warning",
                  value: "55%",
                  title: "VideoConference",
                  to: "/rooms/policies/video-conference",
                  "trend-value": "ko",
                  "trend-color": "warning"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  icon: "vi vi-c-remove",
                  "icon-color": "error",
                  value: "0%",
                  title: "Security",
                  to: "/rooms/policies/security",
                  "trend-value": "missing",
                  "trend-color": "error"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  icon: "vi vi-c-check",
                  "icon-color": "success",
                  value: "85%",
                  title: "Update",
                  to: "/rooms/policies/update",
                  "trend-value": "ok",
                  "trend-color": "success"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-6" },
        [
          _c("div", [
            _vm._v("Policies optimizations could affect energy consumption")
          ]),
          _c("va-button", { attrs: { cta: "Become awesome" } })
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-bar-chart", {
                    attrs: {
                      chartData: _vm.hwConsumption,
                      area: "",
                      "hide-dots": "",
                      title: "Hardware consumption"
                    }
                  }),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("span", [
                      _vm._v(" Improve your haerware consumption by "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://valarea.com/",
                            target: "_blank"
                          }
                        },
                        [_vm._v(" doing something ")]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.averageStartTime,
                      area: "",
                      "hide-dots": "",
                      title: "Average start time"
                    }
                  }),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("span", [
                      _vm._v(" Improve this by "),
                      _c(
                        "a",
                        {
                          staticClass: "font-bold",
                          attrs: {
                            href: "https://valarea.com/",
                            target: "_blank"
                          }
                        },
                        [_vm._v(" stop being late! ")]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }