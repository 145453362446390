var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.room
    ? _c(
        "div",
        { attrs: { id: "room-remote-management" } },
        [
          _c(
            "div",
            { staticClass: "va-subtitle flex align-center" },
            [
              _c("div", { staticClass: "va-subtitle flex align-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("roomCommands.title")) + " ")
              ]),
              _c("maintenance-switch", {
                attrs: {
                  room: _vm.room,
                  text: _vm.$t("roomInformation.maintenanceMode")
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "va-card",
                    { staticClass: "fit-height" },
                    [
                      _c("div", { staticClass: "va-subtitle-s" }, [
                        _vm._v(_vm._s(_vm.$t("common.roomName")))
                      ]),
                      _c("va-text-field", {
                        staticClass: "mb-5",
                        attrs: {
                          label: _vm.$t("common.roomName"),
                          dense: "",
                          disabled: _vm.room.isMaintenanceExpired
                        },
                        model: {
                          value: _vm.room.name,
                          callback: function($$v) {
                            _vm.$set(_vm.room, "name", $$v)
                          },
                          expression: "room.name"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row-reverse" },
                        [
                          _c("va-button", {
                            attrs: {
                              size: "s",
                              disabled:
                                _vm.sending ||
                                !_vm.room.name ||
                                _vm.room.isMaintenanceExpired,
                              cta: _vm.$t("common.save")
                            },
                            on: { click: _vm.setName }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "va-card",
                    { staticClass: "fit-height" },
                    [
                      _c("div", { staticClass: "va-subtitle-s" }, [
                        _vm._v("Room info")
                      ]),
                      _c("v-select", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          attach: "",
                          multiple: "",
                          items: _vm.tags,
                          "return-object": "",
                          "item-value": "tagId",
                          "item-text": "name",
                          label: _vm.$t("common.tags"),
                          loading: _vm.updatingTags,
                          disabled:
                            _vm.updatingTags || _vm.room.isMaintenanceExpired
                        },
                        model: {
                          value: _vm.selectedTags,
                          callback: function($$v) {
                            _vm.selectedTags = $$v
                          },
                          expression: "selectedTags"
                        }
                      }),
                      _vm.groups && _vm.groups.length > 0
                        ? _c("v-select", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              attach: "",
                              items: _vm.groups,
                              "item-value": "id",
                              "item-text": "name",
                              "return-object": "",
                              loading: _vm.updatingGroup,
                              disabled:
                                _vm.updatingGroup ||
                                _vm.room.isMaintenanceExpired,
                              label: _vm.$t("common.group")
                            },
                            model: {
                              value: _vm.group,
                              callback: function($$v) {
                                _vm.group = $$v
                              },
                              expression: "group"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "va-card",
                    {},
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "div",
                            { staticClass: "va-subtitle-s flex-grow-1" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common.roomMicrophone")) +
                                  " "
                              )
                            ]
                          ),
                          _c("va-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              text: _vm.room.microphone
                                ? _vm.$t("common.microphoneOn")
                                : _vm.$t("common.microphoneOff"),
                              disabled:
                                _vm.room.microphone === undefined ||
                                _vm.room.isMaintenanceExpired
                            },
                            on: {
                              input: function($event) {
                                return _vm.setMicrophone(!_vm.room.microphone)
                              }
                            },
                            model: {
                              value: _vm.room.microphone,
                              callback: function($$v) {
                                _vm.$set(_vm.room, "microphone", $$v)
                              },
                              expression: "room.microphone"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-slider", {
                        staticClass: "mt-3",
                        attrs: {
                          min: "0",
                          max: "100",
                          "thumb-label": "always",
                          "thumb-size": 26,
                          "prepend-icon": "mdi-volume-high",
                          color: "accent",
                          "hide-details": "",
                          disabled:
                            !_vm.room.microphone ||
                            _vm.room.isMaintenanceExpired
                        },
                        on: { change: _vm.setMicrophoneLevel },
                        model: {
                          value: _vm.room.microphoneLevel,
                          callback: function($$v) {
                            _vm.$set(_vm.room, "microphoneLevel", $$v)
                          },
                          expression: "room.microphoneLevel"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex mt-6" },
                        [
                          _c(
                            "div",
                            { staticClass: "va-subtitle-s flex-grow-1" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common.roomVolume")) + " "
                              )
                            ]
                          ),
                          _c("va-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              text: _vm.room.volume
                                ? _vm.$t("common.volumeOn")
                                : _vm.$t("common.volumeOff"),
                              disabled: _vm.room.isMaintenanceExpired
                            },
                            on: {
                              input: function($event) {
                                return _vm.setVolume(!_vm.room.volume)
                              }
                            },
                            model: {
                              value: _vm.room.volume,
                              callback: function($$v) {
                                _vm.$set(_vm.room, "volume", $$v)
                              },
                              expression: "room.volume"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-slider", {
                        attrs: {
                          min: "0",
                          max: "100",
                          "thumb-label": "always",
                          "thumb-size": 26,
                          "prepend-icon": "mdi-volume-high",
                          color: "accent",
                          "hide-details": "",
                          disabled:
                            !_vm.room.volume || _vm.room.isMaintenanceExpired
                        },
                        on: { change: _vm.setVolumeLevel },
                        model: {
                          value: _vm.room.volumeLevel,
                          callback: function($$v) {
                            _vm.$set(_vm.room, "volumeLevel", $$v)
                          },
                          expression: "room.volumeLevel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("va-card", { staticClass: "fit-height" }, [
                    _c("div", { staticClass: "va-subtitle-s" }, [
                      _vm._v(_vm._s(_vm.$t("common.roomCapacity")))
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-space-between" },
                      [
                        _c("va-text-field", {
                          staticClass: "mr-6",
                          attrs: {
                            label: _vm.$t("common.roomCapacity"),
                            dense: "",
                            number: "",
                            disabled:
                              !_vm.room.roomCapacity.peopleCount ||
                              _vm.room.isMaintenanceExpired
                          },
                          model: {
                            value: _vm.room.roomCapacity.capacity,
                            callback: function($$v) {
                              _vm.$set(_vm.room.roomCapacity, "capacity", $$v)
                            },
                            expression: "room.roomCapacity.capacity"
                          }
                        }),
                        _c("va-switch", {
                          staticClass: "mt-1 mr-6",
                          attrs: {
                            text: _vm.$t("common.peopleCount"),
                            disabled: _vm.room.isMaintenanceExpired
                          },
                          on: { input: _vm.togglePeoplecount },
                          model: {
                            value: _vm.room.roomCapacity.peopleCount,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.room.roomCapacity,
                                "peopleCount",
                                $$v
                              )
                            },
                            expression: "room.roomCapacity.peopleCount"
                          }
                        }),
                        _c("va-switch", {
                          staticClass: "mt-1",
                          attrs: {
                            disabled:
                              !_vm.room.roomCapacity.peopleCount ||
                              _vm.room.isMaintenanceExpired,
                            text: _vm.$t("common.setCapacityNotificationTitle")
                          },
                          model: {
                            value: _vm.room.roomCapacity.showNotification,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.room.roomCapacity,
                                "showNotification",
                                $$v
                              )
                            },
                            expression: "room.roomCapacity.showNotification"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row-reverse" },
                      [
                        _c("va-button", {
                          attrs: {
                            size: "s",
                            disabled:
                              _vm.sending ||
                              (_vm.room.roomCapacity &&
                                _vm.room.roomCapacity.capacity <= 0) ||
                              _vm.room.isMaintenanceExpired,
                            cta: _vm.$t("common.save")
                          },
                          on: { click: _vm.setCapacity }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("va-card", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "pr-6" },
                      [
                        _c("div", { staticClass: "va-subtitle-s" }, [
                          _vm._v(_vm._s(_vm.$t("common.commands")))
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between mb-3" },
                          [
                            _c("va-button", {
                              staticClass: "mr-3",
                              attrs: {
                                size: "s",
                                disabled:
                                  _vm.sending || _vm.room.isMaintenanceExpired,
                                cta:
                                  _vm.$t("common.start") +
                                  " " +
                                  _vm.$t("common.valareaRoomApplication"),
                                type: "outline",
                                color: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setStatus(true)
                                }
                              }
                            }),
                            _c("va-button", {
                              attrs: {
                                size: "s",
                                disabled:
                                  _vm.sending || _vm.room.isMaintenanceExpired,
                                cta:
                                  _vm.$t("common.stop") +
                                  " " +
                                  _vm.$t("common.valareaRoomApplication"),
                                type: "outline",
                                color: "error"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setStatus(false)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between mb-3" },
                          [
                            _c("va-button", {
                              staticClass: "mr-3",
                              attrs: {
                                size: "s",
                                disabled:
                                  _vm.sending || _vm.room.isMaintenanceExpired,
                                cta: _vm.$t("common.restartMachine"),
                                type: "outline"
                              },
                              on: { click: _vm.restartMachine }
                            }),
                            _c("va-button", {
                              staticClass: "mr-3",
                              attrs: {
                                size: "s",
                                disabled:
                                  _vm.sending || _vm.room.isMaintenanceExpired,
                                cta: _vm.$t(
                                  "common.restartWirelessPresentation"
                                ),
                                type: "outline"
                              },
                              on: { click: _vm.restartAirServer }
                            }),
                            _c("va-button", {
                              attrs: {
                                size: "s",
                                disabled:
                                  !_vm.room.online ||
                                  _vm.sending ||
                                  _vm.room.isMaintenanceExpired,
                                cta: _vm.$t("common.downloadLog"),
                                type: "outline"
                              },
                              on: { click: _vm.downloadLog }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-dialog",
                          {
                            attrs: {
                              "content-class": "fullscreen card",
                              scrollable: ""
                            },
                            model: {
                              value: _vm.downloading,
                              callback: function($$v) {
                                _vm.downloading = $$v
                              },
                              expression: "downloading"
                            }
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.$t("common.downloadingLog"))
                                    )
                                  ])
                                ]),
                                _c(
                                  "v-card-text",
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          outlined: "",
                                          color: "error"
                                        },
                                        on: { click: _vm.stopDownload }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.stop")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "pl-6" },
                      [
                        _c("div", { staticClass: "va-subtitle-s" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("common.runPowerShellScript")) +
                              " "
                          )
                        ]),
                        _c("file-uploader", {
                          ref: "scriptUploader",
                          attrs: {
                            files: [],
                            accept: [".ps1"],
                            "max-size": 1048576,
                            "file-type": 4,
                            disabled: _vm.room.isMaintenanceExpired
                          },
                          on: { upload: _vm.uploadScript }
                        }),
                        _vm.script
                          ? _c("v-switch", {
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                inset: "",
                                label: _vm.$t("common.runAsSystem"),
                                disabled: _vm.room.isMaintenanceExpired
                              },
                              model: {
                                value: _vm.scriptRunAsSystem,
                                callback: function($$v) {
                                  _vm.scriptRunAsSystem = $$v
                                },
                                expression: "scriptRunAsSystem"
                              }
                            })
                          : _vm._e(),
                        _vm.script
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mt-6",
                                attrs: {
                                  color: "accent",
                                  label: _vm.$t("common.setScript"),
                                  depressed: "",
                                  small: "",
                                  disabled: _vm.room.isMaintenanceExpired
                                },
                                on: { click: _vm.setScript }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("common.setScript")) + " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }