export default {
  getTenant(): string {
    return process.env.VUE_APP_TENANT || "Mago";
  },
  getTenantCode(): string {
    return process.env.VUE_APP_TENANT_CODE || "REM-MAG-CONSOLE";
  },
  getTenantProductName(): string {
    const tenantNames = {
      Valarea: "Valarea Console",
      Mago: "Mago Admin Console",
      IdeaHubRoom: "IdeaHub Room Admin Console",
      SmartTech: "TeamWorks Admin Console",
      TAEasyMeeting: "TA Easy Meeting Admin Console",
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return tenantNames[this.getTenant()] || "Mago";
  },
  requireAsset(asset: string): string {
    return require(`@/assets/images/logos/${this.getTenant()}/${asset}`);
    // switch (process.env.VUE_APP_TENANT) {
    //   case "TAEasyMeeting":
    //     return require(`@/assets/images/logos/taEasyMeeting/${asset}`);
    //   default:
    //     return require(`@/assets/images/logos/valarea/${asset}`);
    // }
  },
};
