import cloneDeep from "lodash/cloneDeep";
import forOwn from "lodash/forOwn";
import { currentEnv } from "@/utils/index";

interface ProviderConfig {
  url?: string;
  client_id: string;
  response_type: string;
  redirect_uri: string;
  scope?: string;
  state?: string;
  access_type?: string;
  included_granted_scopes?: boolean;
  prompt?: string;
}

const configurations: Record<string, ProviderConfig> = {
  microsoft: {
    url: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    client_id: process.env.VUE_APP_TEAMS_CLIENT_ID,
    response_type: "code",
    redirect_uri: "https://oauth-msft.valarea.com/oauth",
    scope:
      "https://graph.microsoft.com/Calendars.ReadWrite https://graph.microsoft.com/Calendars.ReadWrite.Shared https://graph.microsoft.com/Files.ReadWrite.All https://graph.microsoft.com/Mail.Read https://graph.microsoft.com/Mail.Send offline_access https://graph.microsoft.com/OnlineMeetings.ReadWrite https://graph.microsoft.com/openid https://graph.microsoft.com/profile https://graph.microsoft.com/User.Read",
    state: "",
    prompt: "consent",
  },
  zoom: {
    url: "https://zoom.us/oauth/authorize",
    client_id: process.env.VUE_APP_ZOOM_CLIENT_ID,
    response_type: "code",
    redirect_uri: "https://oauth-zoom.valarea.com/oauth",
    state: "",
  },
  google: {
    url: "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount",
    client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    response_type: "code",
    redirect_uri: "http://localhost:8080/",
    scope: "openid email",
    state: "",
    prompt: "consent",
    access_type: "offline",
    included_granted_scopes: true,
  },
  webex: {
    //url: "https://webexapis.com/v1/authorize",
    url: "https://api.ciscospark.com/v1/authorize",
    client_id: process.env.VUE_APP_WEBEX_CLIENT_ID,
    response_type: "code",
    redirect_uri: "https://oauth-webex.valarea.com/oauth",
    scope: "meeting:schedules_read meeting:schedules_write spark:people_read",
    state: "",
  },
};

/* NOT USED METHODS */
/* Authorization popup */
let popup: Window = null as unknown as Window;

/* Watcher for popup close */
let popupWatcher = 0;

/* Watcher for process pending/aborted */
let processWatcher = 0;

const authorize = (
  url: string,
  params: URLSearchParams,
  cb: (data: any) => void
) => {
  console.log("url", url);
  console.log("params", Object.fromEntries(params));
  window.onmessage = function (e: any) {
    if (e.origin === window.location.origin && e.data.authProvider) {
      // wait for response
      console.log(e.data.authProvider);
      cb(e.data.authProvider); // send response with callback
      popup.close();
    }
  }.bind(this);
  popup = window.open(
    url + "?" + params,
    "popUpWindow",
    `height=500,width=500,top=0,left=0`
  ) as Window;
  popupWatcher = setInterval(() => {
    if (popup && popup.closed) {
      reset();
    }
  }, 2500);
  processWatcher = setTimeout(() => {
    reset();
  }, 120000);
};

const reset = (): void => {
  if (popup) {
    popup.close();
    popup = null as unknown as Window;
  }
  clearInterval(popupWatcher);
  clearTimeout(processWatcher);
  window.onmessage = null;
};

export default {
  connect(provider: string, cb: (data: any) => void, state = ""): void {
    const config = cloneDeep(configurations[provider]);
    if (!config) return;
    config.state = currentEnv();
    if (state) {
      config.state = state + ";" + config.state;
    }
    const url = config.url;
    delete config.url;
    const params = new URLSearchParams();
    // eslint-disable-next-line
    // @ts-ignore
    forOwn(config, (value: string, key: string) => {
      params.append(key, value);
    });
    authorize(url as string, params, cb);
  },
  reset,
};
