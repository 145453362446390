























































































































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { Building } from "@/models/building";
import GeocodingService from "@/services/geocodingService";
// import VaChip from "@/components/atoms/VaChip.vue";
import { RoomDetail } from "@/models/room";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  name: "BuildingForm",

  components: {
    VaIcon,
    VaTextField,
    VaButton,
    // VaChip,
  },
  data() {
    return {
      newFloor: "",
      sectionList: [],
      valid: true,
      loading: false,
      requiredRules: [
        (value: string) => !!value || this.$i18n.t("validate.required"),
      ],
      selectedRooms: [] as RoomDetail[],
    };
  },
  beforeMount() {
    this.$store.dispatch("room/getRooms");
    if (!this.$store.getters["building/currentBuilding"]?.uuid) {
      const building = this.getCurrentBuilding();
      if (building?.uuid)
        this.$store.commit("building/setCurrentBuilding", building);
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementsByName("buildingStreet")[0] as HTMLInputElement
    );
    // eslint-disable-next-line no-undef
    google.maps.event.addListener(autocomplete, "place_changed", this.onChange);
  },
  computed: {
    currentBuilding(): Building {
      return this.$store.getters["building/currentBuilding"];
    },
    floors(): string[] {
      return this.$store.getters["building/currentBuilding"].floors;
    },
    rooms(): RoomDetail[] {
      return this.$store.getters["room/rooms"];
    },
    // selectedRooms(): RoomDetail[] {
    //   return this.$store.getters["room/currentBuilding"].rooms.length > 0
    //     ? this.$store.getters["room/rooms"].filter(
    //         (room: RoomDetail) =>
    //           this.$store.getters["room/currentBuilding"].rooms.findIndex(
    //             (el: string) => el === room.id
    //           ) > -1
    //       )
    //     : ([] as RoomDetail[]);
    // },
  },
  methods: {
    getCurrentBuilding(): Building {
      return this.$store.getters["building/buildings"].find(
        (item: Building) => item.uuid === this.$route.params.id
      );
    },
    async onChange(): Promise<void> {
      const addressInput = document.getElementsByName(
        "buildingStreet"
      )[0] as HTMLInputElement;
      const address = await GeocodingService.getCoordinates(addressInput.value);
      this.currentBuilding.street = address.formatted_address;
      this.currentBuilding.lat = Number(address.geometry.location.lat);
      this.currentBuilding.lng = Number(address.geometry.location.lng);
    },
    async onSave(): Promise<void> {
      if (
        (
          this.$refs.buildingForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        this.currentBuilding.uuid = !this.currentBuilding.uuid
          ? this.$route.params.id
          : this.currentBuilding.uuid;
        this.$store.commit("building/updateBuildings", this.currentBuilding);
      }
    },
    async onDelete(): Promise<void> {
      this.$store.commit("notifications/displayConfirmDialog", {
        visible: true,
        title: this.$i18n.t("buildingsList.deleteDialogTitle"),
        description: `${this.$i18n.t("buildingsList.deleteDescription")} ${
          this.currentBuilding.name
        }`,
        callback: () => {
          this.$store.dispatch(
            "building/deleteBuilding",
            this.currentBuilding.uuid
          );
        },
      });
    },
    onAddFloor(): void {
      if (!this.newFloor) return;
      this.$store.commit("building/addFloor", this.newFloor);
      this.newFloor = "";
    },
    removeFloor(item: string) {
      this.$store.commit("building/removeFloor", item);
    },
  },
});
