import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { OptionsTable, QueryResult } from "@/models/table";
import {
  PodApplication,
  PodApplicationDeserializer,
  PodApplicationSerializer,
  PodApplicationSearchSerializer,
} from "@/models/podApplication";

export default {
  async search(
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = PodApplicationSearchSerializer(options, searchString);
    const res: AxiosResponse = await axios.post(
      apiService.podApplication.searchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },

  async fetch(): Promise<Array<PodApplication>> {
    const res = await axios.get(apiService.podApplication.baseUrl());
    return res.data.map(PodApplicationDeserializer);
  },

  async get(id: string): Promise<Array<PodApplication>> {
    const res = await axios.get(apiService.podApplication.baseUrl() + "/" + id);
    return res.data(PodApplicationDeserializer);
  },
  async post(app: PodApplication): Promise<AxiosResponse> {
    return await axios.post(
      apiService.podApplication.baseUrl(),
      PodApplicationSerializer(app)
    );
  },
  async put(app: PodApplication): Promise<AxiosResponse> {
    return await axios.put(
      apiService.podApplication.baseUrl() + "/" + app.id,
      PodApplicationSerializer(app)
    );
  },
  async delete(app: PodApplication): Promise<AxiosResponse> {
    return await axios.delete(
      apiService.podApplication.baseUrl() + "/" + app.id
    );
  },
};
