


































import Vue from "vue";
import Navigation from "@/components/organisms/Navigation.vue";
import SentryUtil from "@/utils/sentryUtil";
import TopBar from "@/components/organisms/TopBar.vue";

export default Vue.extend({
  name: "MainColumns",
  components: { Navigation, TopBar },
  mounted() {
    SentryUtil.setUser();
  },
  computed: {},
});
