var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "analytics-top-bar",
        {
          attrs: {
            title: "Average Quality 70%",
            icon: "vi vi-award",
            "icon-color": "primary"
          }
        },
        [
          _vm._v(" Average Quality is calculated by "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v("User feedback Hardware feedback air quality audio quality")
          ]),
          _vm._v(" , Lorem ipsum dolor sit amet, consectetur adipiscing elit ")
        ]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.userFeedback,
                      area: "",
                      "hide-dots": "",
                      title: "Users feedback",
                      legend: ""
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between mt-4 align-center"
                    },
                    [
                      _c("div", [
                        _vm._v("Data collected on end meeting survey")
                      ]),
                      _c("va-button", { attrs: { cta: "More details" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.airQuality,
                      area: "",
                      "hide-dots": "",
                      title: "Air Quality"
                    }
                  }),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("span", [
                      _vm._v(" Powered by "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://valarea.com/",
                            target: "_blank"
                          }
                        },
                        [_vm._v(" Remago airquality supersensor ")]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.audioQuality,
                      area: "",
                      "hide-dots": "",
                      title: "Audio Quality"
                    }
                  }),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("span", [
                      _vm._v(" Powered by "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://valarea.com/",
                            target: "_blank"
                          }
                        },
                        [_vm._v(" Mago smart microphones ultra ")]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }