








































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import RoomIssues from "@/components/dashboard/rooms/room/RoomIssues.vue";
import { RoomDetail } from "@/models/room";
import VaBack from "@/components/molecules/VaBack.vue";

export default Vue.extend({
  name: "template_main",
  props: {
    roomId: {
      type: String,
      required: true,
    },
  },
  async beforeMount() {
    if (this.$route.params.id) {
      await this.$store.dispatch("room/fetchRoom", this.$route.params.id);
      this.polling = setInterval(() => {
        //TODO MOVE TO PARENT COMPONENT?
        this.$store.dispatch("room/refreshRoom");
      }, 25000);
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  data() {
    return {
      polling: 0,
      // error: ""
    };
  },
  components: {
    VaBack,
    MainColumns,
    RoomIssues,
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
});
