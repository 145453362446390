




































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaTextArea from "@/components/atoms/VaTextArea.vue";

export default Vue.extend({
  components: {
    VaTextField,
    VaTextArea,
  },
  data: () => ({
    rules: [
      (value: string) => !!value || "Required.",
      (value: string) => (value && value.length >= 3) || "Min 3 characters",
    ],
  }),
});
