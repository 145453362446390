import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { QueryResult, QueryTable } from "@/models/table";
import { User, UserForm, UserList } from "@/models/user";

export default {
  async search(data: QueryTable): Promise<QueryResult> {
    const res: AxiosResponse = await axios.post(
      apiService.usersSearchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },
  async get(): Promise<Array<User>> {
    const res = await axios.get(apiService.usersUrl());
    return res.data.items;
  },
  async post(licenseKey: string, user: UserForm): Promise<AxiosResponse> {
    return axios.post(apiService.usersUrl(), {
      licenseKey: licenseKey,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password,
    });
  },
  async put(user: UserForm): Promise<AxiosResponse> {
    return await axios.put(apiService.usersUrl() + "/" + user.id, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
  },
  //TODO DUPLICATIONS, DELETE ME
  async update(user: UserList): Promise<AxiosResponse> {
    return await axios.put(apiService.usersUrl() + "/" + user.userId, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
  },
  async delete(id: string): Promise<AxiosResponse> {
    return await axios.delete(apiService.usersUrl() + "/" + id);
  },
  async getStatus(
    mail: string
  ): Promise<"new" | "enterprise" | "free" | "blocked"> {
    try {
      const res = await axios.post(apiService.usersUrl() + "/status", {
        email: mail,
      });
      if (res.data.isEnterprise) {
        return "enterprise";
      }
      return "free";
    } catch (error) {
      if (error?.response?.data?.errorCode == "U404") {
        return "new";
      }
      return "blocked";
    }
  },
};
