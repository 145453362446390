<script>
import { Doughnut } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Vue from "vue";

export default Vue.extend({
  extends: Doughnut,
  props: {
    data: {
      required: true,
      type: Object,
    },
    legend: {
      required: false,
      type: Boolean,
      default: true,
    },
    tooltips: {
      required: false,
      type: Boolean,
      default: true,
    },
    datalabels: {
      required: false,
      type: Boolean,
      default: false,
    },
    total: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: true,
        animation: {
          animateRotate: true,
        },
        legend: {
          display: false,
        },
        cutoutPercentage: 70,
        legendCallback: function (chart) {
          if (!this.legend) return "";
          const list = document.createElement("ul");
          const data = chart.data;
          const datasets = data.datasets;
          const labels = data.labels;
          let i, ilen, listItem, listItemSpan, labelItem, totalItem;

          list.setAttribute("class", chart.id + "-legend");
          if (datasets.length) {
            for (i = 0, ilen = datasets[0].data.length; i < ilen; ++i) {
              listItem = list.appendChild(document.createElement("li"));
              listItemSpan = listItem.appendChild(
                document.createElement("span")
              );
              listItemSpan.style.backgroundColor =
                datasets[0].backgroundColor[i];
              listItemSpan.style.borderColor = datasets[0].borderColor[i];
              listItemSpan.classList.add("color");
              if (labels[i]) {
                labelItem = document.createElement("span");
                labelItem.innerText = labels[i];
                labelItem.classList.add("label");
                listItem.appendChild(labelItem);
              }
              if (this.total.length !== 0) {
                totalItem = document.createElement("span");
                totalItem.innerText = this.total[i];
                totalItem.classList.add("total");
                listItem.appendChild(totalItem);
              }
            }
          }
          return list.outerHTML;
        }.bind(this),
        tooltips: {
          enabled: this.tooltips,
        },
        plugins: {
          datalabels: {
            color: "#FFF",
            textAlign: "center",
            display: this.datalabels,
            formatter: function (value) {
              if (value > 0) {
                return value;
              } else {
                return null;
              }
            },
            font: {
              size: 10,
              lineHeight: 1,
              weight: 700,
            },
          },
        },
      },
    };
  },
  methods: {
    update() {
      const legend = this.$data._chart.generateLegend();
      this.$emit("legend", legend);
      this.$data._chart.update();
    },
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.data, this.options);
    const legend = this.$data._chart.generateLegend();
    this.$emit("legend", legend);
  },
});
</script>

<style lang="scss" scoped></style>
