















import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";

export default Vue.extend({
  name: "Analytics",
  props: {},
  components: {
    MainColumns,
  },
});
