<script>
import { Line } from "vue-chartjs";
import Vue from "vue";
//import CapacityUtils from "../../../utils/capacityUtils";

export default Vue.extend({
  extends: Line,
  props: {
    data: {
      type: Object,
      required: true,
    },
    maxPersons: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                max: this.maxPersons,
              },
            },
          ],
          xAxes: [
            {
              display: true,
            },
          ],
        },
        tension: false,
        stepped: 0,
        borderDash: [],
        spanGaps: true,
        tooltips: {
          enabled: false,
          mode: "point",
          intersect: true,
          position: "nearest",
          custom: function (tooltip) {
            //return CapacityUtils.buildTooltip(tooltip, this._chart);
            const id = "capacity-chart-tooltip" + this._chart.id;
            // Tooltip Element
            let tooltipEl = document.getElementById(id);

            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.classList.add("chartjs-tooltip");
              tooltipEl.id = id;
              tooltipEl.style.zIndex = "10";
              tooltipEl.innerHTML = "<table></table>";
              this._chart.canvas.parentNode.appendChild(tooltipEl);
              const tooltipsStillOpened =
                document.getElementsByClassName("chartjs-tooltip");
              for (let i = 0; i < tooltipsStillOpened.length; i++) {
                if (tooltipsStillOpened[i].style.opacity === "1") {
                  tooltipsStillOpened[i].remove();
                }
              }
            }

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
              tooltipEl.style.opacity = "0";
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltip.yAlign) {
              tooltipEl.classList.add(tooltip.yAlign);
            } else {
              tooltipEl.classList.add("no-transform");
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltip.body) {
              let titleLines = tooltip.title || [];
              let bodyLines = tooltip.body.map(getBody);

              let innerHtml = "<thead>";

              titleLines.forEach(function (title) {
                innerHtml += "<tr><th>" + title + "</th></tr>";
              });
              innerHtml += "</thead><tbody>";

              bodyLines.forEach(function (body, i) {
                const colors = tooltip.labelColors[i];
                let style = "background:" + colors.backgroundColor;
                style += "; border: 1px solid " + colors.borderColor;
                const span =
                  '<span class="chartjs-tooltip-key" style="' +
                  style +
                  '"></span>';
                innerHtml += "<tr><td>" + span + body + "</td></tr>";
              });
              const dataset =
                this._chart.config.data.datasets[
                  tooltip.dataPoints[0].datasetIndex
                ].capacityData[tooltip.dataPoints[0].index];
              for (let key in dataset) {
                innerHtml += `<tr><td style='font-size: 10px;'>${key}: ${dataset[key]}</td></tr>`;
              }
              innerHtml += "</tbody>";

              const tableRoot = tooltipEl.querySelector("table");
              tableRoot.innerHTML = innerHtml;
            }

            const positionY = this._chart.canvas.offsetTop;
            const positionX = this._chart.canvas.offsetLeft;

            // Display, position, and set styles for font
            tooltipEl.style.minWidth = "130px";
            tooltipEl.style.opacity = "1";
            tooltipEl.style.left = positionX + tooltip.caretX + "px";
            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
            tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
            tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
            tooltipEl.style.padding =
              tooltip.yPadding + "px " + tooltip.xPadding + "px";
          },
        },
        lineAt: 70,
      },
    };
  },
  computed: {
    chartData() {
      return this.data;
    },
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
  watch: {
    data: function () {
      this.$data._chart.destroy();
      this.renderChart(this.data, this.options);
    },
  },
});
</script>

<style lang="scss" scoped></style>
