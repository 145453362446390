import i18n from "@/i18n";
import {
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";
import { FileFormatted, DeserializeFile } from "@/models/file";

export interface PodApplication {
  id: string;
  name: string;
  path: string;
  parameters: string;
  icon?: FileFormatted;
  description?: string;
  isDefault: boolean;
}

export const podApplicationConfig = {
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
  path: {
    dtoName: "path",
    sortable: false,
    filterable: true,
    label: i18n.t("podApplications.path"),
  },
  parameters: {
    dtoName: "parameters",
    sortable: false,
    filterable: false,
    label: i18n.t("podApplications.parameters"),
  },
  isDefault: {
    dtoName: "isDefault",
    sortable: false,
    filterable: false,
    label: i18n.t("podApplications.type"),
  },
  description: {
    dtoName: "description",
    sortable: false,
    filterable: true,
    label: i18n.t("common.description"),
  },
} as TableConfig;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function PodApplicationDeserializer(input: any): PodApplication {
  return {
    id: input.podApplicationId || "",
    name: input.name || "",
    path: input.path || "",
    parameters: input.parameters || "",
    icon: DeserializeFile(input.iconFile),
    description: input.description,
    isDefault: input.isDefault || false,
  };
}

export function PodApplicationSerializer(input: PodApplication): unknown {
  return {
    name: input.name,
    path: input.path,
    parameters: input.parameters,
    iconfileid: input.icon?.id,
    description: input.description,
    // isDefault: input.isDefault,
  };
}

export function PodApplicationSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, podApplicationConfig),
    filterBy: {
      values: FilterBySerializer(podApplicationConfig, searchString),
    },
  };
}
