








































import Vue, { VueConstructor } from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import validationMixin from "@/components/mixins/validationMixin";
import { LoggerMixin } from "@/components/mixins/errorMixin";
import policiesMixin from "@/components/mixins/policiesMixin";
import { FieldValues } from "@/models/policy";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "HomeLauncherConfiguration",
  components: { VaSwitch },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
      instantMeetingDefaultDurationOptions: [
        {
          value: 15,
          text: "15 " + this.$t("common.min"),
        },
        {
          value: 30,
          text: "30 " + this.$t("common.min"),
        },
        {
          value: 60,
          text: "60 " + this.$t("common.min"),
        },
      ],
    };
  },
});
