var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-template",
    [
      _c(
        "va-card",
        { staticClass: "pa-6" },
        [
          _c("div", { staticClass: "va-title mb-2" }, [
            _vm._v(_vm._s(_vm.$t("forgotPassword.title")))
          ]),
          _vm._v(" " + _vm._s(_vm.$t("forgotPassword.content")) + " "),
          _c(
            "v-form",
            {
              ref: "forgotPasswordForm",
              attrs: { "lazy-validation": "", onSubmit: "return false;" },
              on: { submit: _vm.onSubmit },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("va-text-field", {
                staticClass: "mt-6",
                attrs: {
                  rules: [_vm.required, _vm.validMail],
                  label: _vm.$t("common.email"),
                  disabled: _vm.success
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _c(
                "div",
                { staticClass: "text-right mb-3 mt-1" },
                [
                  _c("router-link", { attrs: { to: "/auth/signin" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("signUp.back")) + " ")
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex mt-5" },
                [
                  _c("va-button", {
                    staticClass: "flex-grow-1",
                    attrs: {
                      submit: "",
                      disabled: !_vm.valid || _vm.success,
                      loading: _vm.loading,
                      cta: _vm.$t("common.submit")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.success
            ? _c("va-notification", { attrs: { type: "success" } }, [
                _vm._v(" " + _vm._s(_vm.$t("forgotPassword.success")) + " ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }