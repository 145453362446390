var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "confirm-btn",
      attrs: {
        depressed: "",
        color: _vm.color,
        outlined: !_vm.confirm,
        disabled: _vm.processing,
        loading: _vm.processing
      },
      on: { click: _vm.onClick }
    },
    [_vm._v(" " + _vm._s(_vm.confirm ? _vm.confirmLabel : _vm.label) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }