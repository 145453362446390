var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("va-icon", {
        staticClass: "cursor-pointer",
        attrs: { icon: "vi vi-c-info" },
        on: {
          click: function($event) {
            _vm.infoDialog = true
          }
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            width: "700",
            "content-class": "fullscreen card",
            scrollable: ""
          },
          model: {
            value: _vm.infoDialog,
            callback: function($$v) {
              _vm.infoDialog = $$v
            },
            expression: "infoDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("h2", [_vm._v(_vm._s(_vm.$t("info.infoTitle")))])
              ]),
              _c("v-card-text", [
                _c("div", { staticClass: "text-l font-bold mb-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("info.consoleVersion")) +
                      ": " +
                      _vm._s(_vm.version) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "text-l font-bold mb-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("info.isOnPrem")) +
                      ": " +
                      _vm._s(_vm.isOnPrem) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "text-l font-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("info.envId")) +
                      ": " +
                      _vm._s(_vm.envId) +
                      " "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          _vm.infoDialog = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.dismiss")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }