import { Group } from "@/models/group";

export interface Coordinates {
  lat?: number;
  lng?: number;
}

export interface Marker {
  id: string;
  name: string;
  address: string;
  refObject: Group | undefined;
  position: Coordinates;
  isLocationVerified: boolean;
}

export interface Address {
  formattedAddress: string;
  coordinates: Coordinates;
  isLocationVerified: boolean;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export const AddressDeserializer = (input: any): Address => {
  return {
    formattedAddress: input?.formattedAddress || "",
    coordinates: {
      lat: input?.lat,
      lng: input?.lng,
    },
    isLocationVerified: input?.isLocationVerified || false,
  };
};

export const AddressSerializer = (input: Address): unknown => {
  return {
    formattedAddress: input.formattedAddress,
    lat: input.coordinates?.lat,
    lng: input.coordinates?.lng,
    isLocationVerified: input.isLocationVerified,
  };
};
