import { render, staticRenderFns } from "./Analytics.vue?vue&type=template&id=cccc197c&"
import script from "./Analytics.vue?vue&type=script&lang=ts&"
export * from "./Analytics.vue?vue&type=script&lang=ts&"
import style0 from "./Analytics.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardText,VCardTitle,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cccc197c')) {
      api.createRecord('cccc197c', component.options)
    } else {
      api.reload('cccc197c', component.options)
    }
    module.hot.accept("./Analytics.vue?vue&type=template&id=cccc197c&", function () {
      api.rerender('cccc197c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/analytics/Analytics.vue"
export default component.exports