































import Vue, { PropType } from "vue";
import { Tag } from "@/models/tag";
import { Group } from "@/models/group";
import { RoomDetail } from "@/models/room";
import { targetType } from "@/models/scheduler";

export default Vue.extend({
  name: "CommandsApplyBy",
  components: {},
  props: {
    value: {
      type: Object as PropType<{ type: targetType; targets: string[] }>,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      mode: "TAG" as targetType,
      selectedItems: [],
    };
  },
  async beforeMount() {
    if (!this.$store.getters["tag/tags"].length)
      this.$store.dispatch("tag/fetchList");
    if (!this.$store.getters["group/groups"].length)
      this.$store.dispatch("group/fetchList");
    if (!this.$store.getters["room/rooms"].length)
      this.$store.dispatch("room/getRooms");
  },
  methods: {
    onchangeMode(mode: targetType) {
      this.mode = mode;
      this.selectedItems = [];
    },
  },
  computed: {
    currentItems(): Tag[] | Group[] | RoomDetail[] {
      switch (this.mode) {
        case "GROUP":
          return this.$store.getters["group/groups"];
        case "ROOM":
          return this.$store.getters["room/rooms"];
        default:
          return this.$store.getters["tag/tags"];
      }
    },
    currentLabel(): string {
      switch (this.mode) {
        case "GROUP":
          return this.$i18n.t("common.selectGroup").toString();
        case "ROOM":
          return this.$i18n.t("common.selectRooms").toString();
        default:
          return this.$i18n.t("common.selectTags").toString();
      }
    },
  },
  watch: {
    value: function (newVal) {
      this.selectedItems = newVal.targets;
      this.mode = newVal.type;
    },
    selectedItems: function (newVal) {
      this.$emit("input", { type: this.mode, targets: newVal });
    },
    mode: function (newVal) {
      this.$emit("input", { type: newVal, targets: this.selectedItems });
    },
  },
});
