import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=702a2dee&"
import script from "./Auth.vue?vue&type=script&lang=ts&"
export * from "./Auth.vue?vue&type=script&lang=ts&"
import style0 from "./Auth.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('702a2dee')) {
      api.createRecord('702a2dee', component.options)
    } else {
      api.reload('702a2dee', component.options)
    }
    module.hot.accept("./Auth.vue?vue&type=template&id=702a2dee&", function () {
      api.rerender('702a2dee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/templates/Auth.vue"
export default component.exports