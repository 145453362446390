import i18n from "@/i18n";

export enum VideoConferenceProvider {
  Webex = 1,
  Zoom,
  Teams,
  GoogleMeet,
  GoToMeeting,
  BlueJeans,
  Lifesize,
  LogMeIn,
  Skype,
  CollaborateSpace,
}

export interface WebExAuthData {
  userAuthenticationCode: string;
  userAccessToken: string;
  userAccessTokenExpiration: number;
  userRefreshToken: string;
}

export interface TeamsAuthData {
  refresh_token: string;
}

export interface GoogleMeetAuthData {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  id_token: string;
  first_issued_at: number;
  expires_at: number;
  calendarId: string;
  calendarName: string;
}

export interface VideoConferenceAuthenticationRequest {
  videoConferenceType: VideoConferenceProvider;
  enabled: boolean;
  videoConferenceToken?: WebExAuthData | TeamsAuthData | GoogleMeetAuthData;
}

export interface VideoConferenceSystem {
  id: string;
  provider: VideoConferenceProvider;
  value: string; // used in AV policy as select option
  name: string;
  icon: string;
  enabled: boolean;
}

export const VIDEO_CONFERENCE_SYSTEMS: Array<VideoConferenceSystem> = [
  {
    id: "teams",
    provider: VideoConferenceProvider.Teams,
    value: "Teams",
    name: i18n.t("common.microsoftTeams") as string,
    icon: require("@/assets/images/av/microsoft-teams.png"),
    enabled: true,
  },
  {
    id: "zoom",
    provider: VideoConferenceProvider.Zoom,
    value: "Zoom",
    name: i18n.t("common.zoom") as string,
    icon: require("@/assets/images/av/zoom.png"),
    enabled: true,
  },
  {
    id: "google-meet",
    provider: VideoConferenceProvider.GoogleMeet,
    value: "GoogleMeet",
    name: i18n.t("common.googleMeet") as string,
    icon: require("@/assets/images/av/google-meet.png"),
    enabled: true,
  },
  {
    id: "webex",
    provider: VideoConferenceProvider.Webex,
    value: "CiscoWebEx",
    name: i18n.t("common.ciscoWebEx") as string,
    icon: require("@/assets/images/av/cisco-webex.png"),
    enabled: true,
  },
  {
    id: "GoToMeeting",
    provider: VideoConferenceProvider.GoToMeeting,
    value: "GoToMeeting",
    name: i18n.t("common.goToMeeting") as string,
    icon: require("@/assets/images/av/goto-meeting.jpeg"),
    enabled: false,
  },
  {
    id: "BlueJeans",
    provider: VideoConferenceProvider.BlueJeans,
    value: "BlueJeans",
    name: i18n.t("common.blueJeans") as string,
    icon: require("@/assets/images/av/blue-jeans.png"),
    enabled: false,
  },
  {
    id: "Lifesize",
    provider: VideoConferenceProvider.Lifesize,
    value: "Lifesize",
    name: i18n.t("common.lifeSize") as string,
    icon: require("@/assets/images/av/lifesize.jpeg"),
    enabled: false,
  },
  {
    id: "JoinMe",
    provider: VideoConferenceProvider.LogMeIn,
    value: "JoinMe",
    name: i18n.t("common.logMeIn") as string,
    icon: require("@/assets/images/av/join-me.png"),
    enabled: false,
  },
  {
    id: "ClearOneCollabSpace",
    provider: VideoConferenceProvider.CollaborateSpace,
    value: "ClearOneCollabSpace",
    name: i18n.t("common.clearOneCollabSpace") as string,
    icon: require("@/assets/images/av/collaborate-space.png"),
    enabled: false,
  },
];
