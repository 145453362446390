var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Kpi")]),
      _c("h3", [_vm._v("simple & trend")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-kpi", { attrs: { value: "10%", title: "Test kpi 1" } }),
          _c("va-kpi", {
            attrs: { value: "10", title: "Test 2", type: "simple" }
          }),
          _c("va-kpi", {
            attrs: { value: "10", "trend-value": "5", title: "Health" }
          }),
          _c("va-kpi", {
            attrs: { value: "80", "trend-value": "15", title: "Health" }
          }),
          _c("va-kpi", {
            attrs: { value: "80%", "trend-value": "-15%", title: "Health" }
          }),
          _c("va-kpi", {
            attrs: {
              value: "80%",
              "trend-value": "+15%",
              title: "Very very long long  title title"
            }
          })
        ],
        1
      ),
      _c("h3", [_vm._v("colours")]),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-kpi", {
            attrs: {
              value: "1%",
              "trend-value": "5",
              title: "Test kpi 1",
              color: "primary",
              "trend-color": "error"
            }
          }),
          _c("va-kpi", {
            attrs: {
              value: "10",
              "trend-value": "5",
              title: "Test 2",
              color: "error",
              "text-color": "white"
            }
          }),
          _c("va-kpi", {
            attrs: {
              value: "120%",
              "trend-value": "5",
              title: "Test 2",
              color: "success",
              "text-color": "white"
            }
          }),
          _c("va-kpi", {
            attrs: {
              value: "10",
              "trend-value": "5",
              title: "Test 2",
              color: "warning",
              "text-color": "error"
            }
          }),
          _c("va-kpi", {
            attrs: {
              value: "10",
              "trend-value": "5",
              title: "Test 2",
              color: "secondary "
            }
          })
        ],
        1
      ),
      _c("h3", [_vm._v("sidebar")]),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-kpi", {
            attrs: {
              sidebar: true,
              value: "80%",
              "trend-value": "15",
              title: "Trend"
            }
          }),
          _c("va-kpi", {
            attrs: {
              sidebar: true,
              value: "80",
              "trend-value": "15",
              title: "Trend with Very very long long  title title"
            }
          }),
          _c("va-kpi", {
            attrs: {
              sidebar: true,
              value: "19000%",
              "trend-value": "15",
              title: "Trend",
              trend: "negative"
            }
          }),
          _c("va-kpi", {
            attrs: {
              sidebar: true,
              value: "NaN",
              "trend-value": "15",
              title: "Trend",
              trend: "neutral"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-kpi", {
            attrs: {
              sidebar: true,
              value: "8",
              "trend-value": "15",
              title: "Trend",
              color: "primary"
            }
          }),
          _c("va-kpi", {
            attrs: {
              sidebar: true,
              value: "8",
              "trend-value": "15",
              title: "Trend",
              color: "secondary"
            }
          }),
          _c("va-kpi", {
            attrs: {
              sidebar: true,
              value: "8",
              "trend-value": "15",
              title: "Trend",
              color: "error"
            }
          }),
          _c("va-kpi", {
            attrs: {
              sidebar: true,
              value: "8",
              "trend-value": "15",
              title: "Trend",
              color: "warining"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }