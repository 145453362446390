var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_vm._v(" right column content")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-tabs",
        { staticClass: "va-card pa-0 mb-6" },
        [
          _c("v-tab", { attrs: { to: "/account" } }, [_vm._v("Info")]),
          _c("v-tab", { attrs: { to: "/account/users" } }, [
            _vm._v("Console Users")
          ]),
          _c("v-tab", { attrs: { to: "/account/external-managers" } }, [
            _vm._v("External managers")
          ]),
          _c("v-tab", { attrs: { to: "/account/managed-accounts" } }, [
            _vm._v("Managed companies")
          ])
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }