var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.display")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c("va-switch", {
            attrs: { text: _vm.$t("policiesFields.PassiveMonitorUsage") },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.PassiveMonitorUsage,
              callback: function($$v) {
                _vm.$set(_vm.fields, "PassiveMonitorUsage", $$v)
              },
              expression: "fields.PassiveMonitorUsage"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }