

























import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import RoomsListTable from "@/components/organisms/rooms/RoomList.vue";

export default Vue.extend({
  name: "RoomsList",
  components: {
    RoomsListTable,
    MainColumns,
    Notifications,
  },
  async beforeMount() {
    await this.$store.dispatch("group/fetchAll");
  },
});
