







































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { Building, buildingConfig } from "@/models/building";
import { v4 as uuidv4 } from "uuid";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import RoomsMap from "@/components/organisms/RoomsMap.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import dataTableMixin from "@/components/mixins/dataTableMixin";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";

export default Vue.extend({
  name: "buildings",
  mixins: [dataTableMixin],
  components: {
    VaDetailsButton,
    VaTableTop,
    MainColumns,
    VaNoDataTag,
    RoomsMap,
  },
  beforeMount() {
    this.$store.dispatch("building/getBuildings");
  },
  computed: {},
  data() {
    return {
      storeName: "building",
      tableConfig: buildingConfig,
      building: {} as Building,
    };
  },
  methods: {
    setCurrentBuilding(item: Building) {
      this.$store.commit("room/setCurrentBuilding", item);
    },
    onCreate(): void {
      this.$router.push(`/buildings/${uuidv4()}/edit`);
    },
    async onEdit(building: Building): Promise<void> {
      this.setCurrentBuilding(building);
      this.$router.push(`/buildings/${building.uuid}`);
    },
  },
});
