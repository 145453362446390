


















































































































































import Vue from "vue";
import {
  CalendarParameter,
  RoomCommandTypes,
  RoomDetail,
  RoomVideoConferenceSystem,
} from "@/models/room";
import RoomService from "@/services/roomService";
import {
  VIDEO_CONFERENCE_SYSTEMS,
  VideoConferenceAuthenticationRequest,
  VideoConferenceProvider,
  VideoConferenceSystem,
} from "@/models/videoConference";
import cloneDeep from "lodash/cloneDeep";
import { Calendar, CalendarProvider, CALENDARS } from "@/models/calendar";
import VersioningUtil from "@/utils/versionUtil";
import providersUtil from "@/utils/providersUtil";
import VaCard from "@/components/molecules/VaCard.vue";
// import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  name: "RoomMeetingSettings",
  components: { VaCard },
  data() {
    return {
      calendarsDialog: false,
      calendar: undefined as { id: string; name: string } | undefined,
      isCalendarSubmit: false,
      calendarsList: [] as { id: string; name: string }[],
      calendars: cloneDeep(CALENDARS),
      systems: cloneDeep(VIDEO_CONFERENCE_SYSTEMS),
    };
  },
  methods: {
    isCalendarConnected(calendar: Calendar): boolean {
      return this.room?.calendar?.provider === calendar.providerId;
    },
    async disconnectCalendar(calendar: Calendar): Promise<void> {
      try {
        await RoomService.sendCommand(RoomCommandTypes.CALENDAR, {
          calendaryType: calendar.providerId,
          calendarToken: null,
          enabled: false,
        } as CalendarParameter);
        this.$notification.success(
          this.$t("roomMeetingSettings.calendarDisconnect")
        );
        this.reset();
      } catch (e) {
        this.$notification.error(
          this.$t("roomMeetingSettings.calendarDisconnectError")
        );
        this.$sentry.capture(e, "RoomMeetingSettings", "removeCalendar");
      }
    },
    async connectCalendar(calendar: Calendar): Promise<void> {
      /* GOOGLE */
      if (calendar.providerId === CalendarProvider.Google) {
        /* Google meets already set */
        if (this.isSystemConnected(VideoConferenceProvider.GoogleMeet)) {
          await RoomService.sendCommand(RoomCommandTypes.CALENDAR, {
            calendaryType: CalendarProvider.Google,
            enabled: true,
          } as CalendarParameter);
          this.$notification.success(
            this.$t("roomMeetingSettings.calendarConnect")
          );
        } else {
          providersUtil.connect(
            "google",
            this.connectGoogleWorkspace,
            this.room.id
          );
        }
      }

      if (calendar.providerId === CalendarProvider.Microsoft) {
        if (this.isSystemConnected(VideoConferenceProvider.Teams)) {
          await RoomService.sendCommand(RoomCommandTypes.CALENDAR, {
            calendaryType: CalendarProvider.Microsoft,
            enabled: true,
          } as CalendarParameter);
          this.$notification.success(
            this.$t("roomMeetingSettings.calendarConnect")
          );
        } else {
          providersUtil.connect(
            "microsoft",
            this.connectMicrosoftOutlook,
            this.room.id
          );
        }
      }
    },
    isSystemConnected(provider: VideoConferenceProvider): boolean {
      const res = this.room.videoConferenceSystems.find(
        (roomSystem: RoomVideoConferenceSystem) => {
          return provider === roomSystem.provider;
        }
      );
      return !!res?.accountEnable;
    },
    async disconnectSystem(system: VideoConferenceSystem): Promise<void> {
      try {
        await RoomService.sendCommand(RoomCommandTypes.CONFERENCE_SYSTEM, {
          videoConferenceType: system.provider,
          enabled: false,
        } as VideoConferenceAuthenticationRequest);
        this.$notification.success(
          this.$t("roomMeetingSettings.avConferenceDisconnect")
        );
      } catch (e) {
        this.$notification.error(
          this.$t("roomMeetingSettings.avConferenceDisconnectError")
        );
      }
    },
    async connectSystem(system: VideoConferenceSystem): Promise<void> {
      try {
        /* GOOGLE MEETS */
        if (system.provider === VideoConferenceProvider.GoogleMeet) {
          if (this.room.calendar?.provider === CalendarProvider.Google) {
            this.sendConferenceSystemCommand({
              videoConferenceType: VideoConferenceProvider.GoogleMeet,
              enabled: true,
            } as VideoConferenceAuthenticationRequest);
          } else {
            providersUtil.connect(
              "google",
              this.connectGoogleMeet,
              this.room.id
            );
          }
          return;
        }

        /* TEAMS */
        if (system.provider === VideoConferenceProvider.Teams) {
          if (this.room.calendar?.provider === CalendarProvider.Microsoft) {
            await RoomService.sendCommand(RoomCommandTypes.CONFERENCE_SYSTEM, {
              videoConferenceType: VideoConferenceProvider.Teams,
              enabled: true,
            });
            this.$notification.success(
              this.$t("roomMeetingSettings.avConferenceConnect")
            );
          } else {
            providersUtil.connect(
              "microsoft",
              this.connectMicrosoftTeams,
              this.room.id
            );
          }
          return;
        }

        /* ZOOM */
        if (system.provider === VideoConferenceProvider.Zoom) {
          providersUtil.connect("zoom", this.connectZoom, this.room.id);
        }
        /* ZOOM & WEBEX */
        if (system.provider === VideoConferenceProvider.Webex) {
          providersUtil.connect("webex", this.connectWebex, this.room.id);
        }
      } catch (e) {
        this.$notification.error(
          this.$t("roomMeetingSettings.avConferenceConnectError")
        );
      }
    },
    sendConferenceSystemCommand(
      data: VideoConferenceAuthenticationRequest
    ): void {
      if (data) {
        try {
          RoomService.sendCommand(RoomCommandTypes.CONFERENCE_SYSTEM, data);
          this.$notification.success(
            this.$t("roomMeetingSettings.avConferenceConnect")
          );
        } catch (e) {
          this.$notification.error(
            this.$t("roomMeetingSettings.avConferenceConnectError")
          );
        }
      } else {
        this.$notification.error(
          this.$t("roomMeetingSettings.avConferenceConnectError")
        );
      }
    },
    async connectMicrosoftOutlook(): Promise<void> {
      await RoomService.sendCommand(RoomCommandTypes.CALENDAR, {
        calendaryType: CalendarProvider.Microsoft,
        enabled: true,
      } as CalendarParameter);
      this.$notification.success(
        this.$t("roomMeetingSettings.calendarConnect")
      );
    },
    async connectGoogleWorkspace(): Promise<void> {
      try {
        this.calendarsList = await RoomService.calendars(this.room.id);
        this.isCalendarSubmit = true;
        this.calendarsDialog = true;
      } catch (e) {
        this.$notification.error(e);
      }
    },
    async connectGoogleMeet(): Promise<void> {
      try {
        this.calendarsList = await RoomService.calendars(this.room.id);
        this.isCalendarSubmit = false;
        this.calendarsDialog = true;
      } catch (e) {
        this.$notification.error(e);
      }
    },
    async connectMicrosoftTeams(): Promise<void> {
      await RoomService.sendCommand(RoomCommandTypes.CONFERENCE_SYSTEM, {
        videoConferenceType: VideoConferenceProvider.Teams,
        enabled: true,
      });
      this.$notification.success(
        this.$t("roomMeetingSettings.avConferenceConnect")
      );
    },
    async connectZoom(): Promise<void> {
      await RoomService.sendCommand(RoomCommandTypes.CONFERENCE_SYSTEM, {
        videoConferenceType: VideoConferenceProvider.Zoom,
        enabled: true,
      });
      this.$notification.success(
        this.$t("roomMeetingSettings.avConferenceConnect")
      );
    },
    async connectWebex(): Promise<void> {
      await RoomService.sendCommand(RoomCommandTypes.CONFERENCE_SYSTEM, {
        videoConferenceType: VideoConferenceProvider.Webex,
        enabled: true,
      });
      this.$notification.success(
        this.$t("roomMeetingSettings.avConferenceConnect")
      );
    },

    async submitCalendar(): Promise<void> {
      let errorMessage = this.isCalendarSubmit
        ? this.$t("roomMeetingSettings.calendarConnectError")
        : this.$t("roomMeetingSettings.avConferenceConnectError");
      try {
        if (this.calendar) {
          const calendarToken = {
            calendarId: this.calendar.id,
            calendarName: this.calendar.name,
          };
          if (this.isCalendarSubmit) {
            await RoomService.sendCommand(RoomCommandTypes.CALENDAR, {
              calendaryType: CalendarProvider.Google,
              calendarToken: calendarToken,
              enabled: true,
            } as CalendarParameter);
            this.$notification.success(
              this.$t("roomMeetingSettings.calendarConnect")
            );
          } else {
            this.sendConferenceSystemCommand({
              videoConferenceType: VideoConferenceProvider.GoogleMeet,
              enabled: true,
              videoConferenceToken: calendarToken,
            } as VideoConferenceAuthenticationRequest);
          }
        }
      } catch (e) {
        this.$notification.error(errorMessage);
        this.$sentry.capture(e, "RoomMeetingSettings", "submitCalendar");
      } finally {
        this.reset();
      }
    },
    async reset(abort = false): Promise<void> {
      this.calendarsDialog = false;
      this.calendar = undefined;
      this.isCalendarSubmit = false;
      if (abort) {
        //this.$notification.warning("Action aborted");
      }
    },
    accountConnected(system: VideoConferenceSystem): string {
      if (!this.room.videoConferenceSystems) return "";
      const sys = this.room.videoConferenceSystems.find(
        (roomSystem: RoomVideoConferenceSystem) => {
          return system.provider === roomSystem.provider;
        }
      );
      return sys ? sys.userName : "";
    },
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
    enabledSystems(): Array<VideoConferenceSystem> {
      return this.systems.filter((system: VideoConferenceSystem) => {
        return system.enabled;
      });
    },
    showAvConferenceSystems(): boolean {
      return VersioningUtil.greaterThan(this.room.version, "3.6.0");
    },
    isProd(): boolean {
      return process.env.NODE_ENV === "production";
    },
  },
  beforeDestroy() {
    providersUtil.reset();
  },
});
