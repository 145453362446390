<script>
import { HorizontalBar } from "vue-chartjs";
import Vue from "vue";

export default Vue.extend({
  extends: HorizontalBar,
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              maxBarThickness: 50,
            },
          ],
        },
      },
    };
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
});
</script>

<style lang="scss" scoped></style>
