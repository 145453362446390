var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          outlined: "",
          "item-disabled": _vm.disabledCommands,
          items: _vm.$store.getters["task/availableCommands"],
          "return-object": "",
          "item-text": function(c) {
            return _vm.$i18n.t("commands." + c.code)
          },
          "item-value": "id",
          label: _vm.$t("common.selectCommand")
        },
        model: {
          value: _vm.currentCommand,
          callback: function($$v) {
            _vm.currentCommand = $$v
          },
          expression: "currentCommand"
        }
      }),
      _vm.currentCommand
        ? _c(
            "div",
            { staticClass: "mt-3 d-flex align-center" },
            [
              _vm.currentCommand.parameterType === "boolean"
                ? _c("va-switch", {
                    attrs: {
                      text: !_vm.currentCommand.parameter
                        ? _vm.$t("common.active")
                        : _vm.$t("common.inactive")
                    },
                    model: {
                      value: _vm.currentCommand.parameter,
                      callback: function($$v) {
                        _vm.$set(_vm.currentCommand, "parameter", $$v)
                      },
                      expression: "currentCommand.parameter"
                    }
                  })
                : _vm._e(),
              _vm.currentCommand.parameterType === "number"
                ? _c("v-slider", {
                    staticClass: "mr-6",
                    attrs: {
                      min: "0",
                      max: "100",
                      "thumb-label": "always",
                      "thumb-size": 26,
                      "prepend-icon": "mdi-volume-high",
                      color: "primary",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.currentCommand.parameter,
                      callback: function($$v) {
                        _vm.$set(_vm.currentCommand, "parameter", $$v)
                      },
                      expression: "currentCommand.parameter"
                    }
                  })
                : _vm._e(),
              _vm.currentCommand.parameterType === "file"
                ? [
                    _c("file-uploader", {
                      ref: "scriptUploader",
                      staticClass: "mr-8",
                      attrs: {
                        files: [],
                        title: _vm.$t("common.script"),
                        accept: [".ps1"],
                        "max-size": 1048576,
                        "file-type": 4
                      },
                      on: {
                        upload: function(file) {
                          return (_vm.scriptFile = file)
                        }
                      }
                    }),
                    _vm.scriptFile
                      ? _c("va-switch", {
                          attrs: { text: _vm.$t("common.runAsSystem") },
                          model: {
                            value: _vm.runAsSystem,
                            callback: function($$v) {
                              _vm.runAsSystem = $$v
                            },
                            expression: "runAsSystem"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e(),
              _c("va-button", {
                staticClass: "ml-auto mr-6",
                attrs: { cta: _vm.$t("common.cancel"), color: "secondary" },
                on: { click: _vm.clearCurrentCommand }
              }),
              _c("va-button", {
                attrs: {
                  disabled: !_vm.canAddCommand,
                  cta: _vm.$t("common.addCommand")
                },
                on: { click: _vm.addCommand }
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }