var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Card")]),
      _c("h3", [_vm._v("test")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-card", [_vm._v("ciao")]),
          _c("va-card", [_vm._v("cane")]),
          _c("va-card", [_vm._v("gatto")]),
          _c("va-card", [
            _vm._v(
              ' "Lorem ipsum dolor sit amet, consectetur adipiscing elit, " '
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }