import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { OptionsTable, QueryResult, QueryTable } from "@/models/table";
import {
  License,
  LicenseDetail,
  LicenseData,
  LicenseSearchSerializer,
  LicenseDeserializer,
  LicenseDetailUserSearchSerializer,
  LicenseDetailRoomSearchSerializer,
} from "@/models/license";

export default {
  async search(
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = LicenseSearchSerializer(options, searchString);
    const res: AxiosResponse = await axios.post(
      apiService.license.searchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },
  async get(): Promise<Array<LicenseDetail>> {
    const res = await axios.get(apiService.license.baseUrl());
    return res.data;
  },
  async getLicense(id: string): Promise<License> {
    const url = apiService.license.detailUrl().replace(":id", id);
    const res: any = await axios.get(url);
    return LicenseDeserializer(res.data);
  },
  // async detail(id: string): Promise<LicenseDetail> {
  //   const url = apiService.license.detailUrl().replace(":id", id);
  //   const res: any = await axios.get(url);
  //   return LicenseService.deserialize(res.data);
  // },
  async detailSearch(id: string, data: QueryTable): Promise<QueryResult> {
    const url = apiService.license.detailSearchUrl().replace(":id", id);
    const res: AxiosResponse = await axios.post(url, data);
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },
  async detailUserSearch(
    id: string,
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = LicenseDetailUserSearchSerializer(options, searchString);
    const url = apiService.license.detailSearchUrl().replace(":id", id);
    const res: AxiosResponse = await axios.post(url, data);
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },

  async detailRoomSearch(
    id: string,
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = LicenseDetailRoomSearchSerializer(options, searchString);
    const url = apiService.license.detailSearchUrl().replace(":id", id);
    const res: AxiosResponse = await axios.post(url, data);
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },
  post(data: LicenseData): Promise<AxiosResponse> {
    return axios.post(apiService.license.baseUrl(), data);
  },
  clearSeats(id: string): Promise<void> {
    return axios.delete(`${apiService.license.baseDEVUrl()}/seats/${id}`);
  },
  clearExtensions(id: string): Promise<void> {
    return axios.delete(`${apiService.license.baseDEVUrl()}/extensions/${id}`);
  },
  deleteLicense(id: string): Promise<void> {
    return axios.delete(`${apiService.license.devBaseUrl()}/${id}`);
  },
};
