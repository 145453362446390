










































import Vue from "vue";
import AuthTemplate from "@/components/templates/Auth.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";
import VaNotification from "@/components/molecules/VaNotification.vue";

export default Vue.extend({
  name: "ForgotPassword",
  components: { AuthTemplate, VaCard, VaButton, VaTextField, VaNotification },
  mixins: [errorMixin, validationMixin],
  data() {
    return {
      email: "",
      completed: false,
    };
  },
  computed: {
    success(): boolean {
      return this.$store.getters["session/signupSuccess"];
    },
    loading(): boolean {
      return this.$store.getters["session/isLoading"];
    },
  },
  mounted() {
    this.$store.commit("session/setSignupSuccess", false);
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (
        (
          this.$refs.forgotPasswordForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        await this.$store.dispatch("session/forgotPassword", this.email);
      }
    },
  },
});
