import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import logger from "@/services/loggerService";
import onPremService from "@/services/onPremService";
import File from "@/models/file";

export interface OnPremState {
  isOnPrem: boolean;
  envId: string;
  loading: boolean;
}

type OnPremContext = ActionContext<OnPremState, RootState>;

export const namespaced = true;

export const state = (): OnPremState => ({
  isOnPrem: false,
  envId: "",
  loading: false,
});

export const getters: GetterTree<OnPremState, RootState> = {
  envId: (state): string => {
    return state.envId;
  },
  isOnPrem: (state): boolean => {
    return state.isOnPrem;
  },
  loading: (state): boolean => {
    return state.loading;
  },
};
export const mutations: MutationTree<OnPremState> = {
  setIsOnPrem(state: OnPremState, data: boolean) {
    state.isOnPrem = data;
  },
  setEnvId(state: OnPremState, data: string) {
    state.envId = data;
  },
  setLoading(state: OnPremState, loading: boolean) {
    state.loading = loading;
  },
};
export const actions: ActionTree<OnPremState, RootState> = {
  async getConfiguration(context: OnPremContext): Promise<void> {
    if (context.state.envId == "") {
      context.commit("setLoading", true);
      try {
        const res = await onPremService.getConfiguration();
        if (res) {
          context.commit("setIsOnPrem", res.isOnprem);
          context.commit("setEnvId", res.id);
        }
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
      }
    }
  },
  async uploadLicense(context: OnPremContext, file: File): Promise<void> {
    context.commit("setLoading", true);
    try {
      await onPremService.uploadLicense(file);
      context.dispatch("license/fetchList", undefined, { root: true });
    } catch (e) {
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: e,
          type: "error",
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
};
