














import Vue from "vue";

export default Vue.extend({
  name: "ConfirmButton",
  props: {
    label: {
      required: true,
      type: String,
    },
    confirmLabel: {
      required: true,
      type: String,
    },
    color: {
      required: true,
      type: String,
    },
    processing: {
      required: true,
      type: Boolean,
    },
    callback: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      confirm: false,
      timeout: 0,
    };
  },
  methods: {
    onClick(): void {
      if (this.confirm) {
        this.callback();
      } else {
        this.confirm = true;
        this.timeout = setTimeout(() => {
          this.confirm = false;
        }, 3000);
      }
    },
  },
  watch: {
    processing: function (n, o) {
      if (!n && o) {
        this.confirm = false;
        clearTimeout(this.timeout);
      }
    },
  },
});
