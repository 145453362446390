import axios, { AxiosResponse } from "axios";
import apiService from "@/services/apiService";
import { OptionsTable, QueryResult } from "@/models/table";
import {
  Task,
  TaskSearchSerializer,
  TaskSerializer,
  TaskDeserializer,
} from "@/models/scheduler";

export default {
  async search(
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = TaskSearchSerializer(options, searchString);
    const res: AxiosResponse = await axios.post(
      apiService.scheduler.searchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },

  async post(task: Task): Promise<string> {
    const data = TaskSerializer(task);
    const res = await axios.post(apiService.scheduler.baseUrl(), data);
    return res.data;
  },
  async put(task: Task): Promise<AxiosResponse> {
    //TODO CHECK ME!
    const data = TaskSerializer(task);
    return await axios.put(
      apiService.scheduler.baseUrl() + "/" + task.id,
      data
    );
  },
  async get(id: string): Promise<Task> {
    const res = await axios.get(apiService.scheduler.baseUrl() + "/" + id);
    return TaskDeserializer(res.data);
  },
  delete(id: string): Promise<void> {
    return axios.delete(apiService.scheduler.baseUrl() + "/" + id);
  },
};
