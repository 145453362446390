var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "buildingForm",
          attrs: { "lazy-validation": "", onSubmit: "return false;" },
          on: { submit: _vm.onSave },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("va-text-field", {
                    staticClass: "mb-4",
                    attrs: {
                      rules: _vm.requiredRules,
                      label: _vm.$t("buildingDetail.nameLabel"),
                      name: "buildingName"
                    },
                    model: {
                      value: _vm.currentBuilding.name,
                      callback: function($$v) {
                        _vm.$set(_vm.currentBuilding, "name", $$v)
                      },
                      expression: "currentBuilding.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("va-text-field", {
                    staticClass: "mb-4",
                    attrs: {
                      rules: _vm.requiredRules,
                      label: _vm.$t("buildingDetail.streetLabel"),
                      name: "buildingStreet"
                    },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.currentBuilding.street,
                      callback: function($$v) {
                        _vm.$set(_vm.currentBuilding, "street", $$v)
                      },
                      expression: "currentBuilding.street"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
                [
                  _c("va-text-field", {
                    attrs: {
                      label: _vm.$t("buildingDetail.latLabel"),
                      name: "buildingLat",
                      value: _vm.currentBuilding.lat,
                      readonly: true
                    },
                    model: {
                      value: _vm.currentBuilding.lat,
                      callback: function($$v) {
                        _vm.$set(_vm.currentBuilding, "lat", $$v)
                      },
                      expression: "currentBuilding.lat"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", sm: "6" } },
                [
                  _c("va-text-field", {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("buildingDetail.lngLabel"),
                      name: "buildingLng",
                      value: _vm.currentBuilding.lng,
                      readonly: true
                    },
                    model: {
                      value: _vm.currentBuilding.lng,
                      callback: function($$v) {
                        _vm.$set(_vm.currentBuilding, "lng", $$v)
                      },
                      expression: "currentBuilding.lng"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass: "input-new-floor",
                    attrs: {
                      name: "building-floor-input",
                      label: _vm.$t("buildingDetail.addFloorLabel"),
                      outlined: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c("va-button", {
                              attrs: {
                                disabled:
                                  !_vm.newFloor ||
                                  (_vm.floors &&
                                    _vm.floors.includes(_vm.newFloor)),
                                icon: "vi vi-d-add",
                                color: "secondary darken-1",
                                type: "square",
                                submit: true
                              },
                              on: { click: _vm.onAddFloor }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.newFloor,
                      callback: function($$v) {
                        _vm.newFloor = $$v
                      },
                      expression: "newFloor"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    _vm._l(_vm.floors, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "\n              chip-with-button\n              d-flex\n              mr-4\n              pa-2\n              rounded\n              border-a\n              va-bg-light\n            "
                        },
                        [
                          _vm._v(" " + _vm._s(item) + " "),
                          _c("va-icon", {
                            staticClass: "ml-2 cursor-pointer",
                            attrs: {
                              size: "xs",
                              icon: "vi vi-i-remove-1",
                              color: "error"
                            },
                            on: {
                              click: function($event) {
                                return _vm.removeFloor(item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row-reverse" },
            [
              _c("va-button", {
                attrs: {
                  disabled: !_vm.valid,
                  submit: "",
                  loading: _vm.loading,
                  cta: _vm.$t("common.save")
                }
              }),
              _c("va-button", {
                staticClass: "mr-4",
                attrs: { cta: _vm.$t("common.delete"), color: "error" },
                on: { click: _vm.onDelete }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }