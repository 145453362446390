var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-6" },
    [
      _c(
        "div",
        { staticClass: "display-2 my-4 d-flex justify-space-between" },
        [_c("div", {}, [_vm._v("Showcase")]), _c("light-switcher")],
        1
      ),
      _c(
        "v-tabs",
        [
          _c("v-tab", [_vm._v("chats")]),
          _c("v-tab", [_vm._v("atoms")]),
          _c("v-tab", [_vm._v("molecules")]),
          _c("v-tab", [_vm._v("organisms")]),
          _c("v-tab", [_vm._v("templates")]),
          _c("v-tab-item", { staticClass: "pt-6" }, [_c("charts")], 1),
          _c(
            "v-tab-item",
            { staticClass: "pt-6" },
            [
              _c("typography"),
              _c("va_switch"),
              _c("chips"),
              _c("icons"),
              _c("avatar"),
              _c("badge"),
              _c("buttons"),
              _c("text-field")
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "pt-6" },
            [
              _c("Notifications"),
              _c("kpi"),
              _c("cards"),
              _c("snackbar"),
              _c("state-badge")
            ],
            1
          ),
          _c("v-tab-item", { staticClass: "pt-6" }, [_c("rooms-map")], 1),
          _c(
            "v-tab-item",
            { staticClass: "pt-6" },
            [
              _c("router-link", { attrs: { to: "/design/main" } }, [
                _vm._v("main view three columns")
              ]),
              _c("br"),
              _c("router-link", { attrs: { to: "/design/auth" } }, [
                _vm._v("auth template")
              ]),
              _c("br")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }