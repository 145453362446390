

































import Vue from "vue";
// import VaChip from "@/components/atoms/VaChip.vue";
import VaBadge from "@/components/atoms/VaBadge.vue";

export default Vue.extend({
  components: {
    VaBadge,
  },
});
