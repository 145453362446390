var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-data-table", {
        staticClass: "border-a pa-6 row-pointer rounded",
        attrs: {
          headers: _vm.generateHeaders(_vm.tableConfig),
          items: _vm.$store.getters["license/licenses"],
          options: _vm.groupOptions,
          "server-items-length": _vm.$store.getters["license/totalItems"],
          "item-key": "key",
          loading: _vm.$store.getters["license/isLoading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["license/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.$router.push("/licenses/" + item.key)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: {
                    title: _vm.$t("licensesList.title"),
                    cta: _vm.$t("common.license")
                  },
                  on: {
                    ctaClick: function($event) {
                      _vm.dialog = true
                    }
                  },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.key",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "py-1" }, [
                  _c("strong", { staticClass: "d-block" }, [
                    _vm._v(_vm._s(item.key))
                  ]),
                  _c("span", { staticClass: "d-block text-caption" }, [
                    _vm._v(_vm._s(item.name))
                  ]),
                  _c(
                    "span",
                    { staticClass: "d-block text-caption secondary--text" },
                    [_vm._v(" " + _vm._s(item.sku) + " ")]
                  )
                ])
              ]
            }
          },
          {
            key: "item.type",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(item.type === "pod" ? "Room" : "Enterprise") +
                    " "
                )
              ]
            }
          },
          {
            key: "item.activations",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(item.activations.current) +
                    " / " +
                    _vm._s(item.activations.max) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.expirationDate",
            fn: function(ref) {
              var item = ref.item
              return [
                item.licenseIsPerpetual
                  ? _c("va-badge", {
                      attrs: {
                        size: "s",
                        text: _vm.$t("licensesList.perpetual"),
                        color: "success"
                      }
                    })
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateTime2string")(item.expirationDate))
                      )
                    ])
              ]
            }
          },
          {
            key: "item.maintenanceExpirationDate",
            fn: function(ref) {
              var item = ref.item
              return [
                item.maintenanceIsPerpetual
                  ? _c("va-badge", {
                      attrs: {
                        size: "s",
                        text: _vm.$t("licensesList.perpetual"),
                        color: "success"
                      }
                    })
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateTime2string")(
                            item.maintenanceExpirationDate
                          )
                        )
                      )
                    ])
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("va-badge", {
                  attrs: {
                    size: "s",
                    text: item.status,
                    color: _vm.statusColor(item)
                  }
                })
              ]
            }
          },
          {
            key: "item.actions",
            fn: function() {
              return [_c("va-details-button")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "card", width: "700", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.isOnPrem
            ? _c(
                "va-card",
                [
                  _c("div", { staticClass: "va-title" }, [
                    _vm._v(_vm._s(_vm.$t("licensesList.newTitle")))
                  ]),
                  _c("v-file-input", {
                    ref: "licenseUploader",
                    attrs: {
                      accept: ".lic",
                      label: "License file",
                      variant: "outlined"
                    },
                    model: {
                      value: _vm.fileLicense,
                      callback: function($$v) {
                        _vm.fileLicense = $$v
                      },
                      expression: "fileLicense"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c("va-button", {
                        staticClass: "mr-4",
                        attrs: {
                          color: "secondary",
                          type: "outline",
                          loading: _vm.$store.getters["onprem/loading"],
                          cta: _vm.$t("common.dismiss")
                        },
                        on: {
                          click: function($event) {
                            _vm.dialog = false
                          }
                        }
                      }),
                      _c("va-button", {
                        attrs: {
                          loading: _vm.$store.getters["onprem/loading"],
                          cta: _vm.$t("common.submit")
                        },
                        on: { click: _vm.onUpload }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "va-card",
                [
                  _c("div", { staticClass: "va-title" }, [
                    _vm._v(_vm._s(_vm.$t("licensesList.newTitle")))
                  ]),
                  _c(
                    "v-form",
                    {
                      ref: "newLicenseForm",
                      attrs: {
                        "lazy-validation": "",
                        onSubmit: "return false;"
                      },
                      on: { submit: _vm.onSave },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("va-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("common.key")
                        },
                        model: {
                          value: _vm.newLicense.licenseKey,
                          callback: function($$v) {
                            _vm.$set(_vm.newLicense, "licenseKey", $$v)
                          },
                          expression: "newLicense.licenseKey"
                        }
                      }),
                      _c("va-switch", {
                        attrs: { text: _vm.$t("licensesList.renew") },
                        model: {
                          value: _vm.newLicense.isRenewal,
                          callback: function($$v) {
                            _vm.$set(_vm.newLicense, "isRenewal", $$v)
                          },
                          expression: "newLicense.isRenewal"
                        }
                      }),
                      _vm.newLicense.isRenewal
                        ? _c("va-text-field", {
                            staticClass: "mb-4",
                            attrs: {
                              rules: [_vm.required],
                              label: _vm.$t("licensesList.oldKey")
                            },
                            model: {
                              value: _vm.newLicense.licenseKeyToRenew,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newLicense,
                                  "licenseKeyToRenew",
                                  $$v
                                )
                              },
                              expression: "newLicense.licenseKeyToRenew"
                            }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        [
                          _c("va-button", {
                            staticClass: "mr-4",
                            attrs: {
                              color: "secondary",
                              type: "outline",
                              loading: _vm.$store.getters["license/isLoading"],
                              cta: _vm.$t("common.dismiss")
                            },
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          }),
                          _c("va-button", {
                            attrs: {
                              submit: "",
                              disabled: !_vm.valid,
                              loading: _vm.$store.getters["license/isLoading"],
                              cta: _vm.$t("common.submit")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }