var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("div", { staticClass: "va-subtitle-s" }, [
                _vm._v(_vm._s(_vm.$t("common.details")))
              ]),
              _c(
                "va-info-row",
                { attrs: { label: _vm.$t("buildingDetail.name") } },
                [_vm._v(" " + _vm._s(_vm.building.name) + " ")]
              ),
              _c(
                "va-info-row",
                { attrs: { label: _vm.$t("buildingDetail.address") } },
                [
                  _c("div", { staticClass: "d-flex align-center" }, [
                    _c("span", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.building.street))
                    ])
                  ])
                ]
              ),
              _c(
                "va-info-row",
                { attrs: { label: _vm.$t("buildingDetail.lat") } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("span", { staticClass: "mr-1" }, [
                        _vm._v(_vm._s(_vm.building.lat))
                      ]),
                      _c("va-icon", {
                        attrs: {
                          size: "s",
                          icon: "fas fa-map-marker-alt",
                          color: "primary"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "va-info-row",
                { attrs: { label: _vm.$t("buildingDetail.lng") } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("span", { staticClass: "mr-1" }, [
                        _vm._v(_vm._s(_vm.building.lng))
                      ]),
                      _c("va-icon", {
                        attrs: {
                          size: "s",
                          icon: "fas fa-map-marker-alt",
                          color: "primary"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "va-info-row",
                { attrs: { label: _vm.$t("buildingDetail.floors") } },
                [
                  _vm.building.floors.length > 0
                    ? _c(
                        "div",
                        { staticClass: "text-right" },
                        _vm._l(_vm.building.floors, function(floor, index) {
                          return _c("va-chip", {
                            key: index,
                            staticClass: "ml-1 mb-1",
                            attrs: { text: floor, size: "s" }
                          })
                        }),
                        1
                      )
                    : _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("common.noFloorsApplied")) + " "
                        )
                      ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-4xl font-bold mb-8 d-flex align-self-center"
                },
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(_vm._s(_vm.$t("common.rooms")))
                  ]),
                  _c("v-spacer"),
                  _c("va-button", {
                    attrs: { size: "s", cta: _vm.$t("roomDetail.addRoom") },
                    on: {
                      click: function($event) {
                        _vm.dialog = true
                      }
                    }
                  })
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "row-pointer",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.rooms,
                  "item-key": "ID",
                  "footer-props": _vm.footerProps,
                  "mobile-breakpoint": "0"
                },
                on: {
                  "click:row": function(item) {
                    return _vm.onEdit(item)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "no-data",
                    fn: function() {
                      return [_c("va-no-data-tag")]
                    },
                    proxy: true
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-row" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "va-button square square-error",
                                attrs: { color: "error", outlined: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.removeRoomFromList(item)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("vi vi-i-remove-1")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "card", scrollable: "", width: "700" },
          on: {
            "click:outside": function($event) {
              _vm.dialog = false
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "pt-8" },
                [
                  _c("rooms-autocomplete", {
                    staticClass: "mb-1",
                    attrs: { icon: "vi vi-d-add" },
                    on: { roomSelected: _vm.onSelectRoom }
                  }),
                  _c("v-virtual-scroll", {
                    attrs: {
                      items: _vm.selectedRooms,
                      "item-height": "73",
                      height: _vm.scrollerHeight
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "pb-4 border-b d-flex align-center"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex-grow-1 cursor-pointer" },
                                  [
                                    _c("va-badge", {
                                      attrs: {
                                        "icon-class": "vi vi-new-construction",
                                        text: "" + item.name
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("va-button", {
                                  attrs: {
                                    color: "error",
                                    type: "square",
                                    icon: "vi vi-i-remove-1"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onRemoveRoom(item)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.selectedRooms.length > 0
                    ? _c("v-select", {
                        attrs: {
                          items: _vm.currentBuilding.floors,
                          outlined: "",
                          label: _vm.$t("buildingDetail.chooseFloorLabel")
                        },
                        model: {
                          value: _vm.selectedFloor,
                          callback: function($$v) {
                            _vm.selectedFloor = $$v
                          },
                          expression: "selectedFloor"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "mb-4 mx-2" },
                [
                  _c("v-checkbox", {
                    staticClass: "checkbox-toggle",
                    attrs: {
                      dense: "",
                      label: _vm.$t("buildingDetail.closeAddRoomDialog"),
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.addRoomChecked,
                      callback: function($$v) {
                        _vm.addRoomChecked = $$v
                      },
                      expression: "addRoomChecked"
                    }
                  }),
                  _c("v-spacer"),
                  _c("va-button", {
                    staticClass: "mr-2",
                    attrs: {
                      color: "secondary",
                      type: "outline",
                      cta: _vm.$t("common.dismiss")
                    },
                    on: { click: _vm.onDismissDialog }
                  }),
                  _c("va-button", {
                    attrs: {
                      color: "accent",
                      disabled: _vm.isDisabled(),
                      outlined: "",
                      cta: _vm.$t("common.save")
                    },
                    on: {
                      click: function($event) {
                        return _vm.onAddRoom()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }