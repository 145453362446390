






















































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import VaBack from "@/components/molecules/VaBack.vue";
import validationMixin from "@/components/mixins/validationMixin";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import CommandsSelector from "@/components/organisms/commands/CommandsSelector.vue";
import CommandsList from "@/components/organisms/commands/CommandsTable.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import CommandsApplyBy from "@/components/organisms/commands/CommandsApplyBy.vue";
import CommandsSchedule from "@/components/organisms/commands/CommandsSchedule.vue";
import { targetType, Task } from "@/models/scheduler";

type targets = { type: targetType; targets: string[] };

export default Vue.extend({
  name: "CreateTaskForm",
  mixins: [validationMixin],
  components: {
    CommandsSchedule,
    CommandsApplyBy,
    VaCard,
    CommandsList,
    CommandsSelector,
    VaButton,
    VaTextField,
    VaBack,
    MainColumns,
    Notifications,
  },
  data() {
    return {};
  },
  methods: {
    onSave(): void {
      if (this.task.id) {
        this.$store.dispatch("task/updateTask", this.task);
      } else {
        this.$store.dispatch("task/addTask", this.task);
      }
    },
  },
  beforeMount() {
    if (this.$route.params.id)
      this.$store.dispatch("task/fetchDetail", this.$route.params.id);
    else this.$store.commit("task/resetCurrentTask");
  },
  computed: {
    taskValid(): boolean {
      return (
        this.$data.valid &&
        this.task.commands.length &&
        this.task.targets.length
      );
    },
    task(): Task {
      return this.$store.getters["task/currentTask"];
    },
    targets: {
      get(): targets {
        return { type: this.task.targetType, targets: this.task.targets };
      },
      set(targets: targets): void {
        this.task.targetType = targets.type;
        this.task.targets = targets.targets;
      },
    },
  },
  watch: {},
});
