


















































import Vue from "vue";
import { RoomDetail } from "@/models/room";
import RoomService from "@/services/roomService";
import VaCard from "@/components/molecules/VaCard.vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import VaNotification from "@/components/molecules/VaNotification.vue";
import tenantService from "@/services/tenantService";

export default Vue.extend({
  name: "RoomUpdate",
  components: { VaNotification, VaSwitch, VaCard },
  data() {
    return {
      sending: false,
      updateLaunched: false,
      // allowBeta: false,
      allowing: false,
      showFastchannel: tenantService.getTenantCode() !== "SMR-TWK-CONSOLE",
    };
  },
  mounted() {
    // this.allowBeta = this.room.betaEnabled;
    const el = document.getElementById("roomupdate");
    if (el && this.$route.hash && this.$route.hash === "#roomupdate") {
      this.$nextTick(() => {
        el.scrollIntoView({ behavior: "smooth" });
      });
    }
  },
  methods: {
    async update(): Promise<void> {
      this.sending = true;
      try {
        await RoomService.sendCommand(9);
        this.$notification.success(this.$t("roomUpdate.success"));
        this.updateLaunched = true;
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "RoomUpdate", "update");
      } finally {
        this.sending = false;
      }
    },
    async onAllowBeta(allow: boolean): Promise<void> {
      this.allowing = true;
      try {
        const room: RoomDetail = await RoomService.patch(this.room.id, {
          isBetaEnabled: allow,
        });
        this.$store.commit("room/setRoom", room);
        this.$notification.success("Room updated with success!");
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "RoomUpdate", "onAllowBeta");
      } finally {
        this.allowing = false;
      }
    },
  },
  computed: {
    canUpdate(): boolean {
      return this.room.hasUpdate;
    },
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
});
