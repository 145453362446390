import i18n from "@/i18n";
import { ChartData } from "@/models/analytics";

export enum PerformanceStatus {
  GOOD,
  AVERAGE,
  POOR,
  NA,
}

export interface PerformanceStatusData {
  id: PerformanceStatus;
  label: string;
  color: string;
}

export const PERFORMANCE_STATUS_DATA: Array<PerformanceStatusData> = [
  {
    id: PerformanceStatus.GOOD,
    label: i18n.t("common.good"),
    color: "success",
  } as PerformanceStatusData,
  {
    id: PerformanceStatus.AVERAGE,
    label: i18n.t("common.warning"),
    color: "warning",
  } as PerformanceStatusData,
  {
    id: PerformanceStatus.POOR,
    label: i18n.t("common.critical"),
    color: "error",
  } as PerformanceStatusData,
  {
    id: PerformanceStatus.NA,
    label: i18n.t("common.n/a"),
    color: "black",
  } as PerformanceStatusData,
];

export enum PerformanceStat {
  CPU,
  MEMORY,
  DISK,
  GPU,
}

export interface PerformanceStatData {
  id: PerformanceStat;
  name: string;
  label: string;
  status: PerformanceStatus;
}

export const PERFORMANCE_STATS_DATA: Array<PerformanceStatData> = [
  {
    id: PerformanceStat.CPU,
    name: "cpu",
    label: i18n.t("common.cpu"),
  } as PerformanceStatData,
  {
    id: PerformanceStat.MEMORY,
    name: "memory",
    label: i18n.t("common.memory"),
  } as PerformanceStatData,
  {
    id: PerformanceStat.DISK,
    name: "disk",
    label: i18n.t("common.disk"),
  } as PerformanceStatData,
  {
    id: PerformanceStat.GPU,
    name: "gpu",
    label: i18n.t("common.gpu"),
  } as PerformanceStatData,
] as Array<PerformanceStatData>;

export enum PerformanceRange {
  OneH = 1,
  SixH,
  TwelveH,
  Daily,
  Weekly,
}

export interface PerformanceSample {
  rate: string;
  count: number;
}

export interface PerformanceRangeData {
  label: string;
  value: PerformanceRange;
  sampling: PerformanceSample;
}

export const PERFORMANCE_RANGE_DATA: Array<PerformanceRangeData> = [
  {
    label: i18n.t("common.last1h") as string,
    value: PerformanceRange.OneH,
    sampling: {
      rate: "1m",
      count: 60,
    },
  },
  {
    label: i18n.t("common.last6h") as string,
    value: PerformanceRange.SixH,
    sampling: {
      rate: "2m",
      count: 180,
    },
  },
  {
    label: i18n.t("common.last12h") as string,
    value: PerformanceRange.TwelveH,
    sampling: {
      rate: "5m",
      count: 144,
    },
  },
  {
    label: i18n.t("common.last24h") as string,
    value: PerformanceRange.Daily,
    sampling: {
      rate: "10m",
      count: 144,
    },
  },
  {
    label: i18n.t("common.last7d") as string,
    value: PerformanceRange.Weekly,
    sampling: {
      rate: "60m",
      count: 168,
    },
  },
];

export interface PerformanceChartRawData {
  date: number;
  values: Array<number>;
  processes: Array<Array<string>>;
}

export interface PerformanceChartFormattedData extends ChartData {
  processes: Array<string>;
}
