import { render, staticRenderFns } from "./CapacityPerformance.vue?vue&type=template&id=7b517dd2&scoped=true&"
import script from "./CapacityPerformance.vue?vue&type=script&lang=ts&"
export * from "./CapacityPerformance.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b517dd2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDivider,VIcon,VList,VListItem,VListItemTitle,VMenu,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b517dd2')) {
      api.createRecord('7b517dd2', component.options)
    } else {
      api.reload('7b517dd2', component.options)
    }
    module.hot.accept("./CapacityPerformance.vue?vue&type=template&id=7b517dd2&scoped=true&", function () {
      api.rerender('7b517dd2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/analytics/CapacityPerformance.vue"
export default component.exports