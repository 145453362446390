










































































































import Vue from "vue";
import VaKpi from "@/components/molecules/VaKpi.vue";

export default Vue.extend({
  name: "KPI",
  components: {
    VaKpi,
  },
});
