import get from "lodash/get";
import set from "lodash/set";

const STORAGE_ID = "valarea-management-console";

export default {
  initPreferences(): void {
    localStorage.setItem(
      STORAGE_ID,
      JSON.stringify({
        createdAt: Date.now(),
        updatedAt: Date.now(),
      })
    );
  },
  getPreferences(): Record<string, any> {
    const storage = localStorage.getItem(STORAGE_ID);
    if (!storage) {
      this.initPreferences();
    }
    return JSON.parse(localStorage.getItem(STORAGE_ID) as string);
  },
  getPreference(key: string): any {
    const preferences = this.getPreferences();
    return get(preferences, key);
  },
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  setPreference(key: string, data: any): void {
    const preferences = this.getPreferences();
    preferences.updatedAt = Date.now();
    set(preferences, key, data);
    localStorage.setItem(STORAGE_ID, JSON.stringify(preferences));
  },
  clearPreferences(): void {
    localStorage.removeItem(STORAGE_ID);
  },
};
