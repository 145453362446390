





























import Vue from "vue";
import { RoomDetail, ROOM_COMMAND_STATUS_DATA } from "@/models/room";
import VaCard from "@/components/molecules/VaCard.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";

export default Vue.extend({
  name: "RoomLastCommands",
  components: { VaNoDataTag, VaCard },
  beforeMount() {
    this.commandsData = ROOM_COMMAND_STATUS_DATA;
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("common.name"),
          value: "commandName",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$t("common.scheduled"),
          value: "isScheduled",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$t("common.startDate"),
          value: "startDate",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$t("common.status"),
          value: "status",
          sortable: false,
          filterable: false,
        },
      ],
      error: "",
      commandsData: [] as Array<{ label: string; icon: string }>,
    };
  },
  methods: {},
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
});
