import { Doughnut, Bar, HorizontalBar, Line } from "vue-chartjs";
import Vue, { PropType } from "vue";
import Chart from "chart.js";

const reactiveChartMixin = Vue.extend({
  props: {
    chartData: {
      type: Object as PropType<Chart.ChartData>,
      required: true,
    },
    options: {
      type: Object as PropType<Chart.ChartOptions>,
      required: false,
    },
  },
  mounted() {
    this.render(this.chartData, this.options);
  },
  watch: {
    chartData: function (newVal: Chart.ChartData) {
      this.render(newVal, this.options);
    },
    options: function (newVal) {
      this.render(this.chartData, newVal);
    },
  },
  methods: {
    render(data: Chart.ChartData, options: Chart.ChartOptions) {
      // eslint-disable-next-line
      // @ts-ignore
      this.renderChart(data, options); //method from BaseChart
    },
  },
});

export const VaDoughnut = Vue.extend({
  extends: Doughnut,
  mixins: [reactiveChartMixin],
});

export const VaBar = {
  extends: Bar,
  mixins: [reactiveChartMixin],
};

export const VaHorizontalBar = {
  extends: HorizontalBar,
  mixins: [reactiveChartMixin],
};

export const VaLine = {
  extends: Line,
  mixins: [reactiveChartMixin],
};
