










































































import Vue from "vue";
import { RoomDetail } from "@/models/room";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";
// import VaSwitch from "@/components/atoms/VaSwitch.vue";
import roomService from "@/services/roomService";
// import { BuildData } from "@/models/builds";
import clone from "lodash/clone";

export default Vue.extend({
  name: "RoomSuperadmin",
  components: { VaTextField, VaButton, VaCard },
  mixins: [errorMixin, validationMixin],
  data() {
    return {
      // password: "",
      buildUrl: "",
      // list: [] as BuildData[],
      // internal: true,
      loading: false,
    };
  },
  methods: {
    // async onSubmit(): Promise<void> {
    //   try {
    //     this.loading = true;
    //     this.list = [];
    //     this.list = await roomService.searchBuilds(
    //       this.password,
    //       this.internal,
    //       this.room.id
    //     );
    //   } catch (e) {
    //     this.$store.commit("notifications/displayNotification", {
    //       message: e,
    //       type: "error",
    //     });
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    async onInstall(): Promise<void> {
      try {
        this.loading = true;
        await roomService.installBuild(this.buildUrl, this.room.id);
        this.$store.commit("notifications/displayNotification", {
          message: this.$t("roomSuperadmin.buildWillBeInstalled"),
          type: "success",
        });
        // this.list = [];
        // this.password = "";
      } catch (e) {
        console.log(e.response?.data.message);
        this.$store.commit("notifications/displayNotification", {
          message: e.response?.data.message ? e.response?.data.message : e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    room(): RoomDetail {
      return clone(this.$store.getters["room/room"]);
    },
  },
});
