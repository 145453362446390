var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "va-card",
    [
      _c("v-data-table", {
        staticClass: "row-pointer",
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          options: _vm.options,
          "server-items-length": _vm.totalItems,
          "item-key": "ID",
          loading: _vm.loading,
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.footerProps,
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          },
          "click:row": function(item) {
            return _vm.onEdit(item)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "va-table-top",
                  {
                    attrs: {
                      title: _vm.$t("common.accounts"),
                      cta: _vm.$t("common.user")
                    },
                    on: { ctaClick: _vm.onCreate },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("usersList.description")) + " ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.firstName",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(item.firstName) +
                    " " +
                    _vm._s(item.lastName) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.actions",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row" },
                  [
                    _c("va-button", {
                      attrs: {
                        icon: "vi vi-c-info",
                        "icon-position": "append",
                        size: "s",
                        cta: _vm.$t("common.details")
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "card", width: "700" },
          on: { "click:outside": _vm.reset },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("validation-observer", {
            ref: "observer",
            attrs: { slim: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var invalid = ref.invalid
                  return [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("h2", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.update
                                    ? _vm.$t("usersList.newTitle")
                                    : _vm.$t("usersList.editTitle")
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c("validation-provider", {
                              attrs: { rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        !_vm.update
                                          ? _c("v-autocomplete", {
                                              attrs: {
                                                outlined: "",
                                                dense: "",
                                                disabled: _vm.update,
                                                clearable: "",
                                                label: _vm.$t("common.license"),
                                                items: _vm.licenses,
                                                "item-value": "key",
                                                "item-text": "key",
                                                "error-messages": errors,
                                                "hide-details": "auto"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "no-data",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "pa-4"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "common.noLicenseAvailable"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  },
                                                  {
                                                    key: "selection",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.key)
                                                          )
                                                        ])
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "item",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "py-1"
                                                          },
                                                          [
                                                            _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "d-block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.key
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "d-block text-caption"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "d-block text-caption secondary--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                        .activations
                                                                        .max -
                                                                        item
                                                                          .activations
                                                                          .current
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("span"),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.seatsRemaining"
                                                                      )
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.license,
                                                callback: function($$v) {
                                                  _vm.license = $$v
                                                },
                                                expression: "license"
                                              }
                                            })
                                          : _c("v-text-field", {
                                              attrs: {
                                                outlined: "",
                                                dense: "",
                                                disabled: "",
                                                label: _vm.$t("common.license"),
                                                value: _vm.license.key,
                                                messages: _vm.license.name
                                              }
                                            })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm.license
                              ? [
                                  _c("h6", { staticClass: "mt-8" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("common.userInformation")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "flex center" },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "flex-grow-1",
                                        attrs: { rules: "required|email" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "common.email"
                                                      ),
                                                      "error-messages": errors,
                                                      "hide-details": "auto",
                                                      disabled:
                                                        _vm.status !==
                                                        "unChecked"
                                                    },
                                                    model: {
                                                      value: _vm.user.email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "user.email"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _vm.status == "unChecked"
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-4",
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onCheckMail(
                                                    _vm.user.email
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "usersList.checkMail"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-4",
                                              attrs: { color: "secondary" },
                                              on: { click: _vm.resetMail }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "usersList.resetMail"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                    ],
                                    1
                                  ),
                                  _vm.status == "blocked"
                                    ? _c(
                                        "va-notification",
                                        { attrs: { type: "error" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("usersList.emailError")
                                            ) + " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.status == "new"
                                    ? _c("validation-provider", {
                                        attrs: { rules: "required" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-text-field", {
                                                    staticClass: "mt-4",
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "common.firstname"
                                                      ),
                                                      "error-messages": errors,
                                                      "hide-details": "auto"
                                                    },
                                                    model: {
                                                      value: _vm.user.firstName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.firstName"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e(),
                                  _vm.status == "new"
                                    ? _c("validation-provider", {
                                        attrs: { rules: "required" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-text-field", {
                                                    staticClass: "mt-4",
                                                    attrs: {
                                                      outlined: "",
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "common.lastname"
                                                      ),
                                                      "error-messages": errors,
                                                      "hide-details": "auto"
                                                    },
                                                    model: {
                                                      value: _vm.user.lastName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "lastName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.lastName"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e(),
                                  _vm.status == "new"
                                    ? _c("validation-provider", {
                                        attrs: {
                                          rules: _vm.update
                                            ? ""
                                            : "required|strong_password"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  !_vm.update
                                                    ? _c("v-text-field", {
                                                        staticClass: "mt-4",
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "append-icon": _vm.showPassword
                                                            ? "mdi-eye"
                                                            : "mdi-eye-off",
                                                          type: _vm.showPassword
                                                            ? "text"
                                                            : "password",
                                                          name: "password",
                                                          label: _vm.$t(
                                                            "common.password"
                                                          ),
                                                          "error-messages":
                                                            errors[0],
                                                          "hide-details": "auto"
                                                        },
                                                        on: {
                                                          "click:append": function(
                                                            $event
                                                          ) {
                                                            _vm.showPassword = !_vm.showPassword
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.user.password,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.user,
                                                              "password",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "user.password"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "v-card-actions",
                          {
                            staticClass:
                              "d-flex justify-space-between align-start"
                          },
                          [
                            _vm.update
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("confirm-button", {
                                      attrs: {
                                        color: "error",
                                        "confirm-label": _vm.$t(
                                          "common.confirmLabel"
                                        ),
                                        label: _vm.$t("common.delete"),
                                        disabled: _vm.saving || _vm.deleting,
                                        processing: _vm.deleting,
                                        callback: _vm.onDelete
                                      }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "d-block error--text font-size-10"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.dataLost")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c("span"),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { color: "secondary", outlined: "" },
                                    on: { click: _vm.reset }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.dismiss")) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "accent",
                                      depressed: "",
                                      disabled:
                                        _vm.status == "unChecked" ||
                                        _vm.status == "blocked" ||
                                        _vm.saving ||
                                        _vm.deleting ||
                                        invalid,
                                      loading: _vm.saving
                                    },
                                    on: { click: _vm.onSave }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.submit")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }