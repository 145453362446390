


























import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import debounce from "lodash/debounce";

export default Vue.extend({
  components: { VaTextField, VaButton },
  props: {
    search: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    cta: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    searchValue: "",
  }),
  created() {
    this.emitDebounced = debounce(this.emitDebounced, 1500);
  },
  methods: {
    emitDebounced(searchValue: string) {
      this.$emit("input", searchValue);
    },
  },
  mounted() {
    this.searchValue = this.value ? String(this.value) : "";
  },
  watch: {
    value: function (newVal) {
      this.searchValue = String(newVal);
    },
  },
});
