

































import Vue from "vue";

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: false,
      default: "solid",
      validator(value) {
        return [
          "solid",
          "text",
          "outline",
          "shadow",
          "icon",
          "circle",
          "square",
          "squareText",
        ].includes(value);
      },
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "m",
      validator(value) {
        return ["xl", "l", "m", "s", "xs"].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    submit: {
      type: Boolean,
      required: false,
      default: false,
    },
    reset: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    icon: {
      type: String,
      required: false,
    },
    iconPosition: {
      type: String,
      required: false,
      default: "prepend",
      validator(value) {
        return ["prepend", "append"].includes(value);
      },
    },
    cta: {
      type: String,
      required: false,
    },
    btnClass: {
      type: String,
      required: false,
      default: "primary",
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    displayCta(): boolean {
      return (
        (this.type === "squareText" && !this.icon && !!this.cta) ||
        (this.type === "square" && !this.icon && !!this.cta) ||
        (this.type === "circle" && !this.icon && !!this.cta) ||
        (this.type === "icon" && !this.icon && !!this.cta) ||
        (this.type !== "circle" &&
          this.type !== "icon" &&
          this.type !== "square" &&
          this.type !== "squareText" &&
          !!this.cta)
      );
    },
    computedBtnType(): string {
      return this.submit ? "submit" : this.reset ? "reset" : "button";
    },
    displayPrependIcon(): boolean {
      return !!this.icon && this.iconPosition === "prepend";
    },
    displayAppendIcon(): boolean {
      return !!this.icon && this.iconPosition === "append";
    },
  },
});
