var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("rooms-map")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-data-table", {
        staticClass: "border-a pa-6 row-pointer rounded",
        attrs: {
          headers: _vm.generateHeaders(_vm.tableConfig),
          items: _vm.$store.getters["building/buildings"],
          options: _vm.groupOptions,
          "server-items-length": _vm.$store.getters["building/totalItems"],
          "item-key": "ID",
          loading: _vm.$store.getters["building/isLoading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["building/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.onEdit(item)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: {
                    title: _vm.$t("common.buildings"),
                    cta: _vm.$t("common.building")
                  },
                  on: { ctaClick: _vm.onCreate },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.actions",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row" },
                  [_c("va-details-button")],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }