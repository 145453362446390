var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-3" }, [
    _c(
      "div",
      {
        class: [
          "d-flex",
          "align-center",
          "rounded",
          "pa-3",
          "shadow",
          _vm.outlined
            ? _vm.computedType.color + "-outline"
            : _vm.computedType.color + "-box"
        ]
      },
      [
        _c("va-icon", {
          staticClass: "mr-3",
          attrs: {
            icon: _vm.computedType.icon,
            color: _vm.outlined ? _vm.computedType.color : "white"
          }
        }),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }