














import Vue from "vue";
import { RoomDetail } from "@/models/room";
import PoliciesChooser from "@/components/organisms/policies/PoliciesChooser.vue";
import VaCard from "@/components/molecules/VaCard.vue";

export default Vue.extend({
  name: "RoomPolicies",
  components: { VaCard, PoliciesChooser },
  methods: {
    onApplied(): void {
      this.$store.dispatch("room/refreshRoom");
    },
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
});
