<template>
  <div class="chart-container">
    <div class="va-subtitle" v-if="title">{{ title }}</div>
    <va-line :chartData="computedData" :options="computedOptions"></va-line>
  </div>
</template>
<script>
//todo convert to TS
import { VaLine } from "@/components/organisms/charts/ChartsWrappers";
import cloneDeep from "lodash/cloneDeep";
import Vue from "vue";
import { convertHexToRGBA } from "@/utils";

export default Vue.extend({
  components: { VaLine },
  props: {
    title: {
      type: String,
      required: false,
    },
    chartData: {
      required: true,
    },
    chartOptions: {
      required: false,
      default() {
        return {};
      },
    },
    legend: {
      type: Boolean,
      required: false,
      default: false,
    },
    stacked: {
      type: Boolean,
      required: false,
      default: false,
    },
    area: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDots: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedData() {
      const data = cloneDeep(this.chartData);
      if (data.datasets) {
        data.datasets = data.datasets.map((dataset, datasetIndex) => {
          if (dataset.backgroundColor?.length > 1) {
            dataset.backgroundColor = dataset.backgroundColor[0];
          }
          if (dataset.borderColor?.length > 1) {
            dataset.borderColor = dataset.borderColor[0];
          }

          if (!dataset.borderColor) {
            if (!dataset.backgroundColor) {
              dataset.borderColor =
                this.$vuetify.theme.currentTheme[`chart${datasetIndex + 1}`];
            } else {
              dataset.borderColor = dataset.backgroundColor;
            }
          }
          if (!dataset.backgroundColor && this.area) {
            dataset.backgroundColor = convertHexToRGBA(
              this.$vuetify.theme.currentTheme[`chart${datasetIndex + 1}`],
              20
            );
          }

          dataset.borderWidth = 2;
          dataset.tension = 0;
          dataset.fill = this.area;

          if (this.hideDots) {
            dataset.pointRadius = 0;
            // dataset.pointBackgroundColor = dataset.pointBackgroundColor || "rgba(0, 0, 0, 0)";
            // dataset.pointBorderColor = dataset.pointBorderColor || "rgba(0, 0, 0, 0)";
            // dataset.pointHoverBackgroundColor = dataset.pointHoverBackgroundColor || dataset.borderColor;
          }

          return dataset;
        });
      }
      return data;
    },
    computedOptions() {
      const opt = cloneDeep(this.chartOptions);
      opt.responsive = true;
      opt.maintainAspectRatio = false;
      opt.legend = {
        display: this.legend,
        position: "bottom",
        labels: {
          usePointStyle: true,
          padding: 16,
          fontColor: this.$vuetify.theme.currentTheme.chartText,
        },
      };
      opt.tooltips = {
        enabled: true,
        backgroundColor: this.$vuetify.theme.currentTheme.navigation,
      };
      opt.scales = {
        offset: false,
        yAxes: [
          {
            stacked: this.stacked,
            ticks: {
              fontColor: this.$vuetify.theme.currentTheme.chartText,
              beginAtZero: true,
            },
            gridLines: {
              color: this.$vuetify.theme.currentTheme.chartLines,
            },
          },
        ],
        xAxes: [
          {
            stacked: this.stacked,
            ticks: {
              fontColor: this.$vuetify.theme.currentTheme.chartText,
              beginAtZero: true,
            },
            gridLines: {
              color: this.$vuetify.theme.currentTheme.chartLines,
            },
          },
        ],
      };
      return opt;
    },
  },
});
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  width: 100%;
}
</style>
