import Vue from "vue";
import logger from "@/services/loggerService";

export interface LoggerMixin extends Vue {
  handleError: (e: Error) => void;
}

export default Vue.mixin({
  methods: {
    handleError(e: Error) {
      logger.error(e);
      this.$store.commit("notifications/displayNotification", {
        message: e,
        type: "error",
      });
    },
  },
});
