





















































import Vue from "vue";
import VaLinesChart from "@/components/organisms/charts/VaLinesChart.vue";
import VaBarChart from "@/components/organisms/charts/VaBarChart.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import AnalyticsTopBar from "@/components/organisms/AnalyticsTopBar.vue";

export default Vue.extend({
  name: "AnalyticsHealth",
  components: {
    AnalyticsTopBar,
    VaCard,
    VaLinesChart,
    VaBarChart,
  },
  data() {
    return {
      overallStatus: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            label: "Ok",
            backgroundColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.success,
            ],
            data: [12, 19, 13, 15, 12, 18, 17],
          },
          {
            label: "Warning",
            backgroundColor: [
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.warning,
            ],
            data: [4, 1, 5, 4, 6, 2, 4],
          },
          {
            label: "Critical",
            backgroundColor: [
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.error,
            ],
            data: [4, 0, 2, 1, 4, 2, 1],
          },
        ],
      },
      incidents: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            backgroundColor: ["rgba(59, 128, 223,0.3)"],
            borderColor: ["rgba(59, 128, 223,0.8)"],
            pointBackgroundColor: this.$vuetify.theme.currentTheme.primary,
            pointBorderColor: this.$vuetify.theme.currentTheme.primary,
            data: [12, 19, 3, 5, 2, 3, 6],
          },
        ],
      },
      performance: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            // backgroundColor:['red', 'green', 'blue', 'purple', 'teal'],
            label: "Cpu",
            data: [10, 30, 20, 30, 80, 90, 40],
          },
          {
            // backgroundColor:['yellow', '#6CAA5C', '#E8A427','#E04A52', 'blue'],
            label: "Memory",
            data: [15, 20, 15, 35, 8, 99, 10],
          },
          {
            label: "Gpu",
            data: [25, 10, 5, 25, 18, 9, 30],
          },
          {
            label: "Disk",
            data: [4, 2, 5, 5, 8, 13, 11],
          },
        ],
      },
      updates: {
        labels: ["Obsolete", "Outdated", "Ok"],
        datasets: [
          {
            backgroundColor: [
              this.$vuetify.theme.currentTheme.error,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.success,
            ],
            data: [3, 5, 10],
          },
        ],
      },
    };
  },
});
