import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import it from "vuetify/src/locale/it";
import en from "vuetify/src/locale/en";

export const Themes = {
  lang: {
    locales: { it, en },
    current: "en",
  },
  light: {
    primary: "#3B80DF",
    navigation: "#1F293B",
    navigationActive: "#0F172A",
    navigationText: "#e2e8f0",
    accent: "#3677FF",
    secondary: {
      darken4: "#3A4047",
      darken3: "#646D79",
      darken2: "#7E8A9A",
      darken1: "#97A1AE",
      base: "#ACB4BE",
      lighten1: "#BEC4CC",
      lighten2: "#CFD3D9",
      lighten3: "#DEE1E5",
      lighten4: "#ECEEF0",
      lighten5: "#FDFDFE",
    },
    success: "#6CAA5C",
    info: "#5E8DD6",
    warning: "#E8A427",
    error: "#E04A52",
    danger: "#000000",
    background: {
      darken4: "#dddfe4",
      darken3: "#e0e3e7",
      darken2: "#e4e6ea",
      darken1: "#e7e9ec",
      base: "#ebecef",
      lighten1: "#eef0f2",
      lighten2: "#f3f4f6",
      lighten3: "#f8f8f9",
      lighten4: "#fcfcfd",
      lighten5: "#ffffff",
    },
    text: {
      darken: "#646D79",
      base: "#7E8A9A",
      lighten: "#CFD3D9",
    },
    chartText: "#000000",
    chartLines: "#DEE1E5",
    chartBorder: "#ffffff",
    chart1: "#E04A52",
    chart2: "#6CAA5C",
    chart3: "#3B80DF",
    chart4: "#E8A427",
    chart5: "#7a1ae1",
    chart6: "#5ea8d6",
    chart7: "#ced65e",
    chart8: "#5E8DD6",
    chart9: "#164d1c",
    chart10: "#b45ed6",
  },
  dark: {
    primary: "#3B80DF",
    navigation: "#0A0F19",
    navigationActive: "#1F293B",
    navigationText: "#e2e8f0",
    accent: "#3677FF",
    secondary: {
      darken4: "#3A4047",
      darken3: "#646D79",
      darken2: "#7E8A9A",
      darken1: "#97A1AE",
      base: "#ACB4BE",
      lighten1: "#BEC4CC",
      lighten2: "#CFD3D9",
      lighten3: "#DEE1E5",
      lighten4: "#ECEEF0",
      lighten5: "#FDFDFE",
    },
    success: "#2D8516",
    info: "#4179CF",
    warning: "#E49401",
    error: "#DB2A34",
    danger: "#000000",
    black: {
      darken5: "#000000",
      darken4: "#121212",
      darken3: "#1E1E1E",
      darken2: "#222222",
      darken1: "#252525",
      base: "#272727",
      lighten1: "#2C2C2C",
      lighten2: "#2E2E2E",
      lighten3: "#333333",
      lighten4: "#363636",
      lighten5: "#373737",
    },
    white: "#FFFFFF",
    background: {
      darken5: "#000000",
      darken4: "#0A0F19",
      darken3: "#121926",
      darken2: "#171F2D",
      darken1: "#1B2435",
      base: "#1F293B",
      lighten1: "#414958",
      lighten2: "#626976",
      lighten3: "#8F949D",
      lighten4: "#BCBFC4",
    },
    text: {
      darken: "#FFFFFF",
      base: "#999999",
      lighten: "#4d4d4d",
    },
    chartText: "#FFFFFF",
    chartLines: "#3A4047",
    chartBorder: "#040408",
    chart1: "#E04A52",
    chart2: "#6CAA5C",
    chart3: "#3B80DF",
    chart4: "#E49401",
    chart5: "#7a1ae1",
    chart6: "#5ea8d6",
    chart7: "#ced65e",
    chart8: "#5E8DD6",
    chart9: "#164d1c",
    chart10: "#b45ed6",
  },
};

export default new Vuetify({
  lang: {
    locales: { it, en },
    current: "it",
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: Themes,
  },
  icons: {
    iconfont: "mdi" || "fa4" || "md" || "fa",
  },
});
