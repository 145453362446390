
















import Vue from "vue";
import VaCard from "@/components/molecules/VaCard.vue";

export default Vue.extend({
  name: "Buttons",
  components: {
    VaCard,
  },
});
