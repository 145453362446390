


































import Vue from "vue";
import { RoomDetail } from "@/models/room";
import VaInfoRow from "@/components/molecules/VaInfoRow.vue";
import PerformanceUtil from "@/utils/performanceUtil";
import {
  PERFORMANCE_STATS_DATA,
  PerformanceStatData,
  PerformanceStatusData,
} from "@/models/performance";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  name: "RoomPerformance",
  components: { VaInfoRow },
  data() {
    return {
      performanceStatsData: cloneDeep(PERFORMANCE_STATS_DATA),
    };
  },
  methods: {
    performanceStatus(value: number): PerformanceStatusData {
      return PerformanceUtil.statusDataByValue(value);
    },
    detail(): void {
      this.$router.push({
        path: "/rooms/" + this.room.id,
        query: { room: this.room.name },
      });
    },
    statLabel(stat: string): string {
      return PERFORMANCE_STATS_DATA.find((statData: PerformanceStatData) => {
        return statData.name === stat;
      })?.label as string;
    },
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
    /*overallPerformanceStatusData(): PerformanceStatusData {
      return PerformanceUtil.overallStatusData(
        this.rooms.performance as RoomPerformance
      );
    },*/
  },
});
