























import Vue from "vue";
import VaNotification from "@/components/molecules/VaNotification.vue";

export default Vue.extend({
  name: "Buttons",
  components: {
    VaNotification,
  },
});
