var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "va-bg-dark d-flex", attrs: { id: "dashboard" } },
    [
      _c(
        "div",
        {
          staticClass:
            "\n      top-bar\n      shadow-md\n      border-b\n      d-flex\n      flex-row-reverse\n      align-center\n      va-bg-base\n    "
        },
        [_c("top-bar", { staticClass: "pr-6" })],
        1
      ),
      _c("navigation"),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _vm.$store.getters["app/loading"]
            ? _c("v-progress-linear", {
                attrs: { fixed: "", color: "primary", indeterminate: "" }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main-column pa-6 align-self-start" },
            [_vm._t("default")],
            2
          ),
          _c(
            "div",
            { staticClass: "right-column va-bg-base pa-6" },
            [_vm._t("rightColumn")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }