


















































































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";
import { COUNTRIES, Country } from "@/models/country";

// this.countryItems = cloneDeep(COUNTRIES);
export default Vue.extend({
  name: "CompanyForm",
  components: {
    VaButton,
    VaTextField,
  },
  mixins: [errorMixin, validationMixin],
  data() {
    return {
      countryItems: COUNTRIES,
    };
  },
  computed: {
    user() {
      return this.$store.getters["session/user"];
    },
  },
  methods: {
    updateCountry(item: Country) {
      this.user.company.country = item.code;
    },
    onSubmit() {
      if (
        (
          this.$refs.forgotPasswordForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        this.$store.dispatch("session/updateUserCompany", this.user.company);
      }
    },
  },
});
