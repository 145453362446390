















































import Vue from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";

export default Vue.extend({
  data() {
    return {
      val: true,
    };
  },
  components: {
    VaSwitch,
  },
});
