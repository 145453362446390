




















import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  components: { VaIcon },
  props: {
    label: {
      required: true,
      type: String,
    },
    tooltip: {
      required: false,
      type: String,
    },
    bold: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
});
