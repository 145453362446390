export interface MaintenanceLog {
  maintenanceId: string;
  startDate: number | null;
  startNote: string;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export const MaintenanceLogDeserializer = (input: any): MaintenanceLog => {
  return {
    maintenanceId: input?.maintenanceId || "",
    startDate: input?.startDate || null,
    startNote: input?.startNote || "",
  };
};

export const MaintenanceLogSerializer = (input: MaintenanceLog): unknown => {
  return {
    maintenanceId: input?.maintenanceId,
    startDate: input?.startDate,
    startNote: input?.startNote,
  };
};
