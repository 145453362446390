import { render, staticRenderFns } from "./Quality.vue?vue&type=template&id=1b19726b&scoped=true&"
import script from "./Quality.vue?vue&type=script&lang=ts&"
export * from "./Quality.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b19726b",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b19726b')) {
      api.createRecord('1b19726b', component.options)
    } else {
      api.reload('1b19726b', component.options)
    }
    module.hot.accept("./Quality.vue?vue&type=template&id=1b19726b&scoped=true&", function () {
      api.rerender('1b19726b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/analytics/Quality.vue"
export default component.exports