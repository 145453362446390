


























































































































import Vue from "vue";
import { RoomDetail, RoomDisplay } from "@/models/room";
import { RoomDisplayType } from "@/models/room";
import RoomService from "@/services/roomService";
import isEqual from "lodash/isEqual";
import FileUtil from "@/utils/fileUtil";
import VaInfoRow from "@/components/molecules/VaInfoRow.vue";
import { LightBox } from "@/models/lightBox";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";

interface Point2D {
  x: number;
  y: number;
}

export default Vue.extend({
  name: "RoomDisplays",
  components: { VaButton, VaCard, VaInfoRow },
  mounted() {
    this.build();
  },
  data() {
    return {
      displays: [] as Array<RoomDisplay>,
      scaleFactor: 0.12,
      displayType: RoomDisplayType,
      previewHeight: 0,
      previewWidth: 0,
      maxWidth: 0,
      init: false,
      negativeOffset: { x: 0, y: 0 } as Point2D,
      panel: [] as Array<number>,
      screenshots: [],
      processing: false,
      polling: 0,
      lightboxItems: [] as Array<string>,
    };
  },
  methods: {
    downloadScreenshot(display: RoomDisplay): void {
      FileUtil.downloadByResource(
        display.preview as string,
        display.model,
        "jpg"
      );
    },
    openScreenshot(index: number): void {
      this.$store.commit("app/openLightBox", {
        index,
        items: this.lightboxItems,
      } as LightBox);
    },
    onToggle(index: number): void {
      if (!this.panel.includes(index)) {
        this.panel.push(index);
      } else {
        this.panel.splice(this.panel.indexOf(index), 1);
      }
    },
    async takeScreenshots(): Promise<void> {
      this.processing = true;
      try {
        const commandId = await RoomService.sendCommand(18);
        this.polling = setInterval(async () => {
          const res = await RoomService.getCommand(this.room.id, commandId);
          if (res.status > 1) {
            clearInterval(this.polling);
            if (res.status === 6) {
              this.lightboxItems = [];
              res.response.forEach(
                (screenshot: { deviceName: string; fileUrl: string }) => {
                  const index = this.displays.findIndex((display) => {
                    return display.deviceName === screenshot.deviceName;
                  });
                  this.displays[index].preview = screenshot.fileUrl;
                  this.lightboxItems.push(screenshot.fileUrl);
                }
              );
              this.$notification.success(this.$t("roomDisplays.success"));
            } else if (res.status === 4) {
              this.$notification.warning(this.$t("roomDisplays.denied"));
            } else {
              this.$notification.error(this.$t("errorCode.undefined"));
            }
            this.processing = false;
          }
        }, 1500);
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "RoomDisplays", "takeScreenshots");
        this.processing = false;
      }
    },
    displayStyle(display: RoomDisplay) {
      return {
        top: (display.top + this.negativeOffset.y) * this.scaleFactor + "px",
        left: (display.left + this.negativeOffset.x) * this.scaleFactor + "px",
        height: display.height * this.scaleFactor + "px",
        width: display.width * this.scaleFactor + "px",
        backgroundImage: display.preview ? "url(" + display.preview + ")" : "",
      };
    },
    displayLabel(type: number): string {
      switch (type) {
        case this.displayType.MAIN:
          return "PRIMARY";
        case this.displayType.CONSOLE:
          return "CONSOLE";
        case this.displayType.EXTENDED:
          return "EXTENDED";
        default:
          return "";
      }
    },
    build(): void {
      if (!this.room?.displays) return;
      this.displays = this.room.displays;
      this.init = false;
      const points = [] as Array<Point2D>;

      this.displays.forEach((display: RoomDisplay) => {
        if (display.width > this.maxWidth) {
          this.maxWidth = display.width;
        }
        if (display.top < this.negativeOffset.y) {
          this.negativeOffset.y = display.top;
        }
        if (display.left < this.negativeOffset.x) {
          this.negativeOffset.x = display.left;
        }
      });

      this.scaleFactor = this.maxWidth > 3000 ? 0.08 : 0.14;

      this.negativeOffset.x = Math.abs(this.negativeOffset.x);
      this.negativeOffset.y = Math.abs(this.negativeOffset.y);

      this.displays.forEach((display: RoomDisplay) => {
        points.push({
          x: display.left + this.negativeOffset.x,
          y: display.top + this.negativeOffset.y,
        } as Point2D);
        points.push({
          x: display.left + display.width + this.negativeOffset.x,
          y: display.top + this.negativeOffset.y,
        } as Point2D);
        points.push({
          x: display.left + display.width + this.negativeOffset.x,
          y: display.top + display.height + this.negativeOffset.y,
        } as Point2D);
        points.push({
          x: display.left + this.negativeOffset.x,
          y: display.top + display.height + this.negativeOffset.y,
        } as Point2D);
      });

      let max = { x: 0, y: 0 } as Point2D;
      points.forEach((point: Point2D) => {
        if (point.x > max.x) {
          max.x = point.x;
        }
        if (point.y > max.y) {
          max.y = point.y;
        }
      });

      this.previewWidth = max.x * this.scaleFactor;
      this.previewHeight = max.y * this.scaleFactor;
      this.init = true;
    },
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
  watch: {
    room: function (current, prev) {
      if (current && !isEqual(current?.displays, prev?.displays)) {
        this.build();
      }
    },
  },
});
