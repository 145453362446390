var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { attrs: { id: "download" } }, [
        _c("div", { staticClass: "va-title" }, [
          _vm._v(_vm._s(_vm.$t("software.title")))
        ]),
        _vm.fromCMS
          ? _c(
              "div",
              _vm._l(_vm.groupedNotes, function(value, key, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "v-card",
                      { staticClass: "mb-6" },
                      [
                        _c("v-card-title", [
                          _c("div", { staticClass: "va-subtitle" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("software." + key)) + " "
                            )
                          ])
                        ]),
                        _c(
                          "v-card-text",
                          _vm._l(value, function(note, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-2xl mb-2 font-weight-bold"
                                    },
                                    [_vm._v(" " + _vm._s(note.title) + " ")]
                                  ),
                                  _c("div", {
                                    staticClass:
                                      "release-notes border-a pa-4 mb-4 rounded",
                                    domProps: {
                                      innerHTML: _vm._s(note.releaseNotes)
                                    }
                                  })
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between text-lg"
                                  },
                                  [
                                    _c("div", [
                                      _c("div", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("software.version")) +
                                            ": "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v(_vm._s(note.version))]
                                        )
                                      ]),
                                      _c("div", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("software.platform")
                                            ) +
                                            ": "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("software." + note.os)
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "align-self-end" },
                                      [
                                        note.url
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: note.url,
                                                  target: "_blank",
                                                  title:
                                                    note.product +
                                                    " for " +
                                                    note.os
                                                }
                                              },
                                              [
                                                _c("va-button", {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    icon: "vi vi-chevron-down",
                                                    size: "s",
                                                    cta: _vm.$t(
                                                      "common.download"
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                ),
                                index !== value.length - 1
                                  ? _c("v-divider", { staticClass: "my-3" })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              [
                _c(
                  "v-card",
                  {},
                  [
                    _c("v-card-text", { staticClass: "pt-6" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between mb-10" },
                        [
                          _c("div", [
                            _c("div", { staticClass: "va-subtitle mb-2" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("software.room")) + " "
                              )
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("software.downloadLatestVersion")
                                ) +
                                " "
                            )
                          ]),
                          _c("va-button", {
                            staticClass: "mb-2",
                            attrs: {
                              size: "l",
                              loading: _vm.downloading,
                              cta: _vm.$t("common.download")
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadLatestVersion(false)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between mb-10" },
                        [
                          _c("div", [
                            _c("div", { staticClass: "va-subtitle mb-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("software.fastchannel")) +
                                  " "
                              )
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("software.fastchannelDescription")
                                ) +
                                " "
                            )
                          ]),
                          _c("va-button", {
                            staticClass: "mb-2",
                            attrs: {
                              size: "l",
                              loading: _vm.downloading,
                              cta: _vm.$t("common.download")
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadLatestVersion(true)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between mb-10" },
                        [
                          _c("div", [
                            _c("div", { staticClass: "va-subtitle mb-2" }, [
                              _vm._v(" " + _vm._s(_vm.$t("software.app")) + " ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("software.valareaAppDescription")
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "a",
                              {
                                staticClass: "rounded",
                                attrs: {
                                  href:
                                    "https://download.mago.com/Valarea-Win-Client-3.8.exe",
                                  target: "_blank",
                                  title: "Valarea Windows"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "download-badge",
                                  attrs: {
                                    src: require("@/assets/images/badges/microsoft.svg"),
                                    alt: "Microsoft"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://itunes.apple.com/app/valarea/id1408732971",
                                  target: "_blank",
                                  title: "Valarea iOS"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "download-badge",
                                  attrs: {
                                    src: require("@/assets/images/badges/ios.svg"),
                                    alt: "iOS"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://play.google.com/store/apps/details?id=com.remago.workspace",
                                  target: "_blank",
                                  title: "Valarea Android"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "download-badge",
                                  attrs: {
                                    src: require("@/assets/images/badges/android.svg"),
                                    alt: "Android"
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between mb-2" },
                        [
                          _c("div", [
                            _c("div", { staticClass: "va-subtitle mb-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("software.controller")) +
                                  " "
                              )
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "software.valareaControllerDescription"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://apps.apple.com/us/app/valarea-controller-preview/id1630363629",
                                  target: "_blank",
                                  title: "Valarea iOS"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "download-badge",
                                  attrs: {
                                    src: require("@/assets/images/badges/ios.svg"),
                                    alt: "iOS"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://play.google.com/store/apps/details?id=com.remago.valarea.wtc",
                                  target: "_blank",
                                  title: "Valarea Android"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "download-badge",
                                  attrs: {
                                    src: require("@/assets/images/badges/android.svg"),
                                    alt: "Android"
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }