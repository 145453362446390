

















































import Vue from "vue";
import VaNotification from "@/components/molecules/VaNotification.vue";
// import AuthService, { SignUpData } from "@/services/authService";
import AuthTemplate from "@/components/templates/Auth.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";
import VaTextField from "@/components/atoms/VaTextField.vue";
import i18n from "@/i18n";
import logger from "@/services/loggerService";

export default Vue.extend({
  components: {
    VaButton,
    VaCard,
    AuthTemplate,
    VaTextField,
    VaNotification,
  },
  mixins: [errorMixin, validationMixin],
  name: "SignUp",
  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },
  // async beforeMount() {
  // this.onConfirm();
  // },
  computed: {},
  methods: {
    matching(value: string) {
      return value == this.password || i18n.t("validate.confirmed");
    },
    async onSubmit(): Promise<void> {
      if (
        (this.$refs.resetForm as Vue & { validate: () => boolean }).validate()
      ) {
        if (this.$route.query.mail && this.$route.query.reset) {
          await this.$store.dispatch("session/resetPassword", {
            token: this.$route.query.reset,
            password: this.password,
            email: this.$route.query.mail,
          });
        } else {
          logger.error(`Malformed reset url: ${this.$route.fullPath}`);
          this.$store.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("resetPassword.malformedUrl"),
              type: "error",
            },
            { root: true }
          );
        }
      }
    },
  },
});
