var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    ref: "picker",
    staticClass: "timezone-picker text-base",
    attrs: {
      "cache-items": "",
      outlined: "",
      attach: "",
      items: _vm.timezones,
      "item-text": "label",
      "item-value": "value",
      "return-object": "",
      "hide-details": "",
      "menu-props": {
        bottom: true,
        transition: "slide-y-transition",
        offsetY: true
      }
    },
    on: {
      focusin: function($event) {
        return _vm.onFocus(true)
      },
      focusout: function($event) {
        return _vm.onFocus(false)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "prepend-inner",
        fn: function() {
          return [
            _vm.$vuetify.breakpoint.smAndUp
              ? _c(
                  "div",
                  { staticClass: "clock d-flex font-weight-bold align-center" },
                  [
                    _c("va-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "mdi-clock-time-three-outline", size: "s" }
                    }),
                    _c("span", {}, [
                      _vm._v(_vm._s(_vm._f("formatTime")(_vm.currentTime)))
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("span", { staticClass: "timezone-label" }, [
              _vm._v(" " + _vm._s("(" + item.utc + ") " + item.label) + " ")
            ])
          ]
        }
      },
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          return [
            !_vm.hide
              ? _c(
                  "div",
                  { staticClass: "timezone-selection" },
                  [
                    _c("va-chip", {
                      staticClass: "mr-2",
                      attrs: { text: item.utc, size: "s" }
                    }),
                    _c("span", [_vm._v(_vm._s(item.label))])
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      }
    ]),
    model: {
      value: _vm.currentTimezone,
      callback: function($$v) {
        _vm.currentTimezone = $$v
      },
      expression: "currentTimezone"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }