

















































import Vue, { PropType } from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { Command } from "@/models/command";

export default Vue.extend({
  name: "CommandsList",
  components: {
    VaButton,
  },
  props: {
    value: {
      type: Array as PropType<Command[]>,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      commandsList: [] as Command[],
      headers: [
        {
          text: this.$t("common.command"),
          value: "code",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$t("common.parameter"),
          value: "parameter",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$i18n.t("common.actions"),
          value: "actions",
          sortable: false,
          filterable: false,
          width: 50,
        },
      ],
    };
  },
  methods: {
    removeCommand(commandToRemove: Command) {
      this.commandsList = this.commandsList.filter((command: Command) => {
        return command !== commandToRemove;
      });
    },
  },
  computed: {},
  mounted() {
    this.commandsList = this.value;
  },
  watch: {
    value: function (newVal) {
      this.commandsList = newVal;
    },
    commandsList: function (newVal) {
      this.$emit("input", newVal);
    },
  },
});
