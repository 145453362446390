var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("va-button", {
    attrs: {
      icon: "vi vi-microsoft",
      color: "secondary darken-2",
      cta: _vm.signup
        ? _vm.$t("mfa.signUpMicrosoft")
        : _vm.$t("mfa.signInMicrosoft")
    },
    on: { click: _vm.handleSignIn }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }