import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import LoggerService from "@/services/loggerService";
import { Building } from "@/models/building";
import cloneDeep from "lodash/cloneDeep";
import * as listable from "./listableModule";
//TODO: remove after API implementation
import { mockBuildings } from "../../tests/mocks/buildings";

const initBuilding = {
  uuid: "",
  name: "",
  rooms: [],
  street: "",
  city: "",
  province: "",
  country: "",
  lat: undefined,
  lng: undefined,
  floors: [],
} as Building;

export interface BuildingState extends listable.ListableState {
  buildings: Building[];
  currentBuilding: Building;
  isLoading: boolean;
}

type BuildingContext = ActionContext<BuildingState, RootState>;

export const namespaced = true;

export const state = (): BuildingState => ({
  ...listable.state,
  ...{
    buildings: [],
    currentBuilding: cloneDeep(initBuilding) as Building,
    isLoading: false,
  },
});

export const getters: GetterTree<BuildingState, RootState> = {
  ...listable.getters,
  ...{
    buildings: (state) => {
      return state.buildings;
    },
    currentBuilding: (state) => {
      return state.currentBuilding;
    },
    isLoading: (state): boolean => {
      return state.isLoading;
    },
  },
};

export const mutations: MutationTree<BuildingState> = {
  ...listable.mutations,
  ...{
    setLoading(state: BuildingState, loading: boolean) {
      state.isLoading = loading;
    },
    setBuildings(state: BuildingState, buildings: Building[]) {
      state.buildings = buildings;
    },
    deleteBuilding(state: BuildingState, buildingId: string) {
      state.buildings = state.buildings.filter(
        (building) => building.uuid !== buildingId
      );
    },
    setCurrentBuilding(state: BuildingState, building: Building) {
      state.currentBuilding = cloneDeep(building) as Building;
    },
    resetCurrentBuilding(state: BuildingState) {
      state.currentBuilding = cloneDeep(initBuilding) as Building;
    },
    updateBuildings(state: BuildingState, building: Building) {
      const bIndex = state.buildings.findIndex(
        (item: Building) => item.uuid === building.uuid
      );
      if (bIndex > -1) {
        state.buildings[bIndex] = building;
      } else {
        state.buildings = [...state.buildings, building];
      }
    },
    setCurrentBuildingFloors(state: BuildingState, floors: string[]) {
      state.currentBuilding.floors = floors;
    },
    setFloors(state: BuildingState, floors: string[]) {
      state.currentBuilding.floors = floors;
    },
    addFloor(state: BuildingState, floor: string) {
      state.currentBuilding.floors?.push(floor);
    },
    removeFloor(state: BuildingState, floor: string) {
      state.currentBuilding.floors = state.currentBuilding.floors?.filter(
        (el: string) => el !== floor
      );
    },
    setCurrentBuildingRooms(
      state: BuildingState,
      rooms: {
        roomId: string;
        name: string;
        floor: string;
      }[]
    ) {
      state.currentBuilding.rooms = rooms;
    },
    addRoomToBuildingRooms(
      state: BuildingState,
      room: {
        roomId: string;
        name: string;
        floor: string;
      }
    ) {
      state.currentBuilding.rooms?.push(room);
    },
  },
};

export const actions: ActionTree<BuildingState, RootState> = {
  ...listable.actions,
  ...{
    async getBuildings(context: BuildingContext) {
      try {
        //const res = await getBuildings();
        context.commit("setBuildings", mockBuildings);
        context.commit("setTotalItems", mockBuildings.length);
      } catch (e) {
        LoggerService.error(e);
      }
    },
    async deleteBuilding(context: BuildingContext, buildingId: string) {
      try {
        //const res = await deleteBuilding();
        context.commit("deleteBuilding", buildingId);
      } catch (e) {
        LoggerService.error(e);
      }
    },
  },
};
