import axios from "axios";
import apiService from "./apiService";
import File from "@/models/file";

export default {
  async getConfiguration(): Promise<
    { isOnprem: boolean; id: string } | undefined
  > {
    const res = await axios.get(apiService.onPrem.configuration());
    if (res.data) {
      return {
        isOnprem: res.data.isOnPremEnvironment,
        id: res.data.customerEnvironmentId,
      };
    }
  },
  async uploadLicense(file: File): Promise<unknown> {
    const formData = new FormData();
    formData.append("LicenseFile", file);
    return await axios.post(apiService.onPrem.import(), formData);
  },
};
