import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export interface ReleaseNotes {
  id: string;
  title: string;
  releaseNotes: string;
  changelog: string;
  product: "app" | "room" | "controller";
  os: "ios" | "android" | "windows";
  version: string;
  url?: string;
  fastChannel: boolean;
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): ReleaseNotes {
    return {
      id: input.sys.id || "",
      title: input.fields.title || "",
      releaseNotes: documentToHtmlString(input.fields.releaseNotes) || "",
      changelog: documentToHtmlString(input.fields.changelog) || "",
      product: input.fields.product || "",
      os: input.fields.os || "",
      version: input.fields.version || "",
      url: input.fields.url,
      fastChannel: input.fields.fastChannel || false,
    };
  },
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  requestSerialize(input: ReleaseNotes): any {
    return {
      sys: {
        id: input.id,
      },
      fields: {
        title: input.title,
        releaseNotes: input.releaseNotes,
        changelog: input.changelog,
        product: input.product,
        os: input.os,
        version: input.version,
        url: input.url,
        fastChannel: input.fastChannel,
      },
    };
  },
};
