








































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import VaKpi from "@/components/molecules/VaKpi.vue";
import LiveData from "@/components/organisms/LiveData.vue";
import BuildingsMap from "@/components/organisms/BuildingsMap.vue";

export default Vue.extend({
  name: "home_dashboard",
  components: { BuildingsMap, MainColumns, VaKpi, LiveData },
});
