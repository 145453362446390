







































import Vue from "vue";
import VaIcon from "@/components/atoms/VaIcon.vue";

export default Vue.extend({
  name: "Buttons",
  components: {
    VaIcon,
  },
});
