
































































import Vue, { PropType } from "vue";
import { RoomDetail } from "@/models/room";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import clone from "lodash/clone";

export default Vue.extend({
  name: "MaintenanceSwitch",
  props: {
    room: {
      type: Object as PropType<RoomDetail>,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
  },
  components: { VaTextField, VaSwitch },
  mixins: [validationMixin],
  data() {
    return {
      maintenance: false,
      formMaintenance: false,
      show: false,
      notes: "",
    };
  },
  async beforeMount() {
    this.maintenance = clone(this.room.isMaintenanceModeEnabled);
  },
  methods: {
    openManteinanceDialog() {
      this.show = true;
      this.formMaintenance = clone(this.maintenance);
    },
    dismissManteinanceDialog() {
      this.show = false;
      this.maintenance = !this.formMaintenance;
      this.$store.dispatch("room/fetchRoom", this.room.id);
      this.$store.dispatch("room/fetchList");
    },
    toggleMaintenance() {
      if (
        (
          this.$refs.maintenanceForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        this.$store.dispatch("room/toggleMaintenance", {
          room: this.room,
          note: this.notes,
          maintenance: this.formMaintenance,
        });
        this.show = false;
      }
    },
  },
  computed: {},
  watch: {
    room: function (newVal): void {
      this.maintenance = clone(newVal.isMaintenanceModeEnabled);
    },
  },
});
