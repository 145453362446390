var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.to ? "cursor-pointer" : "", on: { click: _vm.navigateTo } },
    [
      !_vm.sidebar
        ? _c(
            "div",
            {
              staticClass:
                "\n      trend-box\n      pa-4\n      shadow-md\n      rounded\n      border-a\n      d-flex\n      flex-column\n      va-bg-base\n    ",
              class: [_vm.color, _vm.textColor ? _vm.textColor + "--text" : ""]
            },
            [
              _c(
                "div",
                { staticClass: "mb-1 text-lg d-flex" },
                [
                  _vm.icon
                    ? _c("va-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: _vm.icon, color: _vm.iconColor }
                      })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-5xl align-self-center d-flex font-bold" },
                [_vm._v(" " + _vm._s(_vm.value) + " ")]
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass:
                "trend-box-side pa-4 rounded d-flex flex-column border-a",
              class: [
                _vm.color,
                _vm.textColor ? _vm.textColor + "--text" : "",
                _vm.styled ? "shadow-md va-bg-base border-a" : "va-bg-light"
              ]
            },
            [
              _c(
                "div",
                { staticClass: "text-5xl align-self-center d-flex font-bold" },
                [_vm._v(" " + _vm._s(_vm.value) + " ")]
              ),
              _c("div", { staticClass: "text-center mt-2" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }