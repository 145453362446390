










import Vue from "vue";
import RoomPerformance from "@/components/dashboard/rooms/room/RoomPerformance.vue";
import RoomInformation from "@/components/organisms/rooms/RoomInformation.vue";
import { RoomDetail } from "@/models/room";
import PerformanceLive from "@/components/dashboard/performance/Performance.vue";

export default Vue.extend({
  name: "RoomDetailInfo",
  components: {
    PerformanceLive,
    RoomPerformance,
    RoomInformation,
  },
  props: {
    roomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // error: "",
      // updateRequiredDialog: false
    };
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
});
