var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "border-a pa-6 rounded",
    attrs: {
      items: _vm.rooms,
      headers: _vm.generateHeaders(_vm.tableConfig),
      options: _vm.groupOptions,
      "server-items-length": _vm.$store.getters["room/totalItems"],
      "item-key": "id",
      loading: _vm.$store.getters["room/isLoading"],
      "footer-props": _vm.$store.getters["room/footerProps"],
      "multi-sort": "",
      "mobile-breakpoint": "0"
    },
    on: {
      "update:options": function($event) {
        _vm.groupOptions = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "top",
          fn: function() {
            return [
              _c("va-table-top", {
                attrs: { title: _vm.$t("roomsList.title") },
                model: {
                  value: _vm.searchString,
                  callback: function($$v) {
                    _vm.searchString = $$v
                  },
                  expression: "searchString"
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "no-data",
          fn: function() {
            return [_c("va-no-data-tag")]
          },
          proxy: true
        },
        {
          key: "header.overallState",
          fn: function() {
            return [
              _c("v-select", {
                attrs: {
                  dense: "",
                  "single-line": "",
                  items: _vm.overallStatesData,
                  label: _vm.$t("common.state"),
                  "item-text": "label",
                  "item-value": "id",
                  clearable: "",
                  multiple: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("va-badge", {
                          attrs: {
                            text: item.label,
                            color: item.color,
                            size: "xs"
                          }
                        })
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.filterState,
                  callback: function($$v) {
                    _vm.filterState = $$v
                  },
                  expression: "filterState"
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "header.isMaintenanceModeEnabled",
          fn: function() {
            return [
              _c("v-select", {
                attrs: {
                  dense: "",
                  "single-line": "",
                  items: _vm.maintenanceOptions,
                  label: _vm.$t("common.maintenance"),
                  "item-text": "label",
                  "item-value": "id",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("va-badge", {
                          attrs: { text: item.label, size: "xs" }
                        })
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.filterMaintenance,
                  callback: function($$v) {
                    _vm.filterMaintenance = $$v
                  },
                  expression: "filterMaintenance"
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "header.hasOngoingMeeting",
          fn: function() {
            return [
              _c("v-select", {
                attrs: {
                  dense: "",
                  "single-line": "",
                  items: _vm.onGoingOptions,
                  label: _vm.$t("common.inProgress"),
                  "item-text": "label",
                  "item-value": "id",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("va-badge", {
                          attrs: { text: item.label, size: "xs" }
                        })
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.filterOngoing,
                  callback: function($$v) {
                    _vm.filterOngoing = $$v
                  },
                  expression: "filterOngoing"
                }
              })
            ]
          },
          proxy: true
        },
        _vm.groups && !_vm.group
          ? {
              key: "header.group",
              fn: function() {
                return [
                  _c("v-select", {
                    attrs: {
                      clearable: "",
                      dense: "",
                      "single-line": "",
                      items: _vm.groups,
                      label: _vm.$t("common.group"),
                      "item-text": "name",
                      "item-value": "id",
                      "return-object": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-chip",
                                { attrs: { "x-small": "", label: "" } },
                                [_c("span", [_vm._v(_vm._s(item.name))])]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1453429408
                    ),
                    model: {
                      value: _vm.filterGroup,
                      callback: function($$v) {
                        _vm.filterGroup = $$v
                      },
                      expression: "filterGroup"
                    }
                  })
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "header.tags",
          fn: function() {
            return [
              _c("v-select", {
                staticStyle: { "max-width": "180px" },
                attrs: {
                  dense: "",
                  "single-line": "",
                  items: _vm.tags,
                  label: _vm.$t("common.tags"),
                  multiple: "",
                  "item-text": "name",
                  "item-value": "id",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        index === 0
                          ? _c(
                              "v-chip",
                              { attrs: { "x-small": "", label: "" } },
                              [_c("span", [_vm._v(_vm._s(item.name))])]
                            )
                          : _vm._e(),
                        _vm.filterTags.length > 1
                          ? _c(
                              "span",
                              { staticClass: "grey--text text-caption" },
                              [
                                _vm._v(
                                  " (+" +
                                    _vm._s(_vm.filterTags.length - 1) +
                                    ") "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.filterTags,
                  callback: function($$v) {
                    _vm.filterTags = $$v
                  },
                  expression: "filterTags"
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "item.roomName",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.gotoRoom(item.id)
                    }
                  }
                },
                [_vm._v(_vm._s(item.name))]
              )
            ]
          }
        },
        {
          key: "item.overallState",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("va-state-badge", {
                attrs: { size: "s", type: _vm.getStatus(item) }
              })
            ]
          }
        },
        {
          key: "item.isMaintenanceModeEnabled",
          fn: function(ref) {
            var item = ref.item
            return [_c("maintenance-switch", { attrs: { room: item } })]
          }
        },
        {
          key: "item.hasOngoingMeeting",
          fn: function(ref) {
            var item = ref.item
            return [
              item.hasOngoingMeeting
                ? _c("va-badge", {
                    attrs: {
                      text: _vm.$t("common.inProgress"),
                      color: "primary",
                      size: "s",
                      "icon-class": "vi vi-users-meeting"
                    }
                  })
                : _c("va-badge", {
                    attrs: {
                      text: _vm.$t("common.available"),
                      color: "secondary",
                      size: "s",
                      "icon-class": "vi vi-c-check"
                    }
                  })
            ]
          }
        },
        {
          key: "item.group",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("va-chip", {
                attrs: { text: item.group.name, size: "s", color: "secondary" }
              })
            ]
          }
        },
        {
          key: "item.tags",
          fn: function(ref) {
            var item = ref.item
            return _vm._l(item.tags, function(tag, index) {
              return _c("va-chip", {
                key: index,
                staticClass: "my-1 mr-1",
                attrs: { text: tag.name, size: "s", color: "secondary" }
              })
            })
          }
        },
        {
          key: "item.actions",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.gotoRoom(item.id)
                    }
                  }
                },
                [_c("va-details-button")],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }