// import { mockCommand } from "../../tests/mocks/command";

export const CommandsDecoder = [
  //TODO TEMPORARY REMOVE AFTER API REFACTOR
  {
    id: 1,
    code: "MACHINE_NAME",
    parameterType: "string",
    schedulable: false,
  },
  {
    id: 2,
    code: "VOLUME",
    parameterType: "boolean",
    schedulable: true,
  },
  {
    id: 3,
    code: "VOLUME_LEVEL",
    parameterType: "number",
    parameter: 30,
    schedulable: true,
  },
  {
    id: 4,
    code: "MICROPHONE",
    parameterType: "boolean",
    schedulable: true,
  },
  {
    id: 5,
    code: "MICROPHONE_LEVEL",
    parameterType: "number",
    parameter: 30,
    schedulable: true,
  },
  {
    id: 6,
    code: "LAUNCH",
    parameterType: "none",
    schedulable: true,
  },
  {
    id: 7,
    code: "STOP",
    parameterType: "none",
    schedulable: true,
  },
  {
    id: 9,
    code: "UPDATE",
    parameterType: "none",
    schedulable: true,
  },
  {
    id: 11,
    code: "CALENDAR",
    parameterType: "none", //TODO REVIEW ME
    schedulable: false,
  },
  {
    id: 15,
    code: "LOG",
    parameterType: "none", //TODO REVIEW ME
    schedulable: false,
  },
  {
    id: 16,
    code: "RESTART_MACHINE",
    parameterType: "none",
    schedulable: true,
  },
  {
    id: 17,
    code: "RESTART_AIRSERVER",
    parameterType: "none",
    schedulable: true,
  },
  {
    id: 18,
    code: "SCREENSHOT",
    parameterType: "none",
    schedulable: false,
  },
  {
    id: 19,
    code: "CONFERENCE_SYSTEM",
    parameterType: "none",
    schedulable: false,
  },
  {
    id: 20,
    code: "SCRIPT",
    parameterType: "file",
    schedulable: true,
  },
  {
    id: 20,
    code: "ROOM_CAPACITY",
    parameterType: "number",
    schedulable: false,
  },
];

export type parameterValues =
  | string
  | boolean
  | number
  | { fileId: string; fileName: string; runAsSystem?: boolean };

type commandCode =
  | "MACHINE_NAME"
  | "VOLUME"
  | "VOLUME_LEVEL"
  | "MICROPHONE"
  | "MICROPHONE_LEVEL"
  | "LAUNCH"
  | "STOP"
  | "UPDATE"
  | "CALENDAR"
  | "LOG"
  | "RESTART_MACHINE"
  | "RESTART_AIRSERVER"
  | "SCREENSHOT"
  | "CONFERENCE_SYSTEM"
  | "SCRIPT"
  | "ROOM_CAPACITY";

type parameterType = "string" | "boolean" | "number" | "file" | "none";

export interface Command {
  id?: string;
  code: commandCode;
  parameter?: parameterValues;
  parameterType: parameterType;
  isScheduled?: boolean;
  schedulable?: boolean;
  status?:
    | "NONE"
    | "PROCESSING"
    | "EXPIRED"
    | "CANCELLED"
    | "FAILED"
    | "COMPLETED";
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function CommandDeserializer(input: any): Command {
  //TODO NEED A FULL API REFACTOR
  let code = "";
  let parameterType = "none";
  CommandsDecoder.map((cmd) => {
    if (cmd.id === input.commandTypeId) {
      code = cmd.code;
      parameterType = cmd.parameterType;
    }
  });

  return {
    //TODO NEED A FULL API REFACTOR
    id: input.schedulerCommandId,
    parameter: input.commandParameter || (input.parameter as parameterValues),
    code: code as commandCode,
    parameterType: parameterType as parameterType,
  };
}

export function CommandSerializer(command: Command): unknown {
  //TODO PARTIAL need full refactor
  return {
    commandParameter: command.parameter,
    commandTypeId: command.id,
  };
}
