var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [
              _c("rooms-map", {
                attrs: {
                  "markers-list": _vm.marker,
                  "show-all": false,
                  "map-center": _vm.center
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "va-card",
        [
          _c(
            "div",
            { staticClass: "text-4xl font-bold mb-8 d-flex align-self-center" },
            [
              _c("va-back"),
              _c("span", { staticClass: "ml-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.shouldUpdate
                        ? _vm.$t("common.building") +
                            " " +
                            _vm.currentBuilding.name
                        : _vm.$t("buildingDetail.createBuildingTitle")
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c("building-form")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }