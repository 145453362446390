import semver from "semver";

export default {
  flatten(version: string): string {
    /* ignore build number */
    const splitVersion = version.split(".");
    if (splitVersion.length > 3) {
      return splitVersion.slice(0, 3).join(".");
    } else {
      return version;
    }
  },
  greaterThan(version1: string, version2: string): boolean {
    return semver.gt(this.flatten(version1), this.flatten(version2));
  },
  lessThan(version1: string, version2: string): boolean {
    return semver.lt(this.flatten(version1), this.flatten(version2));
  },
  isProduction(): boolean {
    return process.env.VUE_APP_USER_ENV == "production";
  },
};
