var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.user
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "va-title d-flex align-self-center" },
                [
                  _c("va-back", {
                    attrs: { tooltip: _vm.$t("usersList.back") }
                  }),
                  _c("span", { staticClass: "ml-4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("usersList.editTitle")) + " "),
                    _vm.user.firstName
                      ? _c("span", [_vm._v(" - " + _vm._s(_vm.user.firstName))])
                      : _vm._e(),
                    _vm.user.lastName
                      ? _c("span", [_vm._v(_vm._s(_vm.user.lastName))])
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c(
                "va-card",
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      disabled: "",
                      label: _vm.$t("common.license"),
                      value: _vm.user.license.key,
                      messages: _vm.user.license.name
                    }
                  }),
                  _vm.user.license
                    ? [
                        _c("h6", { staticClass: "mt-8" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.userInformation")) + " "
                          )
                        ]),
                        _c("validation-provider", {
                          attrs: { rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      staticClass: "mt-4",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        label: _vm.$t("common.firstname"),
                                        "error-messages": errors,
                                        "hide-details": "auto"
                                      },
                                      model: {
                                        value: _vm.user.firstName,
                                        callback: function($$v) {
                                          _vm.$set(_vm.user, "firstName", $$v)
                                        },
                                        expression: "user.firstName"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1155991503
                          )
                        }),
                        _c("validation-provider", {
                          attrs: { rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      staticClass: "mt-4",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        label: _vm.$t("common.lastname"),
                                        "error-messages": errors,
                                        "hide-details": "auto"
                                      },
                                      model: {
                                        value: _vm.user.lastName,
                                        callback: function($$v) {
                                          _vm.$set(_vm.user, "lastName", $$v)
                                        },
                                        expression: "user.lastName"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2730883599
                          )
                        }),
                        _c("validation-provider", {
                          attrs: { rules: "required|email" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      staticClass: "mt-4",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        label: _vm.$t("common.email"),
                                        "error-messages": errors,
                                        "hide-details": "auto"
                                      },
                                      model: {
                                        value: _vm.user.email,
                                        callback: function($$v) {
                                          _vm.$set(_vm.user, "email", $$v)
                                        },
                                        expression: "user.email"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1857039375
                          )
                        })
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex mt-6" },
                    [
                      _c("va-button", {
                        staticClass: "mr-auto",
                        attrs: {
                          color: "error",
                          disabled: _vm.saving || _vm.deleting,
                          loading: _vm.deleting,
                          cta: _vm.$t("common.delete")
                        },
                        on: { click: _vm.onDelete }
                      }),
                      _c("va-button", {
                        attrs: {
                          disabled: _vm.saving || _vm.deleting,
                          loading: _vm.saving,
                          cta: _vm.$t("common.submit")
                        },
                        on: { click: _vm.onSave }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }