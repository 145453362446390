<template>
  <va-card>
    <div class="chart-container" :class="chartClasses">
      <v-progress-circular v-if="loading" color="accent" indeterminate />
      <div v-else>
        <div>
          <slot name="beforeTitle"></slot>
          <div class="d-flex justify-space-between">
            <div class="va-title" v-if="title">{{ title }}</div>

            <doughnut-chart
              class="chart-total"
              v-if="hasData && showTotal && type !== 'doughnut'"
              :data="totalChartData"
              :legend="false"
              :tooltips="false"
            />
          </div>
          <slot name="afterTitle"></slot>
        </div>

        <div ref="legend" class="chart-legend"></div>
        <component
          v-if="hasData && redraw"
          class="chart"
          :class="type"
          :is="chartComponent()"
          :data="chartData"
          :stacked="stacked"
          :total="totalData"
          :yTitle="yTitle"
          :xTitle="xTitle"
          @legend="setLegend"
          :click="click"
          :realtime="realtime"
          :realtimeCallback="realtimeCallback"
        />
        <v-alert dense class="d-inline-block light" type="info" v-else
          >{{ $t("common.noData") }}
        </v-alert>
        <slot name="footer"></slot>
      </div>
    </div>
  </va-card>
</template>

<script>
import DoughnutChart from "@/components/shared/charts/DoughnutChart";
import LineChart from "@/components/shared/charts/LineChart";
import VerticalBarChart from "@/components/shared/charts/VerticalBarChart";
import HorizontalBarChart from "@/components/shared/charts/HorizontalBarChart";
import StackedBarChart from "@/components/shared/charts/StackedBarChart";
import Vue from "vue";
import times from "lodash/times";
import constant from "lodash/constant";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import VaCard from "@/components/molecules/VaCard";

export default Vue.extend({
  name: "Chart",
  components: {
    DoughnutChart,
    VerticalBarChart,
    LineChart,
    HorizontalBarChart,
    StackedBarChart,
    VaCard,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    stacked: {
      type: Boolean,
      required: false,
      default: false,
    },
    yTitle: {
      type: String,
      required: false,
      default: "",
    },
    xTitle: {
      type: String,
      required: false,
      default: "",
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    click: {
      type: Function,
      required: false,
      default: () => {
        /**/
      },
    },
    realtime: {
      type: Boolean,
      required: false,
      default: false,
    },
    realtimeCallback: {
      type: Function,
      required: false,
      default: () => {
        /**/
      },
    },
  },
  methods: {
    setLegend(legend) {
      this.$refs.legend.innerHTML = legend;
    },
    chartComponent() {
      switch (this.type) {
        case "doughnut":
          return "DoughnutChart";
        case "vertical-bar":
          return "VerticalBarChart";
        case "horizontal-bar":
          return "HorizontalBarChart";
        case "line":
          return "LineChart";
        case "stacked":
          return "StackedBarChart";
        default:
          break;
      }
    },
  },
  data() {
    return {
      redraw: true, //WORKAROUND force reformat chart to update datk theme switch
    };
  },
  computed: {
    darkMode() {
      return this.$store.getters["session/darkMode"];
    },
    chartData() {
      return this.data;
    },
    hasData() {
      if (this.realtime) return true;
      if (
        isEmpty(this.data) ||
        this.data.datasets.length === 0 ||
        this.data.labels.length === 0
      ) {
        return false;
      }
      return this.data.datasets.some((dataset) => {
        return dataset.data.some((value) => {
          return value > 0;
        });
      });
    },
    chartClasses() {
      const classes = [this.type];
      if (this.showTotal) {
        classes.push("show-total");
      }
      if (!this.hasData) {
        classes.push("no-data");
      }
      return classes;
    },
    totalData() {
      const total = [];
      if (!this.showTotal) return total;
      const data = cloneDeep(this.data);
      data.datasets.forEach((dataset, index) => {
        dataset.data.forEach((value, index2) => {
          if (this.type === "doughnut") {
            total[index2] = total[index2] ? total[index2] + value : value;
          } else {
            total[index] = total[index] ? total[index] + value : value;
          }
        });
      });
      return total;
    },
    totalChartData() {
      const data = cloneDeep(this.data);
      const labels = [];
      const datasets = [];
      datasets.push(data.datasets[0]);
      const total = times(data.datasets.length, constant(0));
      const borders = [];
      const backgrounds = [];
      data.datasets.forEach((dataset, index) => {
        borders.push(dataset.borderColor);
        backgrounds.push(dataset.backgroundColor);
        labels.push(dataset.label);
        dataset.data.forEach((value) => {
          total[index] = total[index] + value;
        });
      });
      datasets[0].data = total;
      datasets[0].backgroundColor = backgrounds;
      datasets[0].borderColor = borders;
      return {
        labels,
        datasets,
      };
    },
  },
  watch: {
    darkMode() {
      this.redraw = false;
      this.$nextTick(() => {
        this.redraw = true;
      });
    },
    chartData(nextval) {
      if (
        (!nextval || !nextval.labels.length) &&
        this.$refs.legend?.innerHTML
      ) {
        this.$refs.legend.innerHTML = "";
      }
    },
  },
});
</script>

<style lang="scss">
.chart-container {
  min-height: 100px;

  .v-progress-circular {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:not(.no-data) {
    height: 100%;
  }

  .chart-legend {
    margin-bottom: 16px;

    ul {
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      list-style: none;
      margin-right: 16px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }

    .color {
      border: 1px solid;
      height: 15px;
      width: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .total {
      font-weight: 600;
      color: var(--v-text-base);
      font-size: 12px;

      &:before {
        content: "-";
        display: inline-block;
        margin: 0 2px;
        font-weight: normal;
        color: var(--v-text-base);
      }
    }

    .label {
      color: var(--v-text-darken);
      font-size: 12px;
      margin-left: 6px;
    }
  }

  .chart-total {
    max-width: 60px;
    max-height: 60px;
    //position: absolute;
    top: 16px;
    right: 16px;
  }
}
</style>
