




















































import Vue from "vue";
import VaLinesChart from "@/components/organisms/charts/VaLinesChart.vue";
import VaBarChart from "@/components/organisms/charts/VaBarChart.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import AnalyticsTopBar from "@/components/organisms/AnalyticsTopBar.vue";

export default Vue.extend({
  name: "AnalyticsHealth",
  components: {
    AnalyticsTopBar,
    VaCard,
    VaLinesChart,
    VaBarChart,
  },
  data() {
    return {
      peopleCount: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            label: "Average Room Capacity",
            data: [7, 7, 7, 7, 8, 8, 8],
          },
          {
            label: "Average People Count",
            data: [4, 4, 9, 7, 7, 8, 7],
          },
        ],
      },
      uptimeDuration: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.7)",
              "rgba(59, 128, 223,0.7)",
              "rgba(59, 128, 223,0.7)",
              "rgba(59, 128, 223,0.7)",
              "rgba(59, 128, 223,0.7)",
              "rgba(59, 128, 223,0.7)",
              "rgba(59, 128, 223,0.7)",
            ],
            label: "Total Meeting Durations",
            data: [4, 3, 2, 2, 5, 7, 6],
          },
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.3)",
            ],
            label: "Total unactive time",
            data: [4, 5, 6, 6, 3, 1, 2],
          },
        ],
      },
      meetingType: {
        labels: [
          "31 Dec",
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
        ],
        datasets: [
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.9)",
              "rgba(59, 128, 223,0.9)",
              "rgba(59, 128, 223,0.9)",
              "rgba(59, 128, 223,0.9)",
              "rgba(59, 128, 223,0.9)",
              "rgba(59, 128, 223,0.9)",
              "rgba(59, 128, 223,0.9)",
            ],
            label: "Applications",
            data: [12, 11, 3, 5, 2, 4, 7],
          },
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.6)",
            ],
            label: "Video",
            data: [3, 2, 13, 7, 4, 9, 13],
          },
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.4)",
              "rgba(59, 128, 223,0.4)",
              "rgba(59, 128, 223,0.4)",
              "rgba(59, 128, 223,0.4)",
              "rgba(59, 128, 223,0.4)",
              "rgba(59, 128, 223,0.4)",
              "rgba(59, 128, 223,0.4)",
            ],
            label: "Whiteboard",
            data: [13, 1, 6, 4, 7, 6, 11],
          },
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.2)",
              "rgba(59, 128, 223,0.2)",
              "rgba(59, 128, 223,0.2)",
              "rgba(59, 128, 223,0.2)",
              "rgba(59, 128, 223,0.2)",
              "rgba(59, 128, 223,0.2)",
              "rgba(59, 128, 223,0.2)",
            ],
            label: "Wireless",
            data: [6, 9, 4, 8, 11, 10, 5],
          },
        ],
      },
    };
  },
});
