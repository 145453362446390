






import Vue from "vue";

export default Vue.extend({
  props: {
    outline: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "m",
      validator(value) {
        return ["xl", "l", "m", "s", "xs"].includes(value);
      },
    },
    color: {
      type: String,
      required: false,
      default: "secondary",
    },
    textColor: {
      type: String,
      required: false,
    },
  },
});
