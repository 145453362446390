




import Vue from "vue";
import VaBadge from "@/components/atoms/VaBadge.vue";

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ["ok", "average", "poor", "offline", "maintenance"].includes(
          value
        );
      },
    },
    size: {
      type: String,
      required: false,
      default: "m",
      validator(value) {
        return ["xl", "l", "m", "s", "xs"].includes(value);
      },
    },
    iconClass: {
      type: String,
      required: false,
    },
  },
  components: { VaBadge },
  computed: {
    color(): string {
      switch (this.type) {
        case "ok":
          return "success";
        case "average":
          return "warning";
        case "poor":
          return "error";
        case "offline":
          return "secondary darken-4";
        case "maintenance":
          return "purple";
        default:
          return "secondary";
      }
    },
    text(): string {
      switch (this.type) {
        case "ok":
          return this.$t("common.ok") as string;
        case "average":
          return this.$t("common.warning") as string;
        case "poor":
          return this.$t("common.critical") as string;
        case "offline":
          return this.$t("common.offline") as string;
        case "maintenance":
          return this.$t("common.maintenance") as string;
        default:
          return "common.ok";
      }
    },
  },
});
