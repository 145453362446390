





















import Vue, { VueConstructor } from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import validationMixin from "@/components/mixins/validationMixin";
import { LoggerMixin } from "@/components/mixins/errorMixin";
import policiesMixin from "@/components/mixins/policiesMixin";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "DisplayConfiguration",
  components: { VaSwitch },
  mixins: [validationMixin, policiesMixin],
});
