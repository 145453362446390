<script>
import { Line } from "vue-chartjs";
import Vue from "vue";
import ChartStreaming from "chartjs-plugin-streaming";

export default Vue.extend({
  extends: Line,
  props: {
    data: {
      type: Object,
      required: true,
    },
    realtime: {
      type: Boolean,
      required: false,
      default: false,
    },
    realtimeCallback: {
      type: Function,
      required: false,
    },
    legend: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.legend,
          fontColor: this.$vuetify.theme.currentTheme.chartText,
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              ticks: {
                stepValue: 10,
                steps: 10,
                fontSize: 10,
                beginAtZero: true,
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
                zeroLineColor: this.$vuetify.theme.currentTheme.chartLines,
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              ticks: {
                fontColor: this.$vuetify.theme.currentTheme.chartText,
              },
              gridLines: {
                color: this.$vuetify.theme.currentTheme.chartLines,
                zeroLineColor: this.$vuetify.theme.currentTheme.chartLines,
                drawBorder: false,
              },
            },
          ],
        },
      };
    },
  },
  methods: {
    update() {
      //const legend = this.$data._chart.generateLegend();
      //this.$emit("legend", legend);
      this.$data._chart.update();
    },
  },
  mounted() {
    this.addPlugin(ChartStreaming);
    this.renderChart(this.data, this.options);
    //const legend = this.$data._chart.generateLegend();
    //this.$emit("legend", legend);
  },
  watch: {
    // realtime: function (n, o) {
    realtime: function () {
      this.$data._chart.destroy();
      this.renderChart(this.data, this.options);
      //this.$data._chart.update();
    },
  },
});
</script>

<style lang="scss" scoped></style>
