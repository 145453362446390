var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("va-switch", {
        attrs: { text: _vm.text },
        on: {
          input: function($event) {
            return _vm.openManteinanceDialog(_vm.room)
          }
        },
        model: {
          value: _vm.maintenance,
          callback: function($$v) {
            _vm.maintenance = $$v
          },
          expression: "maintenance"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "card room-notices",
            width: "700",
            persistent: "",
            scrollable: ""
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("h2", [_vm._v(_vm._s(_vm.$t("common.maintenance")))])
              ]),
              _c(
                "v-form",
                {
                  ref: "maintenanceForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.toggleMaintenance },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "mt-3" },
                    [
                      _vm.room.isMaintenanceExpired
                        ? _c(
                            "v-alert",
                            { staticClass: "light", attrs: { type: "error" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("roomInformation.maintenanceExpired")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.room.hasOngoingMeeting
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "light",
                              attrs: { type: "warning" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("roomInformation.ongoingMeeting")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("va-text-field", {
                        attrs: {
                          disabled: _vm.room.isMaintenanceExpired,
                          rules: [_vm.required],
                          label: _vm.$t("common.notes")
                        },
                        model: {
                          value: _vm.notes,
                          callback: function($$v) {
                            _vm.notes = $$v
                          },
                          expression: "notes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "accent", outlined: "" },
                          on: { click: _vm.dismissManteinanceDialog }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("common.dismiss")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "accent",
                            depressed: "",
                            disabled: _vm.room.isMaintenanceExpired
                          },
                          on: { click: _vm.toggleMaintenance }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("common.save")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }