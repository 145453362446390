






























import Vue from "vue";
import { RoomDetail } from "@/models/room";
import VaNotification from "@/components/molecules/VaNotification.vue";

export default Vue.extend({
  name: "RoomIssues",
  components: { VaNotification },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
});
