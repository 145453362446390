var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    attrs: {
      name: _vm.name,
      label: _vm.label,
      rules: _vm.rules,
      "hide-details": "auto",
      outlined: "",
      dense: _vm.dense,
      "append-icon": _vm.appendIcon,
      "prepend-icon": _vm.prependIcon,
      type: _vm.password ? "password" : _vm.number ? "number" : "text",
      readonly: _vm.readonly,
      disabled: _vm.disabled
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", _vm.myValue)
      }
    },
    model: {
      value: _vm.myValue,
      callback: function($$v) {
        _vm.myValue = $$v
      },
      expression: "myValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }