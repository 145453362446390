






























import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import TimezonePicker from "@/components/organisms/TimezonePicker.vue";
import LocalePicker from "@/components/organisms/LocalePicker.vue";
import MfaSettings from "@/components/organisms/auth/MfaSettings.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import CompanyForm from "@/components/organisms/CompanyForm.vue";
import UserDataForm from "@/components/organisms/UserDataForm.vue";

export default Vue.extend({
  name: "Profile",
  components: {
    CompanyForm,
    UserDataForm,
    LocalePicker,
    TimezonePicker,
    VaCard,
    MainColumns,
    Notifications,
    MfaSettings,
  },
  computed: {
    user() {
      return this.$store.getters["session/user"];
    },
    mfaEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_DISABLED ||
        process.env.VUE_APP_OAUTH_DISABLED === "false"
      );
    },
  },
});
