var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.presentation")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c(
            "section",
            [
              _c("div", { staticClass: "va-subtitle-s my-6" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("policiesFields.HdmiPresentation")) + " "
                )
              ]),
              _c("va-text-field", {
                staticClass: "mt-4",
                attrs: { label: _vm.$t("policiesFields.PresentationUrl") },
                on: { input: _vm.updatePresentationUrl },
                model: {
                  value: _vm.fields.PresentationUrl,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "PresentationUrl", $$v)
                  },
                  expression: "fields.PresentationUrl"
                }
              }),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.WiredHdmiWithBarco") },
                on: { input: _vm.updateHdmiBarco },
                model: {
                  value: _vm.fields.WiredHdmiWithBarco,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "WiredHdmiWithBarco", $$v)
                  },
                  expression: "fields.WiredHdmiWithBarco"
                }
              }),
              _vm.fields.WiredHdmiWithBarco
                ? _c("v-select", {
                    staticClass: "mt-4",
                    attrs: {
                      outlined: "",
                      dense: "",
                      attach: "",
                      items: _vm.wiredHdmiOptions,
                      "item-text": "text",
                      "item-value": "value",
                      label: _vm.$t(
                        "policiesFields.WiredHdmiWithThirdPartyMode"
                      ),
                      "hide-details": "",
                      rules: [_vm.required]
                    },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.fields.WiredHdmiWithThirdPartyMode,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "WiredHdmiWithThirdPartyMode", $$v)
                      },
                      expression: "fields.WiredHdmiWithThirdPartyMode"
                    }
                  })
                : _vm._e(),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("policiesFields.WiredHdmiFullscreenEnabled")
                },
                on: { input: _vm.onChange }
              }),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t(
                    "policiesFields.PerformScreenSharingOnVideoSource"
                  )
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.PerformScreenSharingOnVideoSource,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.fields,
                      "PerformScreenSharingOnVideoSource",
                      $$v
                    )
                  },
                  expression: "fields.PerformScreenSharingOnVideoSource"
                }
              }),
              _c("va-switch", {
                attrs: {
                  disabled: !!_vm.fields.PresentationUrl,
                  text: _vm.$t("policiesFields.WiredHdmiShowInfo")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.WiredHdmiShowInfo,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "WiredHdmiShowInfo", $$v)
                  },
                  expression: "fields.WiredHdmiShowInfo"
                }
              })
            ],
            1
          ),
          _c(
            "section",
            [
              _c("div", { staticClass: "va-subtitle-s my-6" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("policiesFields.WirelessPresentation")) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.DisableAirServer") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.DisableAirServer,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "DisableAirServer", $$v)
                  },
                  expression: "fields.DisableAirServer"
                }
              }),
              !_vm.fields.DisableAirServer
                ? _c("va-switch", {
                    attrs: {
                      text: _vm.$t("policiesFields.RequestProjectionActive")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.RequestProjectionActive,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "RequestProjectionActive", $$v)
                      },
                      expression: "fields.RequestProjectionActive"
                    }
                  })
                : _vm._e(),
              !_vm.fields.DisableAirServer
                ? _c("va-switch", {
                    attrs: { text: _vm.$t("policiesFields.WirelessSharing") },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.WirelessSharing,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "WirelessSharing", $$v)
                      },
                      expression: "fields.WirelessSharing"
                    }
                  })
                : _vm._e(),
              !_vm.fields.DisableAirServer
                ? _c("va-switch", {
                    attrs: {
                      disabled: !!_vm.fields.PresentationUrl,
                      text: _vm.$t("policiesFields.WirelessShowInfo")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.WirelessShowInfo,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "WirelessShowInfo", $$v)
                      },
                      expression: "fields.WirelessShowInfo"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "section",
            [
              _c("div", { staticClass: "va-subtitle-s my-6" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("policiesFields.RoomCodePresentation")) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.RoomCodeSharing") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.RoomCodeSharing,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "RoomCodeSharing", $$v)
                  },
                  expression: "fields.RoomCodeSharing"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }