import { render, staticRenderFns } from "./CommandsSelector.vue?vue&type=template&id=7a3a2ac0&scoped=true&"
import script from "./CommandsSelector.vue?vue&type=script&lang=ts&"
export * from "./CommandsSelector.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a3a2ac0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VSelect,VSlider})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a3a2ac0')) {
      api.createRecord('7a3a2ac0', component.options)
    } else {
      api.reload('7a3a2ac0', component.options)
    }
    module.hot.accept("./CommandsSelector.vue?vue&type=template&id=7a3a2ac0&scoped=true&", function () {
      api.rerender('7a3a2ac0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/commands/CommandsSelector.vue"
export default component.exports