export interface Timezone {
  value: string;
  offset: number;
  label: string;
  utc: string;
}

export type TimezoneItem = Timezone | { header: string } | { divider: boolean };

export const TIMEZONES_ITEMS: Array<TimezoneItem> = [
  {
    header: "Dateline Standard Time (DST)",
  },
  {
    value: "Etc/GMT+12",
    offset: -12,
    label: "Etc/GMT+12",
    utc: "UTC-12:00",
  },
  {
    divider: true,
  },
  {
    header: "UTC-11 (U)",
  },
  {
    value: "Etc/GMT+11",
    offset: -11,
    label: "Etc/GMT+11",
    utc: "UTC-11:00",
  },
  {
    value: "Pacific/Midway",
    offset: -11,
    label: "Pacific/Midway",
    utc: "UTC-11:00",
  },
  {
    value: "Pacific/Niue",
    offset: -11,
    label: "Pacific/Niue",
    utc: "UTC-11:00",
  },
  {
    value: "Pacific/Pago_Pago",
    offset: -11,
    label: "Pacific/Pago Pago",
    utc: "UTC-11:00",
  },
  {
    divider: true,
  },
  {
    header: "Hawaiian Standard Time (HST)",
  },
  {
    value: "Etc/GMT+10",
    offset: -10,
    label: "Etc/GMT+10",
    utc: "UTC-10:00",
  },
  {
    value: "Pacific/Honolulu",
    offset: -10,
    label: "Pacific/Honolulu",
    utc: "UTC-10:00",
  },
  {
    value: "Pacific/Johnston",
    offset: -10,
    label: "Pacific/Johnston",
    utc: "UTC-10:00",
  },
  {
    value: "Pacific/Rarotonga",
    offset: -10,
    label: "Pacific/Rarotonga",
    utc: "UTC-10:00",
  },
  {
    value: "Pacific/Tahiti",
    offset: -10,
    label: "Pacific/Tahiti",
    utc: "UTC-10:00",
  },
  {
    divider: true,
  },
  {
    header: "Alaskan Standard Time (AKDT)",
  },
  {
    value: "America/Anchorage",
    offset: -8,
    label: "America/Anchorage",
    utc: "UTC-09:00",
  },
  {
    value: "America/Juneau",
    offset: -8,
    label: "America/Juneau",
    utc: "UTC-09:00",
  },
  {
    value: "America/Nome",
    offset: -8,
    label: "America/Nome",
    utc: "UTC-09:00",
  },
  {
    value: "America/Sitka",
    offset: -8,
    label: "America/Sitka",
    utc: "UTC-09:00",
  },
  {
    value: "America/Yakutat",
    offset: -8,
    label: "America/Yakutat",
    utc: "UTC-09:00",
  },
  {
    divider: true,
  },
  {
    header: "Pacific Standard Time (Mexico) (PDT)",
  },
  {
    value: "America/Santa_Isabel",
    offset: -7,
    label: "America/Santa Isabel",
    utc: "UTC-08:00",
  },
  {
    divider: true,
  },
  {
    header: "Pacific Daylight Time (PDT)",
  },
  {
    value: "America/Los_Angeles",
    offset: -7,
    label: "America/Los Angeles",
    utc: "UTC-07:00",
  },
  {
    value: "America/Tijuana",
    offset: -7,
    label: "America/Tijuana",
    utc: "UTC-07:00",
  },
  {
    value: "America/Vancouver",
    offset: -7,
    label: "America/Vancouver",
    utc: "UTC-07:00",
  },
  {
    divider: true,
  },
  {
    header: "Pacific Standard Time (PST)",
  },
  {
    value: "America/Los_Angeles",
    offset: -8,
    label: "America/Los Angeles",
    utc: "UTC-08:00",
  },
  {
    value: "America/Tijuana",
    offset: -8,
    label: "America/Tijuana",
    utc: "UTC-08:00",
  },
  {
    value: "America/Vancouver",
    offset: -8,
    label: "America/Vancouver",
    utc: "UTC-08:00",
  },
  {
    value: "PST8PDT",
    offset: -8,
    label: "PST8PDT",
    utc: "UTC-08:00",
  },
  {
    divider: true,
  },
  {
    header: "US Mountain Standard Time (UMST)",
  },
  {
    value: "America/Creston",
    offset: -7,
    label: "America/Creston",
    utc: "UTC-07:00",
  },
  {
    value: "America/Dawson",
    offset: -7,
    label: "America/Dawson",
    utc: "UTC-07:00",
  },
  {
    value: "America/Dawson_Creek",
    offset: -7,
    label: "America/Dawson Creek",
    utc: "UTC-07:00",
  },
  {
    value: "America/Hermosillo",
    offset: -7,
    label: "America/Hermosillo",
    utc: "UTC-07:00",
  },
  {
    value: "America/Phoenix",
    offset: -7,
    label: "America/Phoenix",
    utc: "UTC-07:00",
  },
  {
    value: "America/Whitehorse",
    offset: -7,
    label: "America/Whitehorse",
    utc: "UTC-07:00",
  },
  {
    value: "Etc/GMT+7",
    offset: -7,
    label: "Etc/GMT+7",
    utc: "UTC-07:00",
  },
  {
    divider: true,
  },
  {
    header: "Mountain Standard Time (Mexico) (MDT)",
  },
  {
    value: "America/Chihuahua",
    offset: -6,
    label: "America/Chihuahua",
    utc: "UTC-07:00",
  },
  {
    value: "America/Mazatlan",
    offset: -6,
    label: "America/Mazatlan",
    utc: "UTC-07:00",
  },
  {
    divider: true,
  },
  {
    header: "Mountain Standard Time (MDT)",
  },
  {
    value: "America/Boise",
    offset: -6,
    label: "America/Boise",
    utc: "UTC-07:00",
  },
  {
    value: "America/Cambridge_Bay",
    offset: -6,
    label: "America/Cambridge Bay",
    utc: "UTC-07:00",
  },
  {
    value: "America/Denver",
    offset: -6,
    label: "America/Denver",
    utc: "UTC-07:00",
  },
  {
    value: "America/Edmonton",
    offset: -6,
    label: "America/Edmonton",
    utc: "UTC-07:00",
  },
  {
    value: "America/Inuvik",
    offset: -6,
    label: "America/Inuvik",
    utc: "UTC-07:00",
  },
  {
    value: "America/Ojinaga",
    offset: -6,
    label: "America/Ojinaga",
    utc: "UTC-07:00",
  },
  {
    value: "America/Yellowknife",
    offset: -6,
    label: "America/Yellowknife",
    utc: "UTC-07:00",
  },
  {
    value: "MST7MDT",
    offset: -6,
    label: "MST7MDT",
    utc: "UTC-07:00",
  },
  {
    divider: true,
  },
  {
    header: "Central America Standard Time (CAST)",
  },
  {
    value: "America/Belize",
    offset: -6,
    label: "America/Belize",
    utc: "UTC-06:00",
  },
  {
    value: "America/Costa_Rica",
    offset: -6,
    label: "America/Costa Rica",
    utc: "UTC-06:00",
  },
  {
    value: "America/El_Salvador",
    offset: -6,
    label: "America/El Salvador",
    utc: "UTC-06:00",
  },
  {
    value: "America/Guatemala",
    offset: -6,
    label: "America/Guatemala",
    utc: "UTC-06:00",
  },
  {
    value: "America/Managua",
    offset: -6,
    label: "America/Managua",
    utc: "UTC-06:00",
  },
  {
    value: "America/Tegucigalpa",
    offset: -6,
    label: "America/Tegucigalpa",
    utc: "UTC-06:00",
  },
  {
    value: "Etc/GMT+6",
    offset: -6,
    label: "Etc/GMT+6",
    utc: "UTC-06:00",
  },
  {
    value: "Pacific/Galapagos",
    offset: -6,
    label: "Pacific/Galapagos",
    utc: "UTC-06:00",
  },
  {
    divider: true,
  },
  {
    header: "Central Standard Time (CDT)",
  },
  {
    value: "America/Chicago",
    offset: -5,
    label: "America/Chicago",
    utc: "UTC-06:00",
  },
  {
    value: "America/Indiana/Knox",
    offset: -5,
    label: "America/Indiana/Knox",
    utc: "UTC-06:00",
  },
  {
    value: "America/Indiana/Tell_City",
    offset: -5,
    label: "America/Indiana/Tell City",
    utc: "UTC-06:00",
  },
  {
    value: "America/Matamoros",
    offset: -5,
    label: "America/Matamoros",
    utc: "UTC-06:00",
  },
  {
    value: "America/Menominee",
    offset: -5,
    label: "America/Menominee",
    utc: "UTC-06:00",
  },
  {
    value: "America/North_Dakota/Beulah",
    offset: -5,
    label: "America/North Dakota/Beulah",
    utc: "UTC-06:00",
  },
  {
    value: "America/North_Dakota/Center",
    offset: -5,
    label: "America/North Dakota/Center",
    utc: "UTC-06:00",
  },
  {
    value: "America/North_Dakota/New_Salem",
    offset: -5,
    label: "America/North Dakota/New Salem",
    utc: "UTC-06:00",
  },
  {
    value: "America/Rainy_River",
    offset: -5,
    label: "America/Rainy River",
    utc: "UTC-06:00",
  },
  {
    value: "America/Rankin_Inlet",
    offset: -5,
    label: "America/Rankin Inlet",
    utc: "UTC-06:00",
  },
  {
    value: "America/Resolute",
    offset: -5,
    label: "America/Resolute",
    utc: "UTC-06:00",
  },
  {
    value: "America/Winnipeg",
    offset: -5,
    label: "America/Winnipeg",
    utc: "UTC-06:00",
  },
  {
    value: "CST6CDT",
    offset: -5,
    label: "CST6CDT",
    utc: "UTC-06:00",
  },
  {
    divider: true,
  },
  {
    header: "Central Standard Time (Mexico) (CDT)",
  },
  {
    value: "America/Bahia_Banderas",
    offset: -5,
    label: "America/Bahia Banderas",
    utc: "UTC-06:00",
  },
  {
    value: "America/Cancun",
    offset: -5,
    label: "America/Cancun",
    utc: "UTC-06:00",
  },
  {
    value: "America/Merida",
    offset: -5,
    label: "America/Merida",
    utc: "UTC-06:00",
  },
  {
    value: "America/Mexico_City",
    offset: -5,
    label: "America/Mexico City",
    utc: "UTC-06:00",
  },
  {
    value: "America/Monterrey",
    offset: -5,
    label: "America/Monterrey",
    utc: "UTC-06:00",
  },
  {
    divider: true,
  },
  {
    header: "Canada Central Standard Time (CCST)",
  },
  {
    value: "America/Regina",
    offset: -6,
    label: "America/Regina",
    utc: "UTC-06:00",
  },
  {
    value: "America/Swift_Current",
    offset: -6,
    label: "America/Swift Current",
    utc: "UTC-06:00",
  },
  {
    divider: true,
  },
  {
    header: "SA Pacific Standard Time (SPST)",
  },
  {
    value: "America/Bogota",
    offset: -5,
    label: "America/Bogota",
    utc: "UTC-05:00",
  },
  {
    value: "America/Cayman",
    offset: -5,
    label: "America/Cayman",
    utc: "UTC-05:00",
  },
  {
    value: "America/Coral_Harbour",
    offset: -5,
    label: "America/Coral Harbour",
    utc: "UTC-05:00",
  },
  {
    value: "America/Eirunepe",
    offset: -5,
    label: "America/Eirunepe",
    utc: "UTC-05:00",
  },
  {
    value: "America/Guayaquil",
    offset: -5,
    label: "America/Guayaquil",
    utc: "UTC-05:00",
  },
  {
    value: "America/Jamaica",
    offset: -5,
    label: "America/Jamaica",
    utc: "UTC-05:00",
  },
  {
    value: "America/Lima",
    offset: -5,
    label: "America/Lima",
    utc: "UTC-05:00",
  },
  {
    value: "America/Panama",
    offset: -5,
    label: "America/Panama",
    utc: "UTC-05:00",
  },
  {
    value: "America/Rio_Branco",
    offset: -5,
    label: "America/Rio Branco",
    utc: "UTC-05:00",
  },
  {
    value: "Etc/GMT+5",
    offset: -5,
    label: "Etc/GMT+5",
    utc: "UTC-05:00",
  },
  {
    divider: true,
  },
  {
    header: "Eastern Standard Time (EDT)",
  },
  {
    value: "America/Detroit",
    offset: -4,
    label: "America/Detroit",
    utc: "UTC-05:00",
  },
  {
    value: "America/Havana",
    offset: -4,
    label: "America/Havana",
    utc: "UTC-05:00",
  },
  {
    value: "America/Indiana/Petersburg",
    offset: -4,
    label: "America/Indiana/Petersburg",
    utc: "UTC-05:00",
  },
  {
    value: "America/Indiana/Vincennes",
    offset: -4,
    label: "America/Indiana/Vincennes",
    utc: "UTC-05:00",
  },
  {
    value: "America/Indiana/Winamac",
    offset: -4,
    label: "America/Indiana/Winamac",
    utc: "UTC-05:00",
  },
  {
    value: "America/Iqaluit",
    offset: -4,
    label: "America/Iqaluit",
    utc: "UTC-05:00",
  },
  {
    value: "America/Kentucky/Monticello",
    offset: -4,
    label: "America/Kentucky/Monticello",
    utc: "UTC-05:00",
  },
  {
    value: "America/Louisville",
    offset: -4,
    label: "America/Louisville",
    utc: "UTC-05:00",
  },
  {
    value: "America/Montreal",
    offset: -4,
    label: "America/Montreal",
    utc: "UTC-05:00",
  },
  {
    value: "America/Nassau",
    offset: -4,
    label: "America/Nassau",
    utc: "UTC-05:00",
  },
  {
    value: "America/New_York",
    offset: -4,
    label: "America/New York",
    utc: "UTC-05:00",
  },
  {
    value: "America/Nipigon",
    offset: -4,
    label: "America/Nipigon",
    utc: "UTC-05:00",
  },
  {
    value: "America/Pangnirtung",
    offset: -4,
    label: "America/Pangnirtung",
    utc: "UTC-05:00",
  },
  {
    value: "America/Port-au-Prince",
    offset: -4,
    label: "America/Port-au-Prince",
    utc: "UTC-05:00",
  },
  {
    value: "America/Thunder_Bay",
    offset: -4,
    label: "America/Thunder Bay",
    utc: "UTC-05:00",
  },
  {
    value: "America/Toronto",
    offset: -4,
    label: "America/Toronto",
    utc: "UTC-05:00",
  },
  {
    value: "EST5EDT",
    offset: -4,
    label: "EST5EDT",
    utc: "UTC-05:00",
  },
  {
    divider: true,
  },
  {
    header: "US Eastern Standard Time (UEDT)",
  },
  {
    value: "America/Indiana/Marengo",
    offset: -4,
    label: "America/Indiana/Marengo",
    utc: "UTC-05:00",
  },
  {
    value: "America/Indiana/Vevay",
    offset: -4,
    label: "America/Indiana/Vevay",
    utc: "UTC-05:00",
  },
  {
    value: "America/Indianapolis",
    offset: -4,
    label: "America/Indianapolis",
    utc: "UTC-05:00",
  },
  {
    divider: true,
  },
  {
    header: "Venezuela Standard Time (VST)",
  },
  {
    value: "America/Caracas",
    offset: -4.5,
    label: "America/Caracas",
    utc: "UTC-04:30",
  },
  {
    divider: true,
  },
  {
    header: "Paraguay Standard Time (PYT)",
  },
  {
    value: "America/Asuncion",
    offset: -4,
    label: "America/Asuncion",
    utc: "UTC-04:00",
  },
  {
    divider: true,
  },
  {
    header: "Atlantic Standard Time (ADT)",
  },
  {
    value: "America/Glace_Bay",
    offset: -3,
    label: "America/Glace Bay",
    utc: "UTC-04:00",
  },
  {
    value: "America/Goose_Bay",
    offset: -3,
    label: "America/Goose Bay",
    utc: "UTC-04:00",
  },
  {
    value: "America/Halifax",
    offset: -3,
    label: "America/Halifax",
    utc: "UTC-04:00",
  },
  {
    value: "America/Moncton",
    offset: -3,
    label: "America/Moncton",
    utc: "UTC-04:00",
  },
  {
    value: "America/Thule",
    offset: -3,
    label: "America/Thule",
    utc: "UTC-04:00",
  },
  {
    value: "Atlantic/Bermuda",
    offset: -3,
    label: "Atlantic/Bermuda",
    utc: "UTC-04:00",
  },
  {
    divider: true,
  },
  {
    header: "Central Brazilian Standard Time (CBST)",
  },
  {
    value: "America/Campo_Grande",
    offset: -4,
    label: "America/Campo Grande",
    utc: "UTC-04:00",
  },
  {
    value: "America/Cuiaba",
    offset: -4,
    label: "America/Cuiaba",
    utc: "UTC-04:00",
  },
  {
    divider: true,
  },
  {
    header: "SA Western Standard Time (SWST)",
  },
  {
    value: "America/Anguilla",
    offset: -4,
    label: "America/Anguilla",
    utc: "UTC-04:00",
  },
  {
    value: "America/Antigua",
    offset: -4,
    label: "America/Antigua",
    utc: "UTC-04:00",
  },
  {
    value: "America/Aruba",
    offset: -4,
    label: "America/Aruba",
    utc: "UTC-04:00",
  },
  {
    value: "America/Barbados",
    offset: -4,
    label: "America/Barbados",
    utc: "UTC-04:00",
  },
  {
    value: "America/Blanc-Sablon",
    offset: -4,
    label: "America/Blanc-Sablon",
    utc: "UTC-04:00",
  },
  {
    value: "America/Boa_Vista",
    offset: -4,
    label: "America/Boa Vista",
    utc: "UTC-04:00",
  },
  {
    value: "America/Curacao",
    offset: -4,
    label: "America/Curacao",
    utc: "UTC-04:00",
  },
  {
    value: "America/Dominica",
    offset: -4,
    label: "America/Dominica",
    utc: "UTC-04:00",
  },
  {
    value: "America/Grand_Turk",
    offset: -4,
    label: "America/Grand Turk",
    utc: "UTC-04:00",
  },
  {
    value: "America/Grenada",
    offset: -4,
    label: "America/Grenada",
    utc: "UTC-04:00",
  },
  {
    value: "America/Guadeloupe",
    offset: -4,
    label: "America/Guadeloupe",
    utc: "UTC-04:00",
  },
  {
    value: "America/Guyana",
    offset: -4,
    label: "America/Guyana",
    utc: "UTC-04:00",
  },
  {
    value: "America/Kralendijk",
    offset: -4,
    label: "America/Kralendijk",
    utc: "UTC-04:00",
  },
  {
    value: "America/La_Paz",
    offset: -4,
    label: "America/La Paz",
    utc: "UTC-04:00",
  },
  {
    value: "America/Lower_Princes",
    offset: -4,
    label: "America/Lower Princes",
    utc: "UTC-04:00",
  },
  {
    value: "America/Manaus",
    offset: -4,
    label: "America/Manaus",
    utc: "UTC-04:00",
  },
  {
    value: "America/Marigot",
    offset: -4,
    label: "America/Marigot",
    utc: "UTC-04:00",
  },
  {
    value: "America/Martinique",
    offset: -4,
    label: "America/Martinique",
    utc: "UTC-04:00",
  },
  {
    value: "America/Montserrat",
    offset: -4,
    label: "America/Montserrat",
    utc: "UTC-04:00",
  },
  {
    value: "America/Port_of_Spain",
    offset: -4,
    label: "America/Port of Spain",
    utc: "UTC-04:00",
  },
  {
    value: "America/Porto_Velho",
    offset: -4,
    label: "America/Porto Velho",
    utc: "UTC-04:00",
  },
  {
    value: "America/Puerto_Rico",
    offset: -4,
    label: "America/Puerto Rico",
    utc: "UTC-04:00",
  },
  {
    value: "America/Santo_Domingo",
    offset: -4,
    label: "America/Santo Domingo",
    utc: "UTC-04:00",
  },
  {
    value: "America/St_Barthelemy",
    offset: -4,
    label: "America/St Barthelemy",
    utc: "UTC-04:00",
  },
  {
    value: "America/St_Kitts",
    offset: -4,
    label: "America/St Kitts",
    utc: "UTC-04:00",
  },
  {
    value: "America/St_Lucia",
    offset: -4,
    label: "America/St Lucia",
    utc: "UTC-04:00",
  },
  {
    value: "America/St_Thomas",
    offset: -4,
    label: "America/St Thomas",
    utc: "UTC-04:00",
  },
  {
    value: "America/St_Vincent",
    offset: -4,
    label: "America/St Vincent",
    utc: "UTC-04:00",
  },
  {
    value: "America/Tortola",
    offset: -4,
    label: "America/Tortola",
    utc: "UTC-04:00",
  },
  {
    value: "Etc/GMT+4",
    offset: -4,
    label: "Etc/GMT+4",
    utc: "UTC-04:00",
  },
  {
    divider: true,
  },
  {
    header: "Pacific SA Standard Time (PSST)",
  },
  {
    value: "America/Santiago",
    offset: -4,
    label: "America/Santiago",
    utc: "UTC-04:00",
  },
  {
    value: "Antarctica/Palmer",
    offset: -4,
    label: "Antarctica/Palmer",
    utc: "UTC-04:00",
  },
  {
    divider: true,
  },
  {
    header: "Newfoundland Standard Time (NDT)",
  },
  {
    value: "America/St_Johns",
    offset: -2.5,
    label: "America/St Johns",
    utc: "UTC-03:30",
  },
  {
    divider: true,
  },
  {
    header: "E. South America Standard Time (ESAST)",
  },
  {
    value: "America/Sao_Paulo",
    offset: -3,
    label: "America/Sao Paulo",
    utc: "UTC-03:00",
  },
  {
    divider: true,
  },
  {
    header: "Argentina Standard Time (AST)",
  },
  {
    value: "America/Argentina/La_Rioja",
    offset: -3,
    label: "America/Argentina/La Rioja",
    utc: "UTC-03:00",
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    offset: -3,
    label: "America/Argentina/Rio Gallegos",
    utc: "UTC-03:00",
  },
  {
    value: "America/Argentina/Salta",
    offset: -3,
    label: "America/Argentina/Salta",
    utc: "UTC-03:00",
  },
  {
    value: "America/Argentina/San_Juan",
    offset: -3,
    label: "America/Argentina/San Juan",
    utc: "UTC-03:00",
  },
  {
    value: "America/Argentina/San_Luis",
    offset: -3,
    label: "America/Argentina/San Luis",
    utc: "UTC-03:00",
  },
  {
    value: "America/Argentina/Tucuman",
    offset: -3,
    label: "America/Argentina/Tucuman",
    utc: "UTC-03:00",
  },
  {
    value: "America/Argentina/Ushuaia",
    offset: -3,
    label: "America/Argentina/Ushuaia",
    utc: "UTC-03:00",
  },
  {
    value: "America/Buenos_Aires",
    offset: -3,
    label: "America/Buenos Aires",
    utc: "UTC-03:00",
  },
  {
    value: "America/Catamarca",
    offset: -3,
    label: "America/Catamarca",
    utc: "UTC-03:00",
  },
  {
    value: "America/Cordoba",
    offset: -3,
    label: "America/Cordoba",
    utc: "UTC-03:00",
  },
  {
    value: "America/Jujuy",
    offset: -3,
    label: "America/Jujuy",
    utc: "UTC-03:00",
  },
  {
    value: "America/Mendoza",
    offset: -3,
    label: "America/Mendoza",
    utc: "UTC-03:00",
  },
  {
    divider: true,
  },
  {
    header: "SA Eastern Standard Time (SEST)",
  },
  {
    value: "America/Araguaina",
    offset: -3,
    label: "America/Araguaina",
    utc: "UTC-03:00",
  },
  {
    value: "America/Belem",
    offset: -3,
    label: "America/Belem",
    utc: "UTC-03:00",
  },
  {
    value: "America/Cayenne",
    offset: -3,
    label: "America/Cayenne",
    utc: "UTC-03:00",
  },
  {
    value: "America/Fortaleza",
    offset: -3,
    label: "America/Fortaleza",
    utc: "UTC-03:00",
  },
  {
    value: "America/Maceio",
    offset: -3,
    label: "America/Maceio",
    utc: "UTC-03:00",
  },
  {
    value: "America/Paramaribo",
    offset: -3,
    label: "America/Paramaribo",
    utc: "UTC-03:00",
  },
  {
    value: "America/Recife",
    offset: -3,
    label: "America/Recife",
    utc: "UTC-03:00",
  },
  {
    value: "America/Santarem",
    offset: -3,
    label: "America/Santarem",
    utc: "UTC-03:00",
  },
  {
    value: "Antarctica/Rothera",
    offset: -3,
    label: "Antarctica/Rothera",
    utc: "UTC-03:00",
  },
  {
    value: "Atlantic/Stanley",
    offset: -3,
    label: "Atlantic/Stanley",
    utc: "UTC-03:00",
  },
  {
    value: "Etc/GMT+3",
    offset: -3,
    label: "Etc/GMT+3",
    utc: "UTC-03:00",
  },
  {
    divider: true,
  },
  {
    header: "Greenland Standard Time (GDT)",
  },
  {
    value: "America/Godthab",
    offset: -3,
    label: "America/Godthab",
    utc: "UTC-03:00",
  },
  {
    divider: true,
  },
  {
    header: "Montevideo Standard Time (MST)",
  },
  {
    value: "America/Montevideo",
    offset: -3,
    label: "America/Montevideo",
    utc: "UTC-03:00",
  },
  {
    divider: true,
  },
  {
    header: "Bahia Standard Time (BST)",
  },
  {
    value: "America/Bahia",
    offset: -3,
    label: "America/Bahia",
    utc: "UTC-03:00",
  },
  {
    divider: true,
  },
  {
    header: "UTC-02 (U)",
  },
  {
    value: "America/Noronha",
    offset: -2,
    label: "America/Noronha",
    utc: "UTC-02:00",
  },
  {
    value: "Atlantic/South_Georgia",
    offset: -2,
    label: "Atlantic/South Georgia",
    utc: "UTC-02:00",
  },
  {
    value: "Etc/GMT+2",
    offset: -2,
    label: "Etc/GMT+2",
    utc: "UTC-02:00",
  },
  {
    divider: true,
  },
  {
    header: "Mid-Atlantic Standard Time (MDT)",
  },
  {
    divider: true,
  },
  {
    header: "Azores Standard Time (ADT)",
  },
  {
    value: "America/Scoresbysund",
    offset: 0,
    label: "America/Scoresbysund",
    utc: "UTC-01:00",
  },
  {
    value: "Atlantic/Azores",
    offset: 0,
    label: "Atlantic/Azores",
    utc: "UTC-01:00",
  },
  {
    divider: true,
  },
  {
    header: "Cape Verde Standard Time (CVST)",
  },
  {
    value: "Atlantic/Cape_Verde",
    offset: -1,
    label: "Atlantic/Cape Verde",
    utc: "UTC-01:00",
  },
  {
    value: "Etc/GMT+1",
    offset: -1,
    label: "Etc/GMT+1",
    utc: "UTC-01:00",
  },
  {
    divider: true,
  },
  {
    header: "Morocco Standard Time (MDT)",
  },
  {
    value: "Africa/Casablanca",
    offset: 1,
    label: "Africa/Casablanca",
    utc: "UTC",
  },
  {
    value: "Africa/El_Aaiun",
    offset: 1,
    label: "Africa/El Aaiun",
    utc: "UTC",
  },
  {
    divider: true,
  },
  {
    header: "UTC (UTC)",
  },
  {
    value: "America/Danmarkshavn",
    offset: 0,
    label: "America/Danmarkshavn",
    utc: "UTC",
  },
  {
    value: "Etc/GMT",
    offset: 0,
    label: "Etc/GMT",
    utc: "UTC",
  },
  {
    divider: true,
  },
  {
    header: "GMT Standard Time (GMT)",
  },
  {
    value: "Europe/Isle_of_Man",
    offset: 0,
    label: "Europe/Isle of Man",
    utc: "UTC",
  },
  {
    value: "Europe/Guernsey",
    offset: 0,
    label: "Europe/Guernsey",
    utc: "UTC",
  },
  {
    value: "Europe/Jersey",
    offset: 0,
    label: "Europe/Jersey",
    utc: "UTC",
  },
  {
    value: "Europe/London",
    offset: 0,
    label: "Europe/London",
    utc: "UTC",
  },
  {
    divider: true,
  },
  {
    header: "British Summer Time (BST)",
  },
  {
    value: "Europe/Isle_of_Man",
    offset: 1,
    label: "Europe/Isle of Man",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Guernsey",
    offset: 1,
    label: "Europe/Guernsey",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Jersey",
    offset: 1,
    label: "Europe/Jersey",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/London",
    offset: 1,
    label: "Europe/London",
    utc: "UTC+01:00",
  },
  {
    divider: true,
  },
  {
    header: "GMT Standard Time (GDT)",
  },
  {
    value: "Atlantic/Canary",
    offset: 1,
    label: "Atlantic/Canary",
    utc: "UTC",
  },
  {
    value: "Atlantic/Faeroe",
    offset: 1,
    label: "Atlantic/Faeroe",
    utc: "UTC",
  },
  {
    value: "Atlantic/Madeira",
    offset: 1,
    label: "Atlantic/Madeira",
    utc: "UTC",
  },
  {
    value: "Europe/Dublin",
    offset: 1,
    label: "Europe/Dublin",
    utc: "UTC",
  },
  {
    value: "Europe/Lisbon",
    offset: 1,
    label: "Europe/Lisbon",
    utc: "UTC",
  },
  {
    divider: true,
  },
  {
    header: "Greenwich Standard Time (GST)",
  },
  {
    value: "Africa/Abidjan",
    offset: 0,
    label: "Africa/Abidjan",
    utc: "UTC",
  },
  {
    value: "Africa/Accra",
    offset: 0,
    label: "Africa/Accra",
    utc: "UTC",
  },
  {
    value: "Africa/Bamako",
    offset: 0,
    label: "Africa/Bamako",
    utc: "UTC",
  },
  {
    value: "Africa/Banjul",
    offset: 0,
    label: "Africa/Banjul",
    utc: "UTC",
  },
  {
    value: "Africa/Bissau",
    offset: 0,
    label: "Africa/Bissau",
    utc: "UTC",
  },
  {
    value: "Africa/Conakry",
    offset: 0,
    label: "Africa/Conakry",
    utc: "UTC",
  },
  {
    value: "Africa/Dakar",
    offset: 0,
    label: "Africa/Dakar",
    utc: "UTC",
  },
  {
    value: "Africa/Freetown",
    offset: 0,
    label: "Africa/Freetown",
    utc: "UTC",
  },
  {
    value: "Africa/Lome",
    offset: 0,
    label: "Africa/Lome",
    utc: "UTC",
  },
  {
    value: "Africa/Monrovia",
    offset: 0,
    label: "Africa/Monrovia",
    utc: "UTC",
  },
  {
    value: "Africa/Nouakchott",
    offset: 0,
    label: "Africa/Nouakchott",
    utc: "UTC",
  },
  {
    value: "Africa/Ouagadougou",
    offset: 0,
    label: "Africa/Ouagadougou",
    utc: "UTC",
  },
  {
    value: "Africa/Sao_Tome",
    offset: 0,
    label: "Africa/Sao Tome",
    utc: "UTC",
  },
  {
    value: "Atlantic/Reykjavik",
    offset: 0,
    label: "Atlantic/Reykjavik",
    utc: "UTC",
  },
  {
    value: "Atlantic/St_Helena",
    offset: 0,
    label: "Atlantic/St Helena",
    utc: "UTC",
  },
  {
    divider: true,
  },
  {
    header: "W. Europe Standard Time (WEDT)",
  },
  {
    value: "Arctic/Longyearbyen",
    offset: 2,
    label: "Arctic/Longyearbyen",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Amsterdam",
    offset: 2,
    label: "Europe/Amsterdam",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Andorra",
    offset: 2,
    label: "Europe/Andorra",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Berlin",
    offset: 2,
    label: "Europe/Berlin",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Busingen",
    offset: 2,
    label: "Europe/Busingen",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Gibraltar",
    offset: 2,
    label: "Europe/Gibraltar",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Luxembourg",
    offset: 2,
    label: "Europe/Luxembourg",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Malta",
    offset: 2,
    label: "Europe/Malta",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Monaco",
    offset: 2,
    label: "Europe/Monaco",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Oslo",
    offset: 2,
    label: "Europe/Oslo",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Rome",
    offset: 2,
    label: "Europe/Rome",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/San_Marino",
    offset: 2,
    label: "Europe/San Marino",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Stockholm",
    offset: 2,
    label: "Europe/Stockholm",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Vaduz",
    offset: 2,
    label: "Europe/Vaduz",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Vatican",
    offset: 2,
    label: "Europe/Vatican",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Vienna",
    offset: 2,
    label: "Europe/Vienna",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Zurich",
    offset: 2,
    label: "Europe/Zurich",
    utc: "UTC+01:00",
  },
  {
    divider: true,
  },
  {
    header: "Central Europe Standard Time (CEDT)",
  },
  {
    value: "Europe/Belgrade",
    offset: 2,
    label: "Europe/Belgrade",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Bratislava",
    offset: 2,
    label: "Europe/Bratislava",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Budapest",
    offset: 2,
    label: "Europe/Budapest",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Ljubljana",
    offset: 2,
    label: "Europe/Ljubljana",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Podgorica",
    offset: 2,
    label: "Europe/Podgorica",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Prague",
    offset: 2,
    label: "Europe/Prague",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Tirane",
    offset: 2,
    label: "Europe/Tirane",
    utc: "UTC+01:00",
  },
  {
    divider: true,
  },
  {
    header: "Romance Standard Time (RDT)",
  },
  {
    value: "Africa/Ceuta",
    offset: 2,
    label: "Africa/Ceuta",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Brussels",
    offset: 2,
    label: "Europe/Brussels",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Copenhagen",
    offset: 2,
    label: "Europe/Copenhagen",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Madrid",
    offset: 2,
    label: "Europe/Madrid",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Paris",
    offset: 2,
    label: "Europe/Paris",
    utc: "UTC+01:00",
  },
  {
    divider: true,
  },
  {
    header: "Central European Standard Time (CEDT)",
  },
  {
    value: "Europe/Sarajevo",
    offset: 2,
    label: "Europe/Sarajevo",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Skopje",
    offset: 2,
    label: "Europe/Skopje",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Warsaw",
    offset: 2,
    label: "Europe/Warsaw",
    utc: "UTC+01:00",
  },
  {
    value: "Europe/Zagreb",
    offset: 2,
    label: "Europe/Zagreb",
    utc: "UTC+01:00",
  },
  {
    divider: true,
  },
  {
    header: "W. Central Africa Standard Time (WCAST)",
  },
  {
    value: "Africa/Algiers",
    offset: 1,
    label: "Africa/Algiers",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Bangui",
    offset: 1,
    label: "Africa/Bangui",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Brazzaville",
    offset: 1,
    label: "Africa/Brazzaville",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Douala",
    offset: 1,
    label: "Africa/Douala",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Kinshasa",
    offset: 1,
    label: "Africa/Kinshasa",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Lagos",
    offset: 1,
    label: "Africa/Lagos",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Libreville",
    offset: 1,
    label: "Africa/Libreville",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Luanda",
    offset: 1,
    label: "Africa/Luanda",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Malabo",
    offset: 1,
    label: "Africa/Malabo",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Ndjamena",
    offset: 1,
    label: "Africa/Ndjamena",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Niamey",
    offset: 1,
    label: "Africa/Niamey",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Porto-Novo",
    offset: 1,
    label: "Africa/Porto-Novo",
    utc: "UTC+01:00",
  },
  {
    value: "Africa/Tunis",
    offset: 1,
    label: "Africa/Tunis",
    utc: "UTC+01:00",
  },
  {
    value: "Etc/GMT-1",
    offset: 1,
    label: "Etc/GMT-1",
    utc: "UTC+01:00",
  },
  {
    divider: true,
  },
  {
    header: "Namibia Standard Time (NST)",
  },
  {
    value: "Africa/Windhoek",
    offset: 1,
    label: "Africa/Windhoek",
    utc: "UTC+01:00",
  },
  {
    divider: true,
  },
  {
    header: "GTB Standard Time (GDT)",
  },
  {
    value: "Asia/Nicosia",
    offset: 3,
    label: "Asia/Nicosia",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Athens",
    offset: 3,
    label: "Europe/Athens",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Bucharest",
    offset: 3,
    label: "Europe/Bucharest",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Chisinau",
    offset: 3,
    label: "Europe/Chisinau",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "Middle East Standard Time (MEDT)",
  },
  {
    value: "Asia/Beirut",
    offset: 3,
    label: "Asia/Beirut",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "Egypt Standard Time (EST)",
  },
  {
    value: "Africa/Cairo",
    offset: 2,
    label: "Africa/Cairo",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "Syria Standard Time (SDT)",
  },
  {
    value: "Asia/Damascus",
    offset: 3,
    label: "Asia/Damascus",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "E. Europe Standard Time (EEDT)",
  },
  {
    value: "Asia/Nicosia",
    offset: 3,
    label: "Asia/Nicosia",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Athens",
    offset: 3,
    label: "Europe/Athens",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Bucharest",
    offset: 3,
    label: "Europe/Bucharest",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Chisinau",
    offset: 3,
    label: "Europe/Chisinau",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Helsinki",
    offset: 3,
    label: "Europe/Helsinki",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Kiev",
    offset: 3,
    label: "Europe/Kiev",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Mariehamn",
    offset: 3,
    label: "Europe/Mariehamn",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Nicosia",
    offset: 3,
    label: "Europe/Nicosia",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Riga",
    offset: 3,
    label: "Europe/Riga",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Sofia",
    offset: 3,
    label: "Europe/Sofia",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Tallinn",
    offset: 3,
    label: "Europe/Tallinn",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Uzhgorod",
    offset: 3,
    label: "Europe/Uzhgorod",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Vilnius",
    offset: 3,
    label: "Europe/Vilnius",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Zaporozhye",
    offset: 3,
    label: "Europe/Zaporozhye",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "South Africa Standard Time (SAST)",
  },
  {
    value: "Africa/Blantyre",
    offset: 2,
    label: "Africa/Blantyre",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Bujumbura",
    offset: 2,
    label: "Africa/Bujumbura",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Gaborone",
    offset: 2,
    label: "Africa/Gaborone",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Harare",
    offset: 2,
    label: "Africa/Harare",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Johannesburg",
    offset: 2,
    label: "Africa/Johannesburg",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Kigali",
    offset: 2,
    label: "Africa/Kigali",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Lubumbashi",
    offset: 2,
    label: "Africa/Lubumbashi",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Lusaka",
    offset: 2,
    label: "Africa/Lusaka",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Maputo",
    offset: 2,
    label: "Africa/Maputo",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Maseru",
    offset: 2,
    label: "Africa/Maseru",
    utc: "UTC+02:00",
  },
  {
    value: "Africa/Mbabane",
    offset: 2,
    label: "Africa/Mbabane",
    utc: "UTC+02:00",
  },
  {
    value: "Etc/GMT-2",
    offset: 2,
    label: "Etc/GMT-2",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "FLE Standard Time (FDT)",
  },
  {
    value: "Europe/Helsinki",
    offset: 3,
    label: "Europe/Helsinki",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Kiev",
    offset: 3,
    label: "Europe/Kiev",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Mariehamn",
    offset: 3,
    label: "Europe/Mariehamn",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Riga",
    offset: 3,
    label: "Europe/Riga",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Sofia",
    offset: 3,
    label: "Europe/Sofia",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Tallinn",
    offset: 3,
    label: "Europe/Tallinn",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Uzhgorod",
    offset: 3,
    label: "Europe/Uzhgorod",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Vilnius",
    offset: 3,
    label: "Europe/Vilnius",
    utc: "UTC+02:00",
  },
  {
    value: "Europe/Zaporozhye",
    offset: 3,
    label: "Europe/Zaporozhye",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "Turkey Standard Time (TDT)",
  },
  {
    value: "Europe/Istanbul",
    offset: 3,
    label: "Europe/Istanbul",
    utc: "UTC+03:00",
  },
  {
    divider: true,
  },
  {
    header: "Israel Standard Time (JDT)",
  },
  {
    value: "Asia/Jerusalem",
    offset: 3,
    label: "Asia/Jerusalem",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "Libya Standard Time (LST)",
  },
  {
    value: "Africa/Tripoli",
    offset: 2,
    label: "Africa/Tripoli",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "Jordan Standard Time (JST)",
  },
  {
    value: "Asia/Amman",
    offset: 3,
    label: "Asia/Amman",
    utc: "UTC+03:00",
  },
  {
    divider: true,
  },
  {
    header: "Arabic Standard Time (AST)",
  },
  {
    value: "Asia/Baghdad",
    offset: 3,
    label: "Asia/Baghdad",
    utc: "UTC+03:00",
  },
  {
    divider: true,
  },
  {
    header: "Kaliningrad Standard Time (KST)",
  },
  {
    value: "Europe/Kaliningrad",
    offset: 3,
    label: "Europe/Kaliningrad",
    utc: "UTC+02:00",
  },
  {
    divider: true,
  },
  {
    header: "Arab Standard Time (AST)",
  },
  {
    value: "Asia/Aden",
    offset: 3,
    label: "Asia/Aden",
    utc: "UTC+03:00",
  },
  {
    value: "Asia/Bahrain",
    offset: 3,
    label: "Asia/Bahrain",
    utc: "UTC+03:00",
  },
  {
    value: "Asia/Kuwait",
    offset: 3,
    label: "Asia/Kuwait",
    utc: "UTC+03:00",
  },
  {
    value: "Asia/Qatar",
    offset: 3,
    label: "Asia/Qatar",
    utc: "UTC+03:00",
  },
  {
    value: "Asia/Riyadh",
    offset: 3,
    label: "Asia/Riyadh",
    utc: "UTC+03:00",
  },
  {
    divider: true,
  },
  {
    header: "E. Africa Standard Time (EAST)",
  },
  {
    value: "Africa/Addis_Ababa",
    offset: 3,
    label: "Africa/Addis Ababa",
    utc: "UTC+03:00",
  },
  {
    value: "Africa/Asmera",
    offset: 3,
    label: "Africa/Asmera",
    utc: "UTC+03:00",
  },
  {
    value: "Africa/Dar_es_Salaam",
    offset: 3,
    label: "Africa/Dar es Salaam",
    utc: "UTC+03:00",
  },
  {
    value: "Africa/Djibouti",
    offset: 3,
    label: "Africa/Djibouti",
    utc: "UTC+03:00",
  },
  {
    value: "Africa/Juba",
    offset: 3,
    label: "Africa/Juba",
    utc: "UTC+03:00",
  },
  {
    value: "Africa/Kampala",
    offset: 3,
    label: "Africa/Kampala",
    utc: "UTC+03:00",
  },
  {
    value: "Africa/Khartoum",
    offset: 3,
    label: "Africa/Khartoum",
    utc: "UTC+03:00",
  },
  {
    value: "Africa/Mogadishu",
    offset: 3,
    label: "Africa/Mogadishu",
    utc: "UTC+03:00",
  },
  {
    value: "Africa/Nairobi",
    offset: 3,
    label: "Africa/Nairobi",
    utc: "UTC+03:00",
  },
  {
    value: "Antarctica/Syowa",
    offset: 3,
    label: "Antarctica/Syowa",
    utc: "UTC+03:00",
  },
  {
    value: "Etc/GMT-3",
    offset: 3,
    label: "Etc/GMT-3",
    utc: "UTC+03:00",
  },
  {
    value: "Indian/Antananarivo",
    offset: 3,
    label: "Indian/Antananarivo",
    utc: "UTC+03:00",
  },
  {
    value: "Indian/Comoro",
    offset: 3,
    label: "Indian/Comoro",
    utc: "UTC+03:00",
  },
  {
    value: "Indian/Mayotte",
    offset: 3,
    label: "Indian/Mayotte",
    utc: "UTC+03:00",
  },
  {
    divider: true,
  },
  {
    header: "Moscow Standard Time (MSK)",
  },
  {
    value: "Europe/Kirov",
    offset: 3,
    label: "Europe/Kirov",
    utc: "UTC+03:00",
  },
  {
    value: "Europe/Moscow",
    offset: 3,
    label: "Europe/Moscow",
    utc: "UTC+03:00",
  },
  {
    value: "Europe/Simferopol",
    offset: 3,
    label: "Europe/Simferopol",
    utc: "UTC+03:00",
  },
  {
    value: "Europe/Volgograd",
    offset: 3,
    label: "Europe/Volgograd",
    utc: "UTC+03:00",
  },
  {
    value: "Europe/Minsk",
    offset: 3,
    label: "Europe/Minsk",
    utc: "UTC+03:00",
  },
  {
    divider: true,
  },
  {
    header: "Samara Time (SAMT)",
  },
  {
    value: "Europe/Astrakhan",
    offset: 4,
    label: "Europe/Astrakhan",
    utc: "UTC+04:00",
  },
  {
    value: "Europe/Samara",
    offset: 4,
    label: "Europe/Samara",
    utc: "UTC+04:00",
  },
  {
    value: "Europe/Ulyanovsk",
    offset: 4,
    label: "Europe/Ulyanovsk",
    utc: "UTC+04:00",
  },
  {
    divider: true,
  },
  {
    header: "Iran Standard Time (IDT)",
  },
  {
    value: "Asia/Tehran",
    offset: 4.5,
    label: "Asia/Tehran",
    utc: "UTC+03:30",
  },
  {
    divider: true,
  },
  {
    header: "Arabian Standard Time (AST)",
  },
  {
    value: "Asia/Dubai",
    offset: 4,
    label: "Asia/Dubai",
    utc: "UTC+04:00",
  },
  {
    value: "Asia/Muscat",
    offset: 4,
    label: "Asia/Muscat",
    utc: "UTC+04:00",
  },
  {
    value: "Etc/GMT-4",
    offset: 4,
    label: "Etc/GMT-4",
    utc: "UTC+04:00",
  },
  {
    divider: true,
  },
  {
    header: "Azerbaijan Standard Time (ADT)",
  },
  {
    value: "Asia/Baku",
    offset: 5,
    label: "Asia/Baku",
    utc: "UTC+04:00",
  },
  {
    divider: true,
  },
  {
    header: "Mauritius Standard Time (MST)",
  },
  {
    value: "Indian/Mahe",
    offset: 4,
    label: "Indian/Mahe",
    utc: "UTC+04:00",
  },
  {
    value: "Indian/Mauritius",
    offset: 4,
    label: "Indian/Mauritius",
    utc: "UTC+04:00",
  },
  {
    value: "Indian/Reunion",
    offset: 4,
    label: "Indian/Reunion",
    utc: "UTC+04:00",
  },
  {
    divider: true,
  },
  {
    header: "Georgian Standard Time (GET)",
  },
  {
    value: "Asia/Tbilisi",
    offset: 4,
    label: "Asia/Tbilisi",
    utc: "UTC+04:00",
  },
  {
    divider: true,
  },
  {
    header: "Caucasus Standard Time (CST)",
  },
  {
    value: "Asia/Yerevan",
    offset: 4,
    label: "Asia/Yerevan",
    utc: "UTC+04:00",
  },
  {
    divider: true,
  },
  {
    header: "Afghanistan Standard Time (AST)",
  },
  {
    value: "Asia/Kabul",
    offset: 4.5,
    label: "Asia/Kabul",
    utc: "UTC+04:30",
  },
  {
    divider: true,
  },
  {
    header: "West Asia Standard Time (WAST)",
  },
  {
    value: "Antarctica/Mawson",
    offset: 5,
    label: "Antarctica/Mawson",
    utc: "UTC+05:00",
  },
  {
    value: "Asia/Aqtau",
    offset: 5,
    label: "Asia/Aqtau",
    utc: "UTC+05:00",
  },
  {
    value: "Asia/Aqtobe",
    offset: 5,
    label: "Asia/Aqtobe",
    utc: "UTC+05:00",
  },
  {
    value: "Asia/Ashgabat",
    offset: 5,
    label: "Asia/Ashgabat",
    utc: "UTC+05:00",
  },
  {
    value: "Asia/Dushanbe",
    offset: 5,
    label: "Asia/Dushanbe",
    utc: "UTC+05:00",
  },
  {
    value: "Asia/Oral",
    offset: 5,
    label: "Asia/Oral",
    utc: "UTC+05:00",
  },
  {
    value: "Asia/Samarkand",
    offset: 5,
    label: "Asia/Samarkand",
    utc: "UTC+05:00",
  },
  {
    value: "Asia/Tashkent",
    offset: 5,
    label: "Asia/Tashkent",
    utc: "UTC+05:00",
  },
  {
    value: "Etc/GMT-5",
    offset: 5,
    label: "Etc/GMT-5",
    utc: "UTC+05:00",
  },
  {
    value: "Indian/Kerguelen",
    offset: 5,
    label: "Indian/Kerguelen",
    utc: "UTC+05:00",
  },
  {
    value: "Indian/Maldives",
    offset: 5,
    label: "Indian/Maldives",
    utc: "UTC+05:00",
  },
  {
    divider: true,
  },
  {
    header: "Yekaterinburg Time (YEKT)",
  },
  {
    value: "Asia/Yekaterinburg",
    offset: 5,
    label: "Asia/Yekaterinburg",
    utc: "UTC+05:00",
  },
  {
    divider: true,
  },
  {
    header: "Pakistan Standard Time (PKT)",
  },
  {
    value: "Asia/Karachi",
    offset: 5,
    label: "Asia/Karachi",
    utc: "UTC+05:00",
  },
  {
    divider: true,
  },
  {
    header: "India Standard Time (IST)",
  },
  {
    value: "Asia/Kolkata",
    offset: 5.5,
    label: "Asia/Kolkata",
    utc: "UTC+05:30",
  },
  {
    value: "Asia/Calcutta",
    offset: 5.5,
    label: "Asia/Calcutta",
    utc: "UTC+05:30",
  },
  {
    divider: true,
  },
  {
    header: "Sri Lanka Standard Time (SLST)",
  },
  {
    value: "Asia/Colombo",
    offset: 5.5,
    label: "Asia/Colombo",
    utc: "UTC+05:30",
  },
  {
    divider: true,
  },
  {
    header: "Nepal Standard Time (NST)",
  },
  {
    value: "Asia/Kathmandu",
    offset: 5.75,
    label: "Asia/Kathmandu",
    utc: "UTC+05:45",
  },
  {
    divider: true,
  },
  {
    header: "Central Asia Standard Time (CAST)",
  },
  {
    value: "Antarctica/Vostok",
    offset: 6,
    label: "Antarctica/Vostok",
    utc: "UTC+06:00",
  },
  {
    value: "Asia/Almaty",
    offset: 6,
    label: "Asia/Almaty",
    utc: "UTC+06:00",
  },
  {
    value: "Asia/Bishkek",
    offset: 6,
    label: "Asia/Bishkek",
    utc: "UTC+06:00",
  },
  {
    value: "Asia/Qyzylorda",
    offset: 6,
    label: "Asia/Qyzylorda",
    utc: "UTC+06:00",
  },
  {
    value: "Asia/Urumqi",
    offset: 6,
    label: "Asia/Urumqi",
    utc: "UTC+06:00",
  },
  {
    value: "Etc/GMT-6",
    offset: 6,
    label: "Etc/GMT-6",
    utc: "UTC+06:00",
  },
  {
    value: "Indian/Chagos",
    offset: 6,
    label: "Indian/Chagos",
    utc: "UTC+06:00",
  },
  {
    divider: true,
  },
  {
    header: "Bangladesh Standard Time (BST)",
  },
  {
    value: "Asia/Dhaka",
    offset: 6,
    label: "Asia/Dhaka",
    utc: "UTC+06:00",
  },
  {
    value: "Asia/Thimphu",
    offset: 6,
    label: "Asia/Thimphu",
    utc: "UTC+06:00",
  },
  {
    divider: true,
  },
  {
    header: "Myanmar Standard Time (MST)",
  },
  {
    value: "Asia/Rangoon",
    offset: 6.5,
    label: "Asia/Rangoon",
    utc: "UTC+06:30",
  },
  {
    value: "Indian/Cocos",
    offset: 6.5,
    label: "Indian/Cocos",
    utc: "UTC+06:30",
  },
  {
    divider: true,
  },
  {
    header: "SE Asia Standard Time (SAST)",
  },
  {
    value: "Antarctica/Davis",
    offset: 7,
    label: "Antarctica/Davis",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Bangkok",
    offset: 7,
    label: "Asia/Bangkok",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Hovd",
    offset: 7,
    label: "Asia/Hovd",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Jakarta",
    offset: 7,
    label: "Asia/Jakarta",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Phnom_Penh",
    offset: 7,
    label: "Asia/Phnom Penh",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Pontianak",
    offset: 7,
    label: "Asia/Pontianak",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Saigon",
    offset: 7,
    label: "Asia/Saigon",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Vientiane",
    offset: 7,
    label: "Asia/Vientiane",
    utc: "UTC+07:00",
  },
  {
    value: "Etc/GMT-7",
    offset: 7,
    label: "Etc/GMT-7",
    utc: "UTC+07:00",
  },
  {
    value: "Indian/Christmas",
    offset: 7,
    label: "Indian/Christmas",
    utc: "UTC+07:00",
  },
  {
    divider: true,
  },
  {
    header: "N. Central Asia Standard Time (NCAST)",
  },
  {
    value: "Asia/Novokuznetsk",
    offset: 7,
    label: "Asia/Novokuznetsk",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Novosibirsk",
    offset: 7,
    label: "Asia/Novosibirsk",
    utc: "UTC+07:00",
  },
  {
    value: "Asia/Omsk",
    offset: 7,
    label: "Asia/Omsk",
    utc: "UTC+07:00",
  },
  {
    divider: true,
  },
  {
    header: "China Standard Time (CST)",
  },
  {
    value: "Asia/Hong_Kong",
    offset: 8,
    label: "Asia/Hong Kong",
    utc: "UTC+08:00",
  },
  {
    value: "Asia/Macau",
    offset: 8,
    label: "Asia/Macau",
    utc: "UTC+08:00",
  },
  {
    value: "Asia/Shanghai",
    offset: 8,
    label: "Asia/Shanghai",
    utc: "UTC+08:00",
  },
  {
    divider: true,
  },
  {
    header: "North Asia Standard Time (NAST)",
  },
  {
    value: "Asia/Krasnoyarsk",
    offset: 8,
    label: "Asia/Krasnoyarsk",
    utc: "UTC+08:00",
  },
  {
    divider: true,
  },
  {
    header: "Singapore Standard Time (MPST)",
  },
  {
    value: "Asia/Brunei",
    offset: 8,
    label: "Asia/Brunei",
    utc: "UTC+08:00",
  },
  {
    value: "Asia/Kuala_Lumpur",
    offset: 8,
    label: "Asia/Kuala Lumpur",
    utc: "UTC+08:00",
  },
  {
    value: "Asia/Kuching",
    offset: 8,
    label: "Asia/Kuching",
    utc: "UTC+08:00",
  },
  {
    value: "Asia/Makassar",
    offset: 8,
    label: "Asia/Makassar",
    utc: "UTC+08:00",
  },
  {
    value: "Asia/Manila",
    offset: 8,
    label: "Asia/Manila",
    utc: "UTC+08:00",
  },
  {
    value: "Asia/Singapore",
    offset: 8,
    label: "Asia/Singapore",
    utc: "UTC+08:00",
  },
  {
    value: "Etc/GMT-8",
    offset: 8,
    label: "Etc/GMT-8",
    utc: "UTC+08:00",
  },
  {
    divider: true,
  },
  {
    header: "W. Australia Standard Time (WAST)",
  },
  {
    value: "Antarctica/Casey",
    offset: 8,
    label: "Antarctica/Casey",
    utc: "UTC+08:00",
  },
  {
    value: "Australia/Perth",
    offset: 8,
    label: "Australia/Perth",
    utc: "UTC+08:00",
  },
  {
    divider: true,
  },
  {
    header: "Taipei Standard Time (TST)",
  },
  {
    value: "Asia/Taipei",
    offset: 8,
    label: "Asia/Taipei",
    utc: "UTC+08:00",
  },
  {
    divider: true,
  },
  {
    header: "Ulaanbaatar Standard Time (UST)",
  },
  {
    value: "Asia/Choibalsan",
    offset: 8,
    label: "Asia/Choibalsan",
    utc: "UTC+08:00",
  },
  {
    value: "Asia/Ulaanbaatar",
    offset: 8,
    label: "Asia/Ulaanbaatar",
    utc: "UTC+08:00",
  },
  {
    divider: true,
  },
  {
    header: "North Asia East Standard Time (NAEST)",
  },
  {
    value: "Asia/Irkutsk",
    offset: 8,
    label: "Asia/Irkutsk",
    utc: "UTC+08:00",
  },
  {
    divider: true,
  },
  {
    header: "Japan Standard Time (JST)",
  },
  {
    value: "Asia/Dili",
    offset: 9,
    label: "Asia/Dili",
    utc: "UTC+09:00",
  },
  {
    value: "Asia/Jayapura",
    offset: 9,
    label: "Asia/Jayapura",
    utc: "UTC+09:00",
  },
  {
    value: "Asia/Tokyo",
    offset: 9,
    label: "Asia/Tokyo",
    utc: "UTC+09:00",
  },
  {
    value: "Etc/GMT-9",
    offset: 9,
    label: "Etc/GMT-9",
    utc: "UTC+09:00",
  },
  {
    value: "Pacific/Palau",
    offset: 9,
    label: "Pacific/Palau",
    utc: "UTC+09:00",
  },
  {
    divider: true,
  },
  {
    header: "Korea Standard Time (KST)",
  },
  {
    value: "Asia/Pyongyang",
    offset: 9,
    label: "Asia/Pyongyang",
    utc: "UTC+09:00",
  },
  {
    value: "Asia/Seoul",
    offset: 9,
    label: "Asia/Seoul",
    utc: "UTC+09:00",
  },
  {
    divider: true,
  },
  {
    header: "Cen. Australia Standard Time (CAST)",
  },
  {
    value: "Australia/Adelaide",
    offset: 9.5,
    label: "Australia/Adelaide",
    utc: "UTC+09:30",
  },
  {
    value: "Australia/Broken_Hill",
    offset: 9.5,
    label: "Australia/Broken Hill",
    utc: "UTC+09:30",
  },
  {
    divider: true,
  },
  {
    header: "AUS Central Standard Time (ACST)",
  },
  {
    value: "Australia/Darwin",
    offset: 9.5,
    label: "Australia/Darwin",
    utc: "UTC+09:30",
  },
  {
    divider: true,
  },
  {
    header: "E. Australia Standard Time (EAST)",
  },
  {
    value: "Australia/Brisbane",
    offset: 10,
    label: "Australia/Brisbane",
    utc: "UTC+10:00",
  },
  {
    value: "Australia/Lindeman",
    offset: 10,
    label: "Australia/Lindeman",
    utc: "UTC+10:00",
  },
  {
    divider: true,
  },
  {
    header: "AUS Eastern Standard Time (AEST)",
  },
  {
    value: "Australia/Melbourne",
    offset: 10,
    label: "Australia/Melbourne",
    utc: "UTC+10:00",
  },
  {
    value: "Australia/Sydney",
    offset: 10,
    label: "Australia/Sydney",
    utc: "UTC+10:00",
  },
  {
    divider: true,
  },
  {
    header: "West Pacific Standard Time (WPST)",
  },
  {
    value: "Antarctica/DumontDUrville",
    offset: 10,
    label: "Antarctica/DumontDUrville",
    utc: "UTC+10:00",
  },
  {
    value: "Etc/GMT-10",
    offset: 10,
    label: "Etc/GMT-10",
    utc: "UTC+10:00",
  },
  {
    value: "Pacific/Guam",
    offset: 10,
    label: "Pacific/Guam",
    utc: "UTC+10:00",
  },
  {
    value: "Pacific/Port_Moresby",
    offset: 10,
    label: "Pacific/Port Moresby",
    utc: "UTC+10:00",
  },
  {
    value: "Pacific/Saipan",
    offset: 10,
    label: "Pacific/Saipan",
    utc: "UTC+10:00",
  },
  {
    value: "Pacific/Truk",
    offset: 10,
    label: "Pacific/Truk",
    utc: "UTC+10:00",
  },
  {
    divider: true,
  },
  {
    header: "Tasmania Standard Time (TST)",
  },
  {
    value: "Australia/Currie",
    offset: 10,
    label: "Australia/Currie",
    utc: "UTC+10:00",
  },
  {
    value: "Australia/Hobart",
    offset: 10,
    label: "Australia/Hobart",
    utc: "UTC+10:00",
  },
  {
    divider: true,
  },
  {
    header: "Yakutsk Standard Time (YST)",
  },
  {
    value: "Asia/Chita",
    offset: 9,
    label: "Asia/Chita",
    utc: "UTC+09:00",
  },
  {
    value: "Asia/Khandyga",
    offset: 9,
    label: "Asia/Khandyga",
    utc: "UTC+09:00",
  },
  {
    value: "Asia/Yakutsk",
    offset: 9,
    label: "Asia/Yakutsk",
    utc: "UTC+09:00",
  },
  {
    divider: true,
  },
  {
    header: "Central Pacific Standard Time (CPST)",
  },
  {
    value: "Antarctica/Macquarie",
    offset: 11,
    label: "Antarctica/Macquarie",
    utc: "UTC+11:00",
  },
  {
    value: "Etc/GMT-11",
    offset: 11,
    label: "Etc/GMT-11",
    utc: "UTC+11:00",
  },
  {
    value: "Pacific/Efate",
    offset: 11,
    label: "Pacific/Efate",
    utc: "UTC+11:00",
  },
  {
    value: "Pacific/Guadalcanal",
    offset: 11,
    label: "Pacific/Guadalcanal",
    utc: "UTC+11:00",
  },
  {
    value: "Pacific/Kosrae",
    offset: 11,
    label: "Pacific/Kosrae",
    utc: "UTC+11:00",
  },
  {
    value: "Pacific/Noumea",
    offset: 11,
    label: "Pacific/Noumea",
    utc: "UTC+11:00",
  },
  {
    value: "Pacific/Ponape",
    offset: 11,
    label: "Pacific/Ponape",
    utc: "UTC+11:00",
  },
  {
    divider: true,
  },
  {
    header: "Vladivostok Standard Time (VST)",
  },
  {
    value: "Asia/Sakhalin",
    offset: 11,
    label: "Asia/Sakhalin",
    utc: "UTC+11:00",
  },
  {
    value: "Asia/Ust-Nera",
    offset: 11,
    label: "Asia/Ust-Nera",
    utc: "UTC+11:00",
  },
  {
    value: "Asia/Vladivostok",
    offset: 11,
    label: "Asia/Vladivostok",
    utc: "UTC+11:00",
  },
  {
    divider: true,
  },
  {
    header: "New Zealand Standard Time (NZST)",
  },
  {
    value: "Antarctica/McMurdo",
    offset: 12,
    label: "Antarctica/McMurdo",
    utc: "UTC+12:00",
  },
  {
    value: "Pacific/Auckland",
    offset: 12,
    label: "Pacific/Auckland",
    utc: "UTC+12:00",
  },
  {
    divider: true,
  },
  {
    header: "UTC+12 (U)",
  },
  {
    value: "Etc/GMT-12",
    offset: 12,
    label: "Etc/GMT-12",
    utc: "UTC+12:00",
  },
  {
    value: "Pacific/Funafuti",
    offset: 12,
    label: "Pacific/Funafuti",
    utc: "UTC+12:00",
  },
  {
    value: "Pacific/Kwajalein",
    offset: 12,
    label: "Pacific/Kwajalein",
    utc: "UTC+12:00",
  },
  {
    value: "Pacific/Majuro",
    offset: 12,
    label: "Pacific/Majuro",
    utc: "UTC+12:00",
  },
  {
    value: "Pacific/Nauru",
    offset: 12,
    label: "Pacific/Nauru",
    utc: "UTC+12:00",
  },
  {
    value: "Pacific/Tarawa",
    offset: 12,
    label: "Pacific/Tarawa",
    utc: "UTC+12:00",
  },
  {
    value: "Pacific/Wake",
    offset: 12,
    label: "Pacific/Wake",
    utc: "UTC+12:00",
  },
  {
    value: "Pacific/Wallis",
    offset: 12,
    label: "Pacific/Wallis",
    utc: "UTC+12:00",
  },
  {
    divider: true,
  },
  {
    header: "Fiji Standard Time (FST)",
  },
  {
    value: "Pacific/Fiji",
    offset: 12,
    label: "Pacific/Fiji",
    utc: "UTC+12:00",
  },
  {
    divider: true,
  },
  {
    header: "Magadan Standard Time (MST)",
  },
  {
    value: "Asia/Anadyr",
    offset: 12,
    label: "Asia/Anadyr",
    utc: "UTC+12:00",
  },
  {
    value: "Asia/Kamchatka",
    offset: 12,
    label: "Asia/Kamchatka",
    utc: "UTC+12:00",
  },
  {
    value: "Asia/Magadan",
    offset: 12,
    label: "Asia/Magadan",
    utc: "UTC+12:00",
  },
  {
    value: "Asia/Srednekolymsk",
    offset: 12,
    label: "Asia/Srednekolymsk",
    utc: "UTC+12:00",
  },
  {
    divider: true,
  },
  {
    header: "Kamchatka Standard Time (KDT)",
  },
  {
    value: "Asia/Kamchatka",
    offset: 13,
    label: "Asia/Kamchatka",
    utc: "UTC+12:00",
  },
  {
    divider: true,
  },
  {
    header: "Tonga Standard Time (TST)",
  },
  {
    value: "Etc/GMT-13",
    offset: 13,
    label: "Etc/GMT-13",
    utc: "UTC+13:00",
  },
  {
    value: "Pacific/Enderbury",
    offset: 13,
    label: "Pacific/Enderbury",
    utc: "UTC+13:00",
  },
  {
    value: "Pacific/Fakaofo",
    offset: 13,
    label: "Pacific/Fakaofo",
    utc: "UTC+13:00",
  },
  {
    value: "Pacific/Tongatapu",
    offset: 13,
    label: "Pacific/Tongatapu",
    utc: "UTC+13:00",
  },
  {
    divider: true,
  },
  {
    header: "Samoa Standard Time (SST)",
  },
  {
    value: "Pacific/Apia",
    offset: 13,
    label: "Pacific/Apia",
    utc: "UTC+13:00",
  },
  {
    divider: true,
  },
];

/* https://github.com/dmfilipenko/timezones.json/blob/master/timezones.json */
export const TIMEZONES_RAW = [
  {
    value: "Dateline Standard Time",
    abbr: "DST",
    offset: -12,
    isdst: false,
    text: "(UTC-12:00) International Date Line West",
    utc: ["Etc/GMT+12"],
  },
  {
    value: "UTC-11",
    abbr: "U",
    offset: -11,
    isdst: false,
    text: "(UTC-11:00) Coordinated Universal Time-11",
    utc: ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"],
  },
  {
    value: "Hawaiian Standard Time",
    abbr: "HST",
    offset: -10,
    isdst: false,
    text: "(UTC-10:00) Hawaii",
    utc: [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti",
    ],
  },
  {
    value: "Alaskan Standard Time",
    abbr: "AKDT",
    offset: -8,
    isdst: true,
    text: "(UTC-09:00) Alaska",
    utc: [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat",
    ],
  },
  {
    value: "Pacific Standard Time (Mexico)",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-08:00) Baja California",
    utc: ["America/Santa_Isabel"],
  },
  {
    value: "Pacific Daylight Time",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-07:00) Pacific Time (US & Canada)",
    utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver"],
  },
  {
    value: "Pacific Standard Time",
    abbr: "PST",
    offset: -8,
    isdst: false,
    text: "(UTC-08:00) Pacific Time (US & Canada)",
    utc: [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT",
    ],
  },
  {
    value: "US Mountain Standard Time",
    abbr: "UMST",
    offset: -7,
    isdst: false,
    text: "(UTC-07:00) Arizona",
    utc: [
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "America/Whitehorse",
      "Etc/GMT+7",
    ],
  },
  {
    value: "Mountain Standard Time (Mexico)",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    utc: ["America/Chihuahua", "America/Mazatlan"],
  },
  {
    value: "Mountain Standard Time",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Mountain Time (US & Canada)",
    utc: [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT",
    ],
  },
  {
    value: "Central America Standard Time",
    abbr: "CAST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Central America",
    utc: [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos",
    ],
  },
  {
    value: "Central Standard Time",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Central Time (US & Canada)",
    utc: [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT",
    ],
  },
  {
    value: "Central Standard Time (Mexico)",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    utc: [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
    ],
  },
  {
    value: "Canada Central Standard Time",
    abbr: "CCST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Saskatchewan",
    utc: ["America/Regina", "America/Swift_Current"],
  },
  {
    value: "SA Pacific Standard Time",
    abbr: "SPST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Bogota, Lima, Quito",
    utc: [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5",
    ],
  },
  {
    value: "Eastern Standard Time",
    abbr: "EDT",
    offset: -4,
    isdst: true,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
      "EST5EDT",
    ],
  },
  {
    value: "US Eastern Standard Time",
    abbr: "UEDT",
    offset: -4,
    isdst: true,
    text: "(UTC-05:00) Indiana (East)",
    utc: [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis",
    ],
  },
  {
    value: "Venezuela Standard Time",
    abbr: "VST",
    offset: -4.5,
    isdst: false,
    text: "(UTC-04:30) Caracas",
    utc: ["America/Caracas"],
  },
  {
    value: "Paraguay Standard Time",
    abbr: "PYT",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Asuncion",
    utc: ["America/Asuncion"],
  },
  {
    value: "Atlantic Standard Time",
    abbr: "ADT",
    offset: -3,
    isdst: true,
    text: "(UTC-04:00) Atlantic Time (Canada)",
    utc: [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda",
    ],
  },
  {
    value: "Central Brazilian Standard Time",
    abbr: "CBST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Cuiaba",
    utc: ["America/Campo_Grande", "America/Cuiaba"],
  },
  {
    value: "SA Western Standard Time",
    abbr: "SWST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    utc: [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4",
    ],
  },
  {
    value: "Pacific SA Standard Time",
    abbr: "PSST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Santiago",
    utc: ["America/Santiago", "Antarctica/Palmer"],
  },
  {
    value: "Newfoundland Standard Time",
    abbr: "NDT",
    offset: -2.5,
    isdst: true,
    text: "(UTC-03:30) Newfoundland",
    utc: ["America/St_Johns"],
  },
  {
    value: "E. South America Standard Time",
    abbr: "ESAST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Brasilia",
    utc: ["America/Sao_Paulo"],
  },
  {
    value: "Argentina Standard Time",
    abbr: "AST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Buenos Aires",
    utc: [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza",
    ],
  },
  {
    value: "SA Eastern Standard Time",
    abbr: "SEST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Cayenne, Fortaleza",
    utc: [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3",
    ],
  },
  {
    value: "Greenland Standard Time",
    abbr: "GDT",
    offset: -3,
    isdst: true,
    text: "(UTC-03:00) Greenland",
    utc: ["America/Godthab"],
  },
  {
    value: "Montevideo Standard Time",
    abbr: "MST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Montevideo",
    utc: ["America/Montevideo"],
  },
  {
    value: "Bahia Standard Time",
    abbr: "BST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Salvador",
    utc: ["America/Bahia"],
  },
  {
    value: "UTC-02",
    abbr: "U",
    offset: -2,
    isdst: false,
    text: "(UTC-02:00) Coordinated Universal Time-02",
    utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"],
  },
  {
    value: "Mid-Atlantic Standard Time",
    abbr: "MDT",
    offset: -1,
    isdst: true,
    text: "(UTC-02:00) Mid-Atlantic - Old",
    utc: [],
  },
  {
    value: "Azores Standard Time",
    abbr: "ADT",
    offset: 0,
    isdst: true,
    text: "(UTC-01:00) Azores",
    utc: ["America/Scoresbysund", "Atlantic/Azores"],
  },
  {
    value: "Cape Verde Standard Time",
    abbr: "CVST",
    offset: -1,
    isdst: false,
    text: "(UTC-01:00) Cape Verde Is.",
    utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"],
  },
  {
    value: "Morocco Standard Time",
    abbr: "MDT",
    offset: 1,
    isdst: true,
    text: "(UTC) Casablanca",
    utc: ["Africa/Casablanca", "Africa/El_Aaiun"],
  },
  {
    value: "UTC",
    abbr: "UTC",
    offset: 0,
    isdst: false,
    text: "(UTC) Coordinated Universal Time",
    utc: ["America/Danmarkshavn", "Etc/GMT"],
  },
  {
    value: "GMT Standard Time",
    abbr: "GMT",
    offset: 0,
    isdst: false,
    text: "(UTC) Edinburgh, London",
    utc: [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London",
    ],
  },
  {
    value: "British Summer Time",
    abbr: "BST",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Edinburgh, London",
    utc: [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London",
    ],
  },
  {
    value: "GMT Standard Time",
    abbr: "GDT",
    offset: 1,
    isdst: true,
    text: "(UTC) Dublin, Lisbon",
    utc: [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon",
    ],
  },
  {
    value: "Greenwich Standard Time",
    abbr: "GST",
    offset: 0,
    isdst: false,
    text: "(UTC) Monrovia, Reykjavik",
    utc: [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena",
    ],
  },
  {
    value: "W. Europe Standard Time",
    abbr: "WEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    utc: [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich",
    ],
  },
  {
    value: "Central Europe Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    utc: [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane",
    ],
  },
  {
    value: "Romance Standard Time",
    abbr: "RDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    utc: [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris",
    ],
  },
  {
    value: "Central European Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    utc: ["Europe/Sarajevo", "Europe/Skopje", "Europe/Warsaw", "Europe/Zagreb"],
  },
  {
    value: "W. Central Africa Standard Time",
    abbr: "WCAST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) West Central Africa",
    utc: [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1",
    ],
  },
  {
    value: "Namibia Standard Time",
    abbr: "NST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) Windhoek",
    utc: ["Africa/Windhoek"],
  },
  {
    value: "GTB Standard Time",
    abbr: "GDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Athens, Bucharest",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
    ],
  },
  {
    value: "Middle East Standard Time",
    abbr: "MEDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Beirut",
    utc: ["Asia/Beirut"],
  },
  {
    value: "Egypt Standard Time",
    abbr: "EST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Cairo",
    utc: ["Africa/Cairo"],
  },
  {
    value: "Syria Standard Time",
    abbr: "SDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Damascus",
    utc: ["Asia/Damascus"],
  },
  {
    value: "E. Europe Standard Time",
    abbr: "EEDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) E. Europe",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye",
    ],
  },
  {
    value: "South Africa Standard Time",
    abbr: "SAST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Harare, Pretoria",
    utc: [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2",
    ],
  },
  {
    value: "FLE Standard Time",
    abbr: "FDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    utc: [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye",
    ],
  },
  {
    value: "Turkey Standard Time",
    abbr: "TDT",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Istanbul",
    utc: ["Europe/Istanbul"],
  },
  {
    value: "Israel Standard Time",
    abbr: "JDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Jerusalem",
    utc: ["Asia/Jerusalem"],
  },
  {
    value: "Libya Standard Time",
    abbr: "LST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Tripoli",
    utc: ["Africa/Tripoli"],
  },
  {
    value: "Jordan Standard Time",
    abbr: "JST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Amman",
    utc: ["Asia/Amman"],
  },
  {
    value: "Arabic Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Baghdad",
    utc: ["Asia/Baghdad"],
  },
  {
    value: "Kaliningrad Standard Time",
    abbr: "KST",
    offset: 3,
    isdst: false,
    text: "(UTC+02:00) Kaliningrad",
    utc: ["Europe/Kaliningrad"],
  },
  {
    value: "Arab Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kuwait, Riyadh",
    utc: [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh",
    ],
  },
  {
    value: "E. Africa Standard Time",
    abbr: "EAST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Nairobi",
    utc: [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte",
    ],
  },
  {
    value: "Moscow Standard Time",
    abbr: "MSK",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
    utc: [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd",
      "Europe/Minsk",
    ],
  },
  {
    value: "Samara Time",
    abbr: "SAMT",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"],
  },
  {
    value: "Iran Standard Time",
    abbr: "IDT",
    offset: 4.5,
    isdst: true,
    text: "(UTC+03:30) Tehran",
    utc: ["Asia/Tehran"],
  },
  {
    value: "Arabian Standard Time",
    abbr: "AST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Abu Dhabi, Muscat",
    utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"],
  },
  {
    value: "Azerbaijan Standard Time",
    abbr: "ADT",
    offset: 5,
    isdst: true,
    text: "(UTC+04:00) Baku",
    utc: ["Asia/Baku"],
  },
  {
    value: "Mauritius Standard Time",
    abbr: "MST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Port Louis",
    utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"],
  },
  {
    value: "Georgian Standard Time",
    abbr: "GET",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Tbilisi",
    utc: ["Asia/Tbilisi"],
  },
  {
    value: "Caucasus Standard Time",
    abbr: "CST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Yerevan",
    utc: ["Asia/Yerevan"],
  },
  {
    value: "Afghanistan Standard Time",
    abbr: "AST",
    offset: 4.5,
    isdst: false,
    text: "(UTC+04:30) Kabul",
    utc: ["Asia/Kabul"],
  },
  {
    value: "West Asia Standard Time",
    abbr: "WAST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Ashgabat, Tashkent",
    utc: [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives",
    ],
  },
  {
    value: "Yekaterinburg Time",
    abbr: "YEKT",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Yekaterinburg",
    utc: ["Asia/Yekaterinburg"],
  },
  {
    value: "Pakistan Standard Time",
    abbr: "PKT",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Islamabad, Karachi",
    utc: ["Asia/Karachi"],
  },
  {
    value: "India Standard Time",
    abbr: "IST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    utc: ["Asia/Kolkata", "Asia/Calcutta"],
  },
  {
    value: "Sri Lanka Standard Time",
    abbr: "SLST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Sri Jayawardenepura",
    utc: ["Asia/Colombo"],
  },
  {
    value: "Nepal Standard Time",
    abbr: "NST",
    offset: 5.75,
    isdst: false,
    text: "(UTC+05:45) Kathmandu",
    utc: ["Asia/Kathmandu"],
  },
  {
    value: "Central Asia Standard Time",
    abbr: "CAST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Nur-Sultan (Astana)",
    utc: [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos",
    ],
  },
  {
    value: "Bangladesh Standard Time",
    abbr: "BST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Dhaka",
    utc: ["Asia/Dhaka", "Asia/Thimphu"],
  },
  {
    value: "Myanmar Standard Time",
    abbr: "MST",
    offset: 6.5,
    isdst: false,
    text: "(UTC+06:30) Yangon (Rangoon)",
    utc: ["Asia/Rangoon", "Indian/Cocos"],
  },
  {
    value: "SE Asia Standard Time",
    abbr: "SAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    utc: [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas",
    ],
  },
  {
    value: "N. Central Asia Standard Time",
    abbr: "NCAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Novosibirsk",
    utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"],
  },
  {
    value: "China Standard Time",
    abbr: "CST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"],
  },
  {
    value: "North Asia Standard Time",
    abbr: "NAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Krasnoyarsk",
    utc: ["Asia/Krasnoyarsk"],
  },
  {
    value: "Singapore Standard Time",
    abbr: "MPST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Kuala Lumpur, Singapore",
    utc: [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8",
    ],
  },
  {
    value: "W. Australia Standard Time",
    abbr: "WAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Perth",
    utc: ["Antarctica/Casey", "Australia/Perth"],
  },
  {
    value: "Taipei Standard Time",
    abbr: "TST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Taipei",
    utc: ["Asia/Taipei"],
  },
  {
    value: "Ulaanbaatar Standard Time",
    abbr: "UST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Ulaanbaatar",
    utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
  },
  {
    value: "North Asia East Standard Time",
    abbr: "NAEST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Irkutsk",
    utc: ["Asia/Irkutsk"],
  },
  {
    value: "Japan Standard Time",
    abbr: "JST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    utc: [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau",
    ],
  },
  {
    value: "Korea Standard Time",
    abbr: "KST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Seoul",
    utc: ["Asia/Pyongyang", "Asia/Seoul"],
  },
  {
    value: "Cen. Australia Standard Time",
    abbr: "CAST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Adelaide",
    utc: ["Australia/Adelaide", "Australia/Broken_Hill"],
  },
  {
    value: "AUS Central Standard Time",
    abbr: "ACST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Darwin",
    utc: ["Australia/Darwin"],
  },
  {
    value: "E. Australia Standard Time",
    abbr: "EAST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Brisbane",
    utc: ["Australia/Brisbane", "Australia/Lindeman"],
  },
  {
    value: "AUS Eastern Standard Time",
    abbr: "AEST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Canberra, Melbourne, Sydney",
    utc: ["Australia/Melbourne", "Australia/Sydney"],
  },
  {
    value: "West Pacific Standard Time",
    abbr: "WPST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Guam, Port Moresby",
    utc: [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk",
    ],
  },
  {
    value: "Tasmania Standard Time",
    abbr: "TST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Hobart",
    utc: ["Australia/Currie", "Australia/Hobart"],
  },
  {
    value: "Yakutsk Standard Time",
    abbr: "YST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Yakutsk",
    utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
  },
  {
    value: "Central Pacific Standard Time",
    abbr: "CPST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Solomon Is., New Caledonia",
    utc: [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape",
    ],
  },
  {
    value: "Vladivostok Standard Time",
    abbr: "VST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Vladivostok",
    utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"],
  },
  {
    value: "New Zealand Standard Time",
    abbr: "NZST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Auckland, Wellington",
    utc: ["Antarctica/McMurdo", "Pacific/Auckland"],
  },
  {
    value: "UTC+12",
    abbr: "U",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Coordinated Universal Time+12",
    utc: [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis",
    ],
  },
  {
    value: "Fiji Standard Time",
    abbr: "FST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Fiji",
    utc: ["Pacific/Fiji"],
  },
  {
    value: "Magadan Standard Time",
    abbr: "MST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Magadan",
    utc: [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk",
    ],
  },
  {
    value: "Kamchatka Standard Time",
    abbr: "KDT",
    offset: 13,
    isdst: true,
    text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    utc: ["Asia/Kamchatka"],
  },
  {
    value: "Tonga Standard Time",
    abbr: "TST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Nuku'alofa",
    utc: [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu",
    ],
  },
  {
    value: "Samoa Standard Time",
    abbr: "SST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Samoa",
    utc: ["Pacific/Apia"],
  },
];
