



























































import Vue from "vue";
import { RoomDetail } from "@/models/room";
import CapacityService from "@/services/capacityService";
import CapacityUtil from "../../../utils/capacityUtils";
import { ChartData } from "@/models/analytics";
import CapacityChart from "@/components/dashboard/analytics/CapacityChart.vue";
import { RoomCapacity, CAPACITY_RANGE_DATA } from "@/models/capacity";
import VaInfoRow from "@/components/molecules/VaInfoRow.vue";

export default Vue.extend({
  name: "CapacityPerformance",
  components: { CapacityChart, VaInfoRow },
  data() {
    return {
      range: 10,
      ranges: CAPACITY_RANGE_DATA,
      loading: false,
      capacityChartData: [] as Array<RoomCapacity>,
      capacity: 0,
    };
  },
  async beforeMount() {
    await this.getCapacity();
  },
  computed: {
    room(): RoomDetail | null {
      return this.$store.getters["room/room"];
    },
    capacityDatasets(): ChartData {
      return CapacityUtil.buildDatasets(this.capacityChartData, this.range);
    },
  },
  methods: {
    async getCapacity(silent = false): Promise<void> {
      if (!silent) {
        this.loading = true;
      }
      try {
        if (this.room) {
          this.capacityChartData = await CapacityService.get(
            this.room?.id as string,
            this.range
          );
          this.capacity = CapacityUtil.getMaxPeople(this.capacityChartData);
        }
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "Room Capacity", "getCapacity");
      } finally {
        if (!silent) {
          this.loading = false;
        }
      }
    },
  },
  watch: {
    range: async function (): Promise<void> {
      await this.getCapacity();
    },
  },
});
