<template>
  <div class="chart-container">
    <div class="va-subtitle" v-if="title">{{ title }}</div>
    <va-doughnut
      :chartData="computedData"
      :options="computedOptions"
    ></va-doughnut>
  </div>
</template>
<script>
//todo convert me to TS
import { VaDoughnut } from "@/components/organisms/charts/ChartsWrappers";
import cloneDeep from "lodash/cloneDeep";
import Vue from "vue";

export default Vue.extend({
  components: { VaDoughnut },
  props: {
    title: {
      type: String,
      required: false,
    },
    chartData: {
      required: true,
    },
    chartOptions: {
      required: false,
      default() {
        return {};
      },
    },
    legend: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    computedData() {
      const data = cloneDeep(this.chartData);
      if (data.datasets && data.datasets[0].data) {
        data.datasets = data.datasets.map((dataset) => {
          if (!dataset.backgroundColor) {
            dataset.backgroundColor = dataset.data.map((val, index) => {
              const i = (((index % 10) + 10) % 10) + 1; //loop 10 possible colors
              return this.$vuetify.theme.currentTheme[`chart${i}`];
            });
          }
          if (!dataset.borderColor) {
            dataset.borderColor = dataset.data.map((val, index) => {
              const i = (((index % 10) + 10) % 10) + 1; //loop 10 possible colors
              return this.$vuetify.theme.currentTheme[`chart${i}`];
              // return this.$vuetify.theme.currentTheme.chartBorder;
            });
          }
          dataset.borderWidth = 0;
          return dataset;
        });
      }
      return data;
    },
    computedOptions() {
      const opt = cloneDeep(this.chartOptions);
      opt.responsive = true;
      opt.maintainAspectRatio = true;
      opt.cutoutPercentage = 80;
      opt.legend = {
        display: this.legend,
        position: "bottom",
        labels: {
          usePointStyle: true,
          padding: 16,
          fontColor: this.$vuetify.theme.currentTheme.chartText,
        },
      };
      opt.tooltips = {
        enabled: true,
        backgroundColor: this.$vuetify.theme.currentTheme.navigation,
      };
      return opt;
    },
  },
});
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  width: 100%;
}
</style>
