var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Va-Badge")]),
      _c("h3", [_vm._v("text")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-badge", { attrs: { text: "ok" } }),
          _c("va-badge", { attrs: { text: "average" } }),
          _c("va-badge", { attrs: { text: "custom text" } }),
          _c("va-badge", { attrs: { text: "long long very long text" } })
        ],
        1
      ),
      _c("h3", [_vm._v("color")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-badge", { attrs: { text: "ok", color: "primary" } }),
          _c("va-badge", { attrs: { text: "average", color: "secondary" } }),
          _c("va-badge", { attrs: { text: "custom text", color: "success" } }),
          _c("va-badge", { attrs: { text: "text", color: "warning" } }),
          _c("va-badge", { attrs: { text: "text", color: "error" } }),
          _c("va-badge", { attrs: { text: "text", color: "purple" } })
        ],
        1
      ),
      _c("h3", [_vm._v("size")]),
      _c("v-divider", { staticClass: "mb-4" }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between my-4" },
        [
          _c("va-badge", { attrs: { text: "ok", size: "xs" } }),
          _c("va-badge", { attrs: { text: "average", size: "s" } }),
          _c("va-badge", { attrs: { text: "poor", size: "m" } }),
          _c("va-badge", { attrs: { text: "offline", size: "l" } }),
          _c("va-badge", { attrs: { text: "offline", size: "xl" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }