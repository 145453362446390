























import Vue from "vue";
// import VaChip from "@/components/atoms/VaChip.vue";
import VaStateBadge from "@/components/molecules/VaStateBadge.vue";

export default Vue.extend({
  components: {
    VaStateBadge,
  },
});
