var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.room && _vm.room.isCustomBuildEnabled
    ? _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle" }, [
            _vm._v(_vm._s(_vm.$t("roomSuperadmin.title")))
          ]),
          _c(
            "va-card",
            [
              _c(
                "v-form",
                {
                  ref: "superAdminForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.onInstall },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mt-4 flex align-center" },
                    [
                      _c("va-text-field", {
                        staticClass: "mr-6",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("common.buildUrl"),
                          dense: ""
                        },
                        model: {
                          value: _vm.buildUrl,
                          callback: function($$v) {
                            _vm.buildUrl = $$v
                          },
                          expression: "buildUrl"
                        }
                      }),
                      _c("va-button", {
                        attrs: {
                          submit: "",
                          disabled: !_vm.buildUrl,
                          cta: "submit",
                          loading: _vm.loading
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }