var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "analytics-top-bar",
        {
          attrs: {
            title: "Rooms Usage 75%",
            icon: "vi vi-chart-pie",
            "icon-color": "error"
          }
        },
        [
          _vm._v(" Average Usage is calculated by "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(
              " People count vs Capacity - Uptime vs Tot meeting duration - Meeting type "
            )
          ]),
          _vm._v(", Lorem ipsum dolor sit amet, consectetur adipiscing elit ")
        ]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-lines-chart", {
                    attrs: {
                      chartData: _vm.peopleCount,
                      title: "People count",
                      legend: "",
                      "hide-dots": "",
                      area: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "va-card",
                [
                  _c("va-bar-chart", {
                    attrs: {
                      chartData: _vm.uptimeDuration,
                      title: "Meeting Duration",
                      area: "",
                      "hide-dots": "",
                      legend: "",
                      stacked: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "va-card",
                [
                  _c("va-bar-chart", {
                    attrs: {
                      chartData: _vm.meetingType,
                      title: "Meeting Types",
                      "hide-dots": "",
                      legend: "",
                      area: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }