import apiService from "./apiService";
import axios from "axios";

export default {
  async get(roomId: string, range: number): Promise<any> {
    const url =
      apiService.performance.baseUrl() + "?podId=" + roomId + "&range=" + range;
    const res = await axios.get(url);
    return res.data;
  },
};
