var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "analytics" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("header", [
              _c("div", { staticClass: "va-title" }, [
                _vm._v(_vm._s(_vm.$t("common.analytics")))
              ]),
              _c("h5", { staticClass: "mt-2" }, [
                _vm._v(
                  " - " +
                    _vm._s(
                      _vm.room ? _vm.room.name : _vm.$t("common.allRooms")
                    ) +
                    " - "
                )
              ])
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c("span", { staticClass: "d-block my-2" }, [
                _vm._v(_vm._s(_vm.$t("common.range")))
              ]),
              _c(
                "v-btn-toggle",
                {
                  attrs: { color: "accent", dense: "", mandatory: "" },
                  model: {
                    value: _vm.range,
                    callback: function($$v) {
                      _vm.range = $$v
                    },
                    expression: "range"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.loading, text: "", value: "daily" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.daily")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.loading,
                        text: "",
                        value: "weekly"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.weekly")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.loading,
                        text: "",
                        value: "monthly"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.monthly")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "trends" },
        [
          _c("v-card-title", [
            _c("div", { staticClass: "va-title" }, [
              _vm._v(_vm._s(_vm.$t("common.trends")))
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                _vm._l(_vm.trendsCard, function(card, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      attrs: { cols: "12", sm: "6", md: "4", xl: "3" }
                    },
                    [
                      _c("va-kpi", {
                        attrs: {
                          value: Math.round(card.value),
                          icon: _vm.trendIcon(card.trend),
                          "icon-color": _vm.trendColor(
                            card.trend,
                            card.inverted
                          ),
                          title: card.label,
                          "trend-value":
                            card.trend !== -100
                              ? Math.round(card.trend) + "%"
                              : undefined,
                          trendColor: _vm.trendColor(card.trend, card.inverted)
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "charts" },
        [
          _c("v-card-title", [
            _c("div", { staticClass: "va-title" }, [
              _vm._v(_vm._s(_vm.$t("common.charts")))
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { key: _vm.key },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: _vm.range === "daily" ? "6" : "12",
                        md: _vm.range === "daily" ? "4" : "12"
                      }
                    },
                    [
                      _vm.chartsData.instantBookedChart
                        ? _c("chart", {
                            attrs: {
                              "show-total": "",
                              loading: _vm.loading,
                              type:
                                _vm.range === "daily"
                                  ? "doughnut"
                                  : "vertical-bar",
                              data: _vm.chartsData.instantBookedChart,
                              title: _vm.$t("analytics.instantsVsBooked"),
                              yTitle: _vm.$t("analytics.meetingsCount"),
                              stacked: _vm.range === "monthly"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: _vm.range === "daily" ? "6" : "12",
                        md: _vm.range === "daily" ? "4" : "12"
                      }
                    },
                    [
                      _c("chart", {
                        attrs: {
                          "show-total": "",
                          loading: _vm.loading,
                          type:
                            _vm.range === "daily" ? "doughnut" : "vertical-bar",
                          data: _vm.chartsData.offlineOnlineChart,
                          title: "Offline Meetings vs Online Meetings",
                          yTitle: "Meetings count",
                          stacked: _vm.range === "monthly"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: _vm.range === "daily" ? "6" : "12",
                        md: _vm.range === "daily" ? "4" : "12"
                      }
                    },
                    [
                      _c("chart", {
                        attrs: {
                          "show-total": "",
                          loading: _vm.loading,
                          type:
                            _vm.range === "daily" ? "doughnut" : "vertical-bar",
                          data: _vm.chartsData.earlyLateChart,
                          title: _vm.$t("analytics.earlyVsLate"),
                          yTitle: _vm.$t("analytics.meetingsCount"),
                          stacked: _vm.range === "monthly"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: _vm.range === "daily" ? "6" : "12",
                        md: _vm.range === "daily" ? "4" : "12"
                      }
                    },
                    [
                      _c("chart", {
                        attrs: {
                          "show-total": "",
                          loading: _vm.loading,
                          type:
                            _vm.range === "daily" ? "doughnut" : "vertical-bar",
                          data: _vm.chartsData.airServerProtocolsChart,
                          title: _vm.$t(
                            "analytics.wirelessPresentationProtocols"
                          ),
                          yTitle: _vm.$t("analytics.protocolsCount"),
                          stacked: _vm.range === "monthly"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: _vm.range === "daily" ? "6" : "12",
                        md: _vm.range === "daily" ? "4" : "12"
                      }
                    },
                    [
                      _c("chart", {
                        attrs: {
                          "show-total": "",
                          loading: _vm.loading,
                          type:
                            _vm.range === "daily" ? "doughnut" : "vertical-bar",
                          data: _vm.chartsData.videoConferenceChart,
                          title: _vm.$t("analytics.videoConferencingSystems"),
                          yTitle: _vm.$t("analytics.systemsCount"),
                          stacked: _vm.range !== "daily"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: _vm.range === "daily" ? "6" : "12",
                        md: _vm.range === "daily" ? "4" : "12"
                      }
                    },
                    [
                      _c("chart", {
                        attrs: {
                          "show-total": "",
                          loading: _vm.loading,
                          type:
                            _vm.range === "daily" ? "doughnut" : "vertical-bar",
                          data: _vm.chartsData.meetingsTypesChart,
                          title: _vm.$t("analytics.meetingTypes"),
                          yTitle: _vm.$t("analytics.meetingTypesCount"),
                          stacked: _vm.range === "monthly"
                        }
                      })
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("chart", {
                            attrs: {
                              "show-total": "",
                              loading: _vm.loading,
                              type:
                                _vm.range === "daily"
                                  ? "doughnut"
                                  : "vertical-bar",
                              data: _vm.chartsData.applicationsChart,
                              title: "Applications",
                              yTitle: "Applications count",
                              stacked: _vm.range !== "daily"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: _vm.range === "daily" ? "6" : "12",
                        md: _vm.range === "daily" ? "4" : "12"
                      }
                    },
                    [
                      _vm.range === "daily"
                        ? _c("chart", {
                            attrs: {
                              "show-total": "",
                              loading: _vm.loading,
                              type: "doughnut",
                              data: _vm.chartsData.peopleCountChart,
                              title: _vm.$t("common.peopleCount")
                            }
                          })
                        : _c(
                            "div",
                            [
                              _c("heat-map-chart", {
                                attrs: {
                                  data: _vm.chartsData.peopleCountChart,
                                  title: _vm.$t("common.peopleCount"),
                                  "show-legend": true,
                                  loading: _vm.loading
                                }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _vm.$route.params.id
                    ? _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: _vm.range === "daily" ? "6" : "12",
                            md: _vm.range === "daily" ? "4" : "12"
                          }
                        },
                        [
                          _vm.range !== "daily"
                            ? _c("div", [_c("capacity-performance")], 1)
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }