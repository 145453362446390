var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-container" },
    [
      _vm.title
        ? _c("div", { staticClass: "va-subtitle" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _c("va-bar", {
        attrs: { chartData: _vm.computedData, options: _vm.computedOptions }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }