












































import Vue from "vue";
import VaKpi from "@/components/molecules/VaKpi.vue";
import VaDoughnutChart from "@/components/organisms/charts/VaDoughnutChart.vue";
import VaBarChart from "@/components/organisms/charts/VaBarChart.vue";

export default Vue.extend({
  name: "home_dashboard",

  components: {
    VaKpi,
    VaDoughnutChart,
    VaBarChart,
  },
  data() {
    return {
      ovarallState: {
        labels: ["Ok", "Warning", "Critical", "Offline"],
        datasets: [
          {
            backgroundColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.error,
              "#3A4047",
            ],
            borderColor: [
              this.$vuetify.theme.currentTheme.success,
              this.$vuetify.theme.currentTheme.warning,
              this.$vuetify.theme.currentTheme.error,
              "#3A4047",
            ],
            data: [10, 5, 3, 6],
          },
        ],
      },
      meetingCount: {
        labels: ["12am-6am", "6am-129m", "12pm-18pm", "18pm-12am"],
        datasets: [
          {
            backgroundColor: [
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.8)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.4)",
            ],
            borderColor: [
              "rgba(59, 128, 223,0.3)",
              "rgba(59, 128, 223,0.8)",
              "rgba(59, 128, 223,0.6)",
              "rgba(59, 128, 223,0.4)",
            ],
            data: [1, 30, 20, 6],
          },
        ],
      },
    };
  },
});
