









































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";

export default Vue.extend({
  name: "UserDataForm",
  components: {
    VaButton,
    VaTextField,
  },
  mixins: [errorMixin, validationMixin],
  computed: {
    user() {
      return this.$store.getters["session/user"];
    },
  },
  methods: {
    onSubmit() {
      if (
        (
          this.$refs.userDataForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        this.$store.dispatch("session/updateUserData", {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
        });
      }
    },
  },
});
