var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.language")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c("v-select", {
            staticClass: "mt-4",
            attrs: {
              outlined: "",
              dense: "",
              attach: "",
              items: _vm.languagesOptions,
              "item-text": "text",
              "item-value": "value",
              label: _vm.$t("common.language"),
              "hide-details": "",
              rules: [_vm.required]
            },
            on: { change: _vm.onChange },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-img", {
                      staticClass: "mr-2",
                      attrs: { "max-width": "20", src: item.icon }
                    }),
                    _vm._v(" " + _vm._s(item.text) + " ")
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-img", {
                      staticClass: "mr-2",
                      attrs: { "max-width": "20", src: item.icon }
                    }),
                    _vm._v(" " + _vm._s(item.text) + " ")
                  ]
                }
              }
            ]),
            model: {
              value: _vm.fields.SelectedLanguage,
              callback: function($$v) {
                _vm.$set(_vm.fields, "SelectedLanguage", $$v)
              },
              expression: "fields.SelectedLanguage"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }