import axios from "axios";
import apiService from "./apiService";
import { DashboardData, DashboardTopRoom } from "@/models/dashboard";
import { AnalyticDynamicChartRawData } from "@/models/analytics";

export default {
  async get(): Promise<DashboardData> {
    const res = await axios.get(apiService.dashboard.baseUrl());
    return res.data;
  },
  async topRooms(range = ""): Promise<Array<DashboardTopRoom>> {
    let url = apiService.dashboard.topRoomsChart();
    if (range) {
      url += "?range=" + range;
    }
    const res = await axios.get(url);
    return res.data.data as Array<DashboardTopRoom>;
  },
  async meetingCount(range = ""): Promise<AnalyticDynamicChartRawData> {
    let url = apiService.dashboard.meetingCountChart();
    if (range) {
      url += "?range=" + range;
    }
    const res = await axios.get(url);
    return res.data as AnalyticDynamicChartRawData;
  },
};
