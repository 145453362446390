import { RoomCapacity, RoomCapacityTooltip } from "@/models/capacity";
import { CHART_PALETTES, ChartData } from "@/models/analytics";
import { DateTime } from "luxon";
import i18n from "@/i18n";

export default {
  buildDatasets(
    roomCapacityData: Array<RoomCapacity>,
    range: number
  ): ChartData {
    const min = [] as Array<number>;
    const max = [] as Array<number>;
    const cap = [] as Array<number>;
    const meetingData = [] as Array<RoomCapacityTooltip>;

    const labels: Array<string> = []; // tutti i timestamp
    for (let i = 1; i <= range; i++) {
      labels.push(`Meeting ${i}`);
    }
    if (roomCapacityData.length > 0) {
      roomCapacityData.forEach((rc: RoomCapacity) => {
        min.push(rc.peopleMin);
        max.push(rc.peopleMax);
        cap.push(rc.peopleCap);
        meetingData.push({
          "Meeting title": rc.meetingTitle,
          "People min": rc.peopleMin,
          "People max": rc.peopleMax,
          Capacity: rc.peopleCap,
          Start: DateTime.fromMillis(rc.initDate).toFormat("dd LLL hh:mm"),
          Finish: DateTime.fromMillis(rc.finishDate).toFormat("dd LLL hh:mm"),
        });
      });
    } else {
      min.push(0);
      max.push(0);
      cap.push(0);
    }

    return {
      labels: labels,
      datasets: [
        {
          label: i18n.t("common.capacityMin") as string,
          data: min,
          backgroundColor: CHART_PALETTES[0].backgrounds[0],
          borderColor: CHART_PALETTES[0].borders[0],
          borderWidth: 1.4,
          fill: false,
          capacityData: meetingData,
        },
        {
          label: i18n.t("common.capacityMax") as string,
          data: max,
          backgroundColor: CHART_PALETTES[0].backgrounds[1],
          borderColor: CHART_PALETTES[0].borders[1],
          borderWidth: 1.4,
          fill: false,
          capacityData: meetingData,
        },
        {
          label: i18n.t("common.capacity") as string,
          data: cap,
          backgroundColor: CHART_PALETTES[3].backgrounds[2],
          borderColor: CHART_PALETTES[3].borders[2],
          borderWidth: 1.4,
          fill: false,
          capacityData: meetingData,
        },
      ],
    };
  },
  getMaxPeople(arr: RoomCapacity[]): number {
    const result = Math.max(
      ...arr.map(function (rc) {
        return rc.peopleMax;
      })
    );
    // const result = Math.max.apply(
    //   Math,
    //   arr.map(function (rc) {
    //     return rc.peopleMax;
    //   })
    // );
    return result + 10;
  },
};
