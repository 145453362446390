var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "heat-map-chart", class: _vm.classes },
    [
      !_vm.loading
        ? _c("v-card-title", [
            _vm.title
              ? _c("div", { staticClass: "va-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c("v-card-text", [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c("v-progress-circular", {
                  attrs: { color: "accent", indeterminate: "" }
                })
              ],
              1
            )
          : _c(
              "div",
              [
                _vm._t("beforeContent"),
                _vm.noData && false
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "d-inline-block light",
                        attrs: { dense: "", type: "info" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("common.noData")) + " ")]
                    )
                  : [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center align-center" },
                        [
                          _vm.legend
                            ? _c("div", { staticClass: "legend" }, [
                                _c("span", { staticClass: "less" }, [
                                  _vm._v(_vm._s(_vm.$t("common.less")))
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "items" },
                                  _vm._l(_vm.legend, function(item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "item",
                                        class: _vm.filter.includes(index)
                                          ? "disabled"
                                          : "",
                                        style: {
                                          border:
                                            "1px solid " + item.borderColor,
                                          //color: item.borderColor,
                                          background: _vm.linearBackground(
                                            item.backgroundColor,
                                            _vm.legend[index + 1]
                                              ? _vm.legend[index + 1]
                                                  .backgroundColor
                                              : undefined
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onLegendClick(index)
                                          }
                                        }
                                      },
                                      [
                                        _vm.showValue
                                          ? [
                                              _c(
                                                "span",
                                                { staticClass: "value" },
                                                [_vm._v(_vm._s(item.value))]
                                              ),
                                              index === _vm.legend.length - 1
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "value max"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.max) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                ),
                                _c("span", { staticClass: "more" }, [
                                  _vm._v(_vm._s(_vm.$t("common.more")))
                                ])
                              ])
                            : _vm._e(),
                          _c("v-switch", {
                            staticClass: "show-value-switch",
                            attrs: {
                              inset: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("common.showValue")
                            },
                            model: {
                              value: _vm.showValue,
                              callback: function($$v) {
                                _vm.showValue = $$v
                              },
                              expression: "showValue"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "overflow-shadow" }, [
                        _c(
                          "div",
                          { staticClass: "grid" },
                          _vm._l(_vm.datasets, function(dataset, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "dataset" },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(_vm._s(dataset.label))
                                ]),
                                _vm._l(dataset.data, function(cell, index2) {
                                  return _c(
                                    "div",
                                    {
                                      key: index2,
                                      staticClass: "cell",
                                      class: {
                                        selected: _vm.selection.includes(
                                          index + ":" + index2
                                        ),
                                        disabled: _vm.isCellDisabled(cell)
                                      },
                                      style: _vm.getStyle(cell),
                                      on: {
                                        click: function($event) {
                                          return _vm.onCellClick(index, index2)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "value"
                                                          },
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v(_vm._s(cell))]
                                                    ),
                                                    index ===
                                                    _vm.data.datasets.length - 1
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "dataset-label text--secondary"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.data.labels[
                                                                  index2
                                                                ]
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(dataset.label) +
                                                " | " +
                                                _vm._s(
                                                  _vm.data.labels[index2]
                                                ) +
                                                ": "
                                            ),
                                            _c("strong", [_vm._v(_vm._s(cell))])
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ]),
                      _vm.selection.length > 0
                        ? _c(
                            "div",
                            { staticClass: "selection" },
                            _vm._l(_vm.selection, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item" },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
              ],
              2
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }