


















































import Vue, { PropType } from "vue";
import { DashboardData } from "@/models/dashboard";
import VaKpi from "@/components/molecules/VaKpi.vue";

export default Vue.extend({
  name: "DashboardKpi",
  components: {
    VaKpi,
  },
  props: {
    dashboard: {
      type: Object as PropType<DashboardData>,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    onlineRoomsCount(): number {
      // ok+warning + critical
      return (
        this.dashboard?.overallStatus?.values[0] +
        this.dashboard?.overallStatus?.values[1] +
        this.dashboard?.overallStatus?.values[2]
      );
    },
    issuesRoomsCount(): number {
      // warning + critical
      return (
        this.dashboard?.overallStatus?.values[1] +
        this.dashboard?.overallStatus?.values[2]
      );
    },
    offlineRoomsCount(): number {
      return this.dashboard?.overallStatus?.values[3];
    },
    maintenanceRoomsCount(): number {
      return this.dashboard?.overallStatus?.values[4];
    },
    ongoingMeetingsCount(): number {
      return this.dashboard.meetings.ongoing;
    },
  },
});
