var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.instantMeetings")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c(
            "section",
            { staticClass: "launcher-session" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("homeLauncherConfiguration.meetingSessionTitle")
                    ) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("policiesFields.EnableTaskSwitcherOpen")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.EnableTaskSwitcherOpen,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "EnableTaskSwitcherOpen", $$v)
                  },
                  expression: "fields.EnableTaskSwitcherOpen"
                }
              }),
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  outlined: "",
                  attach: "",
                  rules: [_vm.required],
                  items: _vm.instantMeetingDefaultDurationOptions,
                  label: _vm.$t(
                    "homeLauncherConfiguration.instantMeetingDefaultDuration"
                  ),
                  "item-text": "text",
                  "item-value": "value"
                },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.fields.InstantMeetingDefaultDuration,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "InstantMeetingDefaultDuration", $$v)
                  },
                  expression: "fields.InstantMeetingDefaultDuration"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }