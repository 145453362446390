import {
  BORDER_WIDTH,
  CHART_PALETTES,
  MAX_BAR_THICKNESS,
  ChartData,
  ChartDataset,
} from "@/models/analytics";
import { DashboardTopRoom } from "@/models/dashboard";

export default {
  buildTopChartData(topRoomsData: Array<DashboardTopRoom>): ChartData {
    const labels = [] as Array<string>;
    const datasets = [] as Array<ChartDataset>;
    const datasetsLabels = ["Instant", "Booked", "BYOM"];
    const palette = CHART_PALETTES[0];
    topRoomsData.forEach((topRoom: DashboardTopRoom) => {
      labels.push(topRoom.podName);
    });

    datasetsLabels.forEach((datasetLabel: string, index: number) => {
      const values = [] as Array<number>;
      topRoomsData.forEach((data: DashboardTopRoom) => {
        values.push(data.values[index]);
      });
      datasets.push({
        backgroundColor: palette.backgrounds[index],
        borderColor: palette.borders[index],
        data: values,
        label: datasetsLabels[index],
        borderWidth: BORDER_WIDTH,
        maxBarThickness: MAX_BAR_THICKNESS,
      });
    });
    return {
      labels,
      datasets,
    } as ChartData;
  },
};
