var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "va-title text-center mb-6" }, [
        _vm._v(" " + _vm._s(_vm.$t("common.liveData")) + " ")
      ]),
      _c(
        "v-row",
        { staticClass: "mb-6" },
        [
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  sidebar: true,
                  styled: false,
                  value: "80%",
                  title: "Room usage"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  sidebar: true,
                  styled: false,
                  value: "9",
                  title: "Meetings"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  sidebar: true,
                  styled: false,
                  value: "27",
                  title: "People count"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  sidebar: true,
                  styled: false,
                  value: "4",
                  title: "Incidents"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-6" },
        [
          _c("va-doughnut-chart", {
            attrs: {
              chartData: _vm.ovarallState,
              title: "Current meeting rooms overall state"
            }
          })
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c("va-bar-chart", {
            attrs: { chartData: _vm.meetingCount, title: "Meeting count" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }