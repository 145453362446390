var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "room-displays" } },
    [
      _c("div", { staticClass: "va-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("roomDisplays.title")))
      ]),
      !_vm.init
        ? _c("v-progress-circular", {
            attrs: { color: "accent", indeterminate: "" }
          })
        : [
            _c("va-card", [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-center align-center",
                  style: { height: _vm.previewHeight + 100 + "px" }
                },
                _vm._l(_vm.displays, function(display, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "display",
                      class: {
                        primary: display.isPrimary,
                        screenshot: display.preview,
                        "ml-3": index > 0
                      },
                      style: _vm.displayStyle(display)
                    },
                    [
                      _vm.processing
                        ? _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              color: display.isPrimary ? "white" : "accent"
                            }
                          })
                        : _c("span", { staticClass: "index" }, [
                            _vm._v(
                              _vm._s(display.index ? display.index : index + 1)
                            )
                          ])
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-end mt-4" },
              [
                _c("va-button", {
                  attrs: {
                    disabled:
                      _vm.processing ||
                      !_vm.room.online ||
                      _vm.room.hasOngoingMeeting,
                    loading: _vm.processing,
                    cta: _vm.processing
                      ? _vm.$t("common.processing")
                      : _vm.$t("common.takeScreenshots"),
                    icon: "vi vi-camera"
                  },
                  on: { click: _vm.takeScreenshots }
                })
              ],
              1
            ),
            _c("div", { staticClass: "va-subtitle-s" }, [
              _vm._v(_vm._s(_vm.$t("common.displayInformation")))
            ]),
            _vm._l(_vm.displays, function(display, index) {
              return _c("va-card", { key: index, staticClass: "my-3" }, [
                _c("div", { staticClass: "d-flex justify-space-between" }, [
                  _c("div", { staticClass: "text-xl font-bold" }, [
                    _vm._v(" " + _vm._s(display.deviceName.substring(4)) + " ")
                  ]),
                  display.preview
                    ? _c(
                        "div",
                        { staticClass: "screenshot-actions" },
                        [
                          _c("va-button", {
                            staticClass: "mr-2",
                            attrs: {
                              size: "s",
                              cta: _vm.$t("common.view"),
                              icon: "vi vi-fullscreen"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openScreenshot(index)
                              }
                            }
                          }),
                          _c("va-button", {
                            attrs: {
                              size: "s",
                              cta: _vm.$t("common.download"),
                              icon: "vi vi-system-update"
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadScreenshot(display)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "display-container",
                      style: {
                        minWidth: _vm.maxWidth * _vm.scaleFactor + "px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "display",
                          class: {
                            primary: display.isPrimary,
                            screenshot: display.preview
                          },
                          style: _vm.displayStyle(display)
                        },
                        [
                          _vm.processing
                            ? _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  color: display.isPrimary ? "white" : "accent"
                                }
                              })
                            : _c("span", { staticClass: "index" }, [
                                _vm._v(
                                  _vm._s(
                                    display.index ? display.index : index + 1
                                  )
                                )
                              ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ml-6 flex-grow-1" },
                    [
                      _c(
                        "va-info-row",
                        { attrs: { label: _vm.$t("common.type") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.displayLabel(display.screenType)) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "va-info-row",
                        { attrs: { label: _vm.$t("common.model") } },
                        [_vm._v(_vm._s(display.model) + " ")]
                      ),
                      _c(
                        "va-info-row",
                        { attrs: { label: _vm.$t("common.refreshRate") } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(display.refreshRate) + " Hz")
                          ]),
                          display.refreshRate < 30
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "d-inline-flex align-center warning--text ml-1"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "warning" } },
                                    [_vm._v("mdi-alert-outline")]
                                  ),
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(_vm.$t("common.low").toUpperCase())
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "va-info-row",
                        { attrs: { label: _vm.$t("common.sizes") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(display.width) +
                              " x " +
                              _vm._s(display.height) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "va-info-row",
                        { attrs: { label: _vm.$t("common.virtualSizes") } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(display.virtualWidth) +
                              " x " +
                              _vm._s(display.virtualHeight) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }