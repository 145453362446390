


































import Vue from "vue";
import { Locale } from "@/models/locale";
import localeUtil from "@/utils/localeUtil";

export default Vue.extend({
  name: "localePicker",
  beforeMount() {
    this.locales = localeUtil.getSupportedLocales();
  },
  data() {
    return {
      locales: [] as Array<Locale>,
    };
  },
  computed: {
    currentLocale: {
      get(): Locale {
        return this.$store.getters["session/locale"];
      },
      set(locale: Locale): void {
        this.$store.commit("session/setLocale", locale);
        this.$notification.success(this.$i18n.t("localePicker.success"));
      },
    },
  },
});
