import apiService from "./apiService";
import axios from "axios";
import { RoomCapacity, RoomCapacityDeserializer } from "@/models/capacity";

export default {
  async get(roomId: string, range: number): Promise<RoomCapacity[]> {
    const url = `${apiService.analytic.baseUrl()}/meeting?range=${range}&podId=${roomId}`;
    const res = await axios.get(url);
    return res.data.meetingPeopleCount.map(RoomCapacityDeserializer);
  },
};
