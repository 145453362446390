











































































































import Vue, { PropType } from "vue";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import { ScheduleOptions, schedulerType } from "@/models/scheduler";
import { SelectOption } from "@/models";
import i18n from "@/i18n";

const days = [
  {
    id: 1,
    label: i18n.t("common.monday").toString(),
  },
  {
    id: 2,
    label: i18n.t("common.tuesday").toString(),
  },
  {
    id: 3,
    label: i18n.t("common.wednesday").toString(),
  },
  {
    id: 4,
    label: i18n.t("common.thursday").toString(),
  },
  {
    id: 5,
    label: i18n.t("common.friday").toString(),
  },
  {
    id: 6,
    label: i18n.t("common.saturday").toString(),
  },
  {
    id: 0,
    label: i18n.t("common.sunday").toString(),
  },
];
const months = [
  {
    id: 1,
    label: i18n.t("common.january").toString(),
  },
  {
    id: 2,
    label: i18n.t("common.february").toString(),
  },
  {
    id: 3,
    label: i18n.t("common.march").toString(),
  },
  {
    id: 4,
    label: i18n.t("common.april").toString(),
  },
  {
    id: 5,
    label: i18n.t("common.may").toString(),
  },
  {
    id: 6,
    label: i18n.t("common.june").toString(),
  },
  {
    id: 7,
    label: i18n.t("common.july").toString(),
  },
  {
    id: 8,
    label: i18n.t("common.august").toString(),
  },
  {
    id: 9,
    label: i18n.t("common.september").toString(),
  },
  {
    id: 10,
    label: i18n.t("common.october").toString(),
  },
  {
    id: 11,
    label: i18n.t("common.november").toString(),
  },
  {
    id: 12,
    label: i18n.t("common.december").toString(),
  },
];
const montDays = [] as SelectOption[];
for (let i = 1; i < 32; i++) {
  montDays.push({ id: i, label: i.toString() });
}
montDays.push({
  id: -1,
  label: i18n.t("common.lastDay").toString(),
});

export default Vue.extend({
  name: "CommandsSchedule",
  components: { DateTimePicker },
  props: {
    value: {
      type: Object as PropType<ScheduleOptions>,
      required: true,
    },
  },
  data() {
    return {
      options: {} as ScheduleOptions,
      schedulerRepeatOptions: [
        {
          id: "NEVER",
          label: this.$t("common.never"),
        },
        {
          id: "DAILY",
          label: this.$t("common.daily"),
        },
        {
          id: "WEEKLY",
          label: this.$t("common.weekly"),
        },
        {
          id: "MONTHLY",
          label: this.$t("common.monthly"),
        },
      ] as { id: schedulerType; label: string }[],
      recurOptions: {
        days: days,
        months: months,
        montDays: montDays,
      },
    };
  },
  methods: {},
  computed: {},
  mounted() {
    this.options = this.value;
  },
  watch: {
    value: function (newVal) {
      this.options = newVal;
    },
    options: function (newVal) {
      this.$emit("input", newVal);
    },
  },
});
