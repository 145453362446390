var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-switch", {
    staticClass: "pl-2",
    attrs: {
      dense: "",
      inset: "",
      color: _vm.color,
      label: _vm.text,
      disabled: _vm.disabled
    },
    on: {
      change: function($event) {
        return _vm.$emit("input", _vm.mutableVal)
      }
    },
    model: {
      value: _vm.mutableVal,
      callback: function($$v) {
        _vm.mutableVal = $$v
      },
      expression: "mutableVal"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }