var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { attrs: { id: "home" } },
        [
          _vm.dashboard
            ? _c("dashboard-kpi", {
                staticClass: "mb-4",
                attrs: { dashboard: _vm.dashboard }
              })
            : _vm._e(),
          _c("rooms-map", {
            staticClass: "mb-6",
            staticStyle: { height: "350px" },
            attrs: { markers: _vm.markers }
          }),
          _c(
            "section",
            { staticClass: "overall-state" },
            [
              _c(
                "v-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: 7 } },
                    [
                      _c(
                        "chart",
                        {
                          attrs: {
                            "show-total": "",
                            loading: _vm.roomsOverallStateLoading,
                            type: "vertical-bar",
                            data: _vm.roomsOverallStateChart,
                            title: _vm.$t("index.roomsOverallState"),
                            yTitle: _vm.$t("common.roomsCount"),
                            stacked: _vm.roomsOverallStateRange === "monthly"
                          }
                        },
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              staticClass: "range-filter",
                              attrs: {
                                slot: "afterTitle",
                                dense: "",
                                mandatory: ""
                              },
                              slot: "afterTitle",
                              model: {
                                value: _vm.roomsOverallStateRange,
                                callback: function($$v) {
                                  _vm.roomsOverallStateRange = $$v
                                },
                                expression: "roomsOverallStateRange"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { small: "", value: "weekly" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("common.weekly")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                { attrs: { small: "", value: "monthly" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("common.monthly")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: 5 } },
                    [
                      _c(
                        "chart",
                        {
                          attrs: {
                            type: "doughnut",
                            loading: _vm.currentRoomsOverallStateLoading,
                            data: _vm.currentRoomsOverallState,
                            title: _vm.$t("index.currentRoomsOverallState"),
                            "show-total": ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-6 text-center",
                              attrs: { slot: "afterTitle" },
                              slot: "afterTitle"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 mb-4",
                                  attrs: {
                                    small: "",
                                    depressed: "",
                                    color: "accent",
                                    disabled: !_vm.overallStateAverage
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        "/rooms?overallState=average"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.warning")))
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("mdi-arrow-right")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 mb-4",
                                  attrs: {
                                    small: "",
                                    color: "accent",
                                    depressed: "",
                                    disabled: !_vm.overallStatePoor
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        "/rooms?overallState=poor"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.critical")))
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("mdi-arrow-right")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 mb-4",
                                  attrs: {
                                    small: "",
                                    color: "accent",
                                    depressed: "",
                                    disabled: !_vm.overallStateOffline
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        "/rooms?overallState=offline"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.offline")))
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("mdi-arrow-right")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", sm: 12 } }, [
                _c(
                  "section",
                  { staticClass: "counts" },
                  [
                    _vm.meetingCountRange === "daily"
                      ? _c(
                          "chart",
                          {
                            attrs: {
                              "show-total": "",
                              loading: _vm.meetingCountLoading,
                              type: "doughnut",
                              data: _vm.meetingCountData,
                              title: _vm.$t("common.meetingCount")
                            }
                          },
                          [
                            _c(
                              "v-btn-toggle",
                              {
                                staticClass: "range-filter",
                                attrs: {
                                  slot: "afterTitle",
                                  dense: "",
                                  mandatory: ""
                                },
                                slot: "afterTitle",
                                model: {
                                  value: _vm.meetingCountRange,
                                  callback: function($$v) {
                                    _vm.meetingCountRange = $$v
                                  },
                                  expression: "meetingCountRange"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", value: "daily" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("common.daily")) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", value: "weekly" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.weekly")) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", value: "monthly" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.monthly")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "heat-map-chart",
                          {
                            attrs: {
                              data: _vm.meetingCountData,
                              title: _vm.$t("common.meetingCount"),
                              "show-legend": true,
                              loading: _vm.meetingCountLoading
                            }
                          },
                          [
                            _c(
                              "v-btn-toggle",
                              {
                                staticClass: "range-filter",
                                attrs: {
                                  slot: "beforeContent",
                                  dense: "",
                                  mandatory: ""
                                },
                                slot: "beforeContent",
                                model: {
                                  value: _vm.meetingCountRange,
                                  callback: function($$v) {
                                    _vm.meetingCountRange = $$v
                                  },
                                  expression: "meetingCountRange"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", value: "daily" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("common.daily")) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", value: "weekly" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.weekly")) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", value: "monthly" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.monthly")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "12", sm: 12 } }, [
                _c(
                  "section",
                  { staticClass: "top-rooms" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "chart",
                              {
                                attrs: {
                                  loading: _vm.topRoomsLoading,
                                  type: "horizontal-bar",
                                  data: _vm.topRoomsChart,
                                  title: _vm.$t("index.topRooms"),
                                  xTitle: _vm.$t("common.meetingsCount"),
                                  stacked: "",
                                  click: _vm.roomAnalytic,
                                  "show-total": ""
                                }
                              },
                              [
                                _c(
                                  "v-btn-toggle",
                                  {
                                    staticClass: "range-filter",
                                    attrs: {
                                      slot: "afterTitle",
                                      dense: "",
                                      mandatory: ""
                                    },
                                    slot: "afterTitle",
                                    model: {
                                      value: _vm.topRoomsRange,
                                      callback: function($$v) {
                                        _vm.topRoomsRange = $$v
                                      },
                                      expression: "topRoomsRange"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      { attrs: { small: "", value: "daily" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.daily")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      { attrs: { small: "", value: "weekly" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.weekly")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { small: "", value: "monthly" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.monthly")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }