var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center mb-6" },
        [
          _c("div", { staticClass: "va-title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ]),
          _vm.cta
            ? _c("va-button", {
                attrs: { cta: _vm.cta, icon: "vi vi-d-add" },
                on: {
                  click: function($event) {
                    return _vm.$emit("ctaClick")
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.search
        ? _c("va-text-field", {
            staticClass: "mb-4",
            attrs: {
              dense: "",
              appendIcon: "vi vi-zoom",
              label: _vm.$t("common.search")
            },
            on: { input: _vm.emitDebounced },
            model: {
              value: _vm.searchValue,
              callback: function($$v) {
                _vm.searchValue = $$v
              },
              expression: "searchValue"
            }
          })
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }