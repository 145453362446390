import * as Sentry from "@sentry/browser";
import store from "@/store";

const isDebugMode = process.env.VUE_APP_DEBUG_MODE === "true";

/* eslint-disable no-console */
function log(data: string): void {
  if (isDebugMode) {
    console.log(data);
  }
}

function info(data: string): void {
  if (isDebugMode) {
    console.info(data);
  }
}

function warn(data: string | Error): void {
  if (isDebugMode) {
    const user = store.getters["users/user"];
    if (typeof data === "string") {
      data = {
        name: "Warning",
        message: data,
      };
    }
    if (
      !process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES ||
      process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES === "false"
    ) {
      Sentry.captureException(data, {
        user: user,
      });
    }
    console.warn(data);
  }
}

function error(data: string | Error | ErrorEvent): void {
  const user = store.getters["users/user"];
  if (typeof data === "string") {
    data = new Error(data);
  }

  if (
    !process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES ||
    process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES === "false"
  ) {
    Sentry.captureException(data, {
      user: user,
    });
  }
  console.error(data);
}

export default { log, info, warn, error };
