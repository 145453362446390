var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("va-card", [
    _c(
      "div",
      { staticClass: "chart-container", class: _vm.chartClasses },
      [
        _vm.loading
          ? _c("v-progress-circular", {
              attrs: { color: "accent", indeterminate: "" }
            })
          : _c(
              "div",
              [
                _c(
                  "div",
                  [
                    _vm._t("beforeTitle"),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-space-between" },
                      [
                        _vm.title
                          ? _c("div", { staticClass: "va-title" }, [
                              _vm._v(_vm._s(_vm.title))
                            ])
                          : _vm._e(),
                        _vm.hasData && _vm.showTotal && _vm.type !== "doughnut"
                          ? _c("doughnut-chart", {
                              staticClass: "chart-total",
                              attrs: {
                                data: _vm.totalChartData,
                                legend: false,
                                tooltips: false
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._t("afterTitle")
                  ],
                  2
                ),
                _c("div", { ref: "legend", staticClass: "chart-legend" }),
                _vm.hasData && _vm.redraw
                  ? _c(_vm.chartComponent(), {
                      tag: "component",
                      staticClass: "chart",
                      class: _vm.type,
                      attrs: {
                        data: _vm.chartData,
                        stacked: _vm.stacked,
                        total: _vm.totalData,
                        yTitle: _vm.yTitle,
                        xTitle: _vm.xTitle,
                        click: _vm.click,
                        realtime: _vm.realtime,
                        realtimeCallback: _vm.realtimeCallback
                      },
                      on: { legend: _vm.setLegend }
                    })
                  : _c(
                      "v-alert",
                      {
                        staticClass: "d-inline-block light",
                        attrs: { dense: "", type: "info" }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.noData")) + " ")]
                    ),
                _vm._t("footer")
              ],
              2
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }