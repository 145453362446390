import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { FilterBy, OptionsTable, QueryResult } from "@/models/table";
import {
  RoomCommandData,
  RoomCommandParameterTypes,
  RoomCommandTypes,
  RoomDeserializer,
  RoomDetail,
  RoomSearchSerializer,
} from "@/models/room";
import store from "@/store";
import { GroupedPolicy } from "@/models/policy";
import { AnalyticChartRawData } from "@/models/analytics";
import {
  BuildData,
  buildDataDeserialize,
  // buildDataSerialize,
} from "@/models/builds";

export default {
  async get(): Promise<Array<RoomDetail>> {
    const res = await axios.get(apiService.room.baseUrl());
    return res.data.map((room: any) => {
      return RoomDeserializer(room) as RoomDetail;
    }) as Array<RoomDetail>;
  },

  async search(
    options: OptionsTable,
    searchString?: string,
    additionalFilters?: FilterBy[]
  ): Promise<QueryResult> {
    const data = RoomSearchSerializer(options, searchString, additionalFilters);
    const res: AxiosResponse = await axios.post(
      apiService.room.searchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },
  async detail(id: string): Promise<RoomDetail> {
    const res: any = await axios.get(apiService.room.baseUrl() + "/" + id);
    return RoomDeserializer(res.data);
  },
  //TODO: fix me
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  async put(id: string, data: any): Promise<RoomDetail> {
    const res = await axios.put(apiService.room.baseUrl() + "/" + id, data);
    return RoomDeserializer(res.data);
  },
  //TODO: fix me
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  async patch(id: string, data: any): Promise<RoomDetail> {
    const res = await axios.patch(apiService.room.baseUrl() + "/" + id, data);
    return RoomDeserializer(res.data);
  },
  async delete(id: string): Promise<void> {
    return await axios.delete(apiService.room.baseUrl() + "/" + id);
  },
  async enableMaintenance(roomId: string, note: string): Promise<void> {
    const url = apiService.room.enableMaintenanceUrl().replace(":id", roomId);
    await axios.post(url, { startNote: note });
  },
  async disableMaintenance(
    roomId: string,
    maintenanceId: string,
    note: string
  ): Promise<void> {
    const url = apiService.room
      .disableMaintenanceUrl()
      .replace(":id", roomId)
      .replace(":maintenanceId", maintenanceId);
    await axios.put(url, { closeNote: note });
  },
  async stopCommand(roomId: string, commandId: string): Promise<any> {
    const url =
      apiService.room.stopCommandUrl().replace(":id", roomId) + "/" + commandId;
    const res = await axios.get(url);
    return res.data;
  },
  async getCommand(roomId: string, commandId: string): Promise<any> {
    const url =
      apiService.room.getCommandUrl().replace(":id", roomId) + "/" + commandId;
    const res = await axios.get(url);
    return res.data;
  },
  async sendCommand(
    id: RoomCommandTypes,
    parameter?: RoomCommandParameterTypes
  ): Promise<any> {
    const room: RoomDetail = store.getters["room/room"];
    const url = apiService.room.sendCommandUrl().replace(":id", room.id);
    const data: RoomCommandData = {
      commandTypeId: id,
    };
    if (parameter !== null) {
      data.commandParameter = parameter;
    }
    const res = await axios.post(url, data);
    return res.data;
  },
  async policies(id: string): Promise<Array<GroupedPolicy>> {
    const url = apiService.room.policiesUrl().replace(":id", id);
    const res: any = await axios.get(url);
    return res.data;
  },
  async applyPolicy(roomId: string, policyId: string): Promise<boolean | null> {
    const url = apiService.room
      .applyPolicyUrl()
      .replace(":pod-id", roomId)
      .replace(":policy-id", policyId);
    const res = await axios.post(url, {});
    return res.data.isInPolicy;
  },
  async removePolicy(
    roomId: string,
    policyId: string
  ): Promise<boolean | null> {
    const url = apiService.room
      .removePolicyUrl()
      .replace(":pod-id", roomId)
      .replace(":policy-id", policyId);
    const res = await axios.delete(url, {});
    return res.data.isInPolicy;
  },
  async lastCommands(id: string): Promise<any> {
    const url = apiService.room.lastCommandsUrl().replace(":id", id);
    const res = await axios.get(url);
    return res.data;
  },
  applyAllPolicies(roomId: string): Promise<void> {
    const url = apiService.room.applyAllPolicies().replace(":id", roomId);
    return axios.post(url, {});
  },
  async overallState(range = "daily"): Promise<Array<AnalyticChartRawData>> {
    let url = apiService.room.overallState();
    if (range) {
      url += "?range=" + range;
    }
    const res = await axios.get(url);
    // return [];
    return res.data.podsStatus;
  },
  async calendars(id: string): Promise<{ id: string; name: string }[]> {
    const res: any = await axios.get(
      apiService.room.baseUrl() + "/" + id + "/calendars"
    );
    return res.data.map((calendar: { id: string; summary: string }) => {
      return {
        id: calendar.id,
        name: calendar.summary,
      };
    });
  },
  async searchBuilds(
    pass: string,
    internal: boolean,
    podId: string
  ): Promise<BuildData[]> {
    const res = await axios.post(
      apiService.room.searchBuildsUrl(),
      {
        podId: podId,
        useInternal: internal,
      },
      {
        headers: { "x-admin-key": pass },
      }
    );
    return res.data?.builds ? res.data.builds.map(buildDataDeserialize) : [];
  },
  async installBuild(buildPath: string, podId: string): Promise<void> {
    return axios.post(apiService.room.installBuildsUrl(), {
      podId: podId,
      buildUrl: buildPath,
    });
  },
};
