import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { OptionsTable, QueryResult } from "@/models/table";
import { Tag, TagSearchSerializer } from "@/models/tag";

export default {
  async search(
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = TagSearchSerializer(options, searchString);
    const res: AxiosResponse = await axios.post(
      apiService.tag.searchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },

  async get(): Promise<Array<Tag>> {
    //TODO REMOVE USAGES AND MOVE TO STORE
    const res = await axios.get(apiService.tag.baseUrl());
    return res.data as Array<Tag>;
  },
  async post(name: string): Promise<AxiosResponse> {
    return await axios.post(apiService.tag.baseUrl(), {
      name,
    });
  },
  async put(id: string, name: string): Promise<AxiosResponse> {
    return await axios.put(apiService.tag.baseUrl() + "/" + id, {
      name,
    });
  },
  async delete(id: string): Promise<AxiosResponse> {
    return await axios.delete(apiService.tag.baseUrl() + "/" + id);
  },
};
