var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "border-b py-2 d-flex justify-space-between" },
    [
      _vm.tooltip
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "", "max-width": "200" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c("div", { staticClass: "d-flex" }, [
                          _c("span", [_vm._v(_vm._s(_vm.label))]),
                          _c(
                            "div",
                            _vm._g({}, on),
                            [
                              _c("va-icon", {
                                staticClass: "ml-2",
                                attrs: { icon: "vi vi-c-question", size: "s" }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ],
                null,
                false,
                1306353064
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
          )
        : _c("span", [_vm._v(_vm._s(_vm.label))]),
      _c(
        "div",
        { class: [_vm.bold ? "font-bold" : ""] },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }