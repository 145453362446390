










































































































































































































































import Vue from "vue";
import ConfirmButton from "@/components/shared/ConfirmButton.vue";
import { User, UserForm, UserList } from "@/models/user";
import { LicenseDetail } from "@/models/license";
import { HeaderTable, OptionsTable, QueryTable } from "@/models/table";
import UserService from "@/services/userService";
import TableUtil from "@/utils/tableUtil";
import LicenseService from "@/services/licenseService";
import debounce from "lodash/debounce";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaNotification from "@/components/molecules/VaNotification.vue";

const defaultUser = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  showPassword: false,
} as UserForm;

export default Vue.extend({
  name: "UsersList",
  components: {
    VaNotification,
    VaNoDataTag,
    VaCard,
    VaTableTop,
    ConfirmButton,
    VaButton,
  },
  props: {},
  beforeMount() {
    this.runQuery();
  },
  data() {
    return {
      dialog: false,
      user: {} as UserForm,
      licenses: [] as Array<LicenseDetail>,
      license: "",
      headers: [
        {
          text: this.$i18n.t("common.email"),
          value: "email",
          sortable: false,
          filterable: true,
        },
        {
          text: this.$i18n.t("common.name"),
          value: "firstName",
          sortable: false,
          filterable: true,
        },
        {
          text: this.$i18n.t("common.licenseKey"),
          value: "license.key",
          sortable: false,
          filterable: true,
        },
        {
          text: this.$i18n.t("common.actions"),
          value: "actions",
          sortable: false,
          filterable: false,
          width: 100,
        },
      ] as HeaderTable[],
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      } as OptionsTable,
      count: 0,
      search: "",
      loading: false,
      saving: false,
      deleting: false,
      update: false,
      items: [] as Array<UserList>,
      footerProps: {
        "items-per-page-options": [5, 10, 15],
        "items-per-page-text": this.$t("common.itemsPerPage"),
        "page-text": "",
      },
      showPassword: false,
      status: "unChecked",
    };
  },
  methods: {
    reset(): void {
      this.$nextTick(() => {
        (this.$refs.observer as Vue & { reset: () => boolean }).reset();
      });
      this.license = "";
      this.user = { ...defaultUser };
      this.dialog = false;
      this.update = false;
    },
    async onSave(): Promise<void> {
      this.saving = true;
      try {
        if (this.update) {
          await UserService.put(this.user);
          this.$store.commit("notifications/displayNotification", {
            message: this.$t("usersList.updated"),
            type: "success",
          });
        } else {
          await UserService.post(this.license, this.user);
          this.$store.commit("notifications/displayNotification", {
            message: this.$t("usersList.created"),
            type: "success",
          });
        }
        this.reset();
        await this.runQuery();
      } catch (e) {
        this.$store.commit("notifications/displayNotification", {
          message: e.message,
          type: "error",
        });
        this.$sentry.capture(e, "UsersList", "onSave");
      } finally {
        this.saving = false;
      }
    },
    async onDelete(): Promise<void> {
      this.deleting = true;
      try {
        await UserService.delete(this.user.id);
        this.$notification.success(this.$t("usersList.deleted"));
        this.reset();
        await this.runQuery();
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "UsersList", "onDelete");
      } finally {
        this.deleting = false;
      }
    },
    onCreate(): void {
      this.dialog = true;
      this.status = "unChecked";
      this.loadLicenses();
    },
    onEdit(user: UserList): void {
      this.$store.commit("users/setCurrentUser", user);
      this.$router.push(`/users/${user.userId}`);
    },
    resetMail() {
      this.status = "unChecked";
      this.user = { ...defaultUser };
    },
    async onCheckMail(email: string): Promise<void> {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        this.status = await UserService.getStatus(email);
      }
    },
    async runQuery(): Promise<void> {
      //TODO: dummy value, change query
      this.loading = true;
      const options = this.options as OptionsTable;
      let query: QueryTable = {
        limit: options.itemsPerPage,
        page: options.page,
        sortBy: TableUtil.buildSortBy(this.options),
        filterBy: TableUtil.buildFilterBy(this.search, this.headers, []),
      } as QueryTable;
      try {
        const res = await UserService.search(query);
        this.count = res.count;
        this.items = res.items;
        // this.items = res.items.filter((user: UserList) => {
        //   return user.userId !== this.currentUser.id;
        // });
        this.footerProps["page-text"] = options.page + "/" + this.totalPages;
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "UsersList", "runQuery");
      } finally {
        this.loading = false;
      }
    },
    async loadLicenses(): Promise<void> {
      try {
        this.licenses = await (
          await LicenseService.get()
        ).filter((license: LicenseDetail) => {
          return !license.isRoom && !license.isPod;
        });
      } catch (e) {
        this.licenses = [] as Array<LicenseDetail>;
        this.$notification.error(e);
        this.$sentry.capture(e, "UsersList", "loadLicenses");
      }
    },
  },
  computed: {
    currentUser(): User {
      return this.$store.getters["session/user"];
    },
    totalItems(): number {
      return this.count;
    },
    totalPages(): number {
      const options = this.options as OptionsTable;
      const itemsPerPage = options.itemsPerPage;
      const totalItems = this.totalItems;
      if (totalItems == 0 || itemsPerPage == 0) {
        return 0;
      }
      return Math.ceil(totalItems / itemsPerPage);
    },
  },
  watch: {
    options: function (): void {
      this.runQuery();
    },
    search: debounce(function (this: any) {
      this.runQuery();
    }, 1500),
    filterUsers: function (): void {
      this.runQuery();
    },
  },
});
