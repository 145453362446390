export interface TokenData {
  token: string;
  refreshToken: string;
  expirationDate?: number;
  expirationDateReal: number;
}

export default {
  deserialize(input: Record<string, string | number>): TokenData {
    return {
      token: input.token,
      refreshToken: input.refreshToken,
      expirationDate: input.expirationUtc,
      expirationDateReal: input.refreshTokenExpirationUtc,
    } as TokenData;
  },
  serialize(input: TokenData): Record<string, string | number | undefined> {
    return {
      token: input.token,
      refreshToken: input.refreshToken,
      expirationUtc: input.expirationDate,
      refreshTokenExpirationUtc: input.expirationDateReal,
    };
  },
};
