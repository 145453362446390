











import Vue from "vue";

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    myValue: "",
  }),
  mounted() {
    this.myValue = this.value;
  },
  watch: {
    value: function (newVal) {
      this.myValue = newVal;
    },
  },
});
