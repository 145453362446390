var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "va-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("roomLastCommands.title")))
      ]),
      _vm.room
        ? _c(
            "va-card",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.room.lastCommands,
                  "hide-default-footer": "",
                  "mobile-breakpoint": "0"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "no-data",
                      fn: function() {
                        return [_c("va-no-data-tag")]
                      },
                      proxy: true
                    },
                    {
                      key: "item.startDate",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("formatDateTime")(item.startDate)) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.isScheduled",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.isScheduled
                            ? _c("v-icon", { attrs: { color: "success" } }, [
                                _vm._v(" mdi-check-circle ")
                              ])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.commandsData[item.status].label) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4203069577
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }