import { Group } from "@/models/group";
import { Marker } from "@/models/address";

export default {
  group2Marker(group: Group): Marker | undefined {
    return {
      id: group.id as string,
      name: group.name,
      address: group.address.formattedAddress,
      refObject: group,
      isLocationVerified: group.address.isLocationVerified,
      position: {
        lat: group.address.coordinates.lat,
        lng: group.address.coordinates.lng,
      },
    };
  },
};
