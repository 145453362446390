















































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { Group, groupConfig } from "@/models/group";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import clone from "lodash/clone";
import validationMixin from "@/components/mixins/validationMixin";
import dataTableMixin from "@/components/mixins/dataTableMixin";
// import RoomsMap from "@/components/organisms/RoomsMap.vue";
// import mapService from "@/services/mapService";
// import { Marker } from "@/models/address";
import Notifications from "@/components/organisms/Notifications.vue";

const emptyGroup = {
  name: "",
  description: "",
} as Partial<Group>;

export default Vue.extend({
  name: "Groups",
  mixins: [validationMixin, dataTableMixin],
  components: {
    // RoomsMap,
    VaButton,
    VaTextField,
    VaCard,
    VaDetailsButton,
    VaTableTop,
    MainColumns,
    VaNoDataTag,
    Notifications,
  },
  data() {
    return {
      storeName: "group",
      dialog: false,
      group: {} as Partial<Group>,
      tableConfig: groupConfig,
    };
  },
  methods: {
    async onSave(): Promise<void> {
      if (
        (
          this.$refs.newGroupForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        const idGroup = await this.$store.dispatch(
          "group/addGroup",
          this.group
        );
        this.$router.push(`/rooms/groups/${idGroup}`);
      }
    },
    onCreate(): void {
      this.group = clone(emptyGroup);
      this.dialog = true;
      this.$nextTick(() => {
        (
          this.$refs.newGroupForm as Vue & { resetValidation: () => void }
        ).resetValidation();
      });
    },
  },
  // computed: {
  //   markers(): Marker[] {
  //     return this.$store.getters["group/groups"].map(mapService.group2Marker);
  //   },
  // },
});
