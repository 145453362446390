import axios from "axios";
import apiService from "./apiService";
import { Analytics } from "@/models/analytics";

export default {
  async get(range = "daily", roomId = ""): Promise<Analytics> {
    let url = apiService.analytic.baseUrl();
    url += "/time?range=" + range;
    if (roomId) {
      url += "&podId=" + roomId;
    }
    const res = await axios.get(url);
    return res.data as Analytics;
  },
};
