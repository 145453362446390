

































































import Vue from "vue";
import AuthTemplate from "@/components/templates/Auth.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";
import VaNotification from "@/components/molecules/VaNotification.vue";
import GoogleSignIn from "@/components/organisms/auth/GoogleSignIn.vue";
import AppleSignIn from "@/components/organisms/auth/AppleSignIn.vue";
import MicrosoftSignIn from "@/components/organisms/auth/MicrosoftSignIn.vue";
import { SignUpData } from "@/services/authService";

export default Vue.extend({
  components: {
    VaNotification,
    VaButton,
    VaCard,
    AuthTemplate,
    VaTextField,
    GoogleSignIn,
    AppleSignIn,
    MicrosoftSignIn,
  },
  name: "SignUp",
  mixins: [errorMixin, validationMixin],
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
      } as SignUpData,
    };
  },
  computed: {
    success(): boolean {
      return this.$store.getters["session/signupSuccess"];
    },
    error(): boolean {
      return this.$store.getters["session/signupError"];
    },
    mfaEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_DISABLED ||
        process.env.VUE_APP_OAUTH_DISABLED === "false"
      );
    },
  },
  mounted() {
    this.$store.commit("session/setSignupSuccess", false);
    this.$store.commit("session/setSignupError", false);
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (
        (this.$refs.signUpForm as Vue & { validate: () => boolean }).validate()
      ) {
        await this.$store.dispatch("session/signUp", this.formData);
      }
    },
  },
});
