













































import Vue, { PropType } from "vue";
import L, { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import apiService from "@/services/apiService";
import { Marker } from "@/models/address";
import { Building } from "@/models/building";

export default Vue.extend({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    Vue2LeafletMarkercluster,
  },
  props: {
    markersList: {
      type: Array as PropType<Marker[]>,
      required: false,
    },
    showAll: {
      type: Boolean,
      required: false,
      default: true,
    },
    mapCenter: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      zoom: 6,
      // center: latLng(43.769562, 11.255814),
      attribution:
        '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        attributionControl: false,
      },
      showMap: true,
      markerObjects: [] as LMarker[],
      clusterOptions: {
        singleMarkerMode: false,
      },
      icon: L.divIcon({
        html: '<i class="vi vi-pin-3 primary--text" style="font-size: 2rem;"></i>',
        className: "myDivIcon",
      }),
    };
  },
  computed: {
    url(): string {
      return this.$store.getters["session/darkMode"]
        ? apiService.map.darkThemeUrl()
        : apiService.map.lightThemeUrl();
    },
    center(): (lat: number, lng: number) => void {
      return this.$props.mapCenter
        ? this.$props.mapCenter
        : latLng(43.769562, 11.255814);
    },
    markers(): Marker[] {
      if (this.$props.markersList?.length > 0) return this.$props.markersList;
      return this.$store.getters["building/buildings"].length > 0 &&
        this.$props.showAll
        ? this.$store.getters["building/buildings"]
            .filter((building: Building) => building.lat)
            .map((building: Building) => {
              if (building.lat) {
                return {
                  id: building.uuid,
                  name: building.name,
                  roomsCount: building.roomsCount,
                  position: { lat: building.lat, lng: building.lng },
                };
              }
            })
        : [];
    },
  },
  beforeMount() {
    this.$store.dispatch("building/getBuildings");
  },
  methods: {
    zoomUpdate(zoom: number) {
      this.currentZoom = zoom;
    },
    centerUpdate(center: (lat: number, lng: number) => void) {
      this.currentCenter = center;
    },
    // click: (e: Event) => console.log("clusterclick", e),
    // ready: (e: Event) => console.log("ready", e),
  },
});
