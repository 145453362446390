



















































































import Vue from "vue";
import AuthService from "@/services/authService";
import { User } from "@/models/user";
import { DashboardData } from "@/models/dashboard";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import LightSwitcher from "@/components/organisms/LightSwitcher.vue";
import VaDivider from "@/components/atoms/VaDivider.vue";
import InfoViewer from "@/components/organisms/InfoViewer.vue";
// import VaNotification from "@/components/molecules/VaNotification.vue";
// import VaCard from "@/components/molecules/VaCard.vue";
// import VaButton from "@/components/atoms/VaButton.vue";
// import CompanySelector from "@/components/organisms/CompanySelector.vue";

export default Vue.extend({
  name: "TopBar",
  // components: { VaAvatar, VaIcon, LightSwitcher, VaDivider, CompanySelector },
  components: {
    InfoViewer,
    VaAvatar,
    VaIcon,
    LightSwitcher,
    VaDivider,
  },
  data: () => ({
    displayMenu: false,
  }),
  methods: {
    onCompleteData(): void {
      this.$store.commit("session/setMissingInfoDismissed", true);
      this.$router.push("/profile").catch(() => {
        // empty
      });
    },
    toggleMenu(): void {
      this.displayMenu = !this.displayMenu;
    },
    onLogout(): void {
      AuthService.logout();
    },
  },
  computed: {
    // missingDataDialog: {
    //   get(): boolean {
    //     return (
    //       this.$store.getters["session/missingInfo"] &&
    //       !this.$store.getters["session/missingInfoDismissed"]
    //     );
    //   },
    //   set(): void {
    //     this.$store.commit("session/setMissingInfoDismissed", true);
    //   },
    // },
    dashboard(): DashboardData {
      return this.$store.getters["app/dashboard"];
    },
    show(): boolean {
      return this.$store.getters["app/showNavigation"];
    },
    user(): User {
      return this.$store.getters["session/user"];
    },
    loading(): boolean {
      return this.$store.getters["app/loading"];
    },
  },
});
