var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _vm._v(" " + _vm._s(_vm.val)),
          _c("br"),
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.applications")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c("v-select", {
            staticClass: "my-4",
            attrs: {
              outlined: "",
              dense: "",
              attach: "",
              items: _vm.availableApps,
              "item-text": "text",
              "item-value": "value",
              label: _vm.$t("policiesGroups.applications"),
              "hide-details": ""
            },
            on: { change: _vm.onAdd },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-img", {
                      staticClass: "mr-2",
                      attrs: { "max-width": "20", src: item.icon }
                    }),
                    _vm._v(" " + _vm._s(item.text) + " ")
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-img", {
                      staticClass: "mr-2",
                      attrs: { "max-width": "20", src: item.icon }
                    }),
                    _vm._v(" " + _vm._s(item.text) + " "),
                    _c("div", { staticClass: "flex-lg-grow-1" }),
                    item.fullItem.isDefault
                      ? _c("va-badge", {
                          staticClass: "ml-10",
                          attrs: {
                            size: "xs",
                            text: _vm.$t("podApplications.default"),
                            color: "secondary"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ]),
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          }),
          _vm._l(_vm.fields.RoomApplications, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "d-flex justify-space-between align-center my-2"
              },
              [
                _c("v-img", {
                  staticClass: "mr-3",
                  attrs: {
                    src: item.icon ? item.icon.path : _vm.logo,
                    height: 30,
                    width: 30,
                    "max-height": 30,
                    "max-width": 30
                  }
                }),
                _vm._v(" " + _vm._s(item.name) + " "),
                _c("div", { staticClass: "flex-lg-grow-1" }),
                item.isDefault
                  ? _c("va-badge", {
                      staticClass: "mx-4",
                      attrs: {
                        size: "xs",
                        text: _vm.$t("podApplications.default"),
                        color: "secondary"
                      }
                    })
                  : _vm._e(),
                _c("va-button", {
                  attrs: {
                    size: "s",
                    loading: _vm.$store.getters["podApplication/loading"],
                    cta: _vm.$t("common.delete"),
                    color: "error"
                  },
                  on: {
                    click: function($event) {
                      return _vm.onDelete(item)
                    }
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }