





























































import Vue from "vue";
import PerformanceChart from "@/components/dashboard/performance/PerformanceChart.vue";
import VaInfoRow from "@/components/molecules/VaInfoRow.vue";
import PerformanceService from "@/services/performanceService";
import {
  PERFORMANCE_RANGE_DATA,
  PERFORMANCE_STATS_DATA,
  PerformanceChartRawData,
  PerformanceRange,
  PerformanceRangeData,
} from "@/models/performance";
import PerformanceUtil from "@/utils/performanceUtil";
import cloneDeep from "lodash/cloneDeep";
import { ChartData } from "@/models/analytics";
import VaButton from "@/components/atoms/VaButton.vue";
import VaNotification from "@/components/molecules/VaNotification.vue";

export default Vue.extend({
  name: "Performance",
  components: {
    VaNotification,
    VaButton,
    VaInfoRow,
    PerformanceChart,
  },
  data() {
    return {
      range: undefined as unknown as PerformanceRangeData,
      ranges: cloneDeep(PERFORMANCE_RANGE_DATA),
      loading: false,
      waiting: false,
      offline: false,
      performanceStats: cloneDeep(PERFORMANCE_STATS_DATA),
      performanceChartData: [] as Array<PerformanceChartRawData>,
      poller: 0,
    };
  },
  props: {
    roomId: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    let r = sessionStorage.getItem("performanceChartRange") || "1";
    this.range = PERFORMANCE_RANGE_DATA.find((range) => {
      return range.value == (r as unknown as number);
    }) as PerformanceRangeData; //range update trigger getPerformance function
    this.attachPoller();
  },
  beforeDestroy() {
    this.detachPoller();
  },
  computed: {
    performanceDatasets(): ChartData {
      return PerformanceUtil.buildDatasets(this.performanceChartData);
    },
  },
  methods: {
    attachPoller() {
      this.poller = setInterval(async () => {
        if (!this.waiting) {
          await this.getPerformance(true);
        }
      }, 60000);
    },
    detachPoller() {
      clearInterval(this.poller);
    },
    async getPerformance(silent = false): Promise<void> {
      if (this.roomId) {
        this.loading = !silent;
        this.waiting = true;
        try {
          const res = await PerformanceService.get(
            this.roomId as string,
            (this.range as PerformanceRangeData).value
          );
          if (!res.isOnline && this.range.value != PerformanceRange.Weekly) {
            this.offline = true;
          } else {
            this.offline = false;
            this.performanceChartData = res.items;
          }
        } catch (e) {
          this.$store.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
          this.$sentry.capture(e, "Performance", "getPerformance");
        } finally {
          this.loading = false;
          this.waiting = false;
        }
      }
    },
  },
  watch: {
    range: async function (newRange): Promise<void> {
      sessionStorage.setItem("performanceChartRange", newRange.value);
      this.getPerformance();
    },
  },
});
